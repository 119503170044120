import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import store from "store";

import LinkButton from "components/forms/buttons/link_button";

const { Integrations: { Tripla } } = store;

function SuccessMessage({ propertyId, title, onClick }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transport: { settings: { protocol, server } } } = store;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CheckCircleTwoTone
        style={{ fontSize: "128px", marginBottom: 24 }}
        twoToneColor="rgb(171 204 171)"
      />
      <div style={{ textAlign: "center" }}>
        {t("applications_page:tripla_import:property_successfully_imported")}
      </div>
      <LinkButton
        onClick={() => {
          navigate(`/properties?searchQuery=${encodeURIComponent(title)}`);
        }}
      >
        {title}
      </LinkButton>
      <div style={{ textAlign: "center" }}>
        <a href={`${protocol}://${server}/api/v1/integrations/tripla_importer/download_mappings?property_id=${propertyId}`}>Download mappings</a>
      </div>
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <Button onClick={onClick}>{t("applications_page:tripla_import:import_another_property")}</Button>
      </div>
    </div>
  );
}

function ErrorMessage({ error, onClick }) {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ExclamationCircleTwoTone
        style={{ fontSize: "128px", marginBottom: 24 }}
        twoToneColor="rgb(255 178 178)"
      />
      <div style={{ textAlign: "center" }}>
        {error.message}
      </div>
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <Button onClick={onClick}>{t("applications_page:tripla_import:import_another_property")}</Button>
      </div>
    </div>
  );
}

function LoadingMessage() {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SyncOutlined
        spin
        style={{ fontSize: "128px", marginBottom: 24, color: "#eee" }}
      />
      <div style={{ textAlign: "center" }}>
        {t("applications_page:tripla_import:importing_property")}
      </div>
    </div>
  );
}

function ImportStep({ data: { triplaProperty }, startAgain }, _ref) {
  const [inProgress, setInProgress] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState({});

  useEffect(() => {
    async function importProperty() {
      const importResult = await Tripla.importProperty({ property: triplaProperty });

      setInProgress(false);
      setIsSuccess(true);

      setResult(importResult);
    }

    importProperty()
      .catch((e) => {
        setIsSuccess(false);
        setResult(e);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {inProgress
        ? <LoadingMessage />
        : isSuccess
          ? <SuccessMessage propertyId={result.propertyId} title={result.title} onClick={startAgain} />
          : <ErrorMessage error={result.errors} onClick={startAgain} />}
    </div>
  );
}

export default React.forwardRef(ImportStep);
