import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router";
import * as Sentry from "@sentry/react";

import ConfirmInvitePage from "pages/confirm_invite_page/confirm_invite_page";
import ConfirmRegistrationPage from "pages/confirm_registration_page/confirm_registration_page";
import PasswordRecoveryPage from "pages/password_recovery_page/password_recovery_page";
import RestorePasswordPage from "pages/restore_password_page/restore_password_page";
import SignInPage from "pages/sign_in_page/sign_in_page";
import SignUpPage from "pages/sign_up_page/sign_up_page";
import TokenExchangePage from "pages/token_exchange_page";

import UnauthorizedRouteRedirect from "redirects/unauthorized_route_redirect";
import buildNamedRoutes from "routing/build_named_routes";
import PublicAppRoutingContext from "routing/public_app_routing_context";

import Layout from "./layout";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

function PublicApp() {
  const routes = [{
    path: "/",
    element: <Layout />,
    children: [
      {
        name: "signIn",
        index: true,
        element: <SignInPage />,
      }, {
        name: "signUp",
        path: "/sign-up",
        element: <SignUpPage />,
      }, {
        name: "passwordRecovery",
        path: "/password-recovery",
        element: <PasswordRecoveryPage />,
      }, {
        name: "restorePassword",
        path: "/restore-password",
        element: <RestorePasswordPage />,
      }, {
        name: "confirmRegistration",
        path: "/confirm-registration",
        element: <ConfirmRegistrationPage />,
      }, {
        name: "confirmInvite",
        path: "/confirm-invite",
        element: <ConfirmInvitePage />,
      }, {
        name: "tokenExchangePage",
        path: "/auth/exchange",
        element: <TokenExchangePage />,
      }, {
        path: "*",
        element: <UnauthorizedRouteRedirect />,
      },
    ],
  }];

  const routesTree = buildNamedRoutes(routes);

  const router = sentryCreateBrowserRouter(routes);

  return (
    <PublicAppRoutingContext.Provider value={routesTree}>
      <RouterProvider router={router} />
    </PublicAppRoutingContext.Provider>
  );
}

export default PublicApp;
