import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { bookingsApiClient } from "store/api_clients/bookings";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import channelCodes from "config/constants/channels/channel_codes";

import { useProperty } from "data/use_property";
import { useRatePlans } from "data/use_rate_plans/_v2";
import { useRoomTypes } from "data/use_room_types/_v2";

import { AppForm } from "components/_v2/forms/hook_form";
import { Loading } from "components/_v2/states/loading";
import { showSuccessMessage } from "components/toasts/messages";

import { formatDate, formatDateTime } from "utils/dates";
import channexApiErrorParser from "utils/parse_api_errors";

import { NewBookingWizardFormBody } from "./new_booking_wizard_form_body/new_booking_wizard_form_body.tsx";
import { EditBookingFormBody } from "./edit_booking_form_body";
import { validationSchema } from "./validation_schema";

import { JsonValue } from "@/types";
import { Property } from "@/types/property";

const useUpdateMutation = (id?: string) => {
  return useMutation({
    mutationFn: (params: PartialFormBooking) => bookingsApiClient.update({ id, params }),
  });
};

const useCreateMutation = () => {
  return useMutation({
    mutationFn: (values: PartialFormBooking) => bookingsApiClient.create(values),
  });
};

const useBooking = (bookingId?: string, options = {}) => {
  return useQuery<Booking>({
    queryKey: ["booking", bookingId],
    queryFn: () => bookingsApiClient.get(bookingId),
    ...options,
  });
};

export type Booking = {
  id: string;
  status: string;
  propertyId: string;
  currency: string;
  arrivalDate: string;
  departureDate: string;
  otaName: string;
  otaReservationCode?: string,
  meta?: {
    source: string;
  }
  rooms: any[];
  customer: any;
  services: any[];
  deposits: any[];
}

export type FormBooking = {
  bookingDateRange: [string, string];
} & Omit<Booking, "arrivalDate" | "departureDate">;

export type PartialFormBooking = Partial<FormBooking>;

type Props = {
  bookingId?: string;
  initialValue?: Booking;
  initialStep?: number;
  errors?: any;
  onFinish: () => void;
}

export const BookingManageForm = ({ bookingId, initialValue, initialStep, errors, onFinish }: Props) => {
  const { t } = useTranslation();

  const updateMutation = useUpdateMutation(bookingId);
  const createMutation = useCreateMutation();

  const bookingQuery = useBooking(bookingId, { enabled: !!bookingId });

  const activePropertyId: string = useSelector(getActivePropertyId);
  const propertyId = bookingId ? bookingQuery.data?.propertyId : activePropertyId;

  const { data: property, isLoading: propertyIsLoading } = useProperty(propertyId);
  const { data: roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true, enabled: !!propertyId });
  const { data: ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { enabled: !!propertyId });
  const { data: multiOccRatePlans, isLoading: isMultiRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, enabled: !!propertyId });

  const isBookingLoading = bookingId && (bookingQuery.isLoading || isRoomTypesLoading || isRatePlansLoading || isMultiRatePlansLoading);
  const isPropertyLoading = propertyId && propertyIsLoading;

  if (isBookingLoading || isPropertyLoading) {
    return <Loading />;
  }

  if (!property) {
    throw new Error("cannot determine property");
  }

  const handleSubmit = (values: PartialFormBooking) => {
    if (bookingId) {
      return updateMutation.mutateAsync(values, {
        onSuccess: () => {
          onFinish();
          showSuccessMessage(t("bookings:messages:booking_updated"), { duration: 3 });
        },
      });
    }

    return createMutation.mutateAsync(values, {
      onSuccess: () => {
        onFinish();
        showSuccessMessage(t("bookings:messages:booking_created"), { duration: 3 });
      },
    });
  };

  const buildInitialBooking = (activeProperty: Property): PartialFormBooking => {
    return {
      propertyId: activeProperty.id,
      currency: activeProperty.currency,
    };
  }

  const uniqueCodes = [...new Set(Object.values(channelCodes).map((item) => item.code))];
  const otaNames = uniqueCodes.map((code) => channelCodes[code]).filter((channel) => !!channel);

  type PrepareBookingParams = {
    booking: Booking | undefined;
    property: Property;
    initialValue: Booking | undefined;
  }
  const prepareBooking = ({ booking, property, initialValue }: PrepareBookingParams): PartialFormBooking => {
    const toForm = ({ arrivalDate, departureDate, ...values }: Booking) => {
      const formBooking: PartialFormBooking = {
        ...values,
        bookingDateRange: [arrivalDate, departureDate],
      }

      if (formBooking.id) {
        // set correct status for fist-time booking edit
        if (formBooking.status === "new") {
          formBooking.status = "modified";
        }
      }

      return formBooking;
    }

    if (booking) {
      return toForm(booking);
    }

    if (initialValue) {
      return toForm(initialValue);
    }

    return buildInitialBooking(property);
  }

  return (
    <AppForm<PartialFormBooking>
      initialValue={prepareBooking({
        booking: bookingQuery.data,
        property,
        initialValue,
      })}
      validationSchema={validationSchema}
      errors={errors}
      fieldNames={[
        "propertyId",
        "otaName",
        "otaReservationCode",
        "meta.source",
        "bookingDateRange",
        "arrivalHour",
        "customer",
        "rooms",
        "services",
        "paymentCollect",
        "paymentType",
        "currency",
        "otaCommission",
        "deposits",
        "notes",
      ]}
      apiErrorParser={(error: unknown) => {
        const apiErrors: Record<string, string> = channexApiErrorParser(error, { camelCaseFields: true, root: "booking" });
        const { arrivalDate, departureDate, ...formErrors }: Record<string, string | string[]> = { ...apiErrors };

        if (arrivalDate || departureDate) {
          formErrors.bookingDateRange = [arrivalDate, departureDate];
        }

        return formErrors;
      }}
      fromForm={({ bookingDateRange, otaReservationCode, meta, ...values }: PartialFormBooking) => {
        const bookingForApi: Record<string, JsonValue> = {
          ...values,
          arrivalDate: bookingDateRange ? formatDate(bookingDateRange[0]) : null,
          departureDate: bookingDateRange ? formatDate(bookingDateRange[1]) : null,
        };

        if (values.deposits) {
          bookingForApi.depostis = values.deposits?.map((deposit) => ({
            ...deposit,
            chargedAt: deposit.chargedAt ? formatDateTime(deposit.chargedAt) : null,
          }));
        }

        if (values.otaName === channelCodes.Offline.code) {
          bookingForApi.meta = meta!;
        } else {
          bookingForApi.otaReservationCode = otaReservationCode!;
        }

        return bookingForApi;
      }}
      onSubmit={handleSubmit}
    >
      {!bookingId && (
        <NewBookingWizardFormBody
          isSubmitting={updateMutation.isPending || createMutation.isPending}
          initialStep={initialStep}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          multiOccRatePlans={multiOccRatePlans}
          otaNames={otaNames}
        />
      )}
      {bookingId && (
        <EditBookingFormBody
          bookingId={bookingId}
          isSubmitting={updateMutation.isPending || createMutation.isPending}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          multiOccRatePlans={multiOccRatePlans}
          otaNames={otaNames}
        />
      )}
    </AppForm>
  );
};
