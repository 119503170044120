import React from "react";

import { useChannels } from "data/use_channels/use_channels";
import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import { Loading } from "components/_v2/states/loading";

import { AvailabilityRuleForm } from "../../availability_rule_form/availability_rule_form";

import { useCreateAction } from "./use_create_action";

export function CreateAvailabilityRuleForm({ propertyId, onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction(propertyId);

  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });
  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { groupResults: true });
  const { channels, isLoading: isChannelsLoading } = useChannels(propertyId);

  const handleSubmit = async (values) => {
    const result = await mutateAsync({ attrs: values });

    onFinish(result);
  };

  if (isRatePlansLoading || isRoomTypesLoading || isChannelsLoading) {
    return <Loading />;
  }

  return (
    <AvailabilityRuleForm
      channels={channels}
      roomTypes={roomTypes}
      ratePlans={ratePlans}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
