import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { Menu } from "antd";
import { getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getUser } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import { features } from "config/feature_flags";

import useFeatures from "hooks/use_features";

import styles from "./application_links.module.css";

type AppLink = {
  path: string;
  label: string;
}

type Props = {
  handleCloseMenu: () => void;
}

export default function ApplicationLinks({ handleCloseMenu }: Props) {
  const { t } = useTranslation();
  const isMessagesInstalled = useSelector(getIsAppInstalled({ applicationCode: appCodes.messages }));
  const isPCIInstalled = useSelector(getIsAppInstalled({ applicationCode: appCodes.pci }));
  const isMobile = useMediaQuery({ query: "(max-width: 1275px)" });

  const { isFeatureEnabled } = useFeatures();
  const navigate = useNavigate();
  const location = useLocation();

  const appLinks: AppLink[] = [];

  if (isMessagesInstalled) {
    appLinks.push({ path: "/messages", label: t("general:links:messages") });
    appLinks.push({ path: "/reviews", label: t("general:links:reviews") });
  }

  if (isPCIInstalled) {
    appLinks.push({ path: "/pci", label: t("general:links:channex_pci") });
  }

  if (isFeatureEnabled(features.TRIPLA_IMPORT)) {
    appLinks.push({ path: "/tripla-import", label: t("general:links:tripla_import") });
  }

  const user = useSelector(getUser);
  appLinks.push({ path: "/apaleo-import", label: t("general:links:apaleo_import") });

  if (user.settings?.allow_apaleo_reserva_import) {
    appLinks.push({ path: "/apaleo-reserva-import", label: t("general:links:apaleo_import_reserva") });
  }

  appLinks.push({ path: "/mews-import", label: t("general:links:mews_import") });

  const items = [{
    key: "applications",
    label: <div className={styles.applicationLink}>{t("general:links:applications")}</div>,
    children: [
      {
        key: "/applications",
        onClick: () => { handleCloseMenu(); navigate("/applications"); },
        label: (
          <div>
            {t("general:links:manage_applications")}
          </div>
        ),
      },
      {
        type: "divider",
      },
      ...appLinks.map(({ path, label }) => (
        {
          key: path,
          onClick: () => { handleCloseMenu(); navigate(path); },
          label: (
            <div>
              {label}
            </div>
          ),
        }
      )),
    ],
  }];

  let selectedKeys: string[] = [];
  if (["/applications", ...appLinks.map(({ path }) => path)].includes(location.pathname)) {
    selectedKeys = [location.pathname];
  }

  return (
    <Menu selectedKeys={selectedKeys} items={items} rootClassName={styles.root} mode={isMobile ? "inline" : "horizontal"} />
  );
}
