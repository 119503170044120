import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";
import PropertyUserAccess from "drawers/property_user_access/property_user_access";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import withRouter from "routing/with_router";

class PropertyUserAccessDrawer extends Component {
  render() {
    const { t, params } = this.props;

    const userId = params.userId;
    const propertyId = params.propertyId;

    return (
      <ChannexDrawerRoutable
        title={t(userId ? "Edit User" : "Invite User")}
        dataTestid="invite_user_drawer"
      >
        {({ componentRef, handleCloseFromContent }) => (
          <DrawerFormWrapper withActions>
            <PropertyUserAccess
              userId={userId}
              propertyId={propertyId}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withTranslation()(withRouter(PropertyUserAccessDrawer));
