import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";

import withLogic from "pages/confirm_registration_page/components/confirm_registration_logic";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import styles from "./confirm_registration.module.css";

function ConfirmRegistrationComponent({ loading, isSuccess }) {
  const { t } = useTranslation();
  const { publicAppRoutes } = useRouting();

  const SuccessMessage = (
    <>
      {t("account_confirmation:success")}
      <br />
      <Link to={pathBuilder(publicAppRoutes.signIn)}>
        {t("public_pages:sign_in_link_for_confirmed_user")}
      </Link>
    </>
  );

  const ErrorMessage = <>{t("account_confirmation:error")}</>;

  return loading ? (
    <div className={styles.wrapperMessage}>
      <LoadingOutlined style={{ fontSize: "32px" }} />
    </div>
  ) : (
    <div className={styles.wrapperMessage}>{isSuccess ? SuccessMessage : ErrorMessage}</div>
  );
}

ConfirmRegistrationComponent.propTypes = {
  loading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default withLogic(ConfirmRegistrationComponent);
