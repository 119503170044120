import { useEffectOnce } from "hooks/use_effect_once";

import { analytics, FEATURE_ACTIONS } from "@/analytics";

const featureName = "bookings-no_show_report";

export const useFeatureTracking = (isOpen: boolean) => {
  useEffectOnce(() => {
    if (!isOpen) {
      return;
    }

    if (isOpen) {
      analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.INIT}`, {
        feature: featureName,
      });
    }
  }, [isOpen]);
}

export const trackFeatureComplete = () => {
  analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.COMPLETE}`, {
    feature: featureName,
  });
}
