import { AppForm } from "components/_v2/forms/hook_form";

import { RoomFormInner } from "./room_form_inner";
import { validationSchema } from "./validation_schema";

export const RoomForm = ({ initialValue, roomTypes, ratePlans, multiOccRatePlans, currency, propertyId, errors, onSubmit }) => {
  return (
    <AppForm
      initialValue={initialValue}
      errors={errors}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <RoomFormInner
        roomTypes={roomTypes}
        ratePlans={ratePlans}
        multiOccRatePlans={multiOccRatePlans}
        currency={currency}
        propertyId={propertyId}
      />
    </AppForm>
  );
};
