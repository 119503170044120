import { httpClient } from "store/api_clients/http_client";
import attributesExtractor from "store/utils/attributes_extractor";
import { convertToCamelCase } from "store/utils/case_converter";

export const channelsEventsApiClient = {
  initDownload: async ({ eventId }: { eventId: string }) => {
    const path = `channel_events/${eventId}/logs/export`;

    const response = await httpClient.send("POST", path);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  downloadLogs: async ({ eventId, token }: { eventId: string, token: string }) => {
    const path = `channel_events/${eventId}/logs/export/${token}`;

    const response = await httpClient._get(path);

    return response.blob();
  }
}
