import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Flex, Space, Tag } from "antd";
import { channelsApiClient } from "store/api_clients/channels";

import { Actions } from "components/menu/actions";
import { menuItem } from "components/menu/items/item";
import { removeMenuItem } from "components/menu/items/remove";
import { showFailureMessage, showSuccessMessage } from "components/toasts/messages";

const useActivateMutation = (channelId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => channelsApiClient.changeListingStatus(channelId, params),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["listings", channelId] });
      showSuccessMessage();
    },
    onError: showFailureMessage,
  });
};

export const ListingRow = ({ channelId, listing, onEditClick, onDefaultRulesClick, onDefaultPricingClick, onPricingSettingsClick, onDeleted }) => {
  const { t } = useTranslation();

  const { listingId, title, listingStatus, publishStatus } = listing;
  const isActive = listingStatus === "active";
  const { isPublished } = publishStatus;

  const activateMutation = useActivateMutation(channelId);

  const handleActivateToggleClick = () => {
    const payload = { listingId };
    payload.status = isActive ? "inactive" : "active";

    return activateMutation.mutateAsync(payload);
  };

  const menuItems = [{
    key: "edit",
    label: t("general:action:edit"),
    onClick: () => onEditClick(),
  }, {
    type: "divider",
  },
  menuItem({
    key: "activate",
    label: isActive ? t("general:action:deactivate") : t("general:action:activate"),
    confirm: true,
    onClick: handleActivateToggleClick,
  }), {
    type: "divider",
  }, {
    key: "default_rules",
    label: t("listings:headings:default_rules"),
    onClick: onDefaultRulesClick,
  }, {
    key: "default_pricing",
    label: t("listings:headings:default_pricing"),
    onClick: onDefaultPricingClick,
  }, {
    key: "pricing_settings",
    label: t("listings:headings:pricing_settings"),
    onClick: onPricingSettingsClick,
  },
  removeMenuItem({
    key: "remove",
    title: t("general:dialog:confirmation:content"),
    text: t("general:action:archive"),
    content: null,
    onRemove: () => activateMutation.mutateAsync({ listingId, status: "archived" }, {
      onSuccess: () => onDeleted(),
    }),
  })];

  return (
    <Flex style={{ padding: "0 16px" }} flex={1} justify="space-between" align="center">
      <div>
        {title}
      </div>
      <Space>
        <Tag color={isActive ? "green" : "red"}>{isActive ? t("common:dicts:active_status:active") : t("common:dicts:active_status:inactive")}</Tag>
        <Tag color={isPublished ? "green" : "red"}>{isPublished ? t("common:dicts:published:published") : t("common:dicts:published:unpublished")}</Tag>
        <Actions items={menuItems} />
      </Space>
    </Flex>
  );
};
