import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex } from "antd";

import { PrevButton } from "components/_v2/forms/buttons/prev";
import { SubmitButton } from "components/_v2/forms/buttons/submit";
import { FormTextArea } from "components/_v2/forms/fields/textarea";
import { AppForm, FormSchemaRenderer } from "components/_v2/forms/hook_form";
import { Legend } from "components/_v2/forms/legend";

import { useWizard, WizardSidebar } from "../wizard";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();

  const fields = [{
    render: () => <Legend marginTop={false} label={t("common:headings:notes")} />,
  }, {
    name: "notes",
    component: FormTextArea,
    label: t("common:terms:notes"),
  }];

  return (
    <FormSchemaRenderer
      dataTestid="notes-form"
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <PrevButton onClick={goPrev} />
              <SubmitButton dataTestid="wizard-submit-btn" loading={isSubmitting}>
                {t("common:actions:save")}
              </SubmitButton>
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step6 = ({ isSubmitting }) => {
  const { finish } = useWizard();
  const { setValue, getValues, formState } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        finish();
      }}
    >
      <StepFormInner isSubmitting={isSubmitting} />
    </AppForm>
  );
};
