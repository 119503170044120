import React, { Component } from "react";
import { DatePicker, Form } from "antd";
import { Field } from "formik";

import dayjs from "utils/dayjs";

import getFieldLayout from "../utils/get_field_layout";

import styles from "styles/form_in_drawer.module.css";

const DEFAULT_FORMAT = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

const RIGHT_ARROW = (
  <span
    style={{
      display: "inline-block",
      paddingTop: "2px",
      width: "14px",
      fill: "rgba(0, 0, 0, 0.25)",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 476.213 476.213" xmlSpace="preserve">
      <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 " />
    </svg>
  </span>
);

class FormikFormRangepicker extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  handleChange = (value, field, form) => {
    const { format = DEFAULT_FORMAT, onChange, name } = this.props;

    const formattedDate = value ? value.map((date) => date.format(format)) : value;

    return onChange
      ? onChange(formattedDate, field, form)
      : form.setFieldValue(name, formattedDate);
  };

  getValue = (fieldValue) => {
    return fieldValue ? fieldValue.map((date) => dayjs(date)) : undefined;
  };

  render() {
    const { name, label, view, errors, onBlur = () => {} } = this.props;
    const formItemLayout = getFieldLayout(view);

    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <Form.Item
              {...formItemLayout}
              {...FormikFormRangepicker.validation(errors)}
              label={label}
              data-testid={`rangepicker_${name}`}
            >
              <RangePicker
                value={this.getValue(field.value)}
                onChange={(value) => this.handleChange(value, field, form)}
                onBlur={onBlur}
                className={styles.buttonGroup}
                separator={RIGHT_ARROW}
              />
            </Form.Item>
          );
        }}
      </Field>
    );
  }
}

export default FormikFormRangepicker;
