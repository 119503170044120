import { useQuery } from "@tanstack/react-query";
import store from "store";

import getSortedArray from "utils/get_sorted_array";

const { RoomTypes } = store;

const DEFAULT_SORT_FIELD = "title";

export function useRoomTypes(propertyId, options = {}) {
  const {
    sorted = false,
    sortedBy = DEFAULT_SORT_FIELD,
    enabled = true,
  } = options;

  return useQuery({
    queryKey: ["roomTypes", propertyId],
    queryFn: () => RoomTypes.options({ property_id: propertyId }),
    enabled,
    select: (data) => {
      if (sorted || sortedBy) {
        return getSortedArray(data || [], sortedBy);
      }

      return data;
    },
  });
}
