import { useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

export function useDebouncedFunction(fn, delay) {
  const debouncedFn = useMemo(() => debounce(fn, delay), [fn, delay]);

  // Cancel any pending debounced calls when the component unmounts
  useEffect(() => {
    return () => {
      debouncedFn.cancel();
    };
  }, [debouncedFn]);

  return debouncedFn;
}
