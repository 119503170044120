import { forwardRef } from "react";
import { Input as AntInput } from "antd";
import currencyCodes from "currency-codes";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

const DEFAULT_DECIMAL_DIGITS = 10;

const isValidInput = (value, currency) => {
  if (value === "") {
    return true;
  }

  let decimalDigits = DEFAULT_DECIMAL_DIGITS

  if (currency) {
    const currencyDetails = currencyCodes.code(currency);
    decimalDigits = currencyDetails.digits;
  }

  const regex = decimalDigits === 0
    ? /^\d+$/
    : new RegExp(`^\\d+(\\.\\d{0,${decimalDigits}})?$`);

  return regex.test(value);
};

export const convertValueToCurrencyFormat = (value, currency) => {
  if (!value) {
    return value;
  }

  const currencyDetails = currencyCodes.code(currency);
  const { digits: decimalDigits } = currencyDetails;

  // Convert value to a float
  const numericValue = parseFloat(value);

  // Check if the value has a fractional part
  const isWholeNumber = numericValue % 1 === 0;

  // Format the number accordingly
  return isWholeNumber
    ? numericValue.toString() // No trailing zeros for whole numbers
    : numericValue.toFixed(decimalDigits); // Limit decimals for fractional numbers
};

export const CurrencyInput = forwardRef(({ currency, onChange, ...props } = {}, ref) => {
  return (
    <AntInput
      {...props}
      onChange={(e) => {
        const value = e.target.value;

        if (!isValidInput(value, currency)) {
          return;
        }

        onChange(e);
      }}
      ref={ref}
    />
  )
});

export const FormCurrency = forwardRef((props, ref) => {
  const {
    sharedProps,
    hookFormInputProps,
    antdFormFieldProps,
    inputProps: {
      currency,
      ...inputProps
    },
  } = parseProps(props);

  if (!currency) {
    console.error("Currency is required for FormCurrency", props);
    throw new Error("Currency is required for FormCurrency");
  }

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
        >
          <CurrencyInput
            {...inputProps}
            {...input}
            currency={currency}
            addonAfter={currency}
            ref={ref}
          />
        </AntdFormField>
      )}
    />
  );
});
