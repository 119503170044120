import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AdminWlEmailSettings } = store;

export function useDeleteAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => AdminWlEmailSettings.delete(id),
    onSuccess: () => {
      void queryClient.invalidateQueries("admin_wl_email_settings");
    },
  });
}
