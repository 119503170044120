import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AvailabilityRules } = store;

export function useCreateAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attrs }) => AvailabilityRules.create(propertyId, attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["availability_rules", propertyId],
      });
    },
  });
}
