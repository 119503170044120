import React from "react";
import { useNavigate , useOutletContext } from "react-router";
import qs from "qs";

import StateChangesDrawer from "./state_changes_drawer";

export const StateChangesDrawerRoutable = () => {
  const navigate = useNavigate();
  const { closePath, ratePlans, isLoadingRatePlans, roomTypes, isLoadingRoomTypes } = useOutletContext();

  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    comma: true,
    plainObjects: true,
  });

  const handleClose = () => {
    navigate(closePath);
  };

  const { property_id, log } = params;

  return (
    <StateChangesDrawer
      visible
      ratePlanId={log.rate_plan_id}
      roomTypeId={log.room_type_id}
      date={log.date}
      restriction={log.restriction}
      propertyId={property_id}
      ratePlans={ratePlans}
      isLoadingRatePlans={isLoadingRatePlans}
      roomTypes={roomTypes}
      isLoadingRoomTypes={isLoadingRoomTypes}
      onClose={handleClose}
    />
  );
};
