import _ from "lodash";

import { getAntdFieldProps } from "./antd_form_field";
import { getHookFormInputProps } from "./hook_form_input";

export const parseProps = (props) => {
  const hookFormInputProps = getHookFormInputProps(props);
  const antdFormFieldProps = getAntdFieldProps(props);

  const sharedProps = {
    name: props.name,
  };

  // all props except hook form and antd field props are input props
  const inputProps = _.omit(props, [
    // hook input props
    ...Object.keys(hookFormInputProps),

    // antd field props
    ...Object.keys(antdFormFieldProps),
    "fieldClassName",
    "grouped",
  ]);

  return {
    sharedProps,
    hookFormInputProps,
    antdFormFieldProps,
    inputProps,
  };
};
