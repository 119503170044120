import { validationMessages } from "config/constants/errors";

import { yup } from "utils/validation/yup";

export const validationSchema = yup.object().shape({
  roomTypeId: yup.string().required(validationMessages.required),
  ratePlanId: yup.string().required(validationMessages.required),
  occupancy: yup.object().shape({
    adults: yup.number().required(validationMessages.required),
    ages: yup.array().of(
      yup.number()
        .typeError(validationMessages.required)
        .required(validationMessages.required),
    ),
  }),
  days: yup.lazy((value) => yup.object(
    Object.keys(value || {}).reduce((acc, date) => {
      acc[date] = yup.string()
        .validNumber(validationMessages.number)
        .required(validationMessages.required);
      return acc;
    }, {}),
  )),
});
