import React, { forwardRef } from "react";

import CopyToClipboard from "../../../forms/buttons/copy_to_clipboard";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

export const IdWithCopy = ({ value }) => {
  return (
    <>
      {value}
      <CopyToClipboard text={value} />
    </>
  );
};

export const Id = forwardRef((props, ref) => {
  const { sharedProps, hookFormInputProps, antdFormFieldProps, inputProps } = parseProps(props);

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
        >
          <IdWithCopy
            {...inputProps}
            {...input}
            ref={ref}
          />
        </AntdFormField>
      )}
    />
  );
});
