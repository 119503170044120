import React from "react";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Form, Row, Tooltip } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import FormWeekdays from "components/forms/inputs/form_weekdays";
import RangePicker from "components/mobile/inventory/range_picker";

import styles from "./date_ranges.module.css";

function DateRanges(props) {
  const {
    t,
    dateRanges,
    dateWeekdays,
    isMobile,
    onDateChange,
    onRemoveRange,
    onWeekdayChange,
    onAddRange,
    defaultCurrentDate,
  } = props;

  const isDateDisabled = (date) => {
    return date.isBefore(defaultCurrentDate, "day");
  };

  return (
    <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:bulk_update:dates")}>
      {dateRanges.map((dateRange, index) => (
        <React.Fragment key={`dateRange-${index}`}>
          <div className={styles.dateRangeRow}>
            <RangePicker
              dataTestid="affected_days"
              isMobile={isMobile}
              disabledDate={isDateDisabled}
              onChange={onDateChange(index)}
            />
            <Tooltip placement="left" title={t("inventory_page:bulk_update:remove_range")}>
              <a
                data-testid="remove_date_range"
                className={styles.addRangeButton}
                onClick={onRemoveRange(index)}
                disabled={dateRanges.length === 1}
                href="#remove_range"
              >
                <DeleteOutlined />
              </a>
            </Tooltip>
          </div>
          <FormWeekdays
            view="vertical"
            name="weekdays"
            value={dateWeekdays[index]}
            onChange={(value) => onWeekdayChange(index, value)}
          />
        </React.Fragment>
      ))}
      <Row>
        <Col>
          <a data-testid="add_date_range" href="#add_range" onClick={onAddRange}>
            {t("inventory_page:bulk_update:add_range")}
          </a>
        </Col>
      </Row>
    </Form.Item>
  );
}

DateRanges.propTypes = {
  t: PropTypes.func.isRequired,
  dateRanges: PropTypes.array.isRequired,
  dateWeekdays: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onRemoveRange: PropTypes.func.isRequired,
  onWeekdayChange: PropTypes.func.isRequired,
  onAddRange: PropTypes.func.isRequired,
  defaultCurrentDate: PropTypes.instanceOf(Date),
};

export default DateRanges;
