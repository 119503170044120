import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button, Steps } from "antd";

import Flexbox from "../flexbox";

const { Step } = Steps;

const DESKTOP_MIN_WIDTH = 600;

export function Workflow({ initialData = {}, initialStep = 0, showStartAgainButton: showStartAgainButtonOpt, startAgainButtonTitle: startAgainButtonTitleOpt, steps }) {
  const { t } = useTranslation();

  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const stepRefs = useRef([...Array(steps.length)].fill(React.createRef()));
  const isDesktop = useMediaQuery({ minWidth: DESKTOP_MIN_WIDTH });

  const currentStepConfig = steps[currentStepIndex];

  const showBackButton = currentStepIndex > 0 && currentStepIndex !== steps.length - 1;
  const showNextButton = currentStepIndex < (steps.length - 1);
  const showStartAgainButton = showStartAgainButtonOpt && currentStepIndex === steps.length - 1;

  const [showButtonsFromStep, setShowButtonsFromStep] = useState(null);
  const showButtons = currentStepConfig.showButtons ?? true;

  const [startAgainButtonTitle, setStartAgainButtonTitle] = useState(null);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);

  const resetState = () => {
    setShowButtonsFromStep(null);
    setLoading(false);
    setNextButtonEnabled(true);
  };

  const handleStartAgainButtonClick = useCallback(() => {
    resetState();
    setCurrentStepIndex(0);
  }, [setCurrentStepIndex]);

  const handleBackButtonClick = useCallback(() => {
    resetState();
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex, setCurrentStepIndex]);

  const next = useCallback(() => {
    resetState();
    setCurrentStepIndex(currentStepIndex + 1);
  }, [currentStepIndex, setCurrentStepIndex]);

  const go = useCallback((stepId) => {
    resetState();
    const nextStepIndex = steps.findIndex((step) => step.id === stepId);
    setCurrentStepIndex(nextStepIndex);
  }, [steps, setCurrentStepIndex]);

  const enableNextButton = useCallback((state) => {
    setNextButtonEnabled(state);
  }, [setNextButtonEnabled]);

  const handleNextButtonClick = useCallback(async () => {
    setLoading(true);

    const currentStep = stepRefs.current[currentStepIndex];

    try {
      if (!currentStep.current.proceed) {
        next();
        return;
      }

      const result = await currentStep.current.proceed();

      // false is used to stay on same step, step should do navigation manually
      if (result === false) {
        return;
      }

      // navigate to specific step
      if (typeof result === "string") {
        return go(result);
      }

      // in other cases just render next step
      next();
    } catch {
      // noop
    } finally {
      setLoading(false);
    }
  }, [setLoading, stepRefs, currentStepIndex, go, next]);

  return (
    <Flexbox style={{ padding: 24 }}>
      {isDesktop && (
        <div>
          <Steps
            direction="vertical"
            current={currentStepIndex}
            style={{ height: 200 }}
          >
            {steps.map((step, i) => (
              <Step key={i} title={step.title} />
            ))}
          </Steps>
        </div>
      )}
      <Flexbox style={{ flexDirection: "column", flexWrap: "wrap", alignContent: "center" }}>
        <div style={{ maxWidth: "800px", width: "100%" }}>
          {currentStepConfig.render({
            ref: stepRefs.current[currentStepIndex],
            data,
            startAgain: handleStartAgainButtonClick,
            go,
            next,
            setData,
            showButtons: setShowButtonsFromStep,
            setStartAgainButtonTitle,
            enableNextButton,
          })}
        </div>
        {(showButtonsFromStep ?? showButtons) && (
          <div style={{ textAlign: "end", paddingTop: 24 }}>
            {(currentStepConfig.backButton ?? showBackButton) && (
              <Button
                type="default"
                style={{ marginRight: 12 }}
                onClick={handleBackButtonClick}
              >
                {t("general:action:back")}
              </Button>
            )}
            {showNextButton && (
              <Button
                disabled={!nextButtonEnabled}
                type="primary"
                loading={loading}
                onClick={() => void handleNextButtonClick()}
              >
                {currentStepConfig.nextButtonTitle ? currentStepConfig.nextButtonTitle : t("general:action:next")}
              </Button>
            )}
            {showStartAgainButton && (
              <Button
                type="primary"
                loading={loading}
                onClick={handleStartAgainButtonClick}
              >
                {(startAgainButtonTitle ?? startAgainButtonTitleOpt) || t("workflow:action:start_again")}
              </Button>
            )}
          </div>
        )}
      </Flexbox>
    </Flexbox>
  );
}
