import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "antd";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import HotelPolicyDrawer from "drawers/hotel_policy_drawer";
import HotelPolicyEditDrawer from "drawers/hotel_policy_edit_drawer";

import ErrorBoundary from "components/error_boundary";

import useBoolState from "utils/use_bool_state";

import getActionTitle from "./get_action_title_for_hotel_policy";

import styles from "./policy_view.module.css";

const { HotelPolicies } = store;

export default function HotelPolicy({ property, handleHotelPolicyChange }) {
  const { t } = useTranslation();
  const { handleError } = useContext(ErrorBoundary.Context);
  const [isLoading, setLoading, setLoadingComplete] = useBoolState(false);
  const [policy, setPolicy] = useState(null);
  const [
    isCreateHotelPolicyDrawerOpen,
    setCreateHotelPolicyDrawerOpen,
    setCreateHotelPolicyDrawerClosed,
  ] = useBoolState(false);

  const [
    isEditHotelPolicyDrawerOpen,
    setEditHotelPolicyDrawerOpen,
    setEditHotelPolicyDrawerClosed,
  ] = useBoolState(false);

  const { id, currency, hotel_policy_id: hotelPolicyId } = property;

  const getPolicy = useCallback(
    (policies) => {
      let result = null;

      const isPolicy = policies.length > 0;
      const defaultPolicy = isPolicy && policies.find((i) => i.id === hotelPolicyId);

      if (isPolicy) {
        result = policies[0];
      }
      if (defaultPolicy) {
        result = defaultPolicy;
      }

      return result;
    },
    [hotelPolicyId],
  );

  const onOpenDrawer = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (policy) {
      setEditHotelPolicyDrawerOpen();
    } else {
      setCreateHotelPolicyDrawerOpen();
    }
  }, [policy, isLoading, setEditHotelPolicyDrawerOpen, setCreateHotelPolicyDrawerOpen]);

  const loadPoliciesOptions = useCallback(async () => {
    try {
      setLoading();
      const policies = await HotelPolicies.options({ property_id: id });

      setPolicy(getPolicy(policies));
      setLoadingComplete();
    } catch (error) {
      handleError(error);
    }
  }, [id, setLoading, setPolicy, getPolicy, setLoadingComplete, handleError]);

  const onChangeHotelPolicy = useCallback(
    ({ data }) => {
      handleHotelPolicyChange(data.id);
      loadPoliciesOptions();
    },
    [handleHotelPolicyChange, loadPoliciesOptions],
  );

  useEffect(
    function loadPoliciesData() {
      loadPoliciesOptions();
    },
    [loadPoliciesOptions],
  );

  const actionTitle = getActionTitle(policy, isLoading, t);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionInner}>
        <Form.Item
          {...horizontalFormItemLayout}
          label={`${t("properties_page:policies:policy_label")}:`}
        >
          <Button data-testid="hotel_policy_button" type="link" onClick={onOpenDrawer}>
            {actionTitle}
          </Button>
        </Form.Item>
      </div>
      <HotelPolicyEditDrawer
        id={policy?.id}
        currency={currency}
        propertyId={id}
        visible={isEditHotelPolicyDrawerOpen}
        onClose={setEditHotelPolicyDrawerClosed}
        onCreate={onChangeHotelPolicy}
      />
      <HotelPolicyDrawer
        currency={currency}
        propertyId={id}
        visible={isCreateHotelPolicyDrawerOpen}
        onClose={setCreateHotelPolicyDrawerClosed}
        onCreate={onChangeHotelPolicy}
      />
    </div>
  );
}
