import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import TaxFormWrapper from "components/tax/form/tax_form_wrapper";

export default function TaxEditDrawer({ visible, propertyId, id, onClose, onCreate }) {
  const { t } = useTranslation();
  const title = id
    ? t("properties_page:policies:tax:edit_dialog_title")
    : t("properties_page:policies:tax:create_dialog_title");

  return (
    <ChannexDrawer dataTestid="tax_drawer" title={title} visible={visible} onClose={onClose}>
      {({ handleCloseFromContent, componentRef }) => (
        <DrawerFormWrapper withActions>
          <TaxFormWrapper
            entityId={id}
            propertyId={propertyId}
            componentRef={componentRef}
            onClose={handleCloseFromContent}
            onCreate={onCreate}
          />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
