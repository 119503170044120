import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { channelsApiClient } from "store/api_clients/channels";

import { ChannexDrawer } from "components/_v2/channex_drawer";
import { Loading } from "components/_v2/states/loading";
import { showSuccessMessage } from "components/toasts/messages";

import { PricingSettingsForm } from "./pricing_settings_form";

const usePricingSettings = (channelId, listingId) => {
  return useQuery({
    queryKey: ["listing_pricing_settings", channelId, listingId],
    queryFn: () => channelsApiClient.getPricingSettings(channelId, listingId),
  });
};

const useUpdatePricingSettings = (channelId, listingId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => channelsApiClient.updatePricingSettings(channelId, params),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["listing_pricing_settings", channelId, listingId] });
      showSuccessMessage();
    },
  });
};

export const PricingSettingsDrawer = ({ visible, listing, onClose }) => {
  const { t } = useTranslation();
  const title = t("hopper_homes:headings:pricing_settings");

  const { channelId } = useParams();
  const { listingId } = listing;
  const pricingSettingsQuery = usePricingSettings(channelId, listingId);
  const updatePricingSettingsMutation = useUpdatePricingSettings(channelId, listingId);

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {pricingSettingsQuery.isLoading && <Loading />}
      {pricingSettingsQuery.isLoading || (
        <PricingSettingsForm
          defaultValue={pricingSettingsQuery.data}
          isLoading={updatePricingSettingsMutation.isLoading}
          onSubmit={(values) => {
            const params = { listingId, ...values };
            return updatePricingSettingsMutation.mutateAsync(params, {
              onSuccess: onClose,
            });
          }}
        />
      )}
    </ChannexDrawer>
  );
};
