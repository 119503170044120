import React, { useCallback, useMemo, useState } from "react";
import { Checkbox, Form } from "antd";

import { horizontalFormItemLayout, verticalFormItemLayout } from "config/constants/layouts/form";

import styles from "./form_weekdays_v2.module.css";

const TWO_CHAR_WEEKDAYS = {
  mo: "Mo",
  tu: "Tu",
  we: "We",
  th: "Th",
  fr: "Fr",
  sa: "Sa",
  su: "Su",
};

const THREE_CHAR_WEEKDAYS = {
  mon: "Mo",
  tue: "Tu",
  wed: "We",
  thu: "Th",
  fri: "Fr",
  sat: "Sa",
  sun: "Su",
};

export const THREE_CHAR_VALUE_MODE = "threeChar";

export const WEEKDAY_VALUES = Object.keys(TWO_CHAR_WEEKDAYS);
export const THREE_CHAR_WEEKDAY_VALUES = Object.keys(THREE_CHAR_WEEKDAYS);

const getWeekdays = (valueMode) => {
  if (valueMode === THREE_CHAR_VALUE_MODE) {
    return THREE_CHAR_WEEKDAYS;
  }

  return TWO_CHAR_WEEKDAYS;
};

const valueToSelectedState = (value, weekdays) => {
  return Object.keys(weekdays).reduce((acc, key) => {
    if (!value) {
      return { ...acc, [key]: true };
    }

    return { ...acc, [key]: value.includes(key) };
  }, {});
};

export const FormWeekdaysV2 = ({ name, label, value, errors, disabled, valueMode, grouped, view = "horizontal", onChange }) => {
  const weekdays = getWeekdays(valueMode);
  const [selectedWeekdays, setSelectedWeekdays] = useState(valueToSelectedState(value, weekdays));

  const validationStatus = useMemo(() => {
    if (!errors) {
      return {};
    }

    return {
      validateStatus: "error",
      help: errors,
    };
  }, [errors]);

  const layout = useMemo(() => {
    if (grouped) {
      return {};
    }

    return view === "horizontal" ? horizontalFormItemLayout : verticalFormItemLayout;
  }, [grouped, view]);

  const handleChange = useCallback((e) => {
    const { checked, value: weekdayValue } = e.target;

    const newSelectedWeekdays = {
      ...selectedWeekdays,
      [weekdayValue]: checked,
    };

    setSelectedWeekdays(newSelectedWeekdays);

    // preserve the order of the weekdays
    const newValue = Object.keys(weekdays).filter((key) => newSelectedWeekdays[key]);

    onChange(newValue);
  }, [selectedWeekdays, setSelectedWeekdays, weekdays, onChange]);

  return (
    <Form.Item
      className={styles.weekdaysContainer}
      {...layout}
      {...validationStatus}
      label={label}
      data-testid={`weekdays_${name}`}
    >
      {Object.entries(weekdays).map(([weekdayValue, weekdayName]) => {
        const checked = selectedWeekdays[weekdayValue];

        return (
          <Checkbox
            data-testid={`day_${weekdayValue}`}
            className={styles.weekday}
            key={weekdayValue}
            checked={checked}
            disabled={disabled}
            value={weekdayValue}
            onChange={handleChange}
          >
            {weekdayName}
          </Checkbox>
        );
      })}
    </Form.Item>
  );
};
