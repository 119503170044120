import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { inventoryApiClient } from "store/api_clients/inventory";

import { Id } from "@/types";
import { RatePlan, SELL_MODE_PER_ROOM } from "@/types/rate_plans";

type UseReceiveFromInventoryMQueryParams = {
  propertyId: string;
  fromDate: string;
  toDate: string;
  onSuccess: (values: any) => void;
}
const useReceiveFromInventoryMQuery = ({ propertyId, fromDate, toDate, onSuccess }: UseReceiveFromInventoryMQueryParams) => {
  return useMutation({
    mutationFn: () => inventoryApiClient.getRates({ propertyId, fromDate, toDate }),
    onSuccess,
  })
}

type GetClosestRatePlanParams = {
  ratePlanId: Id;
  occupancy: number;
  ratePlans: RatePlan[];
  multiOccRatePlans: RatePlan[];
}
const getClosestRatePlan = ({ ratePlanId, occupancy, ratePlans, multiOccRatePlans }: GetClosestRatePlanParams): RatePlan | null => {
  const ratePlan = ratePlans.find((rp) => rp.id === ratePlanId);
  if (!ratePlan) {
    return null;
  }

  if (ratePlan.sell_mode === SELL_MODE_PER_ROOM) {
    return ratePlan;
  }

  const occupancyBasedRatePlans = multiOccRatePlans.filter((rp) => rp.id === ratePlanId || rp.parent_rate_plan_id === ratePlanId);

  let matchedRatePlan = occupancyBasedRatePlans.find((rp) => rp.occupancy === occupancy);

  if (!matchedRatePlan) {
    const maxOccupancy = Math.max(...occupancyBasedRatePlans.map((rp) => rp.occupancy));
    matchedRatePlan = occupancyBasedRatePlans.find((rp) => rp.occupancy === maxOccupancy);
  }

  return matchedRatePlan || null;
}

type UseReceiveRateReturn = {
  isPending: boolean;
  call: () => void;
}
type UseReceiveRatesParams = {
  propertyId: string,
  dates: string[],
  occupancy: number,
  ratePlanId: string,
  ratePlans: RatePlan[],
  multiOccRatePlans: RatePlan[],
  onSuccess: (newRates: Record<string, number>) => void,
  onError: (error: string) => void,
}
export const useInventoryRatesAction = (params: UseReceiveRatesParams): UseReceiveRateReturn => {
  const { t } = useTranslation();
  const { propertyId, dates, occupancy, ratePlanId, ratePlans, multiOccRatePlans, onSuccess, onError } = params;
  const fromDate = dates[0];
  const toDate = dates[dates.length - 1];

  const receiveFromInventoryMQuery = useReceiveFromInventoryMQuery({
    propertyId,
    fromDate: fromDate,
    toDate: toDate,
    onSuccess: (values) => {
      const ratePlanForOccupancy = getClosestRatePlan({ ratePlanId, occupancy, ratePlans, multiOccRatePlans });

      if (!ratePlanForOccupancy) {
        return onError(t("bookings:messages:cannot_find_rates_for_rate_plan"));
      }

      const ratesForRatePlan = values[ratePlanForOccupancy.id];

      if (!ratesForRatePlan || Object.keys(ratesForRatePlan).length === 0) {
        return onError(t("bookings:messages:cannot_find_rates_for_rate_plan"));
      }

      const newRates: Record<string, number> = dates.reduce((acc, date) => {
        const invRateValue = ratesForRatePlan[date]?.rate;

        if (invRateValue === null || invRateValue === undefined) {
          return acc;
        }

        return {
          ...acc,
          [date]: parseFloat(invRateValue),
        }
      }, {});

      onSuccess(newRates);
    }
  });

  return {
    isPending: receiveFromInventoryMQuery.isPending,
    call: receiveFromInventoryMQuery.mutate,
  }
}
