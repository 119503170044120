import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import get from "lodash/get";

import { FormCurrency } from "components/_v2/forms/fields/currency";
import { Legend } from "components/_v2/forms/legend";
import { useInventoryRatesAction } from "components/bookings/manage_form/common/rooms/days/use_inventory_rates_action";

import showErrorMessage from "utils/show_error_message";

const DaysContent = ({ name, days, currency }) => {
  const { t } = useTranslation();

  return (
    days.map((date) => (
      <FormCurrency
        key={date}
        name={`${name}.${date}`}
        label={date}
        placeholder={t("common:forms:fields:amount:placeholder")}
        currency={currency}
      />
    ))
  );
};

export const Days = ({ name, days, currency, occupancy, propertyId, ratePlanId, ratePlans, multiOccRatePlans }) => {
  const { t } = useTranslation();

  const sortedDays = days.sort();

  const formState = useFormState();
  const errors = get(formState.errors, name);
  const sectionError = errors?.message;
  const isError = !!sectionError;

  const { setValue } = useFormContext();
  const inventoryRatesAction = useInventoryRatesAction({
    propertyId,
    dates: sortedDays,
    occupancy,
    ratePlanId,
    ratePlans,
    multiOccRatePlans,
    onSuccess: (newRates) => {
      Object.entries(newRates).forEach(([date, rate]) => {
        setValue(`${name}.${date}`, rate);
      });
    },
    onError: (error) => showErrorMessage(error),
  });

  return (
    <>
      <Legend
        label={t("common:headings:days")}
        isError={isError}
        actions={[
          <Button
            key="receive-from-inventory"
            data-testid="receive-from-inventory-btn"
            type="link"
            disabled={!ratePlanId || occupancy === null}
            loading={inventoryRatesAction.isPending}
            icon={<DownloadOutlined />}
            onClick={inventoryRatesAction.call}
          >
            Receive from Inventory
          </Button>
        ]}
      />
      <DaysContent name={name} days={sortedDays} currency={currency} />
    </>
  );
};
