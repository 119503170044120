import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import store from "store";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

const { Properties } = store;

export function useActiveProperty() {
  const activePropertyId = useSelector(getActivePropertyId);

  const query = useQuery({
    queryKey: ["active_property"],
    queryFn: () => Properties.find(activePropertyId),
  });

  return query;
}
