import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Radio, RadioChangeEvent } from "antd";

import { validationMessages } from "config/constants/errors";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { NextButton } from "components/_v2/forms/buttons/next";
import { Legend } from "components/_v2/forms/legend";
import FormSelect from "components/forms/inputs/form_select";
import { StyledForm } from "components/forms/styled_form";

import { MewsRoom } from "../../types";

import { MewsRoomRatesSelector } from "./mews_rooms_rates_selector";

type Params = {
  propertyOptions: any[];
  groupsOptions: any[];
  mewsRooms: MewsRoom[] | undefined;
  initialValue: any;
  errors: Record<string, any>;
  isSubmitting: boolean;
  onFinish: (values: any) => Promise<void>;
}

export const ImportForm = ({ propertyOptions, groupsOptions, mewsRooms, initialValue, errors: parentErrors, isSubmitting, onFinish }: Params) => {
  const { t } = useTranslation();

  const [action, setAction] = useState(initialValue.action || "connectExistingProperty");
  const [channexGroupId, setChannexGroupId] = useState(initialValue.channexGroupId);
  const [channexPropertyId, setChannexPropertyId] = useState(initialValue.channexPropertyId);
  const [chosenRooms, setChosenRooms] = useState(initialValue.chosenRooms);
  const [formErrors, setFormErrors] = useState<Params["errors"]>(parentErrors || {});

  useEffect(() => { setFormErrors(parentErrors); }, [parentErrors]);

  const handleActionChange = (e: RadioChangeEvent) => {
    setAction(e.target.value);
    setFormErrors({});
  };

  const handleChosenRoomsChange = (value: any) => {
    setChosenRooms(value);
    setFormErrors({});
  };

  const handleSubmit = () => {
    const errors: Record<string, string> = {};

    if (action === "connectExistingProperty") {
      if (!channexPropertyId) {
        errors.channexPropertyId = validationMessages.required();
      }
    }

    if (action === "createProperty") {
      if (!channexGroupId) {
        errors.channexGroupId = validationMessages.required();
      }

      if (chosenRooms.length === 0) {
        errors.chosenRooms = validationMessages.required();
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    return onFinish({
      action,
      channexGroupId,
      channexPropertyId,
      chosenRooms,
    });
  };

  return (
    <BodyContainer overflow dataTestid="import-form">
      <ScrollableContainer leftBorder={false} padding="0 16px">
        <StyledForm>
          {formErrors._global && <Alert message={formErrors._global} type="error" />}
          <Legend marginTop={false} label={t("mews:headings:property")} />
          <Radio.Group
            data-testid="action-type"
            defaultValue={action}
            disabled={isSubmitting}
            onChange={handleActionChange}
            value={action}
            style={{ width: "100%" }}
          >
            <Radio
              data-testid="connectExistingProperty"
              value="connectExistingProperty"
            >
              {t("mews:headings:connect_to_existing_property")}
            </Radio>
            <FormSelect
              disabled={action === "createProperty" || isSubmitting}
              name="channexPropertyId"
              label={t("mews:headings:channex_property")}
              defaultValue={channexPropertyId}
              errors={formErrors.channexPropertyId}
              valueKey="id"
              labelKey="title"
              options={propertyOptions}
              onChange={(value: string) => {
                setChannexPropertyId(value);
                setFormErrors({});
              }}
            />
            <Radio
              style={{ paddingTop: 20 }}
              data-testid="createProperty"
              value="createProperty"
            >
              {t("mews:headings:create_new_property")}
            </Radio>
            <FormSelect
              disabled={action === "connectExistingProperty" || isSubmitting}
              name="channexGroupId"
              errors={formErrors.channexGroupId}
              label={t("mews:headings:channex_group")}
              defaultValue={channexGroupId}
              valueKey="id"
              labelKey="title"
              options={groupsOptions}
              onChange={(value: string) => {
                setChannexGroupId(value);
                setFormErrors({});
              }}
            />
          </Radio.Group>

          {action === "createProperty" && (
            <>
              <Legend label={t("mews:headings:rooms")} />
              <MewsRoomRatesSelector
                initialValue={chosenRooms}
                errors={formErrors.chosenRooms}
                disabled={isSubmitting}
                rooms={mewsRooms}
                onChange={handleChosenRoomsChange}
              />
            </>
          )}
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <NextButton loading={isSubmitting} onClick={handleSubmit} />
      </FooterContainer>
    </BodyContainer>
  );
};
