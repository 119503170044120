import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import defaultPaginationVars from "config/constants/default_pagination_variables";
import messageStatuses from "config/constants/message_statuses";

import { Loading } from "components/_v2/states/loading";

import calculateLimitFromRef from "utils/calculate_limit_from_ref";
import useBoolState from "utils/use_bool_state";

import { ChatActions } from "./chat_actions/chat_actions";
import ChatForm from "./chat_form";
import ChatMessagesList from "./chat_messages_list";

import NothingImage from "static/nothing-to-display.svg";
import styles from "./chat.module.css";

const ITEM_MIN_HEIGHT = 64;

const DEFAULT_SENDER = "property";

export default function Chat({ thread, messages, meta, className, sender, onSend, receiver, disabled = false, disabledReason, onLoad, onThreadReload }) {
  const [scrollToIndex, setScrollToIndex] = useState(undefined);
  const { t } = useTranslation();
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const containerRef = useRef(null);
  const listRef = useRef(null);

  const defaultLimit = meta?.limit || defaultPaginationVars.limit;

  const getScrollToIndex = useCallback(
    (data) => {
      if (data) {
        return data.length;
      }
      return scrollToIndex;
    },
    [scrollToIndex],
  );

  const loadMessagesList = useCallback(
    (nextPage, limit = defaultLimit) => {
      setTrueLoading();

      const newMeta = { ...defaultPaginationVars, page: nextPage, limit };

      return onLoad(newMeta)
        .then(({ data }) => {
          const newScrollToIndex = getScrollToIndex(data);
          setScrollToIndex(newScrollToIndex);
          setFalseLoading();
        })
        .catch(() => {
          setFalseLoading();
        });
    },
    [onLoad, defaultLimit, setFalseLoading, setTrueLoading, getScrollToIndex],
  );

  const loadInitialData = useCallback(() => {
    const limit = calculateLimitFromRef(containerRef, ITEM_MIN_HEIGHT);

    loadMessagesList(1, limit);
  }, [containerRef, loadMessagesList]);

  useEffect(
    function initList() {
      if (messages || loading) {
        return;
      }
      loadInitialData();
    },
    [loadInitialData, messages, loading],
  );

  const onSendMessage = useCallback(
    (val, oldRequestId) => {
      const { message, attachments } = val;

      if (message) {
        const requestId = oldRequestId || uuidv4();

        const newMessage = {
          attributes: {
            insertedAt: new Date(),
            message,
            sender: DEFAULT_SENDER,
            updatedAt: new Date(),
            status: messageStatuses.PENDING,
          },
          id: requestId,
          requestId,
        };

        if (listRef.current) {
          listRef.current.el.scrollTop = 0;
        }
        onSend(newMessage).then(() => {
          setScrollToIndex(getScrollToIndex(messages));
        });
      }

      attachments.forEach((attachment) => {
        const requestId = oldRequestId || uuidv4();

        const newMessage = {
          attributes: {
            insertedAt: new Date(),
            attachmentId: attachment.attachmentId,
            sender: DEFAULT_SENDER,
            updatedAt: new Date(),
            status: messageStatuses.PENDING,
          },
          id: requestId,
          requestId,
        };

        if (listRef.current) {
          listRef.current.el.scrollTop = 0;
        }
        onSend(newMessage).then(() => {
          setScrollToIndex(getScrollToIndex(messages));
        });
      });
    },
    [messages, listRef, getScrollToIndex, onSend],
  );

  const renderContent = () => {
    if (!messages) {
      return <Loading />;
    }

    if (messages.length > 0) {
      return (
        <ChatMessagesList
          items={messages}
          meta={meta}
          listRef={listRef}
          loading={loading}
          receiver={receiver}
          sender={sender}
          onLoad={loadMessagesList}
          onSend={onSendMessage}
        />
      );
    }

    return (
      <div className={styles.emptyWrapper}>
        <img src={NothingImage} alt="Error" />
        {t("bookings_page:booking_view_dialog:messages:no_messages")}
      </div>
    );
  };

  return (
    <>
      <div className={className} ref={containerRef}>
        {renderContent()}
      </div>
      <ChatActions thread={thread} messages={messages} onThreadReload={onThreadReload} />
      {!disabled && <ChatForm onSubmit={onSendMessage} disabled={!messages} />}
      {disabledReason && (
        <div className={styles.disabledReason}>
          {disabledReason}
        </div>
      )}
    </>
  );
}
