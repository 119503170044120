import React from "react";
import { i18n } from "i18n";

import { message } from "../app";

export default (errorText, duration) => {
  if (errorText === "remove mappings before") {
    errorText = i18n.t("channels_page:deactivate_dialog:errors:remove_mappings_before");
  }

  message.error(<span data-testid="error_message">{errorText}</span>, duration);
};
