export const FooterContainer = ({ children, noPadding = false }) => (
  <div
    style={{
      padding: noPadding ? "unset" : "10px 16px",
      borderTop: "1px solid #e8e8e8",
    }}
  >
    {children}
  </div>
);
