import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

import appModes from "config/constants/app_modes";

import AppModeRenderer from "components/app_mode_renderer";
import GroupFilter from "components/group_filter/group_filter";
import PropertyFilter from "components/property_filter/property_filter";

import Logo from "./logo";
import Navigation from "./navigation";
import UserDropDown from "./user_drop_down";

import styles from "./header.module.css";

export default function Header() {
  const [isNavigationMenuOpened, setIsNavigationMenuOpened] = useState(false);
  const navigate = useNavigate();

  const toggleDesktopMode = (isDesktopMode) => {
    if (isDesktopMode) {
      setIsNavigationMenuOpened(false);
    }
  };

  const isDesktop = useMediaQuery({ query: "(min-width: 1275px)" }, undefined, toggleDesktopMode);

  const handleOpenMenu = () => {
    setIsNavigationMenuOpened(true);
  };

  const handleCloseMenu = () => {
    setIsNavigationMenuOpened(false);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppModeRenderer visible={[appModes.DEFAULT]}>
      <div data-testid="header" className={styles.header}>
        <div className={styles.header__Left}>
          <Logo onLogoClick={handleLogoClick} onMenuClick={handleOpenMenu} />

          <Navigation
            isOpened={isNavigationMenuOpened}
            onCloseMenu={handleCloseMenu}
            onLogoClick={handleCloseMenu}
          />
        </div>
        <div className={styles.header__Right}>
          <GroupFilter isMobile={!isDesktop} />
          <PropertyFilter isMobile={!isDesktop} />
          <UserDropDown isMobile={!isDesktop} />
        </div>
      </div>
    </AppModeRenderer>
  );
}
