import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import { Loading } from "components/_v2/states/loading";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import SubmitButton from "components/forms/buttons/submit_button";
import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";

import styles from "styles/form_in_drawer.module.css";

class GeneralForm extends Component {
  render() {
    const { t, properties, value, errors, isLoading, onChange, onSubmit } = this.props;

    if (!properties) {
      return <Loading />;
    }

    const room_kind_options = [
      { value: "room", representation: t("rooms_page:form:room_kind_options:room") },
      { value: "dorm", representation: t("rooms_page:form:room_kind_options:dorm") },
    ];

    const options = properties.map((el) => ({
      value: el.id,
      representation: el.title,
    }));

    if (options.length === 1) {
      value.property_id = options[0].value;
    }

    return (
      <Form onFinish={onSubmit}>
        {value.id && (
          <Form.Item
            data-testid="room_id_container"
            {...horizontalFormItemLayout}
            label={t("general:id")}
          >
            {value.id}
            <CopyToClipboard text={value.id} />
          </Form.Item>
        )}
        <FormSelect
          name="property_title"
          view="horizontal"
          placeholder={t("rooms_page:form:property_placeholder")}
          onChange={onChange("property_id")}
          value={value.property_id}
          label={t("rooms_page:form:property_label")}
          defaultValue={value.property_id}
          errors={errors ? errors.property_id : null}
          options={options}
        />
        <FormInput
          name="title"
          view="horizontal"
          type="text"
          placeholder={t("rooms_page:form:title_placeholder")}
          onChange={onChange("title")}
          label={t("rooms_page:form:title_label")}
          defaultValue={value.title}
          errors={errors ? errors.title : null}
        />
        <FormSelect
          name="type_of_room"
          view="horizontal"
          placeholder={t("rooms_page:form:room_kind_placeholder")}
          onChange={onChange("room_kind")}
          value={value.room_kind}
          label={t("rooms_page:form:room_kind_label")}
          defaultValue={value.room_kind}
          errors={errors ? errors.room_kind : null}
          options={room_kind_options}
        />
        <FormInput
          name="count_of_rooms"
          view="horizontal"
          type="number"
          placeholder={t("rooms_page:form:count_of_rooms_placeholder")}
          onChange={onChange("count_of_rooms")}
          label={t("rooms_page:form:count_of_rooms_label")}
          defaultValue={value.count_of_rooms}
          errors={errors ? errors.count_of_rooms : null}
          min={1}
        />
        {value.room_kind === "dorm" && (
          <FormInput
            name="capacity"
            view="horizontal"
            type="number"
            placeholder={t("rooms_page:form:capacity_placeholder")}
            onChange={onChange("capacity")}
            label={t("rooms_page:form:capacity_label")}
            defaultValue={value.capacity}
            errors={errors ? errors.capacity : null}
            extra={t("rooms_page:form:capacity_help")}
          />
        )}
        <legend>{t("rooms_page:form:occupancy_legend")}</legend>
        <p>{t("rooms_page:form:occupancy_legend_description_1")}</p>
        <p>{t("rooms_page:form:occupancy_legend_description_2")}</p>
        <FormInput
          name="max_adults"
          view="horizontal"
          type="number"
          placeholder={t("rooms_page:form:occ_adults_placeholder")}
          onChange={onChange("occ_adults")}
          label={t("rooms_page:form:occ_adults_label")}
          defaultValue={value.occ_adults}
          errors={errors ? errors.occ_adults : null}
          extra={t("rooms_page:form:occ_adults_help")}
          min={0}
        />
        <FormInput
          name="max_children"
          view="horizontal"
          type="number"
          placeholder={t("rooms_page:form:occ_children_placeholder")}
          onChange={onChange("occ_children")}
          label={t("rooms_page:form:occ_children_label")}
          defaultValue={value.occ_children}
          errors={errors ? errors.occ_children : null}
          extra={t("rooms_page:form:occ_children_help")}
          min={0}
        />
        <FormInput
          name="max_infants"
          view="horizontal"
          type="number"
          placeholder={t("rooms_page:form:occ_infants_placeholder")}
          onChange={onChange("occ_infants")}
          label={t("rooms_page:form:occ_infants_label")}
          defaultValue={value.occ_infants}
          errors={errors ? errors.occ_infants : null}
          extra={t("rooms_page:form:occ_infants_help")}
          min={0}
        />

        <div className={styles.actions}>
          <SubmitButton loading={isLoading}>{t("rooms_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(GeneralForm);
