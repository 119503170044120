import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import BookingView from "components/bookings/booking_view";

export default function BookingViewControlledDrawer({
  visible,
  bookingId,
  onClose,
  zIndex,
  hiddenTabs,
  hiddenFields,
  hideActions,
}) {
  const [booking, setBooking] = useState({});
  const { t } = useTranslation();

  const { unique_id } = booking;
  const title = unique_id
    ? `${t("bookings_page:booking_view_dialog:header_loaded")} ${unique_id}`
    : t("bookings_page:booking_view_dialog:header_loading");

  const handleBookingLoad = useCallback((newBooking) => {
    setBooking(newBooking);
  }, []);

  return (
    <ChannexDrawer
      title={title}
      visible={visible}
      onClose={onClose}
      zIndex={zIndex}
    >
      {() => (
        <BookingView
          bookingId={bookingId}
          onLoad={handleBookingLoad}
          hiddenTabs={hiddenTabs}
          hiddenFields={hiddenFields}
          hideActions={hideActions}
        />
      )}
    </ChannexDrawer>
  );
}
