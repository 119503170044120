import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Collapse, Flex } from "antd";

import List from "components/list";

import { formatToLocal } from "utils/dates";
import { download } from "utils/download";

import { Payload } from "./payload";

import styles from "./channel_events.module.css";

const DownloadButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button style={{ height: 22 }} type="link" icon={<DownloadOutlined />} onClick={onClick}>
      {t("common:actions:download")}
    </Button>
  );
}

const Label = ({ title, onDownloadClick}) => {
  return (
    <Flex justify="space-between">
      <span>{title}</span>
      <DownloadButton onClick={onDownloadClick} />
    </Flex>
  )
}

export default function ChannelEventsLog({ log }) {
  const { t } = useTranslation();
  const isErrorsPresent = Boolean(log.data.errors?.length);

  const nestedPanelItems = [{
    key: `${log.data.query_id}_${log.data.finished_at}_request`,
    label: t("channel_events_page:channel_events_view_dialog:request"),
    children: <Payload data={log.data.request} />,
  }, {
    key: `${log.data.query_id}_${log.data.finished_at}_response`,
    label: t("channel_events_page:channel_events_view_dialog:response"),
    children: <Payload data={log.data.response} />,
  }];

  const title = log.data.action || log.data.method;

  const handleDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const timestamp = formatToLocal(log.data.started_at, t("formats:iso_date_with_miliseconds"));
    const filename = `${title}_${timestamp}.log`.replaceAll(/[^a-z0-9.]/gi, "_");
    const data = `Request:\n${JSON.stringify(log.data.request, null, 2)}\n\nResponse:\n${JSON.stringify(log.data.response, null, 2)}`;

    download(filename, data);
  }

  const items = [{
    label: <Label title={title} onDownloadClick={handleDownloadClick} />,
    extra: isErrorsPresent && <ExclamationCircleOutlined className={styles.errorIcon} />,
    children: (
      <>
        <List>
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:method")}
            description={log.data.action || log.data.method}
          />
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:started_at")}
            description={formatToLocal(log.data.started_at, t("formats:iso_date_with_miliseconds"))}
          />
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:finished_at")}
            description={formatToLocal(
              log.data.finished_at,
              t("formats:iso_date_with_miliseconds"),
            )}
          />
        </List>
        <Collapse items={nestedPanelItems} />
      </>
    ),
  }];

  return (
    <Collapse items={items} />
  );
}
