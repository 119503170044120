import React from "react";
import { useTranslation } from "react-i18next";

import { StepConnectProperty } from "./step_connect_property";
import { StepMappingDetails } from "./step_mapping_details";
import { StepResult } from "./step_result";
import { StepSettings } from "./step_settings";
import { MewsImportWizardData } from "./types";
import { Wizard } from "./wizard";

type Props = {
  initialStep?: number;
  initialData?: MewsImportWizardData;
}

export const MewsImportWizard = ({ initialStep, initialData }: Props) => {
  const { t } = useTranslation();

  return (
    <Wizard
      initialStep={initialStep}
      initialData={initialData}
      steps={[{
        title: t("mews:headings:connection"),
        component: <StepMappingDetails />,
      }, {
        title: t("mews:headings:connect_property"),
        component: <StepConnectProperty />,
      }, {
        title: t("mews:headings:settings"),
        component: <StepSettings />,
      }, {
        title: t("mews:headings:result"),
        component: <StepResult />,
      }]}
    />
  );
};
