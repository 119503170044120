import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import {
  DisconnectOutlined,
  DownOutlined,
  EditOutlined,
  LinkOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Dropdown, Modal, notification } from "antd";
import store from "store";

import APP_MODES from "config/constants/app_modes";
import channelNames from "config/constants/channels/channel_codes";

import CRUDTable from "components/crud_table";
import { removeMenuItem } from "components/menu/items/remove";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import EventEmitter from "utils/event_emitter";
import handleActionError from "utils/handle_action_error";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import AdvancedSearch from "../advanced_search";

import generalStyles from "styles/general.module.css";

const DROPDOWN_TRIGGER = ["click"];
const confirm = Modal.confirm;

const { Channels, query } = store;

class ChannelsTable extends Component {
  state = {
    columnsToHide: 0,
    advancedSearchFilter: {},
  };

  tableRef = React.createRef();

  componentDidMount() {
    EventEmitter.bind("channels:updated", () => {
      this.tableRef.current.reloadTable();
    });
  }

  componentWillUnmount() {
    EventEmitter.unbind("channels:updated");
  }

  componentDidUpdate(prevProps) {
    const { activeGroup, activeProperty } = this.props;
    const isGroupUnchanged = prevProps.activeGroup === activeGroup;
    const isPropertyUnchanged = prevProps.activeProperty === activeProperty;

    if (isGroupUnchanged && isPropertyUnchanged) {
      return;
    }

    const { current } = this.tableRef;

    if (current) {
      current.resetTable();
    }
  }

  loadData = (searchQuery, pagination, order) => {
    const { activeGroup, activeProperty, allowedChannels, appMode } = this.props;
    const { advancedSearchFilter } = this.state;
    const allowedChannelCodes = Object.keys(allowedChannels);
    let filter = advancedSearchFilter;

    if (searchQuery) {
      filter = { ...filter, title: { has: searchQuery } };
    }

    if (activeGroup) {
      filter.group_id = activeGroup;
    } else {
      delete filter.group_id;
    }

    if (activeProperty) {
      filter.property_id = activeProperty;
    } else {
      delete filter.property_id;
    }

    if (appMode === APP_MODES.HEADLESS && allowedChannelCodes.length) {
      filter.channel = allowedChannelCodes;
    }

    return Channels.list(filter, pagination, order);
  };

  actions = (text, record) => {
    const { t, appMode, routes, navigate } = this.props;
    const isRecordActionsPresent = record.actions?.length;

    const items = [
      {
        key: "actions_menu_edit",
        onClick: () => navigate(pathBuilder(routes.userAppRoutes.channels.edit, { channelId: record.id })),
        label: (
          <div data-testid="actions_menu_edit">
            <EditOutlined /> {t("general:action:edit")}
          </div>
        ),
      },
    ];

    if (appMode === APP_MODES.DEFAULT) {
      items.push({ type: "divider" });
      items.push({
        key: "actions_logs_link",
        onClick: () => navigate(pathBuilder(routes.userAppRoutes.channelEvents, { channelId: record.id })),
        label: (
          <div data-testid="actions_logs_link">
            <UnorderedListOutlined /> {t("channels_page:logs_link")}
          </div>
        ),
      });
    }

    if (record.is_active === false) {
      items.push({ type: "divider" });
      items.push({
        key: "actions_menu_activate",
        onClick: this.onActivate(record.id),
        label: (
          <div data-testid="actions_menu_activate">
            <LinkOutlined /> {t("general:action:activate")}
          </div>
        ),
      });
    } else {
      items.push({ type: "divider" });
      items.push({
        key: "actions_menu_deactivate",
        onClick: this.onDeactivate(record.id),
        label: (
          <div data-testid="actions_menu_deactivate">
            <DisconnectOutlined /> {t("general:action:deactivate")}
          </div>
        ),
      });
    }

    if (record.is_active === true) {
      items.push({ type: "divider" });
      items.push({
        key: "full_sync",
        onClick: this.onFullSync(record),
        label: (
          <div>
            <SyncOutlined /> {t("general:action:full_sync")}
          </div>
        ),
      });
    }

    if (isRecordActionsPresent && record.is_active === true) {
      items.push({ type: "divider" });

      record.actions.forEach((action) => {
        items.push({
          key: `channels_page:actions:${action}`,
          onClick: this.onCallAction(record, action),
          label: (
            <div>
              {t(`channels_page:actions:${action}`)}
            </div>
          ),
        });
      });
    }

    items.push({ type: "divider" });

    const messages = {
      AirBNB: {
        title: t("channels_page:remove_dialog:airbnb_title"),
        content: (
          <div data-testid="modal_content">{t("channels_page:remove_dialog:airbnb_message")}</div>
        ),
      },
      default: {
        title: t("channels_page:remove_dialog:default_title"),
        content: (
          <div data-testid="modal_content">{t("channels_page:remove_dialog:default_message")}</div>
        ),
      },
    };
    const { title, content } = messages[record.channel] || messages.default;
    items.push(
      removeMenuItem({
        title,
        content,
        onRemove: () => Channels.remove(record)
          .then(() => {
            this.tableRef.current.reloadTable();
          })
          .catch(handleActionError),
      }),
    );

    return (
      <Dropdown
        menu={{ items }}
        trigger={DROPDOWN_TRIGGER}
      >
        <a
          data-testid="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(event) => event.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  onCallAction = (record, action) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("channels_page:actions:action_dialog_title"),
        content: t("channels_page:actions:action_dialog_content"),
        onOk() {
          query("GET", `channels/${record.id}/execute/${action}`, {}).then(() => {
            notification.open({
              message: t("channels_page:actions:action_notification_title"),
              description: t("channels_page:actions:action_notification_description"),
            });
          });
        },
      });
    };
  };

  onError = (response) => {
    showErrorMessageFromResponse(response);
  };

  handleActivationError = (channelId) => () => {
    const { t } = this.props;
    const key = `notification_${Date.now()}`;

    notification.error({
      message: t("channels_page:errors:channel_activation_failed_message"),
      description: t("channels_page:errors:channel_activation_failed_description"),
      onClick: () => {
        EventEmitter.trigger("channel_management:show_missing_content", channelId);
        notification.destroy(key);
      },
      key,
    });
  };

  onActivate = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        okButtonProps: {
          "data-testid": "modal_ok",
        },
        title: t("general:activate_dialog:title"),
        content: <div data-testid="modal_content">{t("general:activate_dialog:description")}</div>,
        onOk: () => {
          Channels.activate(id).catch(this.handleActivationError(id));
        },
      });
    };
  };

  onDeactivate = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:deactivate_dialog:title"),
        content: t("general:deactivate_dialog:description"),
        onOk: () => {
          Channels.deactivate(id).catch(this.onError);
        },
      });
    };
  };

  onFullSync = (record) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:full_sync_dialog:title"),
        content: t("general:full_sync_dialog:description"),
        onOk() {
          Channels.full_sync(record.id);
        },
      });
    };
  };

  columns = () => {
    const { t } = this.props;

    let dataColumns = [
      {
        title: t("channels_page:columns:title"),
        dataIndex: "title",
        key: "title",
        sorter: true,
      },
      {
        title: t("channels_page:columns:channel"),
        dataIndex: "channel",
        key: "channel",
        sorter: true,
        render: (value) => {
          const channelParams = channelNames[value];

          return channelParams?.title || value;
        },
      },
      {
        title: t("channels_page:columns:is_active"),
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        render: (value) => {
          return value ? t("channels_page:status:active") : t("channels_page:status:disabled");
        },
      },
    ];

    const actionColumns = [
      {
        title: t("channels_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage = () => {
    const { t } = this.props;
    return t("channels_page:empty_message");
  };

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    const { onTableQueryChange } = this.props;

    if (onTableQueryChange) {
      onTableQueryChange(tableQuery);
    }
  };

  advancedSearchApply = (advancedSearchFilter) => {
    this.setState({
      advancedSearchFilter,
    });
  };

  render() {
    const { data, routes } = this.props;
    const { advancedSearchFilter } = this.state;

    return (
      <>
        <Media query="(max-width: 420px)" onChange={this.handleMediaChange(2)} />
        <Media
          query="(min-width: 421px) and (max-width: 520px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 521px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable
          data={data}
          componentRef={this.tableRef}
          emptyMessage={this.emptyMessage()}
          createLink={pathBuilder(routes.userAppRoutes.channels.create)}
          msgCreateLink={pathBuilder(routes.userAppRoutes.channels.create)}
          searchField="title"
          columns={this.columns}
          onTableQueryChange={this.handleTableQueryChange}
          loadData={this.loadData}
          advancedSearch={AdvancedSearch}
          advancedSearchFilter={advancedSearchFilter}
          advancedSearchApply={this.advancedSearchApply}
        />
      </>
    );
  }
}

const mapStateToProps = ({ channels, session }) => {
  const { activeGroup, activeProperty, allowedChannels, appMode } = session || {};

  return {
    appMode,
    activeGroup,
    activeProperty,
    allowedChannels,
    data: channels ? Object.values(channels) : null,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(ChannelsTable)));
