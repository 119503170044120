import React from "react";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import { Id } from "components/_v2/forms/fields/id";
import { AppForm } from "components/_v2/forms/hook_form";
import { Loading } from "components/_v2/states/loading";

import useUser from "../../../use_user";
import useUserUpdateAction from "../../../use_user_update_action";

export default function UserEditForm({ userId, onFinish }) {
  const { data: user, isLoading: isUserLoading } = useUser(userId);
  const { mutateAsync: updateUser, isLoading: isUpdating } = useUserUpdateAction();

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(validationMessages.required),
    email: yup.string().trim().required(validationMessages.required),
  });

  if (isUserLoading) {
    return <Loading />;
  }

  return (
    <AppForm
      initialValue={user?.data?.attributes}
      validationSchema={validationSchema}
      schema={{
        layout: "scrollBox",
        fields: [{
          name: "id",
          label: "ID",
          component: Id,
        }, {
          name: "name",
          label: "Name",
        }, {
          name: "email",
          label: "Email",
        }],
        isSubmitLoading: isUpdating,
      }}
      onSubmit={async (values) => {
        await updateUser({
          id: userId,
          name: values.name,
          email: values.email,
        });

        onFinish();
      }}
    />
  );
}
