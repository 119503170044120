import React from "react";
import { useFieldArray, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "antd";
import get from "lodash/get";

import { IconDeleteButton } from "components/_v2/buttons/icons/delete";
import { IconEditButton } from "components/_v2/buttons/icons/edit";
import { Legend } from "components/_v2/forms/legend";
import { AddButton } from "components/_v2/forms/legend/buttons/add_button";
import { SectionEmpty } from "components/_v2/forms/sections/empty";
import { SectionError } from "components/_v2/forms/sections/error";
import { ItemError } from "components/_v2/forms/sections/item_error";
import { SectionMessage } from "components/_v2/forms/sections/message";

import useDrawer from "hooks/use_drawer";

import { daysBetween } from "utils/dates";

import { EditDrawer } from "./edit_drawer";

const RoomItem = ({ room, roomTypes, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  const roomType = room && roomTypes.find((type) => type.id === room.roomTypeId);
  const title = roomType?.title ?? t("common:terms:unknown");

  return (
    <Flex data-testid="room-item" justify="space-between">
      <Typography.Text style={{ alignContent: "center" }}>
        {isError && <ItemError />}
        {title}
      </Typography.Text>
      <span>
        <IconEditButton onClick={onEdit} />
        <IconDeleteButton onClick={onRemove} />
      </span>
    </Flex>
  );
};

const buildDaysFromBooking = (booking) => {
  if (!booking.bookingDateRange?.[0] || !booking.bookingDateRange?.[1]) {
    return {};
  }

  return daysBetween(booking.bookingDateRange[0], booking.bookingDateRange[1])
    .slice(0, -1)
    .reduce((acc, day) => {
      acc[day] = null;
      return acc;
    }, {});
};

const buildNew = (booking) => ({
  roomTypeId: null,
  ratePlanId: null,
  days: buildDaysFromBooking(booking),
  occupancy: {
    adults: null,
    children: null,
    ages: [],
  },
  services: [],
  taxes: [],
  guests: [],
});

export const Rooms = ({ name, layout, roomTypes, ratePlans, multiOccRatePlans }) => {
  const { t } = useTranslation();

  const [currency, bookingDateRange, propertyId] = useWatch({ name: ["currency", "bookingDateRange", "propertyId"] });
  const { fields, append, remove, update } = useFieldArray({ name, keyName: "key" });

  // parent form updates rooms values, to properly work with useFieldArray we should use field values from useWatch
  // https://github.com/react-hook-form/react-hook-form/issues/1564
  const entries = useWatch({ name });

  const { isOpen, payload, open, close } = useDrawer();
  const booking = useWatch();

  const formState = useFormState();
  const sectionErrors = get(formState.errors, name);
  const isBookingDatesSelected = bookingDateRange?.[0] && bookingDateRange?.[1];
  const isEmpty = fields.length === 0;
  const isSectionError = !!sectionErrors?.message;

  return (
    <>
      <Legend
        marginTop={layout !== "embedded"}
        label={t("common:headings:rooms")}
        isError={isSectionError}
        actions={[
          <AddButton
            key="add"
            disabled={!isBookingDatesSelected}
            onClick={() => open({ entry: buildNew(booking) })}
          />,
        ]}
      />

      {isEmpty && <SectionEmpty />}
      {!isBookingDatesSelected && <SectionMessage message={t("bookings:messages:select_booking_dates_first")} />}
      {isSectionError && <SectionError error={sectionErrors.message} /> }

      {fields.map(({ key }, index) => (
        entries?.[index] && (
          <RoomItem
            key={key}
            room={entries[index]}
            roomTypes={roomTypes}
            errors={sectionErrors?.[index]}
            onRemove={() => remove(index)}
            onEdit={() => {
              open({
                entry: entries[index],
                index,
              });
            }}
          />
        )
      ))}

      <EditDrawer
        visible={isOpen}
        room={payload?.entry}
        roomTypes={roomTypes}
        ratePlans={ratePlans}
        multiOccRatePlans={multiOccRatePlans}
        currency={currency}
        propertyId={propertyId}
        errors={sectionErrors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload?.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </>
  );
};
