import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard";

import prettifyJson from "utils/prettify_json";
import prettifyXml from "utils/prettify_xml";

import styles from "./styles.module.css";

type LogData = string | Record<string, any>;

type Props = {
  data: LogData;
}

const isXML = (data: LogData) => {
  return typeof data === "string" && data.trim().startsWith("<");
};

export const Payload = ({ data }: Props) => {
  const { t } = useTranslation();

  const prettyData = useMemo(() => {
    try {
      if (isXML(data)) {
        return prettifyXml(data);
      }

      return prettifyJson(data);
    } catch {
      return t("channel_events_page:channel_events_view_dialog:unsupported_response_error");
    }
  }, [t, data]);

  return (
    <pre className={styles.log}>
      <CopyToClipboard className={styles.payloadCopyIcon} text={prettyData} />
      {prettyData}
    </pre>
  )
}
