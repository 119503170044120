import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

import { PageHeader } from "components/page";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

export default function ChannelEventsPageHeader({ channelId, channel }) {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();

  let channelLink = null;

  if (channel) {
    const channelTitle = channel.data.attributes.title;
    const pathname = pathBuilder(userAppRoutes.channels.edit, { channelId });

    channelLink = <Link to={{ pathname }}>{channelTitle}</Link>;
  }

  return (
    <PageHeader
      title={t("channel_events_page:header")}
      subTitle={channelLink}
    />
  );
}
