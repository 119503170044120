import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { transformMappingsToArray } from "../../../../utils/transform_mappings_to_array";
import { transformMappingsToObject } from "../../../../utils/transform_mappings_to_object";

import { buildMappingItems } from "./build_mapping_items";

const buildUnknownMappingOptions = ({ mappingOptions, mappingSettings }) => {
  const mappingOptionsById = mappingOptions.rooms.reduce((roomAcc, room) => {
    roomAcc[room.id] = { ...room };
    return roomAcc;
  }, {});

  const unknownMappingOptionsById = {};

  Object.entries(mappingSettings.rooms).forEach(([externalRoomId, roomTypeId]) => {
    if (roomTypeId && !mappingOptionsById[externalRoomId]) {
      unknownMappingOptionsById[externalRoomId] = {
        isInvalid: true,
        isRemoved: true,
        id: externalRoomId,
        title: `${externalRoomId}`,
      };
    }
  });

  const unknownRooms = Object.values(unknownMappingOptionsById);

  return {
    rooms: unknownRooms,
  };
};

const buildMappingsPerExternalRoom = (mappingsAsArray) => {
  return mappingsAsArray.reduce((acc, mapping) => {
    const { room_type_code: roomTypeCode } = mapping.settings;

    acc[roomTypeCode] = acc[roomTypeCode] || {};
    acc[roomTypeCode][mapping.ratePlanId] = true;

    return acc;
  }, {});
};

export const useMappingLogic = (options) => {
  const {
    mappings,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  } = options;

  // in channel management for failed requests mappingOptions is set to null
  const mappingOptions = useMemo(() => options.mappingOptions || {}, [options.mappingOptions]);

  mappingSettings.rooms = mappingSettings.rooms || {};
  mappingOptions.rooms = mappingOptions.rooms || [];

  const { t } = useTranslation();
  const mappingsAsArray = useMemo(() => transformMappingsToArray(mappings), [mappings]);
  const mappingsPerExternalRoom = useMemo(() => buildMappingsPerExternalRoom(mappingsAsArray), [mappingsAsArray]);

  const unknownMappingOptions = useMemo(
    () => buildUnknownMappingOptions({ mappingOptions, mappingSettings }),
    [mappingOptions, mappingSettings],
  );

  const mappingItems = useMemo(
    () => buildMappingItems({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }),
    [mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t],
  );

  const handleRoomMappingChange = useCallback(({ externalRoom, mapping: newMapping, prevMapping }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: newMapping.roomTypeId,
      },
    };

    // if mapping for room was changed we need to remove all rate mappings for that room
    if (prevMapping) {
      const newMappings = mappingsAsArray.filter((mapping) => {
        return mapping.settings.room_type_code !== externalRoom.id;
      });

      if (newMappings.length !== mappingsAsArray.length) {
        onChangeMapping(transformMappingsToObject(newMappings));
      }
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeMapping, onChangeSettings]);

  const handleRoomMappingDelete = useCallback(({ externalRoom }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: null,
      },
    };

    // remove all rate mapping for that room
    const newMappings = mappingsAsArray.filter((mapping) => {
      return mapping.settings.room_type_code !== externalRoom.id;
    });

    if (newMappings.length !== mappingsAsArray.length) {
      onChangeMapping(transformMappingsToObject(newMappings));
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeSettings, onChangeMapping]);

  const handleRateMappingToggle = useCallback(({ roomTypeCode, ratePlanId }) => {
    const isExistingMapping = mappingsPerExternalRoom[roomTypeCode]?.[ratePlanId];

    let newMappings = mappingsAsArray;

    if (isExistingMapping) {
      newMappings = mappingsAsArray.filter((mapping) => {
        return !(mapping.settings.room_type_code === roomTypeCode && mapping.ratePlanId === ratePlanId);
      });
    } else {
      const newMapping = {
        ratePlanId,
        settings: {
          room_type_code: roomTypeCode,
        },
      };

      newMappings.push(newMapping);
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  return {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handleRateMappingToggle,
  };
};
