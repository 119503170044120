import currencies from "world-currencies";

import alphabetSort from "utils/alphabet_sort";

export default ({ only, label = "representation" } = []) => Object.values(currencies)
  .filter(({ iso }) => !only || only.includes(iso.code))
  .filter(({ iso }) => iso.code !== "HRK")
  .map(({ iso, name }) => {
    return {
      value: iso.code,
      [label]: `${name} (${iso.code})`,
    };
  })
  .sort(alphabetSort(label));
