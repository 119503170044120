import { forwardRef } from "react";
import { Input as AntInput } from "antd";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

import styles from "./textarea.module.css";

const DEFAULTS = {
  autoSize: { minRows: 3, maxRows: 5 },
};

export const TextArea = forwardRef((props, ref) => {
  const propsWithDefaults = { ...DEFAULTS, ...props };

  return (
    <AntInput.TextArea
      {...propsWithDefaults}
      ref={ref}
    />
  );
});

export const FormTextArea = forwardRef((props, ref) => {
  const { sharedProps, hookFormInputProps, antdFormFieldProps, inputProps } = parseProps(props);

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
          fieldClassName={styles.textarea}
        >
          <TextArea
            {...inputProps}
            {...input}
            ref={ref}
          />
        </AntdFormField>
      )}
    />
  );
});
