import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

import { IconPlaceholder } from "./icon_placeholder";

type Props = {
  className?: string;
  centerPlacement?: boolean;
  text?: string;
}

export function Loading({ className, centerPlacement, text }: Props) {
  const { t } = useTranslation();

  return (
    <IconPlaceholder
      centerPlacement={centerPlacement}
      className={className}
      dataTestid="loading"
      Icon={LoadingOutlined}
      text={text || t("general:loading")}
    />
  );
}
