import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/_v2/forms/buttons/submit";
import { FormInputNumber } from "components/_v2/forms/fields/input_number";
import { FormSelect } from "components/_v2/forms/fields/select";
import { FormSchemaRenderer } from "components/_v2/forms/hook_form";

import { DerivedAirBnbRatePlanInput } from "./derived_airbnb_rate_plan_input";

export const FormBody = ({ airBnbRatePlans, isSubmitting }) => {
  const { t } = useTranslation();

  const CANCELLATION_POLICY_CATEGORIES = [{
    value: "flexible",
    label: t("airbnb:listing:cancellation_policy_category:flexible"),
  }, {
    value: "moderate",
    label: t("airbnb:listing:cancellation_policy_category:moderate"),
  }, {
    value: "better_strict_with_grace_period",
    label: t("airbnb:listing:cancellation_policy_category:better_strict_with_grace_period"),
  }, {
    value: "strict_14_with_grace_period",
    label: t("airbnb:listing:cancellation_policy_category:strict_14_with_grace_period"),
  }, {
    value: "super_strict_30",
    label: t("airbnb:listing:cancellation_policy_category:super_strict_30"),
  }, {
    value: "super_strict_60",
    label: t("airbnb:listing:cancellation_policy_category:super_strict_60"),
  }, {
    value: "tiered_pricing_non_refundable",
    label: t("airbnb:listing:cancellation_policy_category:tiered_pricing_non_refundable"),
  }, {
    value: "firm_14",
    label: t("airbnb:listing:cancellation_policy_category:firm_14"),
  }];

  const mode = useWatch({ name: "mode" });
  const { resetField } = useFormContext();

  const fields = [{
    name: "name",
    label: t("common:terms:name"),
  }, {
    name: "cancellationPolicyCategory",
    component: FormSelect,
    label: t("common:terms:cancellation_policy_category"),
    options: CANCELLATION_POLICY_CATEGORIES,
  }, {
    name: "minLengthOfStay",
    component: FormInputNumber,
    label: t("common:terms:min_length_of_stay"),
  }, {
    name: "maxLengthOfStay",
    component: FormInputNumber,
    label: t("common:terms:max_length_of_stay"),
  }, {
    name: "minBookingLeadTimeHours",
    component: FormInputNumber,
    label: t("common:terms:min_booking_lead_time_hours"),
  }, {
    name: "maxBookingLeadTimeHours",
    component: FormInputNumber,
    label: t("common:terms:max_booking_lead_time_hours"),
  }, {
    name: "mode",
    component: FormSelect,
    label: t("airbnb:terms:rate_plan_mode"),
    options: [{
      value: "derived",
      label: "Derived",
    }, {
      value: "manual",
      label: "Manual",
    }],
  }];

  if (mode === "derived") {
    fields.push({
      type: "legend",
      label: t("common:headings:derived_rate_plan"),
    }, {
      name: "derivedRatePlanData",
      label: t("airbnb:terms:derived_airbnb_rate_plan"),
      component: DerivedAirBnbRatePlanInput,
      componentProps: {
        airBnbRatePlans,
        resetField,
      },
    });
  }

  return (
    <FormSchemaRenderer
      schema={{
        layout: {
          type: "scrollBox",
          footer: (
            <SubmitButton loading={isSubmitting}>
              {t("common:actions:save")}
            </SubmitButton>
          ),
        },
        fields,
      }}
    />
  );
};
