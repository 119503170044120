import React from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

type Props = {
  label?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const AddButton = ({ label, isDisabled, onClick }: Props) => {
  const { t } = useTranslation();
  const btnLabel = label || t("common:actions:add");

  return (
    <Button
      data-testid="add-button"
      icon={<PlusCircleOutlined />}
      type="link"
      disabled={isDisabled}
      onClick={onClick}
    >
      {btnLabel}
    </Button>
  );
};
