import { i18n } from "i18n";

export const sources = () => {
  return {
    telephone: i18n.t("channels:offline:sources:dict:telephone"),
    website: i18n.t("channels:offline:sources:dict:website"),
    email: i18n.t("channels:offline:sources:dict:email"),
    walk_in: i18n.t("channels:offline:sources:dict:walk_in"),
    other: i18n.t("channels:offline:sources:dict:other"),
  }
}
