import { React, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Layout, Menu } from "antd";

import * as Page from "components/page";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import styles from "./admin_page.module.css";

const { Content, Sider } = Layout;

export default function AdminPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();

  const selectedPage = location.pathname.split("/").at(-1);

  useEffect(() => {
    if (selectedPage === "admin") {
      navigate(pathBuilder(userAppRoutes.admin.users));
    }
  }, [selectedPage, navigate, userAppRoutes.admin.users]);

  const items = [{
    key: "users",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.users)),
    label: (
      <div>Users</div>
    ),
  }, {
    key: "properties",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.properties)),
    label: (
      <div>Properties</div>
    ),
  }, {
    key: "billing_accounts",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.billing_accounts)),
    label: (
      <div>Billing Accounts</div>
    ),
  }, {
    key: "application_access",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.application_access)),
    label: (
      <div>Application Access</div>
    ),
  }, {
    key: "white_label_partners",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.white_label_partners)),
    label: (
      <div>White Label Partners</div>
    ),
  },
  {
    key: "channel_adapters",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.channel_adapters)),
    label: (
      <div>Channel Adapters</div>
    ),
  },
  {
    key: "userRequestLimits",
    onClick: () => navigate(pathBuilder(userAppRoutes.admin.userRequestLimits)),
    label: (
      <div>User Request Limits</div>
    ),
  }];

  return (
    <Page.Main>
      <Layout className={styles.site_layout_background}>
        <Sider width={200}>
          <Menu className={styles.menu} items={items} mode="inline" selectedKeys={[selectedPage]} style={{ height: "100%" }} />
        </Sider>
        <Content style={{ overflow: "auto" }}>
          <Outlet />
        </Content>
      </Layout>
    </Page.Main>
  );
}
