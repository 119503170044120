import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, InputNumber, Typography } from "antd";

import RATE_ACTION_SUB_TYPES from "config/constants/rate_action_sub_types";
import RATE_ACTION_TYPES from "config/constants/rate_action_types";

import GroupRadioButton from "components/forms/inputs/group_radio_button";

import { formatNumber } from "../utils/format_number";

import rateActionSubTypeOptions from "./utils/rate_action_sub_type_options";
import rateActionTypeOptions from "./utils/rate_action_type_options";
import {
  getMinValue,
  getPercent,
  getRangeValues,
  resetArrayValue,
  roundValue,
  validateArrayValues,
} from "./utils";

import styles from "./inventory_value_override_controller.module.css";

const { SET, DECREASE } = RATE_ACTION_TYPES;
const { PERCENT, CURRENCY } = RATE_ACTION_SUB_TYPES;

const NULLABLE_RESTRICTIONS = ["max_availability"];

const MAX_PERCENT_VALUE = 99.9;
function InventoryValueOverrideController({
  restriction,
  value,
  ratePlan,
  date,
  defaultValue,
  error,
  booleanRestrictions,
  horizontalFormItemLayout,
  valid,
  dataTestid,
  eraseIsSelected,
  loadingAri,
  onToggleErase,
  onKeyPress,
  onChangeRateValue,
}) {
  const { t } = useTranslation();
  const [rateActionType, setRateActionType] = useState(SET);
  const [rateActionSubType, setRateActionSubType] = useState(CURRENCY);
  const [inputValue, setInputValue] = useState(getMinValue(value));
  const isNullableRestrictionSelected = NULLABLE_RESTRICTIONS.includes(restriction);
  const isInputDisabled = isNullableRestrictionSelected && eraseIsSelected || loadingAri;

  const valueInputRef = useRef(null);
  useEffect(() => { valueInputRef.current?.focus(); }, []);

  useEffect(() => {
    setInputValue(getMinValue(defaultValue));
    setRateActionType(SET);
  }, [defaultValue, date, restriction]);

  const getValuesByAction = useCallback(
    (type) => {
      const valuesByAction = {
        SET: { values: defaultValue, defaultValue: getMinValue(defaultValue) },
        default: { values: resetArrayValue(value), defaultValue: 0 },
      };

      const { [type]: result = valuesByAction.default } = valuesByAction;

      return result;
    },
    [value, defaultValue],
  );

  const handleChangeValues = useCallback(
    (values, inputVal) => {
      setInputValue(inputVal);
      onChangeRateValue(values);
    },
    [setInputValue, onChangeRateValue],
  );

  const handleSetRateActionType = useCallback(
    (type) => {
      setRateActionType(type);
      if (type === SET) {
        setRateActionSubType(CURRENCY);
      }
      return handleChangeValues(
        getValuesByAction(type).values,
        getValuesByAction(type).defaultValue,
      );
    },
    [getValuesByAction, handleChangeValues],
  );

  const handleSetRateActionSubType = useCallback(
    (type) => {
      setRateActionSubType(type);
      return handleChangeValues(
        getValuesByAction(type).values,
        getValuesByAction(type).defaultValue,
      );
    },
    [getValuesByAction, handleChangeValues],
  );

  const changeValueBySet = useCallback(
    (val) => {
      const newValues = value.map(() => Number(val));

      return handleChangeValues(newValues, val);
    },
    [value, handleChangeValues],
  );

  const increaseValueByPercent = useCallback(
    (val) => {
      const newValues = defaultValue.map((item) => {
        const percent = getPercent(val, item);
        return roundValue(Number(item) + Number(percent));
      });

      const isValidNewValue = defaultValue !== 0 && val !== 0;

      if (isValidNewValue) {
        handleChangeValues(newValues, val);
      }
    },
    [defaultValue, handleChangeValues],
  );

  const increaseValueByCurrency = useCallback(
    (val) => {
      const newValues = defaultValue.map((item) => {
        return roundValue(Number(item) + Number(val));
      });

      return handleChangeValues(newValues, val);
    },
    [defaultValue, handleChangeValues],
  );

  const decreaseValueByPercent = useCallback(
    (val) => {
      const newValues = defaultValue.map((item) => {
        const percent = getPercent(val, item);
        return roundValue(Number(item) - Number(percent));
      });

      const newValuesIsPositiveNumbers = validateArrayValues(newValues);
      const isValidNewValue = val <= Number(MAX_PERCENT_VALUE) && newValuesIsPositiveNumbers;

      if (isValidNewValue) {
        handleChangeValues(newValues, val);
      }
    },
    [defaultValue, handleChangeValues],
  );

  const decreaseValueByAmount = useCallback(
    (val) => {
      const newValues = defaultValue.map((item) => {
        return roundValue(Number(item) - Number(val));
      });

      const newValuesIsPositiveNumbers = validateArrayValues(newValues);
      const isValidNewValue = val < getMinValue(defaultValue) && newValuesIsPositiveNumbers;

      if (isValidNewValue) {
        handleChangeValues(newValues, val);
      }
    },
    [defaultValue, handleChangeValues],
  );

  const actionHandlersByType = useMemo(
    () => ({
      set: {
        currency: changeValueBySet,
      },
      increase: {
        percent: increaseValueByPercent,
        currency: increaseValueByCurrency,
      },
      decrease: {
        percent: decreaseValueByPercent,
        currency: decreaseValueByAmount,
      },
    }),
    [
      changeValueBySet,
      increaseValueByPercent,
      increaseValueByCurrency,
      decreaseValueByPercent,
      decreaseValueByAmount,
    ],
  );

  const handleSetInputValue = useCallback(
    (inputVal) => {
      const val = inputVal;
      let newValues;

      if (val <= 0) {
        newValues = resetArrayValue(value);
        return handleChangeValues(newValues, val);
      }

      return actionHandlersByType[rateActionType][rateActionSubType](val);
    },
    [value, rateActionType, handleChangeValues, rateActionSubType, actionHandlersByType],
  );

  const getMessageLabel = useCallback(() => {
    let message = null;
    const translatePath = "inventory_page:inventory_value_override_controller";

    const actionMessage = t(`${translatePath}:message:${rateActionType}`);

    const percentMessage = rateActionSubType === PERCENT && rateActionType !== SET;

    const typeMessage = percentMessage ? t(`${translatePath}:message:percent`) : ratePlan.currency;

    if (inputValue) {
      message = t(`${translatePath}:message_label`, {
        action: actionMessage,
        value: formatNumber(inputValue),
        type: typeMessage,
      });
    }

    return message;
  }, [t, inputValue, rateActionType, rateActionSubType, ratePlan]);

  const isRateRestriction = restriction === "rate";

  const message = isRateRestriction ? getMessageLabel() : null;

  const isDisabledSubOptions = rateActionType === SET;

  const maxInputValue = useMemo(() => {
    if (rateActionSubType === PERCENT && rateActionType === DECREASE) {
      return MAX_PERCENT_VALUE;
    }
    return undefined;
  }, [rateActionSubType, rateActionType]);

  const rateActionSubTypeOptionsWithCurrency = useMemo(() => {
    const options = rateActionSubTypeOptions(t);
    if (ratePlan.currency) {
      return options.map((item) => {
        if (item.value === CURRENCY) {
          return { ...item, label: ratePlan.currency };
        }
        return item;
      });
    }
    return options;
  }, [t, ratePlan]);

  const currentPriceRange = useMemo(() => getRangeValues(defaultValue), [defaultValue]);

  const validateStatus = valid ? "success" : "error";

  return (
    <>
      {restriction === "rate" && (
        <Form.Item
          {...horizontalFormItemLayout}
          label={t("inventory_page:modal:current_price_label")}
          className={styles.currentPrice}
        >
          {loadingAri && (
            <span><Typography.Text type="secondary">{t("general:loading")}</Typography.Text></span>
          )}
          {!loadingAri && (
            <span>{formatNumber(currentPriceRange)}</span>
          )}
        </Form.Item>
      )}

      {isRateRestriction && (
        <div className={styles.panel}>
          <GroupRadioButton
            disabled={loadingAri}
            value={rateActionType}
            className="rateActionTypes"
            options={rateActionTypeOptions(t)}
            onChange={handleSetRateActionType}
          />

          <GroupRadioButton
            value={rateActionSubType}
            options={rateActionSubTypeOptionsWithCurrency}
            onChange={handleSetRateActionSubType}
            disabled={loadingAri || isDisabledSubOptions}
          />
        </div>
      )}

      {booleanRestrictions.indexOf(restriction) === -1 && (
        <Form.Item
          {...horizontalFormItemLayout}
          label={t("inventory_page:modal:value_label")}
          validateStatus={validateStatus}
          help={valid ? message : error}
          data-testid={dataTestid}
        >
          <InputNumber
            ref={valueInputRef}
            placeholder={t("inventory_page:modal:value_label")}
            defaultValue={String(inputValue)}
            className={styles.input}
            value={String(inputValue)}
            onChange={handleSetInputValue}
            onKeyDown={onKeyPress}
            max={maxInputValue}
            min={0}
            disabled={isInputDisabled}
            stringMode
          />

          {isNullableRestrictionSelected && (
            <Checkbox
              data-testid="modal_erase_value"
              checked={eraseIsSelected}
              onChange={onToggleErase}
            >
              {t("inventory_page:inventory_value_override_controller:erase_value")}
            </Checkbox>
          )}
        </Form.Item>
      )}
    </>
  );
}

export default InventoryValueOverrideController;
