import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Popover, Upload } from "antd";
import csvParser from "csv-parser";
import fileReaderStream from "filereader-stream";
import _ from "lodash";

import styles from "./client_file_reader.module.css";

const mergeObjects = (a, b) => {
  return _.mergeWith(a, b, (targetValue, srcValue) => {
    if (_.isArray(targetValue)) {
      return targetValue.concat(srcValue);
    }

    return undefined;
  });
};

class ClientFileReader extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    status: null,
    errorMessage: null,
  };

  componentDidMount() {
    const { initialStatus } = this.props;

    this.setState({
      status: initialStatus,
    });
  }

  _buildAccept = () => {
    const { format } = this.props;

    const fileExtensions = {
      csv: ".csv",
    };

    return fileExtensions[format] || null;
  };

  _parseFile = (file) => {
    const { format, parserOptions, t } = this.props;

    if (format !== "csv") {
      throw new Error(`unsupported format: ${format}`);
    }

    if (!parserOptions) {
      throw new Error("no parserOptions provided");
    }

    const invertedMapping = {};

    Object.keys(parserOptions.mapping).forEach((field) => {
      const mappingField = parserOptions.mapping[field];

      Object.keys(mappingField).forEach((csvColumn) => {
        const channexColumnName = mappingField[csvColumn];

        invertedMapping[csvColumn] = invertedMapping[csvColumn] || {};

        invertedMapping[csvColumn] = {
          field: invertedMapping[csvColumn].field
            ? [...invertedMapping[csvColumn].field, field]
            : [field],
          name: channexColumnName,
        };
      });
    });

    return new Promise((resolve, reject) => {
      let result = {};
      const stream = fileReaderStream(file);

      stream.pipe(csvParser()).on("data", (data) => {
        const processedData = Object.keys(data).reduce((acc, csvColumn) => {
          const columnMapping = invertedMapping[csvColumn];

          // csv file can contain columns which are not mapped to our system
          // in this case we just skip them
          if (!columnMapping) {
            return acc;
          }

          const fieldsMapping = columnMapping.field.reduce((fieldAcc, field) => {
            fieldAcc[field] = fieldAcc[field] || {};
            fieldAcc[field][columnMapping.name] = fieldAcc[field][columnMapping.name] || [];

            const values = fieldAcc[field][columnMapping.name].length
              ? [...fieldAcc[field][columnMapping.name], data[csvColumn]]
              : [data[csvColumn]];

            return {
              ...fieldAcc,
              [field]: {
                ...fieldAcc[field],
                [columnMapping.name]: values,
              },
            };
          }, {});

          return mergeObjects(acc, fieldsMapping);
        }, {});

        result = mergeObjects(result, processedData);
      });

      stream.on("end", () => {
        result = Object.keys(result).reduce((acc, field) => {
          const fieldOptions = [];

          Object.keys(result[field]).forEach((attr) => {
            result[field][attr].forEach((value, i) => {
              const option = fieldOptions[i];

              if (!option) {
                fieldOptions.push({
                  [attr]: value,
                });

                return;
              }

              option[attr] = value;
            });
          });

          return {
            ...acc,
            [field]: fieldOptions,
          };
        }, {});

        if (this.isCsvValid(result, parserOptions.mapping)) {
          const resultWithValues = Object.keys(result).reduce((acc, field) => {
            return {
              ...acc,
              [field]: {
                values: result[field],
              },
            };
          }, {});

          resolve(resultWithValues);
        } else {
          const requiredColumns = Object.keys(parserOptions.mapping).reduce((acc, field) => {
            const fieldKeys = Object.keys(parserOptions.mapping[field]);

            return [...acc, ...fieldKeys];
          }, []);

          const columns = requiredColumns.join(", ");

          reject(new Error(t("client_file_reader:error", { columns })));
        }
      });
    });
  };

  isCsvValid = (result, mapping) => {
    return _.isEqual(Object.keys(result).sort(), Object.keys(mapping).sort());
  };

  render() {
    const { onChange, preview } = this.props;

    const { disabled, status, errorMessage } = this.state;

    return (
      <div data-testid="upload_file">
        <Upload
          accept={this._buildAccept()}
          disabled={disabled}
          showUploadList={false}
          beforeUpload={(file) => {
            this.setState({
              status: "loading",
              disabled: true,
            });

            this._parseFile(file)
              .then((content) => {
                this.setState({
                  status: "success",
                  disabled: false,
                });

                onChange(content);
              })
              .catch((err) => {
                this.setState({
                  status: "error",
                  errorMessage: err.message,
                  disabled: false,
                });
              });

            return false;
          }}
        >
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
        {status === "loading" ? (
          <span className={styles.uploadFieldContainer} data-testid="loading">
            <span className={styles.status}>
              <LoadingOutlined />
            </span>
          </span>
        ) : null}
        {status === "success" ? (
          <span className={styles.uploadFieldContainer} data-testid="success">
            <span className={styles.status}>
              <CheckCircleOutlined className={styles.status__successIcon} />
              <Popover placement="bottom" content={preview} trigger="click">
                <span className={styles.status__title}>Loaded</span>
              </Popover>
            </span>
          </span>
        ) : null}
        {status === "error" ? (
          <span className={styles.uploadFieldContainer} data-testid="error">
            <span className={styles.status}>
              <WarningOutlined className={styles.status__warningIcon} />
              <Popover placement="bottom" content={errorMessage} trigger="click">
                <span className={styles.status__title}>Error</span>
              </Popover>
            </span>
          </span>
        ) : null}
      </div>
    );
  }
}
export default withTranslation()(ClientFileReader);
