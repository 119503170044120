import React from "react";
import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { SubmitButton } from "components/_v2/forms/buttons/submit";

import { useWizard,WizardStep } from "../wizard";

export const StepResult = () => {
  const { t } = useTranslation();
  const { startAgain } = useWizard();

  return (
    <WizardStep dataTestid="result-step">
      <BodyContainer overflow dataTestid="result-section">
        <ScrollableContainer leftBorder={false} padding="16px 16px">
          <div>
            {t("mews:import:success_message")}
          </div>
        </ScrollableContainer>
        <FooterContainer>
          <SubmitButton dataTestid="import-another-btn" onClick={startAgain}>
            {t("mews:actions:import_another")}
          </SubmitButton>
        </FooterContainer>
      </BodyContainer>
    </WizardStep>
  );
};
