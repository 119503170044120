import React from "react";
import { withTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

function SaveButton({ t, onClick, className, disabled }) {
  return (
    <Button
      data-testid="button_save"
      type="primary"
      icon={<CheckCircleOutlined />}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {t("general:action:save")}
    </Button>
  );
}

export default withTranslation()(SaveButton);
