import React, { Component } from "react";
import { Form, Radio } from "antd";
import { Field } from "formik";

import getFieldLayout from "../utils/get_field_layout";

import styles from "styles/form_in_drawer.module.css";

class RadioInput extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  render() {
    const {
      name,
      options,
      label,
      view,
      errors,
      namespace,
      inputRef,
      onChange,
      onBlur = () => {},
    } = this.props;

    const formItemLayout = getFieldLayout(view);
    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => {
          return (
            <Form.Item {...formItemLayout} {...RadioInput.validation(errors)} label={label}>
              <Radio.Group
                ref={inputRef}
                value={field.value}
                onChange={(e) => (
                  onChange
                    ? onChange(e.target.value, field, form)
                    : form.setFieldValue(name, e.target.value)
                )}
                onBlur={onBlur}
                className={styles.buttonGroup}
              >
                {options.map((option) => (
                  <Radio.Button
                    key={option.value}
                    className={styles.buttonGroup__button__1_2}
                    value={option.value}
                  >
                    <span data-testid={`radio_group_option_${name}_${option.value}`}>
                      {option.representation}
                    </span>
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        }}
      </Field>
    );
  }
}

export default RadioInput;
