import { useQuery } from "@tanstack/react-query";
import store from "store";

import { Property } from "@/types/property";

const { Properties } = store;

export function useProperty(propertyId: string | undefined) {
  return useQuery<Property>({
    queryKey: ["property", propertyId],
    queryFn: () => Properties!.find(propertyId),
    enabled: !!propertyId,
  })
}
