import { useQuery } from "@tanstack/react-query";
import store from "store";

const { AdminUsers } = store;
export default function useProperties(userId) {
  return useQuery({
    queryKey: ["admin_user_properties", userId],
    queryFn: () => AdminUsers.properties(userId),
    enabled: !!userId,
    retry: false,
  });
}
