import React, { Component } from "react";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Form, Select, Spin } from "antd";
import _ from "lodash";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import ChannexModal from "components/channex_modal";
import InventoryRatePlan from "components/inventory/inventory_rate_plan";
import InventoryValueOverrideController from "components/inventory/inventory_value_override_controller";
import InventoryValueSwitchController from "components/inventory/inventory_value_switch_controller";
import RangePicker from "components/mobile/inventory/range_picker";

import { formatDate, isBetween } from "utils/dates";
import getArrayDays from "utils/get_array_days";
import sortRestrictionsByRepresentation from "utils/translated_sort";
import validateRestriction from "utils/validate_restriction";

import { trackValueOverrideCancel } from "./feature_tracking";

const { ARI } = store;

const VISIBLE_RESTRICTIONS = [
  "rate",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
  "min_stay_arrival",
  "min_stay_through",
  "max_stay",
  "availability",
];

const booleanRestrictions = [
  "stop_sell",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
];
const AVAILABILITY_RESTRICTION = "availability";
const BOOKED_RESTRICTION = "booked";

class InventoryValueOverrideModal extends Component {
  static propTypes = {
    t: PropTypes.func,
    isVisible: PropTypes.bool,
    restriction: PropTypes.string,
    date: PropTypes.array,
    values: PropTypes.any,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
    handleModalInputChange: PropTypes.func,
    minStayType: PropTypes.string,
  };

  state = {
    rateValue: this.props.value,
    defaultRateValue: this.props.value,
    visible: false,
    eraseIsSelected: false,
    loadingAri: this.props.loadingAri, // storybook only prop
  };

  componentDidMount() {
    const { isVisible, value } = this.props;

    const eraseIsSelected = value.some((i) => i === null);

    this.setState({
      visible: isVisible,
      eraseIsSelected,
    });
  }

  onClose = (callback) => {
    return () => {
      trackValueOverrideCancel();

      this.setState({
        visible: false,
      });

      callback();
    };
  };

  onChangeRateValue = (arr) => this.setState({ rateValue: arr });

  getAri = () => {
    const { ari } = this.props;
    const { additionalAri } = this.state;

    return additionalAri
      ? _.merge(ari, additionalAri)
      : ari;
  };

  getDifferentInitialValueByDate = (rangeDates, restriction) => {
    const { roomOrRateId, value } = this.props;
    const ari = this.getAri();

    const minValue = Math.min(...value);
    const initialValues = ari[roomOrRateId];

    return rangeDates.map((date) => {
      let result = 0;

      switch (restriction) {
        case "max_availability":
        case "availability":
          result = minValue;
          break;

        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          result = value.some((i) => i);
          break;

        case "rate":
          result = Number(_.get(initialValues, `${date}.${restriction}`, result));
          break;

        default:
          result = _.get(initialValues, `${date}.${restriction}`, result);

          break;
      }

      return result;
    });
  };

  getInitialValueWithChangesByDate = (rangeDates, restriction) => {
    const { changes, roomOrRateId, value, availability } = this.props;
    const ari = this.getAri();

    return rangeDates.map((date) => {
      const valueByDate = changes[date];

      let result = 0;

      const initialValues = ari[roomOrRateId] || availability[roomOrRateId];
      const isAriRestriction = _.get(initialValues, `${date}`, result);
      if (
        restriction === "closed_to_arrival"
        || restriction === "closed_to_departure"
        || restriction === "stop_sell"
        || restriction === "stop_sell_manual"
      ) {
        result = value.some((i) => i);
      }

      if (valueByDate && Object.keys(valueByDate) === restriction) {
        result = Number(valueByDate[restriction]);
      }

      if (isAriRestriction) {
        result = Number(isAriRestriction[restriction]);
      }

      if (restriction === "availability") {
        result = Number(isAriRestriction);
      }

      return result;
    });
  };

  getInitialValueByDate = (dates, restriction = this.props.restriction) => {
    const { changes, roomOrRateId, value, availability } = this.props;
    const { rateValue } = this.state;
    const ari = this.getAri();

    const rangeDates = getArrayDays(dates[0], dates[dates.length - 1]);

    if (!_.isEqual(value, rateValue)) {
      return this.getDifferentInitialValueByDate(rangeDates, restriction);
    }

    if (changes && Object.keys(changes).length > 0) {
      return this.getInitialValueWithChangesByDate(rangeDates, restriction);
    }

    return rangeDates.map((date) => {
      const initialValues = ari[roomOrRateId] || availability[roomOrRateId];

      let result = 0;

      switch (restriction) {
        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          result = value.some((i) => i);
          break;

        case "availability":
          result = Number(_.get(initialValues, `${date}`, result));
          break;

        case "rate":
          result = Number(_.get(initialValues, `${date}.${restriction}`, result));
          break;

        default:
          result = _.get(initialValues, `${date}.${restriction}`, result);
          break;
      }

      return result;
    });
  };

  onChangeDateValue = async (date) => {
    const { inventoryDates, restriction, propertyId, handleModalInputChange } = this.props;

    if (!date) {
      return;
    }

    handleModalInputChange("date")(date);

    const isModalDataRangeInInventoryDateRange = isBetween(date[0], inventoryDates[0], inventoryDates[inventoryDates.length - 1])
      && isBetween(date[1], inventoryDates[0], inventoryDates[inventoryDates.length - 1]);

    const isRateRestriction = restriction === "rate";

    if (isRateRestriction && !isModalDataRangeInInventoryDateRange) {
      this.setState({ loadingAri: true });

      try {
        const response = await ARI.get({
          property_id: propertyId,
          date: {
            gte: inventoryDates[inventoryDates.length - 1],
            lte: formatDate(date[1]),
          },
          restrictions: ["rate"],
        });

        await new Promise((resolve) => {
          this.setState({ additionalAri: response.data }, resolve);
        });
      } finally {
        this.setState({ loadingAri: false });
      }
    }

    const newValue = this.getInitialValueByDate(date);
    this.setState({
      rateValue: newValue,
      defaultRateValue: newValue,
    });
    handleModalInputChange("value")(newValue);
  };

  onChangeRestrictionValue = (val) => {
    const { date, handleModalInputChange } = this.props;

    const newValue = this.getInitialValueByDate(date, val);
    handleModalInputChange("restriction")(val);

    this.setState({ rateValue: newValue, defaultRateValue: newValue });

    handleModalInputChange("value")(newValue);
  };

  onChangeBoolValue = (val) => {
    const { handleModalInputChange } = this.props;
    const { rateValue } = this.state;

    const newValue = rateValue.map(() => val);
    this.setState({ rateValue: newValue, defaultRateValue: newValue });
    handleModalInputChange("value")(newValue);
  };

  onOk = () => {
    const { handleOk, value, restriction, handleModalInputChange } = this.props;
    const { rateValue } = this.state;
    let newValue = [];

    if (_.isEqual(value, rateValue)) {
      const minValue = Math.min(...value).toString();
      const stopSellValue = rateValue.some((i) => i);
      const valueWithNull = rateValue.some((i) => i === null);
      newValue = [];

      switch (restriction) {
        case "max_availability":
          newValue = rateValue.map((i) => (valueWithNull ? null : i));
          break;

        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          newValue = rateValue.map(() => stopSellValue);
          break;

        default:
          newValue = rateValue.map(() => minValue);
          break;
      }
    } else {
      switch (restriction) {
        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
        case "max_availability":
          newValue = [...rateValue];
          break;

        default:
          newValue = rateValue.map((item) => item.toString());
          break;
      }
    }

    handleModalInputChange("value")(newValue);

    return this.onClose(handleOk());
  };

  onKeyPress = (event) => {
    const { restriction } = this.props;
    const { rateValue } = this.state;
    const [isValid] = validateRestriction(restriction, rateValue);

    if (event?.key === "Enter" && isValid) {
      this.onOk();
    }
  };

  onToggleErase = () => {
    const { handleModalInputChange } = this.props;
    const { eraseIsSelected, rateValue } = this.state;

    this.setState({
      eraseIsSelected: !eraseIsSelected,
    });

    const newRestrictionValue = !eraseIsSelected ? null : 0;
    const newValue = rateValue.map(() => newRestrictionValue);
    this.onChangeRateValue(newValue);
    handleModalInputChange("value")(newValue);
  };

  disabledDate = (current) => {
    const { defaultCurrentDate } = this.props;

    return current.isBefore(defaultCurrentDate, "day");
  };

  restrictionTypes = (selectedRestriction) => {
    const { minStayType } = this.props;

    if (selectedRestriction === AVAILABILITY_RESTRICTION) {
      return [AVAILABILITY_RESTRICTION];
    }

    return VISIBLE_RESTRICTIONS.filter(
      (restriction) => restriction !== AVAILABILITY_RESTRICTION && restriction !== BOOKED_RESTRICTION,
    ).filter((restriction) => {
      switch (minStayType) {
        case "arrival":
          return restriction !== "min_stay_through";

        case "through":
          return restriction !== "min_stay_arrival";

        default:
          return true;
      }
    });
  };

  translateMinStay = (restriction) => {
    const { minStayType } = this.props;

    if (minStayType === "arrival" && restriction === "min_stay_arrival") {
      return "min_stay";
    }

    if (minStayType === "through" && restriction === "min_stay_through") {
      return "min_stay";
    }

    return restriction;
  };

  isDerived = (restriction, ratePlan) => {
    if (restriction === "stop_sell_manual") {
      restriction = "stop_sell";
    }

    return ratePlan[`inherit_${restriction}`];
  };

  render() {
    const { t, restriction, date, ratePlan, roomType, value, isMobile, handleCancel } = this.props;
    const { visible, eraseIsSelected, rateValue, loadingAri, defaultRateValue } = this.state;
    const { title: rateTitle, occupancy } = ratePlan;
    const { title: roomTitle } = roomType;
    const isDerived = this.isDerived(restriction, ratePlan);
    const derivedError = isDerived ? t("inventory_page:modal:derived_error") : null;

    const dataTestid = "modal_input";

    const [isValid, error] = validateRestriction(restriction, rateValue);

    return (
      <ChannexModal
        title={t("inventory_page:modal:title")}
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onClose(handleCancel)}
        okButtonProps={{
          disabled: !isValid || isDerived,
        }}
      >
        <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:modal:room_type_label")}>
          {roomTitle}
        </Form.Item>
        {rateTitle && ( // Need check for case when user clicks on room cell
          <Form.Item
            {...horizontalFormItemLayout}
            label={t("inventory_page:modal:rate_plan_label")}
          >
            <InventoryRatePlan title={rateTitle} occupancy={occupancy} />
          </Form.Item>
        )}
        <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:modal:date_range_label")}>
          <Flex align="center" gap={8}>
            <RangePicker
              dataTestid="date_range"
              defaultValue={date}
              value={date}
              isMobile={isMobile}
              disabled={loadingAri}
              onChange={this.onChangeDateValue}
              disabledDate={this.disabledDate}
            />
            {loadingAri && <Spin indicator={<LoadingOutlined spin />} />}
          </Flex>
        </Form.Item>
        <Form.Item
          {...horizontalFormItemLayout}
          label={t("inventory_page:modal:restriction_label")}
          validateStatus={isDerived ? "error" : "success"}
          help={derivedError}
        >
          <Select
            disabled={loadingAri}
            data-testid="modal_restriction"
            defaultValue={restriction}
            value={restriction}
            onChange={this.onChangeRestrictionValue}
          >
            {this.restrictionTypes(restriction)
              .sort(sortRestrictionsByRepresentation(t))
              .map((restrictionType) => (
                <Select.Option key={restrictionType} value={restrictionType}>
                  {t(`general:restrictions:${this.translateMinStay(restrictionType)}`)}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <InventoryValueOverrideController
          valid={isValid && !isDerived}
          booleanRestrictions={booleanRestrictions}
          dataTestid={dataTestid}
          eraseIsSelected={eraseIsSelected}
          horizontalFormItemLayout={horizontalFormItemLayout}
          {...this.props}
          value={rateValue}
          defaultValue={defaultRateValue}
          error={error}
          loadingAri={loadingAri}
          onToggleErase={this.onToggleErase}
          onKeyPress={this.onKeyPress}
          onChangeRateValue={this.onChangeRateValue}
        />
        {booleanRestrictions.indexOf(restriction) !== -1 && (
          <Form.Item
            {...horizontalFormItemLayout}
            label={t("inventory_page:modal:boolean_value_label")}
          >
            <InventoryValueSwitchController
              onChangeBoolValue={this.onChangeBoolValue}
              value={value}
            />
          </Form.Item>
        )}
      </ChannexModal>
    );
  }
}

export default InventoryValueOverrideModal;
