import { useQuery } from "@tanstack/react-query";
import store from "store";

const { ExtraCategories, Extras } = store;

export function useExtras(propertyId, { plainList = false } = {}) {
  const filter = {
    propertyId,
  };

  return useQuery({
    queryKey: ["extras", propertyId],
    queryFn: async () => {
      const extraCategories = await ExtraCategories.list(filter);
      const extras = await Extras.list(filter);

      let resultList;

      if (plainList) {
        resultList = extraCategories.data.flatMap((extraCategory) => ([
          {
            ...extraCategory,
            type: "category",
          },
          ...extras.data
            .filter((extra) => extra.extraCategoryId === extraCategory.id)
            .map((extra) => ({
              ...extra,
              type: "extra",
            })),
        ]));
      } else {
        resultList = extraCategories.data.map((extraCategory) => ({
          ...extraCategory,
          extras: extras.data.filter((extra) => extra.extraCategoryId === extraCategory.id),
        }));
      }

      return resultList;
    },
    enabled: !!propertyId,
    retry: false,
  });
}
