import React from "react";
import { useTranslation } from "react-i18next";

import Flexbox from "components/flexbox";
import * as Page from "components/page";
import { PageHeader } from "components/page";

import TriplaImportWorkflow from "./tripla_import_workflow";

export default function TriplaImportPage() {
  const { t } = useTranslation();

  return (
    <Page.Main>
      <Page.Content>
        <PageHeader title={t("applications_page:tripla_import:header")} />
        <Flexbox>
          <TriplaImportWorkflow />
        </Flexbox>
      </Page.Content>
    </Page.Main>
  );
}
