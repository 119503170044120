import React from "react";

import styles from "./popover.module.css";

function PopoverBody({ children }) {
  return (
    <div data-testid="popover_body" className={styles.popoverBody}>
      {children}
    </div>
  );
}

export default PopoverBody;
