import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import HotelPolicyEditForm from "./components/hotel_policy_edit_form";

class HotelPolicyEditDrawer extends Component {
  render() {
    const { t, id, visible, focusField, onCreate, onClose } = this.props;

    return (
      <ChannexDrawer
        title={t("properties_page:policies:hotel_policy:edit_dialog_title")}
        visible={visible}
        onClose={onClose}
        dataTestid="hotel_policy_drawer"
      >
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <HotelPolicyEditForm
              policyId={id}
              focusField={focusField}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
              onCreate={onCreate}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(HotelPolicyEditDrawer);
