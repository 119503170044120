import { ReactNode } from "react";
import { Space } from "antd";
import classNames from "classnames";

import styles from "./styles.module.css";

type Props = {
  label: string;
  marginBottom?: boolean;
  marginTop?: boolean;
  isError?: boolean
  actions?: ReactNode[];
  actionsSize?: "middle" | "small";
}

export const Legend = ({ label, marginBottom = true, marginTop = true, actionsSize = "small", isError, actions }: Props) => {
  const className = classNames(styles.legend, {
    [styles.woBottomMargin]: !marginBottom,
    [styles.woTopMargin]: !marginTop,
    [styles.withError]: !!isError,
    [styles.middleSizeActions]: actionsSize === "middle",
  });

  if (!actions || actions.length === 0) {
    return (
      <legend className={className}>
        <div className={styles.label}>
          {label}
        </div>
      </legend>
    );
  }

  return (
    <legend className={className}>
      <div className={styles.label}>
        {label}
      </div>
      <Space>
        {actions}
      </Space>
    </legend>
  );
};
