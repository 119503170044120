import { i18n } from "i18n";

export default (restriction, value) => {
  const restrictionConstructors = {
    availability: (val) => val,
    availability_offset: (val) => val,
    closed_to_arrival: (val) => (val ? "✓" : "☐"),
    closed_to_departure: (val) => (val ? "✓" : "☐"),
    max_availability: (val) => (val === null ? i18n.t("general:not_applicable") : val),
    max_stay: (val) => val,
    min_stay_arrival: (val) => val,
    min_stay_through: (val) => val,
    min_stay: (val) => val,
    rate: (val) => parseInt(val),
    stop_sell: (val) => (val ? "✓" : "☐"),
    stop_sell_manual: (val) => (val ? "✓" : "☐"),
    booked: (val) => val,
  };

  return restrictionConstructors[restriction](value);
};
