import React, { Component } from "react";
import { Form, Input } from "antd";
import classNames from "classnames";

import getFieldLayout from "./utils/get_field_layout";

import styles from "./inputs.module.css";

const DEFAULT_AUTOSIZE = { minRows: 5, maxRows: 10 };
const DEFAULT_VIEW = "horizontal";

class FormTextarea extends Component {
  handleChange = (e) => {
    const { onChange, name, hookForm } = this.props;

    if (hookForm) {
      onChange(e);
      return;
    }

    onChange(name, e.target.value);
  };

  validation = (errors) => {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join ? errors.join(" ") : errors,
      };
    }
    return {};
  };

  render() {
    const {
      view = DEFAULT_VIEW,
      name,
      label,
      extra,
      errors,
      className,
      autosize = DEFAULT_AUTOSIZE,
      inputRef,
      hookForm: _hookForm, // take out of ...rest
      grouped = false,
      groupMain = false,
      inputClassName,
      ...rest
    } = this.props;

    const formItemLayout = grouped ? {} : getFieldLayout(view);
    const formItemErrors = this.validation(errors);

    const formItemClassName = classNames(className, {
      [styles.formItemMainInGroup]: groupMain,
    });

    const itemProps = {
      label,
      extra,
      className: formItemClassName,
    };

    const inputClassname = classNames(styles.input, inputClassName);

    return (
      <Form.Item
        {...itemProps}
        {...formItemLayout}
        {...formItemErrors}
        data-testid={`textarea_${name}`}
      >
        <Input.TextArea
          {...rest}
          autoSize={autosize}
          className={inputClassname}
          ref={inputRef}
          onChange={this.handleChange}
        />
      </Form.Item>
    );
  }
}

export default FormTextarea;
