import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router";
import PropTypes from "prop-types";
import store from "store";

import CRUDTable from "components/crud_table";
import CrudTableWithFilters from "components/crud_table/crud_table_with_filters";
import * as Page from "components/page";
import { Content } from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

import ChannelEventsPageHeader from "./channel_events_page_header";

import pageStyles from "./channel_events_page.module.css";
import styles from "styles/dashboard.module.css";

const { ChannelEvents, Channels } = store;

const EMPTY_VALUE_PLACEHOLDER = "—";

class ChannelEventsPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    channel: null,
    columnsToHide: 0,
    tableQuery: "",
  };

  actions = () => {
    const { t, routes } = this.props;

    return (text, record) => {
      const { id, channel_id, type } = record;

      const linkToEventView = pathBuilder(routes.userAppRoutes.channelEvents.view, {
        channelId: channel_id,
        actionId: id,
      });

      const linkToErrorView = pathBuilder(routes.userAppRoutes.channelEvents.viewError, {
        channelId: channel_id,
        issueId: id,
      });

      const pathname = type === "error" ? linkToErrorView : linkToEventView;

      return (
        <span>
          <Link
            to={{
              pathname,
              state: { action: record },
            }}
          >
            {t("general:action:view")}
          </Link>
        </span>
      );
    };
  };

  componentDidMount() {
    const { channelId } = this.props;

    Channels.find(channelId).then((channel) => {
      this.setState({ channel });
    });
  }

  loadData = (filters, pagination, order) => {
    const { channelId } = this.props;
    const loadFilters = { channel_id: channelId };

    if (filters.name) {
      loadFilters.name = filters.name.join(",");
    }

    if (filters.inserted_at) {
      loadFilters.inserted_at = {};

      if (filters.inserted_at[0]) {
        loadFilters.inserted_at.gte = filters.inserted_at[0].startOf("day").utc().format();
      }

      if (filters.inserted_at[1]) {
        loadFilters.inserted_at.lte = filters.inserted_at[1].endOf("day").utc().format();
      }
    }

    return ChannelEvents.list(loadFilters, pagination, order);
  };

  columns = () => {
    const { t } = this.props;

    let dataColumns = [
      {
        title: t("channel_events_page:columns:action"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text) => t(`issues:types:${text}`),
      },
      {
        title: t("channel_events_page:columns:started_at"),
        dataIndex: ["payload", "started_at"],
        key: "payload.started_at",
        className: pageStyles.columnTimestamp,
        render: (_text, record) => {
          return (
            this.renderTimestampValue(record.payload.started_at || record.inserted_at)
          );
        },
      },
      {
        title: t("channel_events_page:columns:finished_at"),
        dataIndex: ["payload", "finished_at"],
        key: "payload.finished_at",
        className: pageStyles.columnTimestamp,
        render: this.renderTimestampValue,
      },
      {
        title: t("channel_events_page:columns:execution_time"),
        dataIndex: ["payload", "execution_time"],
        key: "payload.execution_time",
        className: pageStyles.columnTime,
        render: this.renderTableValue,
      },
      {
        title: t("channel_events_page:columns:result"),
        dataIndex: ["payload", "result"],
        className: pageStyles.columnResult,
        key: "payload.result",
        render: (text, record) => {
          return (
            <div
              className={[
                styles.dashboard__icon,
                record.payload.result === "success"
                  ? styles.dashboard__icons__success
                  : styles.dashboard__icons__fail,
              ].join(" ")}
            />
          );
        },
      },
    ];

    const actionColumns = [
      {
        className: pageStyles.columnActions,
        title: t("bookings_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions(),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  renderTableValue = (value) => {
    if (typeof value !== "number" && !value) {
      return EMPTY_VALUE_PLACEHOLDER;
    }
    return value;
  };

  renderTimestampValue = (value) => {
    const { t } = this.props;

    if (value) {
      return formatToLocal(value, t("formats:iso_date_with_time"));
    }

    return EMPTY_VALUE_PLACEHOLDER;
  };

  emptyMessage() {
    const { t } = this.props;
    return t("channel_events_page:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { channelId, routes } = this.props;
    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.channelEvents, { channelId });
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  getEventNameOptions() {
    const { t } = this.props;

    return [{
      value: "sync",
      label: t("channel_events:event_names:sync"),
    }, {
      value: "full_sync",
      label: t("channel_events:event_names:full_sync"),
    }, {
      value: "mapping_updated",
      label: t("channel_events:event_names:mapping_updated"),
    }, {
      value: "load_future_reservations",
      label: t("channel_events:event_names:load_future_reservations"),
    }, {
      value: "ack_bookings",
      label: t("channel_events:event_names:ack_bookings"),
    }, {
      value: "no_show_report",
      label: t("channel_events:event_names:no_show_report"),
    }, {
      value: "deactivate_channel",
      label: t("channel_events:event_names:deactivate_channel"),
    }, {
      value: "unmapped_rate",
      label: t("channel_events:event_names:unmapped_rate"),
    }, {
      value: "channel_stopped",
      label: t("channel_events:event_names:channel_stopped"),
    }, {
      value: "push_booking",
      label: t("channel_events:event_names:push_booking"),
    }, {
      value: "activate_channel",
      label: t("channel_events:event_names:activate_channel"),
    }, {
      value: "channel_settings_updated",
      label: t("channel_events:event_names:channel_settings_updated"),
    }];
  }

  render() {
    const { data, channelId } = this.props;
    const { channel } = this.state;

    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <Media query="(max-width: 479px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 480px) and (max-width: 755px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 756px) and (max-width: 999px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 1000px) and (max-width: 1265px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 1266px)" onChange={this.handleMediaChange(0)} />

        <Content>
          <ChannelEventsPageHeader channel={channel} channelId={channelId} />
          <CRUDTable.Container>
            <CrudTableWithFilters
              data={data}
              columns={this.columns}
              emptyMessage={this.emptyMessage()}
              showCreateMessage={false}
              defaultOrder={{ inserted_at: "desc" }}
              onTableQueryChange={this.handleTableQueryChange}
              loadData={this.loadData}
              filters={{
                name: {
                  type: "select",
                  options: this.getEventNameOptions(),
                  placeholder: "Action",
                  maxWidth: 320,
                },
                inserted_at: {
                  type: "dateRange",
                  maxWidth: 380,
                },
              }}
            />
          </CRUDTable.Container>
        </Content>

        <Outlet context={{ closePath }} />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ channelEvents }, { params }) => {
  const { channelId } = params;

  return {
    data: channelEvents.entities ? Object.values(channelEvents.entities) : null,
    channelId,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(ChannelEventsPage)));
