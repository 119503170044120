import React, { Component } from "react";

import withRouter from "routing/with_router";

import ChannexDrawer from "./channex_drawer";

class ChannexDrawerRoutable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
    };
  }

  closeDrawer = () => {
    this.setState({ isVisible: false });
  };

  redirectToClosePath = () => {
    const { navigate, matches, outletContext = {} } = this.props;
    let { closePath } = outletContext;

    // EXPERIMENTAL: If closePath is not defined, try to get it from the previous route
    // TODO: remove all closePath logic from the app
    if (!closePath) {
      closePath = matches[matches.length - 2].pathname;
    }

    if (closePath) {
      navigate(closePath);
    }
  };

  render() {
    const { children, title, dataTestid } = this.props;
    const { isVisible } = this.state;

    return (
      <ChannexDrawer
        title={title}
        dataTestid={dataTestid}
        visible={isVisible}
        onClose={this.closeDrawer}
        afterClose={this.redirectToClosePath}
      >
        {children}
      </ChannexDrawer>
    );
  }
}

export default withRouter(ChannexDrawerRoutable);
