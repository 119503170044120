import { useMutation } from "@tanstack/react-query";
import store from "store";

const { AirbnbRatePlans } = store;

export default function useCreateAction() {
  return useMutation({
    mutationFn: ({
      channelId,
      attrs,
    }) => AirbnbRatePlans.create(channelId, attrs),
  });
}
