export const isIframe = () => window.self !== window.top;

export const iframeInfo = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || -1;
  const height = window.innerHeight || document.documentElement.clientHeight || -1;

  return {
    iframeWidth: width,
    iframeHeight: height,
    iframeSize: `${width}x${height}`,
  };
}
