import { useQuery } from "@tanstack/react-query";
import store from "store";

const { Applications } = store;

export default function useApplicationAction({ appCode, action, query }, options = {}) {
  return useQuery({
    queryKey: ["room_spaces", appCode, action, query],
    queryFn: () => Applications.action({
      appCode,
      action,
      query,
    }),
    ...options,
  });
}
