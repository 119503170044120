import { combineReducers } from "redux";

import adminBillingAccountsReducer from "./admin_billing_accounts_reducer";
import adminPropertiesReducer from "./admin_properties_reducer";
import adminUsersReducer from "./admin_users_reducer";
import applicationsReducer from "./applications_reducer";
import bookingStatsReducer from "./booking_stats_reducer";
import bookingsReducer from "./bookings_reducer";
import cancellationPoliciesReducer from "./cancellation_policies_reducer";
import channelEventsReducer from "./channel_events_reducer";
import channelMessagesThreadsReducer from "./channel_messages_threads_reducer";
import channelsHealthReducer from "./channels_health_reducer";
import channelsReducer from "./channels_reducer";
import emailTemplatesReducer from "./email_templates_reducer";
import groupsReducer from "./groups_reducer";
import hotelPoliciesReducer from "./hotel_policies_reducer";
import liveFeedEventsReducer from "./live_feed_events_reducer";
import pciLogsReducer from "./pci_logs_reducer";
import propertiesHealthReducer from "./properties_health_reducer";
import propertiesReducer from "./properties_reducer";
import rateCategoriesReducer from "./rate_categories_reducer";
import ratePlansReducer from "./rate_plans_reducer";
import reviewsReducer from "./reviews_reducer";
import roomTypesReducer from "./room_types_reducer";
import scoresReducer from "./scores_reducer";
import sessionReducer from "./session_reducer";
import tasksReducer from "./tasks_reducer";
import taxSetsReducer from "./tax_sets_reducer";
import taxesReducer from "./taxes_reducer";
import userReducer from "./user_reducer";
import webhookLogsReducer from "./webhook_logs_reducer";
import webhooksReducer from "./webhooks_reducer";
import whiteLabelDomainsReducer from "./white_label_domains_reducer";
import whiteLabelEmailSettingsReducer from "./white_label_email_settings_reducer";
import whiteLabelPartnersReducer from "./white_label_partners_reducer";

const rootReducer = combineReducers({
  applications: applicationsReducer,
  session: sessionReducer,
  properties: propertiesReducer,
  propertiesHealth: propertiesHealthReducer,
  roomTypes: roomTypesReducer,
  ratePlans: ratePlansReducer,
  rateCategories: rateCategoriesReducer,
  channels: channelsReducer,
  channelsHealth: channelsHealthReducer,
  channelEvents: channelEventsReducer,
  emailTemplates: emailTemplatesReducer,
  user: userReducer,
  adminUsers: adminUsersReducer,
  adminBillingAccounts: adminBillingAccountsReducer,
  adminProperties: adminPropertiesReducer,
  whiteLabelPartners: whiteLabelPartnersReducer,
  whiteLabelDomains: whiteLabelDomainsReducer,
  whiteLabelEmailSettings: whiteLabelEmailSettingsReducer,
  tasks: tasksReducer,
  taxes: taxesReducer,
  taxSets: taxSetsReducer,
  groups: groupsReducer,
  bookings: bookingsReducer,
  hotelPolicies: hotelPoliciesReducer,
  cancellationPolicies: cancellationPoliciesReducer,
  liveFeedEvents: liveFeedEventsReducer,
  bookingStats: bookingStatsReducer,
  channelMessagesThreads: channelMessagesThreadsReducer,
  webhooks: webhooksReducer,
  webhook_logs: webhookLogsReducer,
  reviews: reviewsReducer,
  scores: scoresReducer,
  pciLogs: pciLogsReducer,
});

export default rootReducer;
