import React, { useEffect, useState } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { notification } from "antd";
import classNames from "classnames";
import store from "store";
import { getInstalledAppsByPropertyIdAndCode, getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getActivePropertyId, getAppMode } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import appModes from "config/constants/app_modes";

import Application from "components/application";

import NoAppPlaceholder from "./components/no_app_placeholder";
import Threads from "./components/threads";

import styles from "./messages_page.module.css";

const applicationParams = { applicationCode: appCodes.messages };
const { Applications } = store;

function MessagesPage() {
  const [isMobile, setIsMobile] = useState(false);
  const activeProperty = useSelector(getActivePropertyId);
  const appMode = useSelector(getAppMode);

  const appParams = { ...applicationParams };

  if (activeProperty) {
    appParams.propertyId = activeProperty;
  }

  const application = useSelector(getInstalledAppsByPropertyIdAndCode(activeProperty, "channex_messages"));
  const isInstalled = useSelector(getIsAppInstalled(appParams));

  const handleMediaChange = (newVal) => (matches) => matches && setIsMobile(newVal);

  useEffect(() => {
    if (isInstalled && application) {
      Applications.health(application).then((response) => {
        if (response.healthStatus === "error") {
          notification.error({
            message: "Message Application error",
            description: response.error.description || "Unknown Error",
          });
        }
      });
    }
  }, [activeProperty, isInstalled, application]);

  if (!isInstalled) {
    return <NoAppPlaceholder />;
  }

  const containerClassName = classNames(styles.layout, {
    [`${styles.layout__headless}`]: appMode === appModes.HEADLESS,
    [`${styles.layout__mobile}`]: isMobile,
  });

  return (
    <>
      <Media query="(max-width: 930px)" onChange={handleMediaChange(true)} />
      <Media query="(min-width: 931px)" onChange={handleMediaChange(false)} />
      <Application>
        <div className={containerClassName}>
          <Threads isMobile={isMobile} />
          <Outlet context={{ isMobile }} />
        </div>
      </Application>
    </>
  );
}

export default MessagesPage;
