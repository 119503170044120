import React, { Component } from "react";
import * as Sentry from "@sentry/react";

import config from "config";

import { consoleLogInDev } from "utils/console_log_in_dev";

import { ErrorBoundaryContextProvider } from "./error_boundary_context_provider";
import ErrorMessage from "./error_message";

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    if (!error.isHttp) {
      if (import.meta.env.DEV && !config.IS_TEST) {
        console.log(error);
      }

      Sentry.captureException(error);
    }

    return { error };
  }

  resetError = () => {
    this.setState({ error: null });
  };

  handleContextProvidedError = (error) => {
    this.setState({ error });

    if (error.isHttp && !config.IS_TEST) {
      throw error;
    }

    consoleLogInDev(error);

    Sentry.captureException(error);
  };

  render() {
    if (this.state.error) {
      return <ErrorMessage onReload={this.resetError} />;
    }

    return (
      <ErrorBoundaryContextProvider handleError={this.handleContextProvidedError}>
        {this.props.children}
      </ErrorBoundaryContextProvider>
    );
  }
}

export default ErrorBoundary;
