import React from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import PrimaryOccToggleTooltipContent from "./primary_occ_toggle_tooltip_content";

export default function PrimaryOccToggle({ isPrimary, onClick }) {
  const icon = isPrimary ? (
    <StarFilled onClick={onClick} />
  ) : (
    <StarOutlined onClick={onClick} />
  );

  return (
    <Tooltip
      placement="top"
      title={<PrimaryOccToggleTooltipContent isPrimary={isPrimary} />}
      data-testid="primary_occ_toggle"
    >
      {icon}
    </Tooltip>
  );
}
