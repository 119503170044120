import { createWizard } from "components/_v2/wizard";

import { MewsImportWizardData } from "./types";

const {
  Wizard,
  WizardStep,
  WizardSidebar,
  useWizard,
} = createWizard<MewsImportWizardData>();

export {
  Wizard,
  WizardStep,
  WizardSidebar,
  useWizard,
}
