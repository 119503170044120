import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import RateTitle from "components/rates/title";

import styles from "./extra_values_mapping_editor/styles.module.css";

export const MappingValue = ({ mapping, propertiesById, roomTypesById, ratePlansById, isInvalid, onClick }) => {
  const { t } = useTranslation();

  const ratePlanId = mapping?.ratePlanId;

  if (!ratePlanId) {
    return (
      <Button type="link" onClick={onClick} data-testid="mapping_select_button" danger>
        {t("channels_page:form:not_mapped")}
      </Button>
    );
  }

  const ratePlan = ratePlansById[ratePlanId] || { title: t("common:terms:unknown") };
  const roomType = roomTypesById[ratePlan.room_type_id] || { title: t("common:terms:unknown") };
  const property = propertiesById[roomType.property_id] || { title: t("common:terms:unknown") };

  return (
    <a type="link" className={styles.button} onClick={onClick} data-testid="mapping_select_button" disabled={isInvalid}>
      {property.title},{" "}{roomType.title},{" "}<RateTitle rate={ratePlan} />
    </a>
  );
};
