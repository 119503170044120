import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Input, Row } from "antd";

import { useWindowSize } from "hooks/use_window_size/use_window_size";

import { ItemSelector } from "./item_selector";
import { useFilteredCollection } from "./use_filtered_collection";
import { useSelectState } from "./use_select_state";

import styles from "../list_selector.module.css";

const ROW_SELECTOR_HEIGHT = 32;

export const SelectableList = (props) => {
  const {
    value,
    items,
    onChange,
    itemLabelField = "label",
    itemLabelFn,
    filterField = "label",
    filterFn,
  } = props;

  const { t } = useTranslation();
  const { filteredItems, handleFilterFieldChange } = useFilteredCollection({ collection: items, filterFn, filterField });
  const { allSelected, allIndeterminate, toggleAll, selectedItems, toggleItem } = useSelectState({ value, items, onChange });
  const windowSize = useWindowSize();
  const listRef = useRef();

  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: ROW_SELECTOR_HEIGHT,
    fixedWidth: true,
    minHeight: ROW_SELECTOR_HEIGHT,
  }), [filteredItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cache.clearAll();
    listRef.current?.forceUpdateGrid();
  }, [cache, windowSize]);

  const isItemsPresent = items.length !== 0;
  const isSearchResultsEmpty = filteredItems.length === 0;

  const noItemsMessage = "No Items";
  const searchEmptyMessage = "No items found";
  const selectAllLabel = "Select All Items";

  return (
    <div>
      <Input
        data-testid="search"
        className={styles.listSelector__search}
        placeholder={t("general:search_field_placeholder")}
        onChange={handleFilterFieldChange}
        type="text"
        suffix={<SearchOutlined />}
      />
      <div className={styles.listSelector__list}>
        {isItemsPresent && (
          <Row className={styles.listSelector__all}>
            <Col>
              <Checkbox
                data-testid="select_all_checkbox"
                checked={allSelected}
                indeterminate={allIndeterminate}
                onChange={toggleAll}
              >
                {selectAllLabel}
              </Checkbox>
            </Col>
          </Row>
        )}
        {!isItemsPresent && (<div>{noItemsMessage}</div>)}
        {isItemsPresent && isSearchResultsEmpty && (<div>{searchEmptyMessage}</div>)}

        <AutoSizer>
          {({ width }) => (
            <List
              ref={listRef}
              rowCount={filteredItems.length}
              height={214} // probably because of space for "select all" checkbox height is determined wrong, using fixed value for now
              width={width}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={({ index, columnIndex, key, parent, style }) => {
                const item = filteredItems[index];

                let label = item[itemLabelField];
                if (itemLabelFn) {
                  label = itemLabelFn(item);
                }

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    columnIndex={columnIndex}
                    parent={parent}
                    rowIndex={index}
                  >
                    <div style={style}>
                      <ItemSelector
                        item={item}
                        label={label}
                        selected={selectedItems[item.id]?.selected}
                        onToggle={toggleItem}
                      />
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
