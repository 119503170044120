import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import UserInviteFormWrapper from "components/user_invite_form/user_invite_form_wrapper";

function UserInviteDrawer() {
  const { t } = useTranslation();
  const title = t("user_invite_drawer:header");

  return (
    <ChannexDrawerRoutable title={title} dataTestid="user_invite_drawer">
      {({ handleCloseFromContent }) => (
        <DrawerFormWrapper>
          <UserInviteFormWrapper onClose={handleCloseFromContent} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawerRoutable>
  );
}

export default UserInviteDrawer;
