import { AppForm } from "components/_v2/forms/hook_form";

import { ServiceFormInner } from "./service_form_inner";
import { validationSchema } from "./validation_schema";

export const ServiceForm = ({ initialValue, currency, errors, onSubmit }) => {
  return (
    <AppForm
      initialValue={initialValue}
      errors={errors}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <ServiceFormInner currency={currency} />
    </AppForm>
  );
};
