import React from "react";
import { withTranslation } from "react-i18next";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

function CancelButton({ t, onClick, className }) {
  return (
    <Button
      data-testid="button_cancel"
      icon={<CloseCircleOutlined />}
      onClick={onClick}
      className={className}
    >
      {t("general:action:cancel")}
    </Button>
  );
}

export default withTranslation()(CancelButton);
