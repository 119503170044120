import { useQuery } from "@tanstack/react-query";
import store from "store";

export const useTimezones = () => {
  const timezonesQuery = useQuery({
    queryKey: ["timezones"],
    queryFn: () => store.http.send("GET", "timezones"),
    staleTime: 60 * (60 * 1000), // 1 hour
    cacheTime: 61 * (60 * 1000), // 1 hour 1 minute
  });

  const timezones = timezonesQuery.data?.data || [];

  const applyTz = (date, tzCode) => {
    if (timezonesQuery.isLoading || timezonesQuery.isError) {
      throw new Error("timezones are not loaded yet");
    }

    const timezone = timezones.find((tz) => tz.tzCode === tzCode);
    if (!timezone) {
      throw new Error(`timezone ${tzCode} not found`);
    }

    const utcOffset = timezone.utc;

    const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    const offsetHours = parseInt(utcOffset.split(":")[0]);
    const offsetMinutes = parseInt(utcOffset.split(":")[1]);

    return new Date(utc.setHours(utc.getHours() + offsetHours, utc.getMinutes() + offsetMinutes));
  };

  return {
    isLoading: timezonesQuery.isLoading,
    timezones: timezones.map(({ label, utc, tzCode }) => ({ label, value: tzCode, offset: utc })),
    applyTz,
  };
};
