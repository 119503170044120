import React from "react";
import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

import { flattenObject } from "utils/flatten_object";

import styles from "./styles.module.css";

export function GlobalErrors() {
  const { t } = useTranslation();
  const { errors } = useFormState();

  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  const errorObject = errors?.root?.global?.errors || {};

  if (Object.keys(errorObject).length === 0) {
    return null;
  }

  const flattenErrorObject = flattenObject({ obj: errorObject });

  const errorMessages = Object.entries(flattenErrorObject).map(([key, value], i) => (
    <div key={i}>{key}: {value}</div>
  ));

  return (
    <Alert
      className={styles.alert}
      message={t("general:validation_error")}
      description={errorMessages}
      type="error"
    />
  );
}
