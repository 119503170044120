import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import InventoryBulkUpdate from "./inventory_bulk_update";

export default function InventoryBulkUpdateDrawer({
  isMobile,
  roomTypes,
  visible,
  onSubmitBulkUpdate,
  onClose,
  defaultCurrentDate,
  minStayType,
}) {
  const { t } = useTranslation();

  return (
    <ChannexDrawer
      title={t("inventory_page:bulk_update:header")}
      visible={visible}
      onClose={onClose}
      dataTestid="bulk_update_drawer"
    >
      {({ handleCloseFromContent }) => (
        <DrawerFormWrapper withActions>
          <InventoryBulkUpdate
            isMobile={isMobile}
            roomTypes={roomTypes}
            onClose={handleCloseFromContent}
            onSubmitBulkUpdate={onSubmitBulkUpdate}
            defaultCurrentDate={defaultCurrentDate}
            minStayType={minStayType}
          />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
