import { channels } from "config/constants/channels/channel_codes";
import { validationMessages } from "config/constants/errors";

import { yup } from "utils/validation/yup";

export const validationSchema = yup.object().shape({
  propertyId: yup.string().required(validationMessages.required),
  otaName: yup.string().required(validationMessages.required),
  otaReservationCode: yup.string()
    .when("otaName", {
      is: channels.Offline.code,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required(validationMessages.required),
    }),
  meta: yup.object()
    .when("otaName", {
      is: channels.Offline.code,
      then: (schema) => schema.shape({
        source: yup.string().required(validationMessages.required),
      }),
      otherwise: (schema) => schema.nullable(),
    }),
  bookingDateRange: yup.array().required(validationMessages.required)
    .test("isFilled", validationMessages.required, (value) => {
      return value.every(Boolean);
    }),
});
