import React, { ReactNode } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

import { FormSchemaRenderer } from "./form_schema_renderer.tsx";
import { useAppForm, UseAppFormParams, UseAppFormReturn } from "./use_app_form";

type WithProvidedFormProps<TFieldValues extends FieldValues = FieldValues> = {
  form: UseAppFormReturn<TFieldValues>;
  children: React.ReactNode | ((form: UseAppFormReturn<TFieldValues>) => React.ReactNode);
};

const WithProvidedForm = <TFieldValues extends FieldValues = FieldValues>({
  form,
  children,
}: WithProvidedFormProps<TFieldValues>) => {
  return (
    <FormProvider {...(form as unknown as UseFormReturn<TFieldValues>)}>
      {typeof children === "function" ? children(form) : children}
    </FormProvider>
  );
};

type WithOwnFormProps<TFieldValues extends FieldValues = FieldValues> = Partial<UseAppFormParams<TFieldValues>> & {
  children: React.ReactNode;
};
const WithOwnForm = <TFieldValues extends FieldValues = FieldValues>({
  children,
  ...params
}: WithOwnFormProps<TFieldValues>) => {
  const paramsWithDefaults: UseAppFormParams<TFieldValues> = {
    mode: "onChange",
    reValidateMode: "onChange",
    ...params,
  };

  const form = useAppForm<TFieldValues>(paramsWithDefaults);

  return <WithProvidedForm form={form}>{children}</WithProvidedForm>;
};

export type AppFormProps<TFieldValues extends FieldValues = FieldValues> = Partial<UseAppFormParams<TFieldValues>> & {
  form?: UseAppFormReturn<TFieldValues>;
  children?: React.ReactNode;
  schema?: any;
  dataTestid?: string;
};

export const AppForm = <TFieldValues extends FieldValues = FieldValues>({
  form,
  schema,
  children,
  dataTestid,
  ...params
}: AppFormProps<TFieldValues>) => {
  if (schema && children) {
    throw new Error("You can't use schema and children at the same time");
  }

  let innerForm = children;
  if (!innerForm) {
    innerForm = <FormSchemaRenderer schema={schema} dataTestid={dataTestid} />;
  }

  if (form) {
    return (
      <WithProvidedForm form={form}>
        {innerForm}
      </WithProvidedForm>
    );
  }

  return (
    <WithOwnForm {...params}>
      {innerForm as ReactNode}
    </WithOwnForm>
  );
};
