import React, { CSSProperties } from "react";
import { Flex } from "antd";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";

type Props = {
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  sidebarWidth?: number;
  containerPadding?: CSSProperties["padding"];
  content: React.ReactNode;
  footer?: React.ReactNode;
  dataTestid?: string;
  footerNoPadding?: boolean,
  overflow?: boolean,
}

export const ScrollBox = ({
  header,
  sidebar,
  sidebarWidth = 250,
  containerPadding = "16px 16px",
  content,
  footer,
  footerNoPadding,
  overflow,
  dataTestid,
}: Props) => {
  return (
    <BodyContainer overflow={overflow} dataTestid={dataTestid}>
      {header && header}
      <Flex style={{ overflowY: "auto", height: "100%" }} align="flex-start">
        {sidebar && (
          <div style={{ maxWidth: sidebarWidth, padding: "16px 16px" }}>
            {sidebar}
          </div>
        )}
        <ScrollableContainer leftBorder={!!sidebar} padding={containerPadding}>
          {content}
        </ScrollableContainer>
      </Flex>
      {footer && <FooterContainer noPadding={footerNoPadding}>{footer}</FooterContainer>}
    </BodyContainer>
  );
};
