import { useEffect } from "react";
import { useFormState } from "react-hook-form";
import { uniq } from "lodash";

import { useWizard } from "./wizard";

const fieldToStepMap = {
  propertyId: 0,
  otaName: 0,
  otaReservationCode: 0,
  "meta.source": 0,
  bookingDateRange: 0,
  arrivalHour: 0,
  customer: 1,
  rooms: 2,
  services: 3,
  paymentCollect: 4,
  paymentType: 4,
  currency: 4,
  otaCommission: 4,
  deposits: 4,
  notes: 5,
};

export const WizardController = () => {
  const { errors } = useFormState();
  const { goTo, setStepsErrors } = useWizard();

  const errorString = JSON.stringify(errors);

  useEffect(() => {
    if (Object.keys(errors || {}).length > 0) {
      const stepsErrors = uniq(
        Object.keys(errors)
          .map((field) => fieldToStepMap[field])
          .filter((index => index !== undefined)),
      );

      if (stepsErrors.length === 0) {
        return;
      }

      setStepsErrors(stepsErrors);
      const earliestErrorStep = Math.min(...stepsErrors);

      goTo(earliestErrorStep);
    }
  }, [errorString, setStepsErrors, goTo]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
