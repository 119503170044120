import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../../utils/attributes_extractor";

let transport;

export default class ApaleoIntegration {
  constructor(container) {
    transport = container.transport;
  }

  oauth({ appName }) {
    return transport
      .send("POST", `applications/${appName}/connection_link`)
      .then(convertToCamelCase);
  }

  getAccount({ appName }) {
    return transport
      .send("POST", `applications/${appName}/get_account`)
      .then(convertToCamelCase);
  }

  getProperties({ appName }) {
    return transport
      .send("POST", `applications/${appName}/get_properties`)
      .then(convertToCamelCase);
  }

  getPropertyRoomRates({ appName, apaleoProperty }) {
    return transport
      .send("POST", `applications/${appName}/room_rates`, {
        hotel_code: apaleoProperty,
      })
      .then(convertToCamelCase);
  }

  connectExistingProperty({ appName, apaleoProperty, channexProperty }) {
    const body = convertToSnakeCase({
      property: {
        hotelCode: apaleoProperty,
        propertyId: channexProperty,
      },
    });

    return transport
      .send("POST", `applications/${appName}/connect_property`, body)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  createProperty({ appName, apaleoProperty, channexGroup, chosenRooms }) {
    const body = convertToSnakeCase({
      property: {
        hotelCode: apaleoProperty,
        groupId: channexGroup,
        roomRates: chosenRooms,
      },
    });

    return transport
      .send("POST", `applications/${appName}/connect_property`, body)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }
}
