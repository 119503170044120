import React from "react";
import { Alert } from "antd";

type Params = {
  error: string;
}

export const SectionError = ({ error }: Params) => {
  return (
    <Alert data-testid="section-error" type="error" style={{ marginBottom: 4 }} message={error} />
  );
};
