import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppForm } from "components/_v2/forms/hook_form";
import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";

import { validationSchema } from "./validation_schema";

export function ExtraCategoryForm({ value, isLoading, onSubmit }) {
  const { t } = useTranslation();
  const firstInputRef = useRef();

  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: value,
    validationSchema,
    fieldNames: ["title"],
    submitHandler: (formValue) => {
      onSubmit(formValue);
    },
  });

  const isEdit = !!value?.id;

  const handleFormKeyDown = (e) => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!firstInputRef.current) {
      return;
    }

    setTimeout(() => {
      firstInputRef.current?.focus();
    }, 300);
  }, [firstInputRef]);

  return (
    <div onKeyDown={handleFormKeyDown}>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={value.id}
            copy
          />
        )}
        <FormInput
          ref={firstInputRef}
          name="title"
          label={t("general:title")}
          errors={errors?.title?.message}
          control={control}
        />
      </div>

      <BottomActions>
        <SubmitButton onClick={() => void handleSubmit()} loading={isLoading}>
          {t("general:action:save")}
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
