import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikInput from "components/forms/inputs/formik/form_input";
import FormikSelect from "components/forms/inputs/formik/form_select";
import FormikWeekdays from "components/forms/inputs/formik/form_weekdays";
import Layouts from "components/layouts";

import MinNightInput from "./min_nights_input";

import drawerFormStyles from "styles/form_in_drawer.module.css";

class ListingAvailabilityForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape(
    (() => {
      const numberValidationSchema = yup
        .number(validationMessages.number)
        .required(validationMessages.required)
        .typeError(validationMessages.number)
        .min(0, validationMessages.greaterOrEqual(0));

      return {
        max_days_notice: yup
          .number(validationMessages.number)
          .required(validationMessages.required)
          .typeError(validationMessages.number)
          .min(-1, validationMessages.greaterOrEqual(-1)),
        booking_lead_time: numberValidationSchema,
        turnover_days: numberValidationSchema,
        default_max_nights: numberValidationSchema,
        default_min_nights: numberValidationSchema,
        day_of_week_check_in: yup
          .array()
          .required(validationMessages.required)
          .test("day_of_week_check_in", validationMessages.required, (value) => value.includes(true)),
        day_of_week_check_out: yup
          .array()
          .required(validationMessages.required)
          .test("day_of_week_check_out", validationMessages.required, (value) => value.includes(true)),
      };
    })(),
  );

  getDaysOptions = () => {
    const { value } = this.props;

    const optionalDays = value.max_days_notice === 0
      ? [{ value: 0, representation: this.t("unavailable"), disabled: true }]
      : [];

    const defaultDaysOptions = [
      { value: -1, representation: this.t("all_available") },
      { value: 30, representation: "30" },
      { value: 60, representation: "60" },
      { value: 90, representation: "90" },
      { value: 120, representation: "120" },
      { value: 150, representation: "150" },
      { value: 180, representation: "180" },
      { value: 210, representation: "210" },
      { value: 240, representation: "240" },
      { value: 270, representation: "270" },
      { value: 300, representation: "300" },
      { value: 330, representation: "330" },
      { value: 365, representation: "365" },
    ];

    return [...optionalDays, ...defaultDaysOptions];
  };

  getHoursOptions = () => {
    const firsDayValues = new Array(25)
      .fill()
      .map((val, index) => ({ value: index, representation: `${index}` }));

    const otherValues = [
      { value: 48, representation: "48" },
      { value: 72, representation: "72" },
      { value: 168, representation: "168" },
    ];

    return [...firsDayValues, ...otherValues];
  };

  getPreparationTimeOptions = () => [
    { value: 0, representation: "0" },
    { value: 1, representation: "1" },
    { value: 2, representation: "2" },
  ];

  getBookingAllowedCategoryOptions = () => [
    { value: "everyone", representation: this.t("booking_allowed_category:everyone") },
    { value: "well_reviewed_guests", representation: this.t("booking_allowed_category:well_reviewed_guests") },
    { value: "guests_with_verified_identity", representation: this.t("booking_allowed_category:guests_with_verified_identity") },
    { value: "well_reviewed_guests_with_verified_identity", representation: this.t("booking_allowed_category:well_reviewed_guests_with_verified_identity") },
    { value: "off", representation: this.t("booking_allowed_category:off") },
  ];

  t = (varPath) => {
    return this.props.t(`channels_page:form:listing:availability_drawer:${varPath}`);
  };

  render() {
    const {
      t,
      componentRef,
      value,
      submitting,
      errors,
      globalErrors,
      defaultValue,
      onRefresh,
      onChange,
      onSubmit,
    } = this.props;

    return (
      <>
        <Layouts.WithRefresh onRefresh={onRefresh}>
          <ChannexForm
            value={value}
            defaultValue={defaultValue}
            errors={errors}
            globalErrors={globalErrors}
            componentRef={componentRef}
            validationSchema={this.VALIDATION_SCHEMA}
            onChange={onChange}
          >
            {({ handleSubmit }) => (
              <Form onFinish={handleSubmit}>
                <legend>{this.t("maximum_days_legend")}</legend>
                <FormikSelect
                  name="max_days_notice"
                  placeholder={this.t("number_of_days")}
                  label={this.t("number_of_days")}
                  options={this.getDaysOptions()}
                />

                <legend>{this.t("minimum_days_legend")}</legend>
                <FormikSelect
                  name="booking_lead_time"
                  placeholder={this.t("number_of_hours")}
                  label={this.t("number_of_hours")}
                  options={this.getHoursOptions()}
                />

                <legend>{this.t("turnover_days_legend")}</legend>
                <FormikSelect
                  name="turnover_days"
                  placeholder={this.t("preparation_time")}
                  label={this.t("preparation_time")}
                  options={this.getPreparationTimeOptions()}
                />

                <legend>{this.t("length_of_stay_legend")}</legend>
                <FormikInput
                  name="default_max_nights"
                  placeholder={this.t("max_nights")}
                  label={this.t("max_nights")}
                />
                <FormikInput
                  name="default_min_nights"
                  placeholder={this.t("min_nights")}
                  label={this.t("min_nights")}
                />
                <FormikWeekdays label={this.t("checkin_days_legend")} name="day_of_week_check_in" />
                <FormikWeekdays
                  label={this.t("checkout_days_legend")}
                  name="day_of_week_check_out"
                />

                <legend>{this.t("minimal_nights_per_weekend_legend")}</legend>
                <MinNightInput />
              </Form>
            )}
          </ChannexForm>
        </Layouts.WithRefresh>
        <div className={drawerFormStyles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(ListingAvailabilityForm);
