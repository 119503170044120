import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import _ from "lodash";

import { Loading } from "components/_v2/states/loading";

import styles from "./shared.module.css";

export default function RoomCloudSettings({ installation }) {
  const { propertyId } = installation;
  const { t } = useTranslation();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (settings === null) {
      setSettings(installation.settings || { username: null, password: null });
    }
  }, [settings, installation]);

  if (settings === null) {
    return <Loading />;
  }

  return (
    <div>

      <Row>
        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:room_cloud:property_id")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>
        <Col span={12} className={styles.roomTitle}>
          <strong>{propertyId}</strong>
        </Col>

        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:room_cloud:username")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>
        <Col span={12} className={styles.roomTitle}>
          <strong>{settings.username}</strong>
        </Col>

        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:room_cloud:password")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>
        <Col span={12} className={styles.roomTitle}>
          <strong>{settings.password}</strong>
        </Col>
      </Row>

    </div>
  );
}
