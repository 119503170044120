import { validationMessages } from "config/constants/errors";

import { yup } from "utils/validation/yup";

import { validationSchema as roomValidationSchema } from "../../common/rooms/validation_schema";

export const validationSchema = yup.object().shape({
  rooms: yup.array()
    .of(roomValidationSchema)
    .min(1, validationMessages.required),
});
