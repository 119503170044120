import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

type Props = {
  onClick: () => void;
}

export const PrevButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button data-testid="prev-btn" block size="large" onClick={onClick}>
      {t("common:actions:prev")}
    </Button>
  );
};
