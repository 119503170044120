import store from "store";

import AppStorageManager from "utils/app_storage_manager";

import { analytics } from "@/analytics";

const { Auth } = store;

export default async function logout() {
  try {
    await Auth.signOut();
  } finally {
    store.ws.disconnect();
    AppStorageManager.clear();
    window.location.assign("/");
    analytics.reset();
  }
}
