import _ from "lodash";

const checkIfValidUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};

const isContainsDashes = (str) => {
  return str.includes("-");
};

export const stringToCamelCase = (data) => {
  if (checkIfValidUUID(data)) {
    return data;
  }

  if (isContainsDashes(data)) {
    return data;
  }

  return _.camelCase(data);
};

export const stringToSnakeCase = (data) => {
  if (checkIfValidUUID(data)) {
    return data;
  }

  if (isContainsDashes(data)) {
    return data;
  }

  return _.snakeCase(data);
};

const convertDataCase = (converter, data) => {
  if (!data || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((el) => convertDataCase(converter, el));
  }

  const dataWithUpdatedKeys = _.mapKeys(data, (_value, key) => {
    return converter(key);
  });

  const updatedData = _.mapValues(dataWithUpdatedKeys, (value) => convertDataCase(converter, value));

  return updatedData;
};

export const convertToCamelCase = convertDataCase.bind(this, stringToCamelCase);
export const convertToSnakeCase = convertDataCase.bind(this, stringToSnakeCase);
