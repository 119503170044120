import { useEffect } from "react";
import { Outlet, useMatches, useNavigate } from "react-router";
import { Layout } from "antd";

import * as Page from "components/page";

import styles from "./reports_page.module.css";

const { Content } = Layout;

export const ReportsPageLayout = () => {
  const navigate = useNavigate();
  const matches = useMatches();

  useEffect(() => {
    const isOnRootPage = matches[matches.length - 1].pathname === "/reports";

    if (isOnRootPage) {
      navigate("/reports/channels");
    }
  }, [matches, navigate]);

  return (
    <Page.Main>
      <Layout className={styles.site_layout_background}>
        <Content style={{ overflow: "auto" }}>
          <Outlet />
        </Content>
      </Layout>
    </Page.Main>
  );
};
