import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Step1 } from "./step1/step1";
import { Step2 } from "./step2/step2";
import { Step3 } from "./step3/step3";
import { Step4 } from "./step4/step4";
import { Step5 } from "./step5/step5";
import { Step6 } from "./step6/step6";
import { Wizard } from "./wizard";
import { WizardController } from "./wizard_controller";

type Props = {
  roomTypes: any;
  ratePlans: any;
  multiOccRatePlans: any;
  otaNames: any;
  isSubmitting: boolean;
  initialStep?: number;
}

export const NewBookingWizardFormBody = ({ roomTypes, ratePlans, multiOccRatePlans, otaNames, initialStep, isSubmitting }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  return (
    <Wizard
      initialStep={initialStep}
      steps={[{
        title: t("common:headings:basic_info"),
        component: <Step1 otaNames={otaNames} />,
      }, {
        title: t("common:headings:customer"),
        component: <Step2 />,
      }, {
        title: t("common:headings:rooms"),
        component: <Step3 roomTypes={roomTypes} ratePlans={ratePlans} multiOccRatePlans={multiOccRatePlans} />,
      }, {
        title: t("common:headings:services"),
        component: <Step4 />,
      }, {
        title: t("common:headings:payment"),
        component: <Step5 />,
      }, {
        title: t("common:headings:notes"),
        component: <Step6 isSubmitting={isSubmitting} />,
      }]}
      controller={<WizardController />}
      onComplete={(values) => {
        // TODO: need to have correct types for forms for custom handleSubmit
        // @ts-ignore
        handleSubmit(values);
      }}
    />
  );
};
