import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { Button, Flex, Typography } from "antd";
import { channelsApiClient } from "store/api_clients/channels";

import { isChannexValidationError } from "utils/is_channex_validation_error";

import { hopperHomesErrorParser } from "../api/error_parser/hopper_homes_error_parser";

const { Text } = Typography;

const hopperHomesSignupLink = "https://hosts.hopper.com/signup/channex";

const useCreateHostMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (params) => {
      try {
        const result = await channelsApiClient.createHost(params);

        return result;
      } catch (error) {
        if (error.isValidationError) {
          if (isChannexValidationError(error)) {
            throw error.body.errors.details;
          }

          throw hopperHomesErrorParser.parse(error.body.errors, t);
        }

        throw error;
      }
    },
  });
};

const Errors = ({ errors }) => {
  const { t } = useTranslation();

  if (errors === "No verified user found with provided email") {
    return (
      <Flex style={{ paddingTop: 4 }} vertical align="flex-end">
        <Typography.Text type="danger">{t("hopper_homes:errors:unknown_email")}</Typography.Text>
        <a href={hopperHomesSignupLink} target="_blank" rel="noreferrer">{hopperHomesSignupLink}</a>
      </Flex>
    );
  }

  if (errors === "User account already claimed") {
    const subject = "Hopper Homes - Support Request";
    const email = "support@channex.io";
    const mailToLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    return (
      <Flex style={{ paddingTop: 4 }} vertical align="flex-end">
        <Typography.Text type="danger">{t("hopper_homes:errors:email_already_taken")}</Typography.Text>
        <a href={mailToLink} target="_blank" rel="noreferrer">{email}</a>
      </Flex>
    );
  }

  return <Text style={{ paddingTop: 4, alignSelf: "flex-end" }} type="danger">{errors}</Text>;
};

export const CreateHostButton = ({ values, onErrors }) => {
  const { t } = useTranslation();
  const createHostMutation = useCreateHostMutation();
  const navigate = useNavigate();

  const isChannelCreated = !!values.id;

  if (isChannelCreated) {
    return null;
  }

  const { mappingSettings: _mappingSettings, ...params } = values.settings;

  const handleClick = () => {
    createHostMutation.mutate({
      channel: values.channel,
      groupId: values.group_id,
      properties: values.properties,
      title: values.title,
      ...params,
    }, {
      onSuccess: (createdChannel) => {
        const { id } = createdChannel;
        navigate(`/channels/${id}/edit`);
      },
      onError: (errors) => {
        const normalizedErrors = Object.entries(errors).reduce((acc, [key, value]) => {
          const hopperHomesErrorFieldNamesMapping = {
            displayName: "host_name",
          };
          const fieldName = hopperHomesErrorFieldNamesMapping[key] || key;

          return {
            ...acc,
            [fieldName]: value,
          };
        }, {});

        onErrors(normalizedErrors);
      },
    });
  };

  const isGlobalError = typeof createHostMutation.error?.global === "string";

  return (
    <Flex style={{ flexDirection: "column" }} justify="flex-end">
      <Button type="primary" style={{ alignSelf: "flex-end" }} loading={createHostMutation.isLoading} onClick={handleClick}>
        {t("hopper_homes:actions:create_host")}
      </Button>
      {isGlobalError && <Errors errors={createHostMutation.error.global} />}
    </Flex>
  );
};
