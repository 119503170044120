import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import RoomForm from "components/room_form";

import withRouter from "routing/with_router";

class RoomFormDrawerRoutable extends Component {
  render() {
    const { t, params } = this.props;
    const { roomId, section } = params;

    const title = t(roomId ? "rooms_page:edit_dialog_header" : "rooms_page:create_dialog_header");

    return (
      <ChannexDrawerRoutable title={title} dataTestid="room_drawer">
        {({ handleCloseFromContent }) => (
          <RoomForm id={roomId} activeTab={section} routable onClose={handleCloseFromContent} />
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(RoomFormDrawerRoutable));
