import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required(validationMessages.required),
  cancellationPolicyCategory: yup.string().required(validationMessages.required),
  minLengthOfStay: yup.number(validationMessages.number).typeError(validationMessages.number).nullable(),
  maxLengthOfStay: yup.number(validationMessages.number).typeError(validationMessages.number).nullable(),
  minBookingLeadTimeHours: yup.number(validationMessages.number).typeError(validationMessages.number).nullable(),
  maxBookingLeadTimeHours: yup.number(validationMessages.number).typeError(validationMessages.number).nullable(),
  derivedRatePlanData: yup.object().default({}).shape({
    parentRatePlanId: yup
      .string()
      .trim()
      .test("required", validationMessages.required, (value, ctx) => {
        const { mode } = ctx.from[1].value;

        if (mode === "manual") {
          return true;
        }

        return !!value;
      }),
    amount: yup
      .number()
      .positive(validationMessages.positive)
      .when("parentRatePlanId", {
        is: (fieldValue) => !!fieldValue,
        then: (schema) => schema
          .required(validationMessages.required)
          .typeError(validationMessages.number),
      }),
    modifierType: yup
      .string()
      .when("parentRatePlanId", {
        is: (fieldValue) => !!fieldValue,
        then: (schema) => schema
          .required(validationMessages.required)
          .typeError(validationMessages.number),
      }),
  }),
});
