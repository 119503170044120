import { useQuery } from "@tanstack/react-query";
import store from "store";

const { AvailabilityRules } = store;
export function useAvailabilityRules(propertyId, tableParams) {
  const { pagination, order } = tableParams || {};

  const filter = {
    propertyId,
  };

  return useQuery({
    queryKey: ["availability_rules", propertyId, tableParams],
    queryFn: () => AvailabilityRules.list(filter, pagination, order),
    enabled: !!propertyId && !!tableParams,
    retry: false,
  });
}
