import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Typography } from "antd";
import { _ } from "lodash";

import { Loading } from "components/_v2/states/loading";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";

import showErrorMessage from "utils/show_error_message";

import useApplicationGenerateApiKey from "./hooks/use_application_generate_api_key";

import styles from "./shared.module.css";

const { Text } = Typography;

export default function RoomPriceGenieSettings({ installation }) {
  const { t } = useTranslation();
  const [settings, setSettings] = useState(null);

  const { data: apiKey, refetch: generateAPIKey, isSuccess: isAPIKeyGenerated, isFetching: isAPIKeyGenerating } = useApplicationGenerateApiKey({ installation }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    onError: () => {
      showErrorMessage(t("applications_page:make_com:service_unavailable"));
    },
  });

  const isAPIKeyExisted = isAPIKeyGenerated && apiKey && apiKey.key;

  useEffect(() => {
    if (settings === null) {
      setSettings(installation.settings);
    }
  }, [settings, apiKey, installation]);

  if (settings === null) {
    return <Loading />;
  }

  return (
    <div>
      <Row>
        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:room_price_genie:api_key")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>

        <Col>
          <div style={{ lineHeight: "40px" }}>
            <Button
              loading={isAPIKeyGenerating}
              onClick={() => {
                generateAPIKey();
              }}
            >
              {t("applications_page:room_price_genie:generate_api_key")}
            </Button>
          </div>
        </Col>
      </Row>

      {isAPIKeyExisted && (
      <Row>
        <Col>
          <div>
            <p className={styles.apiKey__title}>{t("applications_page:room_price_genie:api_key_description")}</p>
            <div className={styles.apiKey__inner}>
              <Text code>{apiKey.key}</Text>
              <CopyToClipboard text={apiKey.key} />
            </div>
          </div>
        </Col>
      </Row>
      )}

    </div>
  );
}
