import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormNestedListSelector, TREE_VALUE_MODE } from "components/forms/inputs/form_nested_list_selector";
import FormItem from "components/forms/items/form_item";

const outValueFromInput = (value: InNodeValue[]): OutNodeValue[] => {
  return value.map(({ id, children }) => ({
    id,
    ratePlans: children
      ? children.map(({ id: rpId }) => ({
        id: rpId.replace(`${id}_`, ""),
      }))
      : [],
  }));
};

type RatePlan = {
  id: string;
  title: string;
}
type Room = {
  id: string;
  title: string;
  ratePlans: RatePlan[];
}

type InNodeValue = {
  id: string;
  children?: InNodeValue[];
}
type OutNodeValue = {
  id: string;
  ratePlans?: OutNodeValue[];
}

type TreeNode = {
  id: string;
  title: string;
  children?: TreeNode[];
}

type Props = {
  initialValue: InNodeValue[];
  disabled: boolean;
  errors: string | undefined;
  rooms: Room[] | undefined;
  onChange: (value: OutNodeValue[]) => void;
}
export const MewsRoomRatesSelector = ({ initialValue, disabled, errors, rooms, onChange }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<InNodeValue[]>(initialValue || []);

  const tree: TreeNode[] = useMemo(() => {
    return (rooms || []).map(({ id, title, ratePlans }) => {
      const node: TreeNode = {
        id,
        title,
        children: ratePlans.map((ratePlan) => ({
          id: `${id}_${ratePlan.id}`,
          title: ratePlan.title,
        })),
      }

      return node;
    });
  }, [rooms]);

  return (
    <FormItem
      data-testid="mews-rooms-rates-selector"
      validateStatus={errors ? "error" : ""}
      label={t("mews:labels:select_rooms_rates")}
    >
      <FormNestedListSelector
        errors={errors}
        disabled={disabled}
        valueMode={TREE_VALUE_MODE}
        standalone
        value={value}
        tree={tree}
        onChange={(newValue: InNodeValue[]) => {
          setValue(newValue);

          onChange(outValueFromInput(newValue));
        }}
      />
    </FormItem>
  );
};
