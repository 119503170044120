import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import printJS from "print-js";
import store from "store";

import showErrorMessage from "../../../../utils/show_error_message";

import useBookingExportListener from "./use_booking_export_listener";

const { Bookings } = store;

export function usePrintPdf() {
  const [isLoading, setIsLoading] = useState(false);
  const initPdfBuildMutation = useMutation({
    mutationFn: (bookingId) => Bookings.initPdfBuild(bookingId),
  });
  const { t } = useTranslation();

  const { join, subscribe, leave } = useBookingExportListener({
    onComplete: async (token) => {
      leave();
      const blob = await Bookings.pdfBlob(token);
      printJS(URL.createObjectURL(blob));
      setIsLoading(false);
    },
    onFailed: () => {
      showErrorMessage(t("general:errors:request_failed"));
      leave();
      setIsLoading(false);
    },
    onTimeout: () => {
      showErrorMessage(t("general:errors:request_failed"));
      leave();
      setIsLoading(false);
    },
  });

  const printPdf = async (bookingId) => {
    try {
      setIsLoading(true);
      join();
      const token = await initPdfBuildMutation.mutateAsync(bookingId);
      subscribe(token);
    } catch (e) {
      setIsLoading(false);
      leave();
      throw e;
    }
  };

  return {
    printPdf,
    isLoading,
  };
}
