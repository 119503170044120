import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Store from "store";

import GoogleIcon from "static/google_icon.svg";
import styles from "./google.module.css";

const { transport } = Store;

const AUTH_URL = "auth/google";

export default function Google() {
  const { t } = useTranslation();
  const href = transport.url(AUTH_URL);

  return (
    <Button
      data-testid="google_button"
      className={styles.button}
      size="large"
      type="primary"
      href={href}
    >
      <img src={GoogleIcon} alt={t("sign_in_page:google")} />
      {t("sign_in_page:google")}
    </Button>
  );
}
