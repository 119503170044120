import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { ConnectPropertyPayload, mewsApiClient } from "store/api_clients/applications/mews";
import { useUserChannel } from "store/channels_provider";
import { getGroupsOptions } from "store/storage/selectors/groups_selector";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getActiveGroup } from "store/storage/selectors/session_selector";

import { useWizard,WizardStep } from "../wizard";

import { ImportForm } from "./import_form";
import { useChannelSubscription } from "./use_channel_subscription";

const useConnectMutation = () => {
  return useMutation({
    mutationFn: (payload: ConnectPropertyPayload) => mewsApiClient.connectProperty(payload),
  });
};

export const StepConnectProperty = () => {
  const { t } = useTranslation();
  const { goNext, data, updateData } = useWizard();

  const groupsOptions = useSelector(getGroupsOptions);
  const propertyOptions = useSelector(getPropertiesOptions);
  const activeGroupId = useSelector(getActiveGroup);
  const [importInProgress, setImportInProgress] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const connectMutation = useConnectMutation();

  const userChannel = useUserChannel();

  if (!data?.token || !data?.mewsRooms) {
    throw new Error("Missing required data for step");
  }

  const { token, mewsRooms } = data;

  const userChannelSubscription = useChannelSubscription(userChannel, {
    eventTimeout: 60000,
    onTimeout: () => {
      setErrors({
        _global: t("mews:messages:mews_property_failed"),
      });
      setImportInProgress(false);
    },
  });

  const handleSubmit = async (values: any) => {
    setImportInProgress(true);

    try {
      userChannelSubscription.captureEvents({
        events: ["mews_property_connected", "mews_property_failed"],
      });

      let connectPayload: ConnectPropertyPayload;

      if (values.action === "connectExistingProperty") {
        connectPayload = {
          connectionToken: token,
          propertyId: values.channexPropertyId,
        };
      } else {
        connectPayload = {
          connectionToken: token,
          groupId: values.channexGroupId,
          roomRates: values.chosenRooms,
        };
      }

      const result = await connectMutation.mutateAsync(connectPayload);

      userChannelSubscription.processEvents(result.token, (eventName: string, payload: any) => {
        if (eventName === "mews_property_failed") {
          if (payload.message === "application_exists") {
            setErrors({
              channexPropertyId: t("mews:messages:application_exists_on_property"),
            });
          } else {
            setErrors({
              _global: t("mews:messages:mews_property_failed"),
            });
          }

          setImportInProgress(false);

          return;
        }

        updateData({
          applicationInstallationId: payload.application_installation_id,
        });

        userChannelSubscription.stop();

        goNext();
      });
    } catch (error: any) {
      setImportInProgress(false);
      userChannelSubscription.stop();

      if (error.isHttp) {
        throw error;
      }

      Sentry.captureException(error);
    }
  };

  const initialValue = {
    action: "connectExistingProperty",
    channexGroupId: activeGroupId || null,
    channexPropertyId: null,
    chosenRooms: [],
  };

  return (
    <WizardStep dataTestid="connect-property-step">
      <ImportForm
        groupsOptions={groupsOptions}
        propertyOptions={propertyOptions}
        mewsRooms={mewsRooms}
        initialValue={initialValue}
        errors={errors}
        isSubmitting={importInProgress}
        onFinish={handleSubmit}
      />
    </WizardStep>
  );
};
