import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { SubmitButton } from "components/_v2/forms/buttons/submit";
import { FormInputNumber } from "components/_v2/forms/fields/input_number";
import { FormSelect } from "components/_v2/forms/fields/select";
import { Legend } from "components/_v2/forms/legend";
import { StyledForm } from "components/forms/styled_form";

import { Services } from "../services";

import { Days } from "./days";
import { Guests } from "./guests";

const AGES = Array.from({ length: 18 }, (_, i) => i);

const ageOptions = AGES.map((age) => ({
  value: age,
  label: age.toString(),
}));

export const RoomFormInner = ({ roomTypes, ratePlans, multiOccRatePlans, currency, propertyId }) => {
  const { t } = useTranslation();
  const [days, roomTypeId, ratePlanId, adults] = useWatch({ name: ["days", "roomTypeId", "ratePlanId", "occupancy.adults"] });
  const { setValue, getValues } = useFormContext();

  const roomTypeOptions = (roomTypes || []).map((roomType) => ({
    value: roomType.id,
    label: roomType.title,
  }));

  const roomRatePlans = ratePlans.filter((rp) => rp.room_type_id === roomTypeId);
  const ratePlanOptions = roomRatePlans.map((ratePlan) => ({
    value: ratePlan.id,
    label: ratePlan.title,
  }));

  const occupancyChildren = useWatch({ name: "occupancy.children" });

  const onOccupancyChildrenChange = (newChildrenCount) => {
    const currentAges = getValues("occupancy.ages");

    const newAgeElements = Array.from({ length: newChildrenCount }, (_, i) => currentAges[i] ?? null);
    setValue("occupancy.ages", newAgeElements);
  };

  return (
    <BodyContainer dataTestid="room-form">
      <ScrollableContainer padding="16px 16px">
        <StyledForm>
          <FormSelect
            name="roomTypeId"
            label={t("common:terms:room_type")}
            placeholder={t("common:forms:fields:room_type:placeholder")}
            options={roomTypeOptions}
          />
          <FormSelect
            name="ratePlanId"
            label={t("common:terms:rate_plan")}
            placeholder={t("common:forms:fields:rate_plan:placeholder")}
            options={ratePlanOptions}
          />

          <Legend label={t("common:headings:occupancy")} />
          <FormInputNumber
            name="occupancy.adults"
            label={t("common:terms:adults")}
            placeholder={t("common:forms:fields:adults:placeholder")}
          />
          <FormInputNumber
            name="occupancy.children"
            label={t("common:terms:children")}
            placeholder={t("common:forms:fields:children:placeholder")}
            onFieldChange={onOccupancyChildrenChange}
          />
          {occupancyChildren > 0 && Array.from({ length: occupancyChildren }, (_, i) => i).map((_, index) => (
            <FormSelect
              key={index}
              name={`occupancy.ages.${index}`}
              label={t("common:headings:child_n_age", { n: index + 1 })}
              placeholder={t("common:forms:fields:age:placeholder")}
              options={ageOptions}
            />
          ))}

          <Days
            name="days"
            days={Object.keys(days || {})}
            currency={currency}
            occupancy={adults}
            propertyId={propertyId}
            ratePlanId={ratePlanId}
            ratePlans={ratePlans}
            multiOccRatePlans={multiOccRatePlans}
          />
          <Guests name="guests" />
          <Services name="services" currency={currency} />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
