import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(validationMessages.required),
  contactName: yup.string().trim(),
  contactEmail: yup.string().trim(),
  contactPhone: yup.string().trim(),
  agreementContentUrl: yup.string().trim(),
  agreementVersion: yup.string().trim(),
});
