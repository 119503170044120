import { useQuery } from "@tanstack/react-query";
import store from "store";

const { Integrations: { Apaleo } } = store;

export const useApaleoProperties = ({ appName }) => {
  return useQuery({
    queryKey: ["apaleo_properties"],
    queryFn: () => Apaleo.getProperties({ appName }),
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: 0,
  });
};
