import { useMutation } from "@tanstack/react-query";
import store from "store";

const { AirbnbRatePlans } = store;

export default function useUpdateAction() {
  return useMutation({
    mutationFn: ({ channelId, attrs }) => AirbnbRatePlans.update(channelId, attrs),
  });
}
