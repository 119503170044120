import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";
import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

import styles from "styles/form_in_drawer.module.css";

class UserInviteForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    email: yup.string().email().trim().required(validationMessages.required),
    name: yup.string().trim().required(validationMessages.required),
    preferred_language: yup.string().trim().required(validationMessages.required),
  });

  render() {
    const { t, componentRef, value, submitting, errors, onChange, onSubmit } = this.props;

    return (
      <>
        <ChannexForm
          defaultValue={{
            email: "",
            name: "",
            preferred_language: SUPPORTED_LANGUAGES[0].key,
          }}
          value={value}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              <FormikFormInput
                name="email"
                label={t("user_invite_drawer:form:email:label")}
                placeholder={t("user_invite_drawer:form:email:placeholder")}
              />
              <FormikFormInput
                name="name"
                label={t("user_invite_drawer:form:name:label")}
                placeholder={t("user_invite_drawer:form:name:placeholder")}
              />
              <FormikFormSelect
                name="preferred_language"
                label={t("user_invite_drawer:form:preferred_language:label")}
                placeholder={t("user_invite_drawer:form:preferred_language:placeholder")}
                options={SUPPORTED_LANGUAGES.map((lang) => ({ value: lang.key, representation: lang.value }))}
              />
            </Form>
          )}
        </ChannexForm>

        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(UserInviteForm);
