import { stringifyQueryParams } from "routing/utils";

import { httpClient } from "./http_client";

type GetRatesParams = {
  propertyId: string;
  fromDate: string;
  toDate: string;
}

export const inventoryApiClient = {
  getRates: async ({ propertyId, fromDate, toDate }: GetRatesParams) => {
    const params = stringifyQueryParams({
      filter: {
        date: {
          gte: fromDate,
          lte: toDate,
        },
        restrictions: "rate",
        property_id: propertyId,
      }
    });

    const path = `restrictions${params}`;
    const response = await httpClient.send("GET", path);

    return response.data;
  },
};
