import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex } from "antd";

import { NextButton } from "components/_v2/forms/buttons/next";
import { PrevButton } from "components/_v2/forms/buttons/prev";
import { CountrySelector } from "components/_v2/forms/fields/country_selector";
import { AppForm, FormSchemaRenderer } from "components/_v2/forms/hook_form";
import { Legend } from "components/_v2/forms/legend";

import { useWizard, WizardSidebar } from "../wizard";

import { validationSchema } from "./validation_schema";

export const StepFormInner = () => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();

  const fields = [{
    render: () => <Legend marginTop={false} label={t("common:headings:customer")} />,
  }, {
    name: "customer.name",
    label: t("common:terms:name"),
  }, {
    name: "customer.surname",
    label: t("common:terms:surname"),
  }, {
    name: "customer.address",
    label: t("common:terms:address"),
  }, {
    name: "customer.zip",
    label: t("common:terms:zip"),
  }, {
    name: "customer.city",
    label: t("common:terms:city"),
  }, {
    name: "customer.country",
    component: CountrySelector,
    label: t("common:terms:country"),
    placeholder: t("common:forms:fields:country:placeholder"),
  }, {
    name: "customer.phone",
    label: t("common:terms:phone"),
  }];

  return (
    <FormSchemaRenderer
      dataTestid="customer-form"
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <PrevButton onClick={goPrev} />
              <NextButton />
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step2 = () => {
  const { goNext } = useWizard();
  const { setValue, getValues, formState } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner />
    </AppForm>
  );
};
