import React from "react";

type CSSProperties = React.CSSProperties;

type FlexboxProps = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  fullHeight?: boolean;

  display?: Extract<CSSProperties["display"], "flex" | "inline-flex">;
  flex?: CSSProperties["flex"];
  flexDirection?: CSSProperties["flexDirection"];
  flexWrap?: CSSProperties["flexWrap"];
  flexFlow?: CSSProperties["flexFlow"];
  order?: CSSProperties["order"];
  flexGrow?: CSSProperties["flexGrow"];
  flexShrink?: CSSProperties["flexShrink"];
  flexBasis?: CSSProperties["flexBasis"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  alignContent?: CSSProperties["alignContent"];
} & React.HTMLAttributes<HTMLDivElement>

/**
 * @deprecated Use Ant Flex component instead.
 */
export default function Flexbox({
  children,
  className,
  style,
  display = "flex",
  flex = "1",
  flexDirection,
  flexWrap,
  flexFlow,
  order,
  flexGrow,
  flexShrink,
  flexBasis,
  justifyContent,
  alignItems,
  alignSelf,
  alignContent,
  fullHeight = false,
  ...divProps
}: FlexboxProps) {
  const flexStyles: CSSProperties = {
    display,
    flex,
    flexDirection,
    flexWrap,
    flexFlow,
    order,
    flexGrow,
    flexShrink,
    flexBasis,
    justifyContent,
    alignItems,
    alignSelf,
    alignContent,
  };

  const cleanFlexStyles = Object.fromEntries(
    Object.entries(flexStyles).filter(([_, value]) => value !== undefined),
  ) as CSSProperties;

  return (
    <div
      className={className}
      style={{
        ...cleanFlexStyles,
        ...(fullHeight ? { height: "100%" } : {}),
        ...style,
      }}
      {...divProps}
    >
      {children}
    </div>
  );
}
