export const BodyContainer = ({ children, overflow, dataTestid }) => (
  <div
    data-testid={dataTestid}
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: overflow ? "auto" : "initial",
    }}
  >
    {children}
  </div>
);
