export const download = (filename: string, data: string) => {
  const anchor = document.createElement("a");
  const objectUrl = window.URL.createObjectURL(new Blob([data]));

  document.body.appendChild(anchor);

  anchor.href = objectUrl;
  anchor.download = filename;
  anchor.click();
  document.body.removeChild(anchor);

  window.URL.revokeObjectURL(objectUrl);
}
