import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { useNoReplyThreadAction } from "./use_no_reply_trhread_action";

export function BookingCom({ thread, messages, onThreadReload }) {
  const mutation = useNoReplyThreadAction();
  const { t } = useTranslation();

  const handleNoReplyNeededClick = useCallback(() => {
    const perform = async () => {
      await mutation.mutateAsync(thread.id);
      onThreadReload();
    }

    void perform();
  }, [mutation, thread.id, onThreadReload]);

  const lastMessage = messages?.[0];

  if (!lastMessage) {
    return null;
  }

  const isLastMessageFromGuest = lastMessage.attributes.sender === "guest";

  if (!isLastMessageFromGuest) {
    return null;
  }

  return (
    <div
      style={{
        padding: 12,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button loading={mutation.isLoading} onClick={handleNoReplyNeededClick}>
        {t("messages:actions:no_reply_needed")}
      </Button>
    </div>
  );
}
