import { useQuery } from "@tanstack/react-query";
import store from "store";

const { Applications } = store;

export default function useApplicationGenerateApiKey({ installation }, options = {}) {
  return useQuery({
    queryKey: ["application_generate_api_key", installation],
    queryFn: () => Applications.generateApiKey(installation),
    ...options,
  });
}
