import React, { useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import { Drawer } from "antd";

import ErrorBoundary from "components/error_boundary";

import styles from "./styles.module.css";

const DRAWER_WIDTH = 700;
const DEFAULT_CALLBACK = () => {};

export const ChannexDrawer = ({
  title,
  children,
  visible,
  zIndex,
  onClose = DEFAULT_CALLBACK,
  afterClose = DEFAULT_CALLBACK,
  afterOpen = DEFAULT_CALLBACK,
}) => {
  const contentRef = useRef(null);

  const closeDrawer = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseFromDrawer = useCallback(() => {
    const performClose = async () => {
      if (!contentRef.current?.beforeClose) {
        closeDrawer();
        return;
      }

      try {
        await contentRef.current.beforeClose();
        closeDrawer();
      } catch (error) {
        Sentry.captureException(error);
        closeDrawer();
      }
    };

    void performClose();
  }, [closeDrawer]);

  const handleAfterOpenChange = useCallback((visibility) => {
    if (visibility) {
      afterOpen();
    } else {
      afterClose();
    }
  }, [afterOpen, afterClose]);

  return (
    <Drawer
      open={visible}
      title={title}
      placement="right"
      onClose={handleCloseFromDrawer}
      destroyOnClose
      afterOpenChange={handleAfterOpenChange}
      rootClassName={styles.drawer}
      zIndex={zIndex}
      width={DRAWER_WIDTH}
    >
      <ErrorBoundary>
        {typeof children === "function"
          ? children({
            handleCloseFromContent: closeDrawer,
            componentRef: contentRef,
          })
          : children}
      </ErrorBoundary>
    </Drawer>
  );
};
