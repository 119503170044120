import React from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";

export const ItemError = () => {
  const { t } = useTranslation();

  return (
    <Tag data-testid="item-error" icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>
  );
};
