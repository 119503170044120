import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Typography } from "antd";
import _ from "lodash";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import { Loading } from "components/_v2/states/loading";
import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/form_input";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./shared.module.css";

const { Applications } = store;
const ID_SLICE_LENGTH = 12;

const buildSettings = (roomTypes, ratePlans, installation) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    const ratePlanIds = ratePlans[roomType.id]?.map((rP) => rP.id);
    const mappedRatePlans = Object.values(installation.ratePlans).filter((rP) => ratePlanIds?.includes(rP.ratePlanId));

    acc[roomType.id] = {
      code: mappedRatePlans[0]?.settings?.roomTypeCode,
      title: roomType.title,
      ratePlans: {},
    };

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      const mappedRatePlan = mappedRatePlans.find((rP) => rP.ratePlanId === ratePlan.id);

      accRP[ratePlan.id] = {
        code: mappedRatePlan?.settings?.ratePlanCode,
        id: mappedRatePlan?.id,
        title: ratePlan.title,
      };

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, installation.settings || { hotelCode: null });
};

const buildFieldNames = (roomTypes, ratePlans) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    acc.push(`rooms.${roomType.id}.code`);

    _.forEach(ratePlans[roomType.id], (ratePlan) => {
      acc.push(`rooms.${roomType.id}.ratePlans.${ratePlan.id}.code`);
    });

    return acc;
  }, ["hotelCode"]);
};

export default function HotelNabeSettings({ installation, onClose }) {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setError,
    control,
  } = useForm();

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    reset(initialSettings);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading, installation, reset]);

  const formFieldNames = useMemo(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return [];
    }

    return buildFieldNames(roomTypes, ratePlans);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading]);

  const submit = (settingsData) => {
    const updatedSettings = _.omitBy(settingsData, (value, _key) => value?.title);

    const applicationRatePlans = Object.values(settingsData).reduce((acc, room) => {
      if (room?.code === undefined) {
        return acc;
      }

      return Object.entries(room.ratePlans).reduce((accRatePlans, [ratePlanId, rP]) => {
        if (rP?.code === undefined) {
          return accRatePlans;
        }

        return [
          ...accRatePlans,
          {
            id: rP.id,
            rate_plan_id: ratePlanId,
            settings: {
              room_type_code: room.code,
              rate_plan_code: rP.code,
            },
          },
        ];
      }, []).concat(acc);
    }, []);

    setSaveInProgress(true);

    Applications
      .update({ ...installation, settings: updatedSettings, ratePlans: null, rate_plans: applicationRatePlans })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, formFieldNames);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });
      })
      .finally(() => setSaveInProgress(false));
  };

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors.global} />

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:hotel_nabe:hotel_code")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="hotelCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  view="fullWidth"
                  placeholder={t("applications_page:hotel_nabe:hotel_code")}
                  errors={errors.hotelCode}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        <Row className={styles.columnHeaders}>
          <Col span={11}>{t("applications_page:hotel_nabe:channel_manager")}</Col>
          <Col span={1}>&rarr;</Col>
          <Col span={12}>{t("applications_page:hotel_nabe:hotel_nabe")}</Col>
        </Row>

        {roomTypes.map((roomType) => (
          <div key={roomType.id}>
            <Row>
              <Col span={11} className={styles.roomTitle}>
                {roomType.title}
              </Col>
              <Col span={1} className={styles.arrow}>
                &rarr;
              </Col>
              <Col span={12} className={styles.inputCol}>
                <Controller
                  name={`${roomType.id}.code`}
                  defaultValue={roomType.id.slice(-ID_SLICE_LENGTH)}
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      hookForm
                      view="fullWidth"
                      placeholder={t("applications_page:hotel_nabe:room_type_code")}
                      errors={errors[`${roomType.id}.code`]}
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>

            {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

            {ratePlans[roomType.id] && ratePlans[roomType.id].map((ratePlan) => (
              <Row key={ratePlan.id}>
                <Col span={11} className={styles.rateTitle}>
                  {ratePlan.title} {ratePlan.occupancy}
                </Col>
                <Col span={1} className={styles.arrow}>
                  &rarr;
                </Col>
                <Col span={12} className={styles.inputCol}>
                  <Controller
                    name={`${roomType.id}.ratePlans.${ratePlan.id}.code`}
                    defaultValue={ratePlan.id.slice(-ID_SLICE_LENGTH)}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        hookForm
                        view="fullWidth"
                        placeholder={t("applications_page:hotel_nabe:rate_plan_code")}
                        errors={errors[`${roomType.id}.ratePlans.${ratePlan.id}.code`]}
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>
            ))}
          </div>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={() => { clearErrors(); handleSubmit(submit)(); }} loading={saveInProgress}>
          {t("applications_page:hotel_nabe:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
