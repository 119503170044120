import { NestedList } from "./nested_list";
import { useCalcSelectorState } from "./use_calc_selector_state";

export const FormNestedListSelector = ({ value, tree, disabledValue = [], valueMode, onChange, ...listProps }) => {
  const { lookupTable, allSelected, allIndeterminate, allDisabled, toggleAll, toggleNode } = useCalcSelectorState({ value, disabled: disabledValue, tree, valueMode });

  const handleAllToggle = (event) => {
    const newValue = toggleAll(event.target.checked);
    onChange(newValue);
  };

  const handleItemToggle = (itemId) => (event) => {
    const newValue = toggleNode(itemId, event.target.checked);
    onChange(newValue);
  };

  return (
    <NestedList
      {...listProps}
      lookupTable={lookupTable}
      allSelected={allSelected}
      allIndeterminate={allIndeterminate}
      allDisabled={allDisabled}
      onAllToggle={handleAllToggle}
      onItemToggle={handleItemToggle}
    />
  );
};
