import { useQuery } from "@tanstack/react-query";
import store from "store";

const { AdminUsers } = store;
export default function useUser(userId) {
  return useQuery({
    queryKey: ["admin_users", userId],
    queryFn: () => AdminUsers.find(userId),
    enabled: !!userId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });
}
