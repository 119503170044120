import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import store from "store";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import { Loading } from "components/_v2/states/loading";

import { useMobile } from "../../use_mobile";

import ChannelTotalScore from "./channel_total_score";
import NonSelectedPropertyPlaceholder from "./non_selected_property_placeholder";
import SectionTitle from "./section_title";

import styles from "./styles.module.css";

const { Scores } = store;

export const PropertyScoreRoutableSection = () => {
  const isMobile = useMobile();
  const activeProperty = useSelector(getActivePropertyId);

  const [channelScores, setChannelScores] = useState(null);
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    function loadScores() {
      if (!activeProperty) {
        return;
      }

      Scores.scoreDetailed(activeProperty).then((response) => {
        const loadedChannelScores = response.relationships.otaScores.map((score) => score.data.attributes);
        const loadedProperty = response.relationships.property.data;

        setChannelScores(loadedChannelScores);
        setProperty(loadedProperty);
        setLoading(false);
      });
    },
    [activeProperty],
  );

  if (!activeProperty) {
    if (isMobile) {
      return null;
    }

    return <NonSelectedPropertyPlaceholder />;
  }

  if (loading) {
    return <Loading />;
  }

  const bodyClassname = classnames(styles.body, {
    [styles.bodyMobile]: isMobile,
  });

  const containerClassname = classnames(styles.container, {
    [styles.containerMobile]: isMobile,
  });

  return (
    <div className={containerClassname}>
      <SectionTitle title="Review Score" />

      <div className={bodyClassname}>
        {channelScores.map((channelScore) => (
          <ChannelTotalScore key={channelScore.channelId} score={channelScore} property={property} />
        ))}
      </div>
    </div>
  );
};
