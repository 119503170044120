import { createStore } from "redux";

import actions from "./actions";
import rootReducer from "./reducers";

function assignActions(target) {
  Object.keys(actions).forEach((action) => {
    if (typeof target[action] === "undefined") {
      target[action] = actions[action](target);
    } else {
      throw new Error(`${action} is present at storage object`);
    }
  });

  return target;
}

export const createStorage = (preloadedState) => {
  const storage = createStore(rootReducer, preloadedState);
  return assignActions(storage);
};
