import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import RateForm from "components/rate_form";

import withRouter from "routing/with_router";

class RateFormDrawerRoutable extends Component {
  render() {
    const { t, params } = this.props;
    const { rateId, roomId } = params;

    const title = rateId
      ? t("rates_page:edit_dialog_header")
      : t("rates_page:create_dialog_header");

    return (
      <ChannexDrawerRoutable title={title} dataTestid="rate_drawer">
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <RateForm
              id={rateId}
              roomTypeId={roomId}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(RateFormDrawerRoutable));
