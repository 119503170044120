import { useQuery } from "@tanstack/react-query";
import { adminUserRequestLimitsClient } from "store/api_clients/admin/user_request_limits";

export function useUserRequestLimits(tableParams) {
  const { searchQuery, filters = {}, pagination, order } = tableParams || {};

  const resultFilters = { ...filters };

  if (searchQuery) {
    resultFilters.title = searchQuery;
  }

  return useQuery({
    queryKey: ["user_request_limits", tableParams],
    queryFn: () => adminUserRequestLimitsClient.list(resultFilters, pagination, order),
    enabled: !!tableParams,
  });
}
