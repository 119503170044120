import React from "react";
import { useFieldArray, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "antd";
import get from "lodash/get";

import { IconDeleteButton } from "components/_v2/buttons/icons/delete";
import { IconEditButton } from "components/_v2/buttons/icons/edit";
import { Legend } from "components/_v2/forms/legend";
import { AddButton } from "components/_v2/forms/legend/buttons/add_button";
import { SectionEmpty } from "components/_v2/forms/sections/empty";
import { SectionError } from "components/_v2/forms/sections/error";
import { ItemError } from "components/_v2/forms/sections/item_error";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const ServiceItem = ({ service, errors, onRemove, onEdit }) => {
  const isError = Object.keys(errors || {}).length !== 0;

  return (
    <Flex data-testid="section-item" justify="space-between">
      <Typography.Text>
        {isError && <ItemError />}
        {service.name}
      </Typography.Text>
      <span>
        <IconEditButton onClick={onEdit} />
        <IconDeleteButton onClick={onRemove} />
      </span>
    </Flex>
  );
};

const buildNew = () => ({});

export const Services = ({ name, currency, layout }) => {
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({ name });
  const entries = useWatch({ name });
  const { isOpen, payload, open, close } = useDrawer();

  const formState = useFormState();
  const sectionErrors = get(formState.errors, name);
  const isEmpty = fields.length === 0;
  const isSectionError = !!sectionErrors?.message;

  return (
    <>
      <Legend
        marginTop={layout !== "embedded"}
        label={t("common:headings:services")}
        isError={isSectionError}
        actions={[
          <AddButton key="add" onClick={() => open({ entry: buildNew() })} />,
        ]}
      />

      {isEmpty && <SectionEmpty />}
      {isSectionError && <SectionError error={sectionErrors.message} /> }

      {fields.map((field, index) => (
        entries?.[index] && (
          <ServiceItem
            key={field.id}
            service={entries[index]}
            errors={sectionErrors?.[index]}
            onRemove={() => remove(index)}
            onEdit={() => {
              open({
                entry: entries[index],
                index,
              });
            }}
          />
        )
      ))}

      <EditDrawer
        visible={isOpen}
        service={payload?.entry}
        currency={currency}
        errors={sectionErrors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </>
  );
};
