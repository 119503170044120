import { useEffect } from "react";
import { useMatches } from "react-router-dom";

import { analytics } from "@/analytics";

const restorePathTemplate = (pathname: string, params: Record<string, string | undefined>) =>
  Object.entries(params)
    .reduce((template, [key, value]) => {
      if (!value) {
        return template;
      }

      return template.replace(value, `:${key}`);
    }, pathname);

export const usePageTracking = () => {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];

  const pathTemplate = restorePathTemplate(lastMatch.pathname, lastMatch.params);

  useEffect(() => {
    analytics.trackPageView(pathTemplate);
  }, [pathTemplate]);
};
