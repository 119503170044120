import React, { createRef, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

import { Legend } from "components/_v2/forms/legend";
import SubmitButton from "components/forms/buttons/submit_button";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormInput from "components/forms/inputs/form_input";
import FormInputNumber from "components/forms/inputs/form_input_number";
import FormSelect from "components/forms/inputs/form_select";

import styles from "styles/form_in_drawer.module.css";

const MIN_STATE_LENGTH = 100;
const MAX_STATE_LENGTH = 730;
const DEFAULT_MAX_DAY_ADVANCE = 365;

export default function SettingsForm({ value, errors, loading, focusField, onChange, onSubmit }) {
  const { t } = useTranslation();
  const { settings } = value;
  const { settings: settingsErrors } = errors;

  const inputRefs = useRef({
    min_price: createRef(),
    max_price: createRef(),
    allow_availability_autoupdate_on_confirmation: createRef(),
    allow_availability_autoupdate_on_modification: createRef(),
    allow_availability_autoupdate_on_cancellation: createRef(),
    state_length: createRef(),
    min_stay_type: createRef(),
    cut_off_time: createRef(),
    cut_off_days: createRef(),
    max_day_advance: createRef(),
  });

  const initialValue = useRef({ ...settings });

  useEffect(() => {
    if (focusField) {
      inputRefs?.current?.[focusField]?.current?.focus();
    }
  }, [focusField, inputRefs]);

  const handleCutOffTimeChange = useCallback(
    (fieldValue, fieldName) => {
      const newSettings = { ...settings, [fieldName]: fieldValue };

      newSettings.cut_off_days = fieldValue === null ? null : 0;

      onChange(newSettings, "settings");
    },
    [settings, onChange],
  );

  const handleAllowMaxDayAdvanceChange = useCallback(
    (isEnabled) => {
      const newSettings = { ...settings };

      newSettings.max_day_advance = isEnabled === true
        ? (initialValue.current.max_day_advance ?? DEFAULT_MAX_DAY_ADVANCE)
        : null;

      onChange(newSettings, "settings");
    },
    [settings, onChange],
  );

  const handleSettingsChange = useCallback(
    (fieldValue, fieldName) => {
      const newSettings = { ...settings, [fieldName]: fieldValue };

      onChange(newSettings, "settings");
    },
    [settings, onChange],
  );

  const getMinStayTypeOptions = () => [
    { value: "both", representation: t("properties_page:form:min_stay_type_options:both") },
    { value: "arrival", representation: t("properties_page:form:min_stay_type_options:arrival") },
    { value: "through", representation: t("properties_page:form:min_stay_type_options:through") },
  ];

  const getCutOffTimeOptions = () => {
    const options = [{ value: null, representation: "None" }];
    for (let i = 0; i < 48; i++) {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30;
      const time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

      options.push({ value: `${time}:00`, representation: time });
    }
    return options;
  };

  const getCutOffDaysOptions = () => {
    const options = [{ value: 0, representation: t("properties_page:form:cut_off_days_options:same_date") }];

    for (let i = 2; i < 31; i++) {
      options.push({ value: i - 1, representation: `${i} ${t("properties_page:form:cut_off_days_options:days")}` });
    }

    return options;
  };

  const isMaxDayAdvanceEnabled = settings.max_day_advance !== null;

  return (
    <Form onFinish={onSubmit}>
      <Legend marginTop={false} label={t("properties_page:form:price_settings_legend")} />
      <FormInput
        view="horizontal"
        type="text"
        name="min_price"
        placeholder={t("properties_page:form:min_price_placeholder")}
        label={t("properties_page:form:min_price_label")}
        value={settings.min_price}
        inputRef={inputRefs.current.min_price}
        onChange={handleSettingsChange}
        errors={settingsErrors?.min_price}
      />
      <FormInput
        view="horizontal"
        type="text"
        name="max_price"
        placeholder={t("properties_page:form:max_price_placeholder")}
        label={t("properties_page:form:max_price_label")}
        value={settings.max_price}
        onChange={handleSettingsChange}
        errors={settingsErrors?.max_price}
        extra={t("properties_page:form:price_settings_description")}
        inputRef={inputRefs.current.max_price}
      />
      <legend>{t("properties_page:form:availability_settings_legend")}</legend>
      <p>{t("properties_page:form:availability_settings_description")}</p>
      <FormCheckbox
        view="horizontal"
        name="allow_availability_autoupdate_on_confirmation"
        label={t("properties_page:form:autoupdate_on_confirmation_label")}
        defaultValue={settings.allow_availability_autoupdate_on_confirmation}
        onChange={handleSettingsChange}
        errors={settingsErrors?.allow_availability_autoupdate_on_confirmation}
        extra={t("properties_page:form:availability_on_confirmation_settings_description")}
        inputRef={inputRefs.current.allow_availability_autoupdate_on_confirmation}
      />
      <FormCheckbox
        view="horizontal"
        name="allow_availability_autoupdate_on_modification"
        label={t("properties_page:form:autoupdate_on_modification_label")}
        defaultValue={settings.allow_availability_autoupdate_on_modification}
        onChange={handleSettingsChange}
        errors={settingsErrors?.allow_availability_autoupdate_on_modification}
        extra={t("properties_page:form:availability_on_modification_settings_description")}
        inputRef={inputRefs.current.allow_availability_autoupdate_on_modification}
      />
      <FormCheckbox
        view="horizontal"
        name="allow_availability_autoupdate_on_cancellation"
        label={t("properties_page:form:autoupdate_on_cancellation_label")}
        defaultValue={settings.allow_availability_autoupdate_on_cancellation}
        onChange={handleSettingsChange}
        errors={settingsErrors?.allow_availability_autoupdate_on_cancellation}
        extra={t("properties_page:form:availability_on_cancellation_settings_description")}
        inputRef={inputRefs.current.allow_availability_autoupdate_on_cancellation}
      />
      <legend>{t("properties_page:form:state_length_legend")}</legend>
      <FormInputNumber
        view="horizontal"
        name="state_length"
        precision="0"
        min={MIN_STATE_LENGTH}
        max={MAX_STATE_LENGTH}
        placeholder={t("properties_page:form:state_length_placeholder")}
        label={t("properties_page:form:state_length_label")}
        value={settings.state_length}
        onChange={handleSettingsChange}
        errors={settingsErrors?.state_length}
        extra={t("properties_page:form:state_length_description")}
        inputRef={inputRefs.current.state_length}
      />
      <legend>{t("properties_page:form:min_stay_type_legend")}</legend>
      <p>{t("properties_page:form:min_stay_type_description")}</p>
      <p>{t("properties_page:form:min_stay_type_note")}</p>
      <FormSelect
        view="horizontal"
        label={t("properties_page:form:min_stay_type_label")}
        placeholder={t("properties_page:form:min_stay_type_label")}
        name="min_stay_type"
        value={settings.min_stay_type}
        inputRef={inputRefs.current.min_stay_type}
        onChange={handleSettingsChange}
        options={getMinStayTypeOptions()}
      />
      <legend>{t("properties_page:form:cut_off_time_legend")}</legend>
      <p>{t("properties_page:form:cut_off_time_description")}</p>
      <p>{t("properties_page:form:cut_off_time_note")}</p>
      <FormSelect
        view="horizontal"
        label={t("properties_page:form:cut_off_time_label")}
        placeholder={t("properties_page:form:cut_off_time_label")}
        name="cut_off_time"
        value={settings.cut_off_time}
        inputRef={inputRefs.current.cut_off_time}
        onChange={handleCutOffTimeChange}
        options={getCutOffTimeOptions()}
      />
      {settings.cut_off_time !== null && (
        <FormSelect
          view="horizontal"
          label={t("properties_page:form:cut_off_days_label")}
          placeholder={t("properties_page:form:cut_off_days_label")}
          name="cut_off_days"
          value={settings.cut_off_days}
          inputRef={inputRefs.current.cut_off_days}
          onChange={handleSettingsChange}
          options={getCutOffDaysOptions()}
        />
      )}
      <legend>{t("properties_page:form:max_day_advance_legend")}</legend>
      <p>{t("properties_page:form:max_day_advance_description")}</p>
      <p>{t("properties_page:form:max_day_advance_note")}</p>
      <FormCheckbox
        view="horizontal"
        label={t("properties_page:form:allow_max_day_advance_label")}
        defaultValue={isMaxDayAdvanceEnabled}
        onChange={handleAllowMaxDayAdvanceChange}
      />
      {isMaxDayAdvanceEnabled && (
        <FormInputNumber
          view="horizontal"
          label={t("properties_page:form:max_day_advance_label")}
          placeholder={t("properties_page:form:max_day_advance_label")}
          name="max_day_advance"
          value={settings.max_day_advance}
          inputRef={inputRefs.current.max_day_advance}
          onChange={handleSettingsChange}
          errors={settingsErrors?.max_day_advance}
          min={0}
        />
      )}
      <br />
      <div className={styles.actions}>
        <SubmitButton loading={loading}>{t("properties_page:submit_button")}</SubmitButton>
      </div>
    </Form>
  );
}
