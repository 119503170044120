import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import store from "store";
import { getUser } from "store/storage/selectors/session_selector";

const UserChannelContext = createContext(undefined);

export function UserChannelProvider({ children }: { children: React.ReactNode }) {
  const user = useSelector(getUser);
  const userId = user.id;
  // @ts-ignore
  const channel = useMemo(() => store.ws.socket.channel(`user:${userId}`), [userId]);

  useEffect(() => {
    channel.join();

    channel.onError(() => {
      Sentry.captureMessage("phoenix channel error: user", {
        extra: {
          userId,
        }
      });
    });

    return () => {
      channel.leave();
    };
  }, [channel, userId]);

  return (
    <UserChannelContext.Provider value={channel}>
      {children}
    </UserChannelContext.Provider>
  );
}

export function useUserChannel() {
  return useContext(UserChannelContext);
}
