import React, { useCallback } from "react";
import { useMatch, useNavigate } from "react-router";
import { Tag, Tooltip } from "antd";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import styles from "./groups_column.module.css";

const DEFAULT_ONCLICK_HANDLER = () => {};

export default function GroupTag({ id, title, onClick = DEFAULT_ONCLICK_HANDLER }) {
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();
  const isOnPropertiesPage = useMatch({ path: userAppRoutes.properties._path, end: false });

  const handleClick = useCallback(() => {
    onClick();

    const basePath = isOnPropertiesPage
      ? userAppRoutes.properties.editGroup
      : userAppRoutes.groups.edit;
    const pathParams = { groupId: id };
    const groupPath = pathBuilder(basePath, pathParams);

    navigate(groupPath);
  }, [navigate, id, isOnPropertiesPage, userAppRoutes, onClick]);

  return (
    <Tag className={styles.groupsItem} onClick={handleClick}>
      <Tooltip title={title}>{title}</Tooltip>
    </Tag>
  );
}
