import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useMatch } from "react-router";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getActiveGroup, getActivePropertyId, getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import useRouting from "routing/use_routing";

export default function Redirects({ children }) {
  const propertiesOptions = useSelector(getPropertiesOptions);
  const activeProperty = useSelector(getActivePropertyId);
  const activeGroup = useSelector(getActiveGroup);
  const appMode = useSelector(getAppMode);
  const { userAppRoutes } = useRouting();

  const { search } = useLocation();

  const isOnPropertiesPage = useMatch({ path: userAppRoutes.properties._path, end: false });
  const isOnProfilePage = useMatch({ path: userAppRoutes.userProfile._path, end: false });
  const isOnQueryErrorPage = useMatch({ path: userAppRoutes.queryErrorPage._path, end: false });
  const isAdminRootPage = useMatch({ path: userAppRoutes.admin._path, end: false });
  const hasEmptyProperties = propertiesOptions?.length === 0;
  const hasHeadlessModeErrors = appMode === APP_MODES.HEADLESS && (!activeProperty && !activeGroup);

  const urlParams = new URLSearchParams(search);
  const routeToRedirectTo = urlParams.get("redirect_to");

  if (hasHeadlessModeErrors && !isOnQueryErrorPage) {
    return <Navigate to={userAppRoutes.queryErrorPage._path} />;
  }

  if (hasEmptyProperties && !isOnPropertiesPage && !isOnProfilePage) {
    return <Navigate to={userAppRoutes.properties._path} />;
  }

  if (routeToRedirectTo) {
    return <Navigate to={routeToRedirectTo} />;
  }

  if (isAdminRootPage && isAdminRootPage.isExact) {
    return <Navigate to={userAppRoutes.admin.users._path} />;
  }

  return children;
}
