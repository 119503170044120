import { useCallback } from "react";
import { useController } from "react-hook-form";

export const getHookFormInputProps = (props) => {
  if (props.onChange) {
    throw new Error("onChange prop is not allowed, use onFieldChange instead, it would be called after React Hook Form state update and doesnt change the state");
  }

  return {
    name: props.name,
    control: props.control,
    onFieldChange: props.onFieldChange,
  };
};

export const HookFormInput = ({ name, control, render, onFieldChange }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback((event) => {
    field.onChange(event); // Update React Hook Form state
    onFieldChange?.(event); // Call custom handler if provided
  }, [field, onFieldChange]);

  return render({
    input: {
      ...field,
      onChange: handleChange,
    },
    field: {
      error,
    },
  });
};
