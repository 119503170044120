import React from "react";
import { Controller } from "react-hook-form";
import { Col, DatePicker, Form, Row } from "antd";

import getFieldLayout from "../utils/get_field_layout";

export function FormDatePicker({ name, label, errors, control, grouped, ...inputProps }) {
  const formItemLayout = grouped ? {} : getFieldLayout();

  const validation = () => {
    if (!errors) {
      return {};
    }

    const message = Array.isArray(errors) ? errors.join(". ") : errors;

    return {
      validateStatus: "error",
      help: <span data-testid="input_error_message">{message}</span>,
    };
  };

  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Form.Item
              {...formItemLayout}
              {...validation(errors)}
              label={label}
              data-testid={`datepicker_${name}`}
            >
              <DatePicker
                {...field}
                {...inputProps}
              />
            </Form.Item>
          )}
        />
      </Col>
    </Row>
  );
}
