import React from "react";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button } from "antd";

import styles from "./save_button.module.css";

export default function SaveButton({ disabled, loading, iconOnly, onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      icon={<CloudUploadOutlined />}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      className={styles.save_button}
      data-testid="save_changes_button"
    >
      {iconOnly || t("general:action:save_changes")}
    </Button>
  );
}
