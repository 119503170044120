import { useQuery } from "@tanstack/react-query";
import store from "store";

const { Integrations: { Apaleo } } = store;

export const useApaleoRoomRates = ({ apaleoProperty, appName, enabled }) => {
  return useQuery({
    queryKey: ["apaleo_room_rates", apaleoProperty],
    queryFn: () => Apaleo.getPropertyRoomRates({ appName, apaleoProperty }),
    enabled: !!apaleoProperty && enabled,
  });
};
