import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import compose from "recompose/compose";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import RadioInput from "components/forms/inputs/formik/radio";

class InternetAccessForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    internet_access_type: yup.string().required(validationMessages.required),
    internet_access_coverage: yup.string().when("internet_access_type", {
      is: (val) => val !== "none",
      then: () => yup.string().required(validationMessages.required),
    }),
    internet_access_cost_type: yup.string(),
    internet_access_cost: yup
      .number()
      .nullable()
      .when("internet_access_cost_type", {
        is: "paid",
        then: () => yup
          .number()
          .positive(validationMessages.positive)
          .required(validationMessages.required)
          .typeError(validationMessages.number),
      }),
  });

  DEFAULT_VALUE = {
    internet_access_type: "none",
    internet_access_coverage: "entire_property",
    internet_access_cost_type: "free",
  };

  static FIELDS = [
    "internet_access_type",
    "internet_access_coverage",
    "internet_access_cost_type",
    "internet_access_cost",
  ];

  inputRefs = {
    internet_access_type: React.createRef(),
    internet_access_coverage: React.createRef(),
    internet_access_cost_type: React.createRef(),
    internet_access_cost: React.createRef(),
  };

  componentDidMount() {
    const { focusField } = this.props;

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  componentDidUpdate() {
    const { value = {}, focusField, onChange } = this.props;
    const { internet_access_cost, internet_access_cost_type } = value;

    if (internet_access_cost && internet_access_cost_type !== "paid") {
      onChange({ ...value, internet_access_cost_type: "paid" });
    }

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  getTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "none",
        representation: t("policies:internet_access:type_options:none"),
      },
      {
        value: "wired",
        representation: t("policies:internet_access:type_options:wired"),
      },
      {
        value: "wifi",
        representation: t("policies:internet_access:type_options:wifi"),
      },
    ];
  };

  getCoverageOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "entire_property",
        representation: t("policies:internet_access:coverage_options:entire_property"),
      },
      {
        value: "public_areas",
        representation: t("policies:internet_access:coverage_options:public_areas"),
      },
      {
        value: "all_rooms",
        representation: t("policies:internet_access:coverage_options:all_rooms"),
      },
      {
        value: "some_rooms",
        representation: t("policies:internet_access:coverage_options:some_rooms"),
      },
      {
        value: "business_centre",
        representation: t("policies:internet_access:coverage_options:business_centre"),
      },
    ];
  };

  getCostOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "free",
        representation: t("policies:internet_access:cost_options:free"),
      },
      {
        value: "paid",
        representation: t("policies:internet_access:cost_options:paid"),
      },
    ];
  };

  isInternetPresent = (values) => {
    const { internet_access_type } = values;

    return internet_access_type && internet_access_type !== "none";
  };

  isPaidInternet = (values) => {
    const { internet_access_cost_type } = values;

    return internet_access_cost_type === "paid";
  };

  handleChange = (values) => {
    const { onChange } = this.props;
    const { _internet_access_cost, ...rest } = values;

    const resultingValue = this.isPaidInternet(values)
      ? values
      : { ...rest, internet_access_cost: null };

    onChange(resultingValue);
  };

  render() {
    const { t, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={InternetAccessForm.FIELDS}
        onChange={this.handleChange}
      >
        {({ values, handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormSelect
              name="internet_access_type"
              inputRef={this.inputRefs.internet_access_type}
              view="horizontal"
              placeholder={t("policies:internet_access:title")}
              label={t("policies:internet_access:title")}
              options={this.getTypeOptions()}
            />
            {this.isInternetPresent(values) && (
              <>
                <FormikFormSelect
                  name="internet_access_coverage"
                  inputRef={this.inputRefs.internet_access_coverage}
                  view="horizontal"
                  placeholder={t("policies:internet_access:dict:coverage")}
                  label={t("policies:internet_access:dict:coverage")}
                  options={this.getCoverageOptions()}
                />
                <RadioInput
                  label={t("policies:internet_access:dict:is_paid")}
                  name="internet_access_cost_type"
                  inputRef={this.inputRefs.internet_access_cost_type}
                  options={this.getCostOptions()}
                />
                {this.isPaidInternet(values) && (
                  <FormikFormInput
                    name="internet_access_cost"
                    inputRef={this.inputRefs.internet_access_cost}
                    view="horizontal"
                    placeholder={t("policies:internet_access:dict:cost")}
                    label={t("policies:internet_access:dict:cost")}
                    addonAfter={value.currency}
                  />
                )}
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(InternetAccessForm);
