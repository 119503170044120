import React, { Component } from "react";
import { Drawer } from "antd";

import ErrorBoundary from "components/error_boundary";

import styles from "./channex_drawer.module.css";

const DEFAULT_CALLBACK = () => {};

class ChannexDrawer extends Component {
  content = React.createRef();

  handleCloseFromDrawer = () => {
    if (!this.content.current || !this.content.current.beforeClose) {
      return this._closeDrawer();
    }

    return this.content.current.beforeClose().catch(this._closeDrawer);
  };

  _closeDrawer = () => {
    const { onClose = DEFAULT_CALLBACK } = this.props;

    onClose();
  };

  afterOpenChange = (visibility) => {
    const { afterClose = DEFAULT_CALLBACK, afterOpen = DEFAULT_CALLBACK } = this.props;

    if (visibility) {
      afterOpen();
    }

    if (!visibility) {
      afterClose();
    }
  };

  render() {
    const { title, children, visible, dataTestid, zIndex } = this.props;

    return (
      (
        <Drawer
          title={title}
          placement="right"
          onClose={this.handleCloseFromDrawer}
          open={visible}
          destroyOnClose
          afterOpenChange={this.afterOpenChange}
          rootClassName={styles.drawer}
          data-testid={dataTestid}
          zIndex={zIndex}
          width={700}
        >
          <ErrorBoundary>
            {typeof children === "function"
              ? children({
                handleCloseFromContent: this._closeDrawer,
                componentRef: this.content,
              })
              : children}
          </ErrorBoundary>
        </Drawer>
      )
    );
  }
}

export default ChannexDrawer;
