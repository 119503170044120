import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { Col, notification, Row } from "antd";
import store from "store";
import { getActiveFeatureFlags } from "store/storage/selectors/session_selector";

import { features } from "config/feature_flags";

import AnnouncementsWidget from "pages/dashboard_page/components/announcements_widget";
import BookingSourcesWidget from "pages/dashboard_page/components/booking_sources_widget";
import LiveFeedEventsWidget from "pages/dashboard_page/components/live_feed_events_widget";

import styles from "./dashboard_page.module.css";

const { Auth } = store;

const NOTIFICATION_DURATION = 10;

function DashboardPage() {
  const activeFeatureFlags = useSelector(getActiveFeatureFlags);
  const isBookingSourcesViewEnabled = activeFeatureFlags[features.BOOKING_SOURCES_WIDGET];

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const confirmationToken = location && location.state && location.state.confirmationToken;
    if (!confirmationToken) {
      return;
    }

    Auth.confirmRegistration(confirmationToken)
      .then(() => {
        notification.success({
          duration: NOTIFICATION_DURATION,
          message: t("account_confirmation:success"),
        });
      })
      .catch(() => {
        notification.error({
          duration: NOTIFICATION_DURATION,
          message: t("account_confirmation:error"),
        });
      });
  }, [location, t]);

  return (
    <>
      <div className={styles.content}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col flex="auto">
                {isBookingSourcesViewEnabled && (
                  <BookingSourcesWidget />
                )}
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <AnnouncementsWidget />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12}>
            <LiveFeedEventsWidget />
          </Col>
        </Row>
      </div>

      <Outlet context={{ closePath: "/" }} />
    </>
  );
}

export default DashboardPage;
