import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AdminWlPartners } = store;

export function useUpdateAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, attrs }) => AdminWlPartners.update(id, attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries("admin_wl_partners");
    },
  });
}
