import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router";
import { Row } from "antd";
import { compose } from "recompose";
import { getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import AuthFormSubTitle from "components/auth_form_sub_title";
import AuthFormTitle from "components/auth_form_title";
import SocialAuth from "components/social_auth";

import withNotifications from "containers/with_notifications";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";
import withRouter from "routing/with_router";

import MFAChallengeForm from "./components/mfa_challenge_form";
import SignInForm from "./components/sign_in_form";

function SignInPage() {
  const { t } = useTranslation();
  const [MFAToken, setMFAToken] = useState(null);
  const isDisableSignUp = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_SIGN_UP));
  const { publicAppRoutes } = useRouting();

  const rowJustifyPosition = isDisableSignUp ? "center" : "space-between";

  return (
    <>
      <AuthFormTitle>{t("sign_in_page:title")}</AuthFormTitle>
      <AuthFormSubTitle>{t("sign_in_page:sub_title")}</AuthFormSubTitle>
      {
        !MFAToken && (
          <>
            <SocialAuth />
            <SignInForm setMFAToken={setMFAToken} />
          </>
        )
      }
      {
        MFAToken && <MFAChallengeForm mfaToken={MFAToken} />
      }

      <Row justify={rowJustifyPosition}>
        <Link data-testid="password_recovery_link" to={pathBuilder(publicAppRoutes.passwordRecovery)}>
          {t("public_pages:password_recovery_link")}
        </Link>
        {!isDisableSignUp && (
          <Link data-testid="sign_up_link" to={pathBuilder(publicAppRoutes.signUp)}>
            {t("public_pages:sign_up_link")}
          </Link>
        )}
      </Row>
    </>
  );
}

const enhance = compose(withRouter, withNotifications);

export default enhance(SignInPage);
