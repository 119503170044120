import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Form, Formik } from "formik";

import FormikTextareaInput from "components/forms/inputs/formik/form_textarea";

import { notification } from "../../app";

import ActionsBar from "./actions_bar/actions_bar";
import AttachedFiles from "./attached_files/attached_files";
import uploadAttachment from "./upload_attachment";
import useDragging from "./use_dragging";

import styles from "./chat.module.css";

const initialValues = {
  message: "",
  attachments: [],
};

const autosizeTextArea = {
  minRows: 1,
  maxRows: 10,
};

export default function ChatForm({ onSubmit, disabled }) {
  const { t } = useTranslation();
  const formControls = React.useRef({});

  const submit = (values, resetForm) => {
    if (!disabled) {
      onSubmit({
        message: values.message,
        attachments: values.attachments,
      });
      resetForm();
    }
  };

  const onKeyPress = (event, callback) => {
    if (event.charCode === 13 && event.ctrlKey) {
      callback();
    }
  };

  const formClassName = classNames(styles.formWrapper, {
    [`${styles.formWrapper__disabled}`]: disabled,
  });

  const handleFileAttachmentStatusChange = (attachment, status) => {
    const currentAttachments = formControls.current.attachments;
    const setFieldValue = formControls.current.setFieldValue;

    if (status === "abort") {
      const newAttachments = currentAttachments.filter((a) => a.id !== attachment.id);
      setFieldValue("attachments", newAttachments);
      return;
    }

    if (status === "error") {
      notification.error({
        message: t("messages:text:attachment_error_message"),
        description: t("messages:text:attachment_error_description"),
      });

      const newAttachments = currentAttachments.filter((a) => a.id !== attachment.id);
      setFieldValue("attachments", newAttachments);
      return;
    }

    const attachmentIndex = currentAttachments.findIndex((a) => a.id === attachment.id);
    const newAttachments = [...currentAttachments];

    if (attachmentIndex > -1) {
      newAttachments[attachmentIndex] = { ...attachment, status };
    } else {
      newAttachments.push({ ...attachment, status });
    }

    setFieldValue("attachments", newAttachments);
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    const perform = async () => {
      if (fileRejections.length) {
        notification.error({
          message: t("messages:text:attachment_error_message"),
          description: t("messages:text:attachment_error_wrong_format"),
        });
      }

      await Promise.allSettled(acceptedFiles.map((file) => uploadAttachment(file, handleFileAttachmentStatusChange)));
    }

    void perform();
  };

  const isDragging = useDragging();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
  });

  const dropzoneClassName = classNames(styles.dropzone, {
    [`${styles.dropzone__active}`]: isDragActive,
  });

  const areAttachmentsReady = (attachments) => {
    if (!attachments.length) {
      return true;
    }

    return attachments.every((a) => a.status === "success");
  };

  return (
    <div style={{ position: "relative" }}>
      <input {...getInputProps()} />

      {isDragging && (
        <div {...getRootProps()} className={dropzoneClassName}>
          <div className={styles.dropzoneText}>{t("messages:text:drop_here")}</div>
        </div>
      )}

      <Formik
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          // we need to pass these values to the parent component from this closure
          formControls.current.setFieldValue = setFieldValue;
          formControls.current.attachments = values.attachments;

          const canSend = (
            (values.message && values.attachments.length === 0)
            || (values.message && areAttachmentsReady(values.attachments))
            || (values.attachments.length > 0 && areAttachmentsReady(values.attachments))
          );

          return (
            <Form className={formClassName}>
              <div className={styles.messageInputContainer}>
                <FormikTextareaInput
                  name="message"
                  className={styles.messageInputContainer__messageInput}
                  view="fullWidth"
                  autosize={autosizeTextArea}
                  onKeyPress={(e) => onKeyPress(e, handleSubmit)}
                  placeholder={t("messages_page:dialog:form_placeholder")}
                />
                <AttachedFiles
                  files={values.attachments}
                  onDelete={(attachment) => {
                    if (attachment.status === "uploading" && attachment.requestController) {
                      attachment.requestController.abort();
                    }

                    const newAttachments = values.attachments.filter((a) => a.id !== attachment.id);
                    setFieldValue("attachments", newAttachments);
                  }}
                />
                <ActionsBar
                  canSend={canSend}
                  onSend={handleSubmit}
                  onChange={(attachments) => {
                    setFieldValue("attachments", attachments);
                  }}
                  onFileAttachmentStatusChange={handleFileAttachmentStatusChange}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
