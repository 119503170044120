import { Navigate } from "react-router";

export function ApaleoAuthSuccessPage({ path }) {
  return (
    <Navigate
      to={path}
      replace
      state={{
        oauthSuccess: true,
      }}
    />
  );
}
