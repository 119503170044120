import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Typography } from "antd";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

const { Paragraph, Text } = Typography;
const { Webhooks } = store;

export default function WebhookTest({ model, beforeTest }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { t } = useTranslation();

  const testWebhook = async () => {
    try {
      await beforeTest();
    } catch {
      return; // validation failed
    }

    setLoading(true);
    setResult(null);

    try {
      const response = await Webhooks.test(model);
      setResult(response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <legend style={{ padding: "0 16px" }}>{t("webhook_drawer:test:legend")}</legend>
      <Row>
        <Col xs={{ span: 24, offset: 24 }} sm={{ span: 16, offset: 8 }}>
          <Button loading={loading} onClick={() => void testWebhook()}>
            {t("webhook_drawer:test:send")}
          </Button>
        </Col>
      </Row>
      {result ? (
        <Form.Item label={t("webhook_drawer:test:response")} {...horizontalFormItemLayout}>
          <div>
            {result.status === 200 ? (
              <Text type="success">
                <CheckCircleOutlined /> {t("webhook_drawer:test:success")}
              </Text>
            ) : (
              <Text type="danger">
                <ExclamationCircleOutlined /> {t("webhook_drawer:test:failed")}
              </Text>
            )}
            <Paragraph>
              <pre>{JSON.stringify(result, null, 4)}</pre>
            </Paragraph>
          </div>
        </Form.Item>
      ) : null}
    </div>
  );
}
