import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import _ from "lodash";

const DEBOUNCE_DELAY = 300;

export default function useUnhandledRejectionHandler(showError) {
  const { t } = useTranslation();

  // Debounce error messages to avoid showing multiple messages for the same failure request reasons, like unauthenticated errors
  const showErrorDebounced = _.debounce((text) => {
    showError(text);
  }, DEBOUNCE_DELAY, { leading: true, trailing: false });

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      const error = event.reason;

      if (!error.isHttp) {
        Sentry.captureException(error);
        return;
      }

      const errorMessages = {
        unauthenticated: t("general:errors:unauthenticated"),
        authentication_expired: t("general:errors:authentication_expired"),
        forbidden: t("general:errors:forbidden"),
        not_found: t("general:errors:not_found"),
        default: t("general:errors:failed_request"),
      };

      const errorMessage = errorMessages[error.subType] ? errorMessages[error.subType] : errorMessages.default;
      showErrorDebounced(errorMessage);

      event.preventDefault();
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener("unhandledrejection", handleUnhandledRejection);
    };
  }, [t, showErrorDebounced]);
}
