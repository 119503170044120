import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Input, Row } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import RoomTypesAndRatePlansSelectableList from "./room_types_and_rate_plans_selectable_list";

import styles from "./rate_selector.module.css";

export default function RateSelector(props) {
  const {
    allSelected,
    allIndeterminate,
    allDisabled,
    selectedRoomTypes,
    selectedRatePlans,
    disabledRatePlans = {},
    onAllToggle,
    onRoomTypeToggle,
    onRatePlanToggle,
    roomTypes,
    label,
  } = props;

  const [search, setSearch] = useState(null);
  const { t } = useTranslation();

  const onSearchFilterChange = (event) => {
    setSearch(event.target.value.toLowerCase().trim() || null);
  };

  const filterRatePlans = useCallback((ratePlan) => {
    if (search === null) {
      return true;
    }

    return `${ratePlan.title} ${ratePlan.occupancy}`.toLowerCase().includes(search);
  }, [search]);

  const filterRoomTypes = useCallback((roomType) => {
    if (search === null) {
      return true;
    }

    const ratePlansIsVisible = roomType.ratePlans
      .map((el) => `${el.title} ${el.occupancy}`.toLowerCase().includes(search))
      .reduce((acc, el) => {
        return acc || el;
      }, false);

    return roomType.title.toLowerCase().includes(search) || ratePlansIsVisible;
  }, [search]);

  const sortRoomTypes = useCallback((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  }, []);

  const sortRatePlans = useCallback((a, b) => {
    if (`${a.title.toLowerCase()}${a.occupancy}` < `${b.title.toLowerCase()}${b.occupancy}`) {
      return -1;
    }
    if (`${a.title.toLowerCase()}${a.occupancy}` > `${b.title.toLowerCase()}${b.occupancy}`) {
      return 1;
    }
    return 0;
  }, []);

  const getFilteredRoomData = useCallback(() => {
    return roomTypes
      .sort(sortRoomTypes)
      .filter(filterRoomTypes)
      .map((roomType) => {
        const filteredRatePlans = roomType.ratePlans
          .sort(sortRatePlans)
          .filter(filterRatePlans);

        return { ...roomType, ratePlans: filteredRatePlans };
      });
  }, [roomTypes, sortRoomTypes, filterRoomTypes, sortRatePlans, filterRatePlans]);

  const filteredRoomData = useMemo(() => getFilteredRoomData(), [getFilteredRoomData]);

  const areRoomsPresent = roomTypes.length !== 0;
  const areSearchResultsEmpty = filteredRoomData.length === 0;

  const fieldLabel = label === undefined ? t("inventory_page:bulk_update:affected_rooms") : label;

  return (
    <div className={styles.ratesSelector}>
      <Form.Item
        {...horizontalFormItemLayout}
        label={fieldLabel}
      >
        <Input
          data-testid="search_room"
          className={styles.ratesSelector__search}
          placeholder={t("general:search_field_placeholder")}
          onChange={onSearchFilterChange}
          type="text"
          suffix={<SearchOutlined />}
        />
        <div className={styles.ratesSelector__list}>
          {areRoomsPresent && (
            <Row className={styles.ratesSelector__all}>
              <Col>
                <Checkbox
                  data-testid="select_all_rates_checkbox"
                  onChange={onAllToggle}
                  checked={allSelected}
                  indeterminate={allIndeterminate}
                  disabled={allDisabled}
                >
                  {t("inventory_page:bulk_update:all_rates")}
                </Checkbox>
              </Col>
            </Row>
          )}
          {!areRoomsPresent && (<div>{t("inventory_page:bulk_update:no_rate_plans")}</div>)}
          {areRoomsPresent && areSearchResultsEmpty && (<div>{t("inventory_page:bulk_update:no_rate_plans_found")}</div>)}

          <RoomTypesAndRatePlansSelectableList
            rooms={filteredRoomData}
            selectedRatePlans={selectedRatePlans}
            disabledRatePlans={disabledRatePlans}
            selectedRoomTypes={selectedRoomTypes}
            onRoomTypeToggle={onRoomTypeToggle}
            onRatePlanToggle={onRatePlanToggle}
          />
        </div>
      </Form.Item>
    </div>
  );
}

RateSelector.propTypes = {
  roomTypes: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired,
  allIndeterminate: PropTypes.bool.isRequired,
  selectedRoomTypes: PropTypes.object.isRequired,
  selectedRatePlans: PropTypes.object.isRequired,
  disabledRatePlans: PropTypes.object,
  onAllToggle: PropTypes.func.isRequired,
  onRoomTypeToggle: PropTypes.func.isRequired,
  onRatePlanToggle: PropTypes.func.isRequired,
};
