import { useTranslation } from "react-i18next";
import { Modal } from "antd";

import FailedState from "./failed_state";
import { trackFeatureCancel, trackFeatureComplete, useFeatureTracking } from "./feature_tracking";
import FinishedState from "./finished_state";
import InProgressState from "./in_progress_state";
import InitialState from "./initial_state";
import StartingState from "./starting_state";
import useExport, { EXPORT_STATES } from "./use_export";

const DOWNLOAD_FILENAME = "bookings.csv";

export default function ExportModal({ isOpen, filters, onClose }) {
  const { t } = useTranslation();
  const { exportState, totalCount, exportedCount, startExport, cleanState, download } = useExport();

  useFeatureTracking(isOpen, exportState);

  const handleDownloadClick = () => {
    download();
    trackFeatureComplete();
    onClose();
  };

  const handleModalClose = () => {
    trackFeatureCancel();
    onClose();
  };

  const handleStartClick = () => {
    startExport(filters, DOWNLOAD_FILENAME);
  };

  const steps = {
    [EXPORT_STATES.INITIAL]: () => <InitialState onCloseClick={handleModalClose} onStartClick={handleStartClick} />,
    [EXPORT_STATES.STARTING]: () => <StartingState onCloseClick={handleModalClose} />,
    [EXPORT_STATES.IN_PROGRESS]: () => <InProgressState onCloseClick={handleModalClose} totalCount={totalCount} exportedCount={exportedCount} exportState={exportState} />,
    [EXPORT_STATES.FINISHED]: () => <FinishedState handleDownloadClick={handleDownloadClick} />,
    [EXPORT_STATES.FAILED]: () => <FailedState onCloseClick={handleModalClose} />,
  };

  const renderStep = steps[exportState];

  return (
    <Modal
      open={isOpen}
      afterClose={cleanState}
      width={500}
      zIndex={10000}
      onCancel={handleModalClose}
      title={t("bookings_export:title")}
      footer={null}
    >
      {renderStep()}
    </Modal>
  );
}
