import * as Sentry from "@sentry/react";
import { i18n } from "i18n";

import { writeAuthTokens, writeTokensToOldPlace } from "../../auth/tokens";
import AppStorageManager from "../../utils/app_storage_manager";
import { convertToCamelCase } from "../utils/case_converter";

import { analytics } from "@/analytics";

let transport;
let storage;

export default class Auth {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  signIn(attrs) {
    return transport.send("POST", "auth", { user: attrs }, { refreshToken: false }).then(this._handleSuccessfulAuth);
  }

  signOut() {
    return transport.send("POST", "sign_out", {});
  }

  signUp(attrs) {
    return transport.send("POST", "sign_up", { user: attrs }).then(this._handleSuccessfulAuth);
  }

  mfaChallenge(attrs) {
    return transport.send("POST", "auth/mfa_challenge", { mfa_challenge: attrs }).then(this._handleSuccessfulAuth);
  }

  exchangeSocialAuthKey(sessionKey) {
    return transport
      .send("GET", `auth/exchange?oauth_session_key=${sessionKey}`)
      .then(this._handleSuccessfulAuth);
  }

  _handleSuccessfulAuth = (response) => {
    if (response.data.mfa_token) {
      return response;
    }

    const { attributes, relationships } = response.data;
    const uiSettings = convertToCamelCase(relationships.user_ui_setting?.data?.attributes);
    const user = relationships.user.data.attributes;

    Sentry.setUser({ email: user.email });
    analytics.identify(user.email);

    const { token, refresh_token, ...restAttrs } = attributes;

    storage.sessionAdd(restAttrs);
    storage.userAdd(user);
    storage.setUiSettings(uiSettings);

    writeAuthTokens({
      authToken: token,
      refreshToken: refresh_token,
    });
    writeTokensToOldPlace({
      authToken: token,
      refreshToken: refresh_token,
    });

    if (user.preferred_language) {
      if (!AppStorageManager.getItem("CHANNEX_LANGUAGE")) {
        AppStorageManager.setItem("CHANNEX_LANGUAGE", user.preferred_language);
        i18n.changeLanguage(user.preferred_language);
      }
    }

    return response;
  };

  whiteLabelSignUp(attrs) {
    return transport.send("POST", "wl_sign_up", { user: attrs }).then((response) => {
      const { attributes } = response.data;
      storage.sessionAdd(attributes);

      return response;
    });
  }

  requestRestorePassword(email) {
    return transport.send("POST", "request_restore_password", { user: { email } });
  }

  restorePassword(attrs) {
    return transport.send("POST", "restore_password", { user: attrs });
  }

  confirmRegistration(token) {
    return transport.send("GET", `confirm_registration?token=${token}`);
  }

  confirmInvite(attrs) {
    return transport.send("POST", "confirm_invite", { user: attrs });
  }

  chooseProperty(property) {
    storage.chooseProperty(property);
  }

  chooseGroup(group) {
    storage.chooseGroup(group);
  }
}
