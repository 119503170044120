import { useQuery } from "@tanstack/react-query";
import store from "store";

const { Applications } = store;

export const useInstallation = (installationId: string) => {
  return useQuery({
    queryKey: ["applications", installationId],
    queryFn: () => Applications!.find(installationId),
  });
};
