import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Radio, Row, Space, Typography } from "antd";
import _ from "lodash";
import store from "store";

import { useProperty } from "data/use_property";
import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import { Loading } from "components/_v2/states/loading";
import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormInput from "components/forms/inputs/form_input";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./shared.module.css";

const { Applications } = store;
const PRICE_TYPES = ["gross", "net"];
const RATE_MODES = ["obp", "standard"];

const buildSettings = (roomTypes, ratePlans, installation) => {
  const defaultSettings = _.isEmpty(installation.settings) ? null : installation.settings;

  return _.reduce(roomTypes, (acc, roomType) => {
    const ratePlanIds = ratePlans[roomType.id]?.map((rP) => rP.id);
    const mappedRatePlans = Object.values(installation.ratePlans).filter((rP) => ratePlanIds?.includes(rP.ratePlanId));

    acc[roomType.id] = {
      code: mappedRatePlans[0]?.settings?.roomTypeCode,
      title: roomType.title,
      ratePlans: {},
    };

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      const mappedRatePlan = mappedRatePlans.find((rP) => rP.ratePlanId === ratePlan.id);

      accRP[ratePlan.id] = {
        code: mappedRatePlan?.settings?.ratePlanCode,
        ignoreRate: mappedRatePlan?.settings?.ignoreRate,
        id: mappedRatePlan?.id,
        title: ratePlan.title,
      };

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, defaultSettings || { hotelCode: null, currency: null, priceType: PRICE_TYPES[0], rateMode: RATE_MODES[0] });
};

export default function RateTigerSettings({ installation, onClose }) {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      hotelCode: installation.settings?.hotelCode,
      currency: installation.settings?.currency,
      priceType: installation.settings?.priceType,
      rateMode: installation.settings?.rateMode,
    },
  });

  const [settings, setSettings] = useState(null);
  const values = watch();
  const { hotelCode, priceType, rateMode } = values;

  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });
  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { groupResults: true });
  const { data: property, isLoading: isPropertyLoading } = useProperty(propertyId);

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading || isPropertyLoading || settings !== null) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    reset(initialSettings);

    setSettings(initialSettings);
  }, [isRatePlansLoading, isRoomTypesLoading, isPropertyLoading, settings, installation, ratePlans, roomTypes, reset]);

  const submit = () => {
    settings.hotelCode = hotelCode;
    settings.currency = property.currency;
    setSettings({ ...settings });

    const updatedSettings = _.omitBy(settings, (value, _key) => value?.title);

    const applicationRatePlans = Object.values(settings).reduce((acc, room) => {
      if (!room?.ratePlans) {
        return acc;
      }

      return Object.entries(room.ratePlans).reduce((accRatePlans, [ratePlanId, rP]) => {
        return [
          ...accRatePlans,
          {
            id: rP.id,
            rate_plan_id: ratePlanId,
            settings: {
              ignore_rate: rP.ignoreRate,
            },
          },
        ];
      }, []).concat(acc);
    }, []);

    setSaveInProgress(true);

    Applications
      .update({ ...installation, settings: updatedSettings, ratePlans: null, rate_plans: applicationRatePlans })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, []);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });
      })
      .finally(() => setSaveInProgress(false));
  };

  const handleRatePlanChange = (roomTypeId) => {
    return (event) => {
      Object.keys(settings[roomTypeId].ratePlans).forEach((ratePlanId) => {
        settings[roomTypeId].ratePlans[ratePlanId].settings.primary = false;
      });

      const ratePlanId = event.target.value;
      settings[roomTypeId].ratePlans[ratePlanId].settings.primary = true;

      setSettings({ ...settings });
    };
  };

  const handleRatePlanSettingsChange = (roomTypeId, ratePlanId) => {
    return (checked) => {
      settings[roomTypeId].ratePlans[ratePlanId].ignoreRate = checked;

      setSettings({ ...settings });
    };
  };

  const getPrimaryRatePlan = (roomTypeId) => {
    const roomRatePlans = settings[roomTypeId].ratePlans;

    const primaryRatePlanId = Object.keys(roomRatePlans).findLast((ratePlanId) => roomRatePlans[ratePlanId].settings?.primary);

    return primaryRatePlanId;
  };

  const getRatePlanSettings = (roomTypeId, ratePlanId) => {
    return settings[roomTypeId].ratePlans[ratePlanId]?.ignoreRate;
  };

  if (isRatePlansLoading || isRoomTypesLoading || isPropertyLoading || settings === null) {
    return <Loading />;
  }

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors} />

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:rate_tiger:hotel_code")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="hotelCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  view="fullWidth"
                  placeholder={t("applications_page:rate_tiger:hotel_code")}
                  errors={errors.hotelCode}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:rate_tiger:price_type")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>

          {priceType && (

          <Col span={12} className={styles.inputCol}>
            <Radio.Group defaultValue={priceType}>
              <Space direction="vertical">
                {PRICE_TYPES.map((pT) => (
                  <div key={pT}>
                    <Radio
                      value={pT}
                      onChange={(e) => {
                        settings.priceType = e.target.value;
                      }}
                    >
                      {t(`applications_page:rate_tiger:price_type_${pT}`)}
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </Col>
          )}
        </Row>

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:rate_tiger:rate_mode")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>

          {rateMode && (

          <Col span={12} className={styles.inputCol}>
            <Radio.Group defaultValue={rateMode}>
              <Space direction="vertical">
                {RATE_MODES.map((rM) => (
                  <div key={rM}>
                    <Radio
                      value={rM}
                      onChange={(e) => {
                        settings.rateMode = e.target.value;
                      }}
                    >
                      {t(`applications_page:rate_tiger:rate_mode_${rM}`)}
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </Col>
          )}
        </Row>

        <Row>
          <Col span={24}>
            {t("applications_page:rate_tiger:description")}
          </Col>
        </Row>

        {roomTypes.map((roomType) => (
          <div key={roomType.id}>
            <Row>
              <Col span={24} className={styles.roomTitle}>
                <strong>{roomType.title}</strong>
              </Col>
            </Row>

            {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

            {ratePlans[roomType.id] && (
              <Radio.Group style={{ width: "100%" }} onChange={handleRatePlanChange(roomType.id)} defaultValue={getPrimaryRatePlan(roomType.id)}>
                <Space style={{ width: "100%" }} direction="vertical">
                  {ratePlans[roomType.id].map((ratePlan) => (
                    <div key={ratePlan.id}>
                      <Row>
                        <Col span={7} className={styles.roomTitle}>
                          {ratePlan.title} {roomType.occ_adults}
                        </Col>
                        <Col span={15} className={styles.roomTitle}>
                          <FormCheckbox
                            view="horizontal"
                            name="ignore_rate"
                            label={t("applications_page:rate_tiger:ignore_rate")}
                            onChange={handleRatePlanSettingsChange(roomType.id, ratePlan.id)}
                            defaultValue={getRatePlanSettings(roomType.id, ratePlan.id)}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Space>
              </Radio.Group>
            )}
          </div>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={() => { clearErrors(); handleSubmit(submit)(); }} loading={saveInProgress}>
          {t("applications_page:rate_tiger:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
