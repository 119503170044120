import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../../utils/attributes_extractor";

let transport;

const ENDPOINT = "integrations/tripla_importer";

export default class TriplaIntegration {
  constructor(container) {
    transport = container.transport;
  }

  findProperty({ username, password, hotelCode, mappingId, publicKey, secretKey }) {
    const requestParams = convertToSnakeCase({
      searchRequest: {
        username,
        password,
        hotelCode,
        mappingId,
        publicKey,
        secretKey,
      },
    });

    return transport
      .send("POST", `${ENDPOINT}/get_info`, requestParams)
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);

        return convertedData;
      });
  }

  importProperty({ property }) {
    const requestParams = convertToSnakeCase({ property });

    return transport
      .send("POST", `${ENDPOINT}/import`, requestParams)
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);

        return convertedData;
      });
  }
}
