import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

type Props = {
  onClick: () => void;
}

export const IconDeleteButton = ({ onClick }: Props) => {
  return (
    <Button data-testid="delete-btn" type="link" danger onClick={onClick} icon={<DeleteOutlined />} />
  );
};
