import _ from "lodash";
import { convertToCamelCase } from "store/utils/case_converter";

import { validationMessages } from "config/constants/errors";

import { flattenObject } from "utils/flatten_object";

const FIELD_MAPPING = {
  address1: "address",
};

class HopperHomesErrorParser {
  constructor() {
    // Define patterns with their corresponding transformers
    this.errorPatterns = [
      {
        type: "required",
        pattern: /^Got value 'null' with wrong type, expecting (\w+) at '(.+?)'/,
        transform: (matches) => ({
          type: "required",
          field: matches[2],
        }),
      },
      {
        type: "invalid",
        pattern: /^Got value '(.+?)' with wrong type, expecting (\w+) at '(.+?)'/,
        transform: (matches) => ({
          type: "invalid",
          field: matches[3],
        }),
      },
      {
        type: "minLength",
        pattern: /^expected (.+?) to have length greater than or equal to (\d+), but got: "(.+?)"/,
        transform: (matches) => ({
          type: "minLength",
          field: matches[1],
          payload: parseInt(matches[2]),
        }),
      },
      {
        type: "lessOrEqual",
        pattern: /^expected (.+?) to be less than or equal to (-?\d+\.?\d*), but got (.+?)/,
        transform: (matches) => ({
          type: "lessOrEqual",
          field: matches[1],
          payload: parseFloat(matches[2]),
        }),
      },
      {
        type: "greaterOrEqual",
        pattern: /^expected (.+?) to be greater than or equal to (-?\d+\.?\d*), but got (.+?)/,
        transform: (matches) => ({
          type: "greaterOrEqual",
          field: matches[1],
          payload: parseFloat(matches[2]),
        }),
      },
      {
        type: "type",
        pattern: /^(\w+) at '(.+?)'/,
        transform: (matches) => ({
          type: "required",
          field: matches[2],
        }),
      },
    ];
  }

  parseIndividualError(error, t) {
    // Try each pattern until we find a match
    for (const { pattern, transform } of this.errorPatterns) {
      const matches = error.match(pattern);

      if (matches) {
        return transform(matches);
      }
    }

    // global form errors
    const GLOBAL_ERRORS = {
      "Either a default price or all dayOfWeek prices must be specified": t("hopper_homes:errors:either_default_price_or_all_day_of_week_prices_must_be_specified"),
    };

    const errorMessage = GLOBAL_ERRORS[error] || error;

    return {
      type: "global",
      field: "global",
      rawMessage: errorMessage,
    };
  }

  cleanErrorMessage(errorMessage) {
    return errorMessage
      .replace(/^Invalid value for: body \(|\)$/g, "")
      .replace(/\n/g, "");
  }

  splitIntoIndividualErrors(cleanMessage) {
    return cleanMessage
      .split(/(?=Got |expected |Int )/)
      .map((msg) => msg.trim())
      .filter(Boolean);
  }

  aggregateErrors(structuredErrors) {
    return structuredErrors.reduce((acc, error) => {
      const fieldName = FIELD_MAPPING[error.field] || error.field;
      _.set(acc, fieldName, this.buildErrorMessage(error));
      return acc;
    }, {});
  }

  buildErrorMessage(error) {
    if (validationMessages[error.type]) {
      if (error.payload) {
        return validationMessages[error.type](error.payload)();
      }

      return validationMessages[error.type]();
    }
    return error.rawMessage;
  }

  parse(errorMessage, t) {
    const cleanMessage = this.cleanErrorMessage(errorMessage);
    const individualErrors = this.splitIntoIndividualErrors(cleanMessage);
    const structuredErrors = individualErrors.map((error) => this.parseIndividualError(error, t));
    const errors = this.aggregateErrors(structuredErrors);
    const camelCasedErrors = convertToCamelCase(errors);

    return flattenObject({ obj: camelCasedErrors });
  }
}

export const hopperHomesErrorParser = new HopperHomesErrorParser();
