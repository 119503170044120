import React from "react";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import { AppForm } from "components/_v2/forms/hook_form";

import useCreateSubscriptionAction from "./use_create_subscription_action";

export default function ManageSubscriptionForm({ billingAccountId, onFinish }) {
  const { mutateAsync: createSubscription, isLoading: isCreateLoading } = useCreateSubscriptionAction();

  const validationSchema = yup.object().shape({
    customerId: yup.string().trim().required(validationMessages.required),
    subscriptionId: yup.string().trim().required(validationMessages.required),
  });

  return (
    <AppForm
      validationSchema={validationSchema}
      schema={{
        layout: "scrollBox",
        fields: [{
          name: "customerId",
          label: "Billsby Customer ID",
        }, {
          name: "subscriptionId",
          label: "Billsby Subscription ID",
        }],
      }}
      onSubmit={async ({ customerId, subscriptionId }) => {
        await createSubscription({
          billingAccountId,
          body: { customerId, subscriptionId },
        });

        onFinish();
      }}
      isSubmitLoading={isCreateLoading}
    />
  );
}
