import extractRelationships from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "webhooks";

export default class Webhooks {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { inserted_at: "desc" }) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.webhooksLoad(response.data, response.meta);
      return {
        entities: response.data.map((el) => el.attributes),
        meta: response.meta,
      };
    });
  }

  events() {
    return transport.send("GET", `${ENDPOINT}/events`).then(({ data }) => data.events);
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then(({ data }) => {
      const entity = extractRelationships(data);

      storage.webhooksAdd(data);
      return entity;
    });
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { webhook: attrs }).then(({ data }) => {
      const { attributes } = data;

      storage.webhooksAdd(data);
      return attributes;
    });
  }

  update(attrs) {
    return transport.send("PUT", `${ENDPOINT}/${attrs.id}`, { webhook: attrs }).then(({ data }) => {
      const { attributes } = data;

      storage.webhooksAdd(data);
      return attributes;
    });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      storage.webhooksDrop(attrs);
      return response;
    });
  }

  test(attrs) {
    const request_attrs = { ...attrs };
    let headers;
    let request_params;

    try {
      headers = JSON.parse(attrs.headers);
    } catch {
      headers = {};
    }
    try {
      request_params = JSON.parse(attrs.request_params);
    } catch {
      request_params = {};
    }

    request_attrs.event_mask = request_attrs.event_mask.join(";");
    request_attrs.headers = headers;
    request_attrs.request_params = request_params;

    return transport.send("POST", `${ENDPOINT}/test`, { webhook: request_attrs })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        return error.body;
      });
  }

  logs(id, pagination = { page: 1, limit: 10 }, order = { inserted_at: "desc" }) {
    return transport
      .send("GET", `${ENDPOINT}/${id}/logs`, { pagination, order })
      .then((response) => {
        storage.webhookLogsLoad(response.data, response.meta);
        return {
          entities: response.data.map((el) => el.attributes),
          meta: response.meta,
        };
      });
  }

  findLog(webhookId, webhookLogId) {
    return transport
      .send("GET", `${ENDPOINT}/${webhookId}/logs/${webhookLogId}`)
      .then((response) => {
        return response;
      });
  }
}
