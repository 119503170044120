import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AdminChannelAdapters } = store;

export function useSetAccessModeAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, attrs }) => AdminChannelAdapters.update(id, attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries("admin_channel_adapters");
    },
  });
}
