import { httpClient } from "store/api_clients/http_client";

import attributesExtractor from "../../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../../utils/case_converter";

type ConnectNewPropertyPayload = {
  connectionToken: string;
  groupId: string;
  roomRates: {
    id: string,
    ratePlans: { id: string }[]
  }[];
}
type ConnectExistingPropertyPayload = {
  connectionToken: string;
  propertyId: string;
};
export type ConnectPropertyPayload = ConnectNewPropertyPayload | ConnectExistingPropertyPayload;

export const mewsApiClient = {
  getMappingDetails: async ({ token }: { token: string }) => {
    const path = "applications/mews/mapping_details";
    const body = convertToSnakeCase({
      connectionToken: token,
    });

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  connectProperty: async (payload: ConnectPropertyPayload) => {
    const path = "applications/mews/connect_property";
    const body = convertToSnakeCase({
      property: payload,
    });

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },
};
