import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Flex } from "antd";
import { channelsApiClient } from "store/api_clients/channels";

import { Item, TabList } from "components/tab_list";

import useDrawer from "hooks/use_drawer";

import { DefaultPricingDrawer } from "./default_pricing_drawer/default_pricing_drawer";
import { DefaultRulesDrawer } from "./default_rules_drawer/default_rules_drawer";
import { PricingSettingsDrawer } from "./pricing_settings_drawer/pricing_settings_drawer";
import { ListingRow } from "./listing_row";
import { ManageListingDrawer } from "./manage_listing_drawer";

const useListings = (channelId) => {
  return useQuery({
    queryKey: ["listings", channelId],
    queryFn: () => channelsApiClient.listings(channelId),
  });
};

export const HopperHomesListingsTab = ({ model: channel, onMappingRefresh }) => {
  const { t } = useTranslation();

  const {
    isOpen: isManageDrawerOpen,
    open: openManageDrawer,
    close: closeManageDrawer,
    payload: manageDrawerPayload,
  } = useDrawer();

  const {
    isOpen: isDefaultRulesDrawerOpen,
    open: openDefaultRulesDrawer,
    close: closeDefaultRulesDrawer,
    payload: defaultRulesDrawerPayload,
  } = useDrawer();

  const {
    isOpen: isDefaultPricingDrawerOpen,
    open: openDefaultPricingDrawer,
    close: closeDefaultPricingDrawer,
    payload: defaultPricingDrawerPayload,
  } = useDrawer();

  const {
    isOpen: isPricingSettingsDrawerOpen,
    open: openPricingSettingsDrawer,
    close: closePricingSettingsDrawer,
    payload: pricingSettingsDrawerPayload,
  } = useDrawer();

  const channelId = channel.id;
  const listingsQuery = useListings(channelId);

  return (
    <Flex vertical flex={1}>
      <TabList
        itemsQuery={listingsQuery}
        filterFn={(item, searchQuery) => item.title.toLowerCase().includes(searchQuery)}
        renderItem={(item) => (
          <Item>
            <ListingRow
              channelId={channelId}
              listing={item}
              onEditClick={() => openManageDrawer(item)}
              onDefaultRulesClick={() => openDefaultRulesDrawer(item)}
              onDefaultPricingClick={() => openDefaultPricingDrawer(item)}
              onPricingSettingsClick={() => openPricingSettingsDrawer(item)}
              onDeleted={() => onMappingRefresh()}
            />
          </Item>
        )}
        emptyMessage={t("generic:empty_message")}
        createMessageText={t("general:action:create")}
        onCreateClick={() => openManageDrawer()}
      />
      <ManageListingDrawer
        visible={isManageDrawerOpen}
        listing={manageDrawerPayload}
        onClose={(changedListing) => {
          closeManageDrawer();

          if (changedListing) {
            onMappingRefresh();
          }
        }}
      />
      {!!defaultRulesDrawerPayload && (
        <DefaultRulesDrawer
          visible={isDefaultRulesDrawerOpen}
          listing={defaultRulesDrawerPayload}
          onClose={closeDefaultRulesDrawer}
        />
      )}
      {!!defaultPricingDrawerPayload && (
        <DefaultPricingDrawer
          visible={isDefaultPricingDrawerOpen}
          listing={defaultPricingDrawerPayload}
          onClose={closeDefaultPricingDrawer}
        />
      )}
      {!!pricingSettingsDrawerPayload && (
        <PricingSettingsDrawer
          visible={isPricingSettingsDrawerOpen}
          listing={pricingSettingsDrawerPayload}
          onClose={closePricingSettingsDrawer}
        />
      )}
    </Flex>
  );
};
