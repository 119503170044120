import { useSelector } from "react-redux";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getActiveGroup, getActivePropertyId } from "store/storage/selectors/session_selector";

export function useCurrentProperties() {
  const propertyOptions = useSelector(getPropertiesOptions);

  const activePropertyId = useSelector(getActivePropertyId);
  const activeGroup = useSelector(getActiveGroup);

  if (activePropertyId) {
    return propertyOptions.filter((property) => property.id === activePropertyId);
  }

  if (activeGroup) {
    return propertyOptions.filter((property) => property.group_id === activeGroup);
  }

  return propertyOptions;
}
