import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../utils/attributes_extractor";

let transport;

const ENDPOINT = "user_api_keys";

const MAX_PAGE_SIZE = 100;

export default class UserApiKeys {
  constructor(container) {
    transport = container.transport;
  }

  list(filter = {}, pagination = { page: 1, limit: MAX_PAGE_SIZE }, order = { inserted_at: "desc" }) {
    return transport
      .send("GET", ENDPOINT, { filter, pagination, order })
      .then(convertToCamelCase)
      .then((response) => {
        const entities = attributesExtractor(response.data);

        return {
          entities,
          meta: response.meta,
        };
      });
  }

  find(id) {
    return transport
      .send("GET", `${ENDPOINT}/${id}`)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const convertedAttrs = convertToSnakeCase(attrs);

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  create(attrs) {
    const convertedAttrs = convertToSnakeCase(attrs);

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        const formattedData = attributesExtractor(response.data);

        return formattedData;
      });
  }

  withdraw(id) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/withdraw`)
      .then(convertToCamelCase)
      .then((response) => {
        const formattedData = attributesExtractor(response.data);

        return formattedData;
      });
  }
}
