import { useTranslation } from "react-i18next";

import { ChannexDrawer } from "components/_v2/channex_drawer";

import { ServiceForm } from "./service_form";

export const EditDrawer = ({ visible, service, currency, errors, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const title = service?.name
    ? t("common:headings:service_edit")
    : t("common:headings:service_create");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <ServiceForm
        initialValue={service}
        currency={currency}
        errors={errors}
        onSubmit={(values) => {
          onSubmit(values);
          onClose();
        }}
      />
    </ChannexDrawer>
  );
};
