import { useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import { Channel } from "store/channels_provider";

import { JsonValue } from "@/types";

const debug = false;
const noop = () => {};

export type Options = {
  events: string[],
  handler: EventHandler,
  eventTimeout?: number;
  onTimeout?: () => void;
}

export type EventPayload = Record<string, JsonValue>;
export type EventToken = string;

export type Event = {
  name: string;
  payload: EventPayload;
}

type Context = {
  token: string | null;
}
export type EventHandler = (params: { eventName: string, payload: EventPayload, ctx: Context }) => void | Promise<void>;
export type EventBuffer = Record<EventToken, Event[]>;

export type ProcessEventParams = {
  name: string;
  eventToken: EventToken;
  payload: EventPayload;
}

export type ProcessSubscriptionEventParams = {
  name: string;
  payload: EventPayload;
}

export type UseChannelSubscriptionReturn = {
  startEventsCapture: () => void;
  processEventsForToken: (token: string) => void;
  stop: () => void;
}

export const useTokenBasedChannelSubscription = (channel: Channel, options: Options): UseChannelSubscriptionReturn => {
  const nextEventTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const isTimedOutSubscribedTokenEventStream = useRef<boolean>(false);
  const subscribedToken = useRef<string | null>(null);
  const buffer = useRef<EventBuffer>({});
  const eventHandlers = useRef<Record<string, (payload: EventPayload) => void>>({});

  const processingChain = useRef<Promise<void>>(Promise.resolve());

  const debugMessage = (...args: any[]): void => {
    if (!debug) {
      return;
    }

    console.log(...args);
  };

  const handler = options.handler;
  const eventTimeout = options.eventTimeout;
  const onTimeout = options.onTimeout || noop;

  const nextEventTimeout = useCallback(() => {
    debugMessage("nextEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);

    if (eventTimeout) {
      nextEventTimeoutRef.current = setTimeout(() => {
        isTimedOutSubscribedTokenEventStream.current = true;

        debugMessage("timeout");
        onTimeout();
      }, eventTimeout);
    }
  }, [onTimeout, eventTimeout]);

  const cleanEventTimeout = useCallback(() => {
    debugMessage("cleanEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);
  }, []);

  const processEventsForToken = useCallback((token: string) => {
    debugMessage("subscribe", { token, handler });
    subscribedToken.current = token;
    const tokenEvents = buffer.current[token] || [];

    // Serialize events execution
    let chain = processingChain.current;

    tokenEvents.forEach((event: Event) => {
      chain = chain
        .then(() => {
          debugMessage("handle buffered message", { name: event.name, payload: event.payload });
          return handler({ eventName: event.name, payload: event.payload, ctx: { token: subscribedToken.current } });
        })
        .catch((error) => {
          debugMessage("Error handling event", { name: event.name, error });
          Sentry.captureException(error);
        });
    });

    processingChain.current = chain;

    buffer.current[token] = [];
  }, [handler]);

  const processEventWithoutSubscription = useCallback(({ name, eventToken, payload }: ProcessEventParams) => {
    debugMessage("processEventWithoutSubscription", { name, eventToken, payload });

    buffer.current[eventToken] = buffer.current[eventToken] || [];
    buffer.current[eventToken].push({ name, payload });
  }, []);

  const processEventWithSubscription = useCallback(({ name, payload }: ProcessSubscriptionEventParams) => {
    debugMessage("processEventWithSubscription", { name, payload });
    const token = subscribedToken.current!;

    // Update the chain to process this event after previous events
    const currentChain = processingChain.current;
    const newChain = currentChain
      .then(() => handler({ eventName: name, payload, ctx: { token } }))
      .catch((error) => {
        debugMessage("Error in event handler", { name, error });
        Sentry.captureException(error);
      });

    processingChain.current = newChain;
  }, [handler]);

  const bufferedEventRunner = useCallback((name: string) => {
    return (payload: EventPayload): void => {
      debugMessage("bufferedEventRunner", { name, payload });
      nextEventTimeout();

      const eventToken = payload.token;

      if (!eventToken || typeof eventToken !== "string") {
        debugMessage("bufferedEventRunner: no token or token invalid in event payload");
        Sentry.captureMessage("useChannelSubscription: no token or token invalid in event payload");
        return;
      }

      const isSubscribed = !!subscribedToken.current;
      if (!isSubscribed) {
        processEventWithoutSubscription({ name, eventToken, payload });
        return;
      }

      const isEventFromSubscription = subscribedToken.current === eventToken;
      if (!isEventFromSubscription) {
        debugMessage("bufferedEventRunner: event from another subscription, skip", { name, payload });
        return;
      }

      if (isTimedOutSubscribedTokenEventStream.current) {
        return;
      }

      processEventWithSubscription({ name, payload });
    };
  }, [nextEventTimeout, processEventWithSubscription, processEventWithoutSubscription]);

  const startEventsCapture = useCallback(() => {
    debugMessage("startEventsCapture");
    nextEventTimeout();

    options.events.forEach((eventName: string) => {
      debugMessage(`subscribe for event: ${eventName}`);

      const handler = bufferedEventRunner(eventName);

      // Store the handler for cleanup
      eventHandlers.current[eventName] = handler;

      // Register the handler
      channel.on(eventName, handler);
    });
  }, [channel, nextEventTimeout, options.events, bufferedEventRunner]);

  const stop = useCallback(() => {
    debugMessage("stop");
    buffer.current = {};
    processingChain.current = Promise.resolve();
    subscribedToken.current = null;
    isTimedOutSubscribedTokenEventStream.current = false;
    cleanEventTimeout();

    options.events.forEach((eventName: string) => {
      const handler = eventHandlers.current[eventName];

      if (handler) {
        channel.off(eventName, handler);
      }
    });

    eventHandlers.current = {};
  }, [channel, cleanEventTimeout, options.events]);

  return {
    startEventsCapture,
    processEventsForToken,
    stop,
  };
};
