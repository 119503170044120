import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Alert } from "antd";
import store from "store";

import { Loading } from "components/_v2/states/loading";

import { Location } from "../../../location";
import { StepTitle } from "../step_title";

const { Integrations: { Apaleo } } = store;

export const useApaleoAccount = (appName) => {
  return useQuery({
    queryKey: ["apaleo_account"],
    queryFn: () => Apaleo.getAccount({ appName }),
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: 0,
  });
};

export const AuthStep = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { appName } = props;
  const apaleoAccount = useApaleoAccount(appName);

  const accountName = apaleoAccount?.data?.data?.account?.name;

  const mutation = useMutation({
    mutationFn: () => Apaleo.oauth({ appName }),
  });

  useImperativeHandle(ref, () => ({
    proceed: async () => {
      const result = await mutation.mutateAsync();
      Location.assign(result.authUrl);

      return false;
    },
  }));

  if (apaleoAccount.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <StepTitle>{t("apaleo_import:titles:authenticate")}</StepTitle>
      <p>
        {t("apaleo_import:messages:authenticate")}
      </p>

      {accountName && (
      <Alert
        showIcon
        type="info"
        icon={<ExclamationCircleFilled />}
        description={t("apaleo_import:messages:authenticate_warning", { accountName })}
      />
      )}
    </div>
  );
});
