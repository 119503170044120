import { useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import { Channel } from "store/channels_provider";

const debug = false;
const noop = () => {};

export type Options = {
  eventTimeout?: number;
  onTimeout?: () => void;
}

export type Event = {
  name: string;
  payload: any;
}

export type EventHandler = (eventName: string, payload: any) => void;
export type EventBuffer = Record<string, Event[]>;

export type ProcessEventParams = {
  name: string;
  eventToken: string;
  payload: any;
}

export type ProcessSubscriptionEventParams = {
  name: string;
  payload: any;
}

export type CaptureEventsParams = {
  events: string[];
}

export type UseChannelSubscriptionReturn = {
  captureEvents: (params: CaptureEventsParams) => void;
  processEvents: (token: string, handler: EventHandler) => void;
  stop: () => void;
}

export const useChannelSubscription = (channel: Channel, options: Options = {}): UseChannelSubscriptionReturn => {
  const nextEventTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const isTimedOutSubscribedTokenEventStream = useRef<boolean>(false);
  const subscribedToken = useRef<string | null>(null);
  const buffer = useRef<EventBuffer>({});
  const handlers = useRef<EventHandler[]>([]);

  const debugMessage = (...args: any[]): void => {
    if (!debug) {
      return;
    }

    console.log(...args);
  };

  const eventTimeout = options.eventTimeout;
  const onTimeout = options.onTimeout || noop;

  const nextEventTimeout = useCallback(() => {
    debugMessage("nextEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);

    if (eventTimeout) {
      nextEventTimeoutRef.current = setTimeout(() => {
        isTimedOutSubscribedTokenEventStream.current = true;

        debugMessage("timeout");
        onTimeout();
      }, eventTimeout);
    }
  }, [onTimeout, eventTimeout]);

  const cleanEventTimeout = useCallback(() => {
    debugMessage("cleanEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);
  }, []);

  const processEvents = useCallback((token: string, handler: EventHandler) => {
    debugMessage("subscribe", { token, handler });
    subscribedToken.current = token;
    const existingEvents = buffer.current[token] || [];

    debugMessage("subscribe", { existingEvents });
    existingEvents.forEach((event: Event) => {
      debugMessage("handle buffered message", { name: event.name, payload: event.payload });
      handler(event.name, event.payload);
    });

    buffer.current[token] = [];

    handlers.current.push(handler);
  }, []);

  const processEventWithoutSubscription = useCallback(({ name, eventToken, payload }: ProcessEventParams) => {
    debugMessage("processEventWithoutSubscription", { name, eventToken, payload });
    buffer.current[eventToken] = buffer.current[eventToken] || [];
    buffer.current[eventToken].push({ name, payload });
  }, []);

  const processEventWithSubscription = useCallback(({ name, payload }: ProcessSubscriptionEventParams) => {
    debugMessage("processEventWithSubscription", { name, payload });
    handlers.current.forEach((handler) => {
      debugMessage("invoke subscribed handler", { name, payload });
      handler(name, payload);
    });
  }, []);

  const captureEvents = useCallback(({ events }: CaptureEventsParams) => {
    debugMessage("start", { events });

    nextEventTimeout();

    const bufferedEventRunner = (name: string) => {
      return (payload: any) => {
        debugMessage("bufferedEventRunner", { name, payload });
        nextEventTimeout();

        const eventToken = payload.token;

        if (!eventToken) {
          debugMessage("bufferedEventRunner: no token in event payload");
          Sentry.captureMessage("useChannelSubscription: no token in event payload");
          return;
        }

        const isSubscribed = !!subscribedToken.current;
        if (!isSubscribed) {
          processEventWithoutSubscription({ name, eventToken, payload });
          return;
        }

        const isEventFromSubscription = subscribedToken.current === eventToken;
        if (!isEventFromSubscription) {
          debugMessage("bufferedEventRunner: event from another subscription, skip", { name, payload });
          return;
        }

        if (isTimedOutSubscribedTokenEventStream.current) {
          return;
        }

        processEventWithSubscription({ name, payload });
      };
    };

    events.forEach((eventName: string) => {
      debugMessage("listening event", { event: eventName });

      channel.on(eventName, (event) => bufferedEventRunner(eventName)(event));
    });
  }, [channel, nextEventTimeout, processEventWithSubscription, processEventWithoutSubscription]);

  const stop = useCallback(() => {
    debugMessage("stop");
    buffer.current = {};
    subscribedToken.current = null;
    handlers.current = [];
    isTimedOutSubscribedTokenEventStream.current = false;
    cleanEventTimeout();
  }, [cleanEventTimeout]);

  return {
    captureEvents,
    processEvents,
    stop,
  };
};
