import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import store from "store";
import { propertiesApiClient } from "store/api_clients/properties";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";

import { useRoomTypes } from "data/use_room_types/_v2";

import { SubmitButton } from "components/_v2/forms/buttons/submit";
import { FormSelect } from "components/_v2/forms/fields/select";
import { AppForm, useAppForm } from "components/_v2/forms/hook_form";
import { Loading } from "components/_v2/states/loading";

import { Stepper } from "../stepper";
import { validationSchema } from "../validation_schema";

const { Channels, RoomTypes } = store;

const useChannel = (channelId) => {
  return useQuery({
    queryKey: ["channel", channelId],
    queryFn: () => Channels.find(channelId),
  });
};

const usePropertyAndRoomType = () => {
  return useMutation({
    mutationKey: ["propertyAndRoomType"],
    mutationFn: async ({ propertyId, roomTypeId }) => {
      const result = await Promise.all([
        propertiesApiClient.fetchById(propertyId, { relationships: "all" }),
        RoomTypes.find(roomTypeId),
      ]);

      return {
        property: result[0],
        roomType: result[1],
      };
    },
  });
};

const usePropertyOptionsByChannel = (channelId) => {
  const channelQuery = useChannel(channelId);

  const channelPropertyIds = channelQuery.data?.data?.attributes?.properties || [];
  const properties = useSelector(getPropertiesOptions);
  const channelProperties = properties.filter((property) => channelPropertyIds.includes(property.id));
  const propertiesOptions = channelProperties.map((property) => ({ value: property.id, label: property.title }));

  return {
    propertiesOptions,
    propertiesOptionsIsLoading: channelQuery.isLoading,
  };
};

const useRoomTypesOptions = (propertyId) => {
  const roomTypesQuery = useRoomTypes(propertyId, {
    enabled: !!propertyId,
  });
  const roomTypes = roomTypesQuery.data || [];
  const roomTypeOptions = roomTypes.map((roomType) => ({ value: roomType.id, label: roomType.title }));

  return {
    roomTypeOptionsIsLoading: roomTypesQuery.isLoading,
    roomTypeOptions,
  };
};

export const InitialDataForm = ({ channelId, onFinish }) => {
  const { t } = useTranslation();

  const propertyAndRoomTypeOneTimeQuery = usePropertyAndRoomType();

  const { propertiesOptionsIsLoading, propertiesOptions } = usePropertyOptionsByChannel(channelId);

  const handleSubmit = async (formValue) => {
    const result = await propertyAndRoomTypeOneTimeQuery.mutateAsync({
      propertyId: formValue.propertyId,
      roomTypeId: formValue.roomTypeId,
    });

    onFinish(result);
  };

  const form = useAppForm({
    validationSchema,
    reValidateMode: "onChange",
    onSubmit: handleSubmit,
  });

  const propertyId = form.watch("propertyId");
  const { roomTypeOptionsIsLoading, roomTypeOptions } = useRoomTypesOptions(propertyId);

  if (propertiesOptionsIsLoading) {
    return <Loading />;
  }

  return (
    <AppForm
      form={form}
      schema={{
        layout: {
          type: "scrollBox",
          containerPadding: "0 16px",
          header: <Stepper step={0} />,
          footer: (
            <SubmitButton loading={propertyAndRoomTypeOneTimeQuery.isLoading}>
              {t("common:actions:next")}
            </SubmitButton>
          ),
        },
        fields: [{
          name: "propertyId",
          component: FormSelect,
          label: t("common:terms:property"),
          placeholder: t("common:forms:fields:property:placeholder"),
          options: propertiesOptions,
          onChange: () => {
            form.setValue("roomTypeId", null);
          },
        }, {
          name: "roomTypeId",
          component: FormSelect,
          label: t("common:terms:room_type"),
          placeholder: t("common:forms:fields:room_type:placeholder"),
          loading: roomTypeOptionsIsLoading,
          options: roomTypeOptions,
        }],
      }}
    />
  );
};
