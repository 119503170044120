import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessages.required).typeError(validationMessages.required).min(5, validationMessages.minLength(5)),
  description: yup.string().required(validationMessages.required).typeError(validationMessages.required).min(200, validationMessages.minLength(200)),
  propertyType: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  roomType: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  address: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  registrationNumber: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  city: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  state: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  postalCode: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  checkInInstructions: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  houseRules: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  checkInStartTime: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  checkOutEndTime: yup.string().required(validationMessages.required).typeError(validationMessages.required),
  amenities: yup.array()
    .required(validationMessages.required).of(
      yup.string().required(validationMessages.required).typeError(validationMessages.required),
    ).min(1, validationMessages.required),
  numberOfBedrooms: yup.number().required(validationMessages.required).typeError(validationMessages.number),
  numberOfBeds: yup.number().required(validationMessages.required).typeError(validationMessages.number),
  numberOfBathrooms: yup.number().required(validationMessages.required).typeError(validationMessages.number),
  maxAdults: yup.number().required(validationMessages.required).typeError(validationMessages.number),
  latitude: yup.number().typeError(validationMessages.number).required(validationMessages.required),
  longitude: yup.number().typeError(validationMessages.number).required(validationMessages.required),

  bedConfigurationOptions: yup.array().of(yup.object().shape({
    bedDescriptions: yup.array().of(yup.object().shape({
      type: yup.string().required(validationMessages.required).typeError(validationMessages.required),
      size: yup.string().required(validationMessages.required).typeError(validationMessages.required),
      count: yup.number(validationMessages.number).required(validationMessages.required).typeError(validationMessages.number),
    })),
  })),
});
