import { useMutation } from "@tanstack/react-query";
import store from "store";

const { AdminBillingAccounts } = store;
export default function useCreateSubscriptionAction() {
  return useMutation({
    mutationFn: ({
      billingAccountId,
      body,
    }) => AdminBillingAccounts.createSubscription(billingAccountId, body),
  });
}
