import React from "react";
import { useTranslation } from "react-i18next";

import { NextButton } from "components/_v2/forms/buttons/next";
import { AppForm } from "components/_v2/forms/hook_form";
import { AppFormProps } from "components/_v2/forms/hook_form/app_form";

import { validationSchema } from "./validation_schema";

export type FormValues = {
  token: string;
}

type Params = {
  isSubmitting: boolean;
  initialValue?: FormValues;
  errors?: AppFormProps["errors"];
  onFinish: AppFormProps["onSubmit"];
}

export const TokenForm = ({ isSubmitting, initialValue, errors, onFinish }: Params) => {
  const { t } = useTranslation();

  return (
    <AppForm<FormValues>
      dataTestid="token-form"
      initialValue={initialValue}
      errors={errors}
      validationSchema={validationSchema}
      schema={{
        fields: [{
          label: t("common:terms:token"),
          name: "token",
        }],
        layout: {
          type: "scrollBox",
          footer: (
            <NextButton loading={isSubmitting} />
          ),
        },
      }}
      onSubmit={onFinish}
    />
  );
};
