import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AdminWlDomains } = store;

export function useCreateAction(wlPartnerId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attrs }) => AdminWlDomains.create(wlPartnerId, attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries("admin_wl_domains");
    },
  });
}
