import { useTranslation } from "react-i18next";

import { ChannexDrawer } from "components/_v2/channex_drawer";

import { ListingCheckoutTasks } from "./listing_checkout_tasks";

export const ListingCheckoutTasksDrawer = ({ listing, visible, channelId, onClose }) => {
  const { t } = useTranslation();
  const title = t("channels_page:form:listing:checkout_tasks_drawer:title");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <ListingCheckoutTasks
        listing={listing}
        channelId={channelId}
        onFinish={onClose}
      />
    </ChannexDrawer>
  );
};
