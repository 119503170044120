import APP_MODES from "config/constants/app_modes";

const fallback = () => {};

const appHandlers = {
  fallback,
};

const initOnboardingApp = (session) => {
  const { appSettings, appMode } = session;
  const isAppDisabled = appMode === APP_MODES.HEADLESS;

  if (!appSettings || isAppDisabled) {
    return;
  }

  const { onboardingApp } = appSettings;

  if (!onboardingApp?.app) {
    return;
  }

  const appHandler = appHandlers[onboardingApp?.app] || appHandlers.fallback;

  appHandler(onboardingApp);
};

export default initOnboardingApp;
