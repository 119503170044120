import React, { createContext, useContext } from "react";

import { BaseWizardData, WizardParams } from "./types";
import { useWizardLogic } from "./use_wizard_logic";

type WizardContextType<TData extends BaseWizardData = BaseWizardData> = ReturnType<typeof useWizardLogic<TData>>;

function createWizardContext<TData extends BaseWizardData>() {
  return createContext<WizardContextType<TData> | null>(null);
}

export function createWizardProvider<TData extends BaseWizardData>() {
  const WizardContext = createWizardContext<TData>();

  const WizardProvider = ({
    children,
    initialStep = 0,
    initialData,
    steps,
    onComplete,
  }: WizardParams<TData> & { children: React.ReactNode }) => {
    const wizardLogic = useWizardLogic<TData>({ initialStep, initialData, steps, onComplete });

    return (
      <WizardContext.Provider value={wizardLogic}>
        {children}
      </WizardContext.Provider>
    );
  };

  const useWizard = () => {
    const context = useContext(WizardContext);
    if (!context) {
      throw new Error("useWizard must be used within a WizardProvider");
    }
    return context;
  };

  return {
    WizardProvider,
    useWizard
  };
}
