import React from "react";

import { useCurrentProperties } from "hooks/use_current_properties";

import { FormSelect } from "../select";

export const FormPropertySelector = (props) => {
  const properties = useCurrentProperties();
  const options = properties.map((property) => ({
    value: property.id,
    label: property.title,
  }));

  return (
    <FormSelect
      options={options}
      {...props}
    />
  );
};
