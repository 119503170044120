import React from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

import { AntdFormField } from "components/_v2/forms/fields/_base/antd_form_field";
import { FormInput } from "components/_v2/forms/fields/input";
import { Legend } from "components/_v2/forms/legend";
import { AddButton } from "components/_v2/forms/legend/buttons/add_button";

import styles from "./styles.module.css";

const GuestEmbeddedForm = ({ name, index, onRemove }) => {
  return (
    <AntdFormField
      label={`Guest ${index + 1}`}
      centerLabel
    >
      <Space.Compact style={{ width: "100%" }}>
        <FormInput
          name={`${name}.name`}
          placeholder="Name"
          grouped
        />
        <FormInput
          name={`${name}.surname`}
          placeholder="Surname"
          grouped
        />
        <Button className={styles.groupedAction} type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </Space.Compact>
    </AntdFormField>
  );
};

const buildNew = () => ({
  name: "",
  surname: "",
});

export const Guests = ({ name }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({ name });

  const isEmpty = fields.length === 0;

  return (
    <>
      <Legend
        label={t("common:headings:guests")}
        actions={[
          <AddButton key="add" onClick={() => append(buildNew())} />,
        ]}
      />

      {isEmpty && <Typography.Text type="secondary">{t("common:messages:no_items_added")}</Typography.Text>}

      {fields.map((field, index) => (
        <GuestEmbeddedForm
          key={field.id}
          index={index}
          name={`${name}.${index}`}
          onRemove={() => remove(index)}
        />
      ))}
    </>
  );
};
