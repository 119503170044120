import WSTransport from "./transport/ws";

let ws = null;

export const initWS = (settings) => {
  ws = new WSTransport(settings);

  return ws;
};

export const getWS = () => {
  return ws;
};
