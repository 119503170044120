import React from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { Col, Row } from "antd";

import FormInputNumber from "components/forms/inputs/form_input_number";
import FormSelect from "components/forms/inputs/form_select";
import InputGroup from "components/forms/inputs/input_group/input_group";

import styles from "./style.module.css";

export function DerivedAirBnbRatePlanInput({ airBnbRatePlans = [], name, resetField }) {
  const fieldValue = useWatch({ name });
  const { errors: formErrors } = useFormState();
  const errors = formErrors?.[name];
  const ratePlans = airBnbRatePlans.map((ratePlan) => ({
    label: ratePlan.name,
    value: ratePlan.id,
  }));

  const PRICE_MODIFIERS = [{
    label: "Increase by amount",
    value: "increaseByAmount",
  }, {
    label: "Decrease by amount",
    value: "decreaseByAmount",
  }, {
    label: "Increase by percentage",
    value: "increaseByPercentage",
  }, {
    label: "Decrease by percentage",
    value: "decreaseByPercentage",
  }];

  return (
    <Row>
      <Col span={24}>
        <Controller
          name={`${name}.parentRatePlanId`}
          render={({ field }) => (
            <FormSelect
              hookForm
              allowClear
              label="Parent Rate Plan"
              errors={errors?.parentRatePlanId?.message}
              options={ratePlans}
              {...field}
              onChange={(value) => {
                if (!value) {
                  resetField(`${name}.modifierType`);
                  resetField(`${name}.amount`);
                }
                field.onChange(value);
              }}
            />
          )}
        />

        {fieldValue?.parentRatePlanId && (
          <>
            <legend>Price Settings</legend>
            <InputGroup label="Rate Logic" className={styles.inputGroup}>
              <Controller
                name={`${name}.modifierType`}
                render={({ field }) => (
                  <FormSelect
                    className={styles.modifierType}
                    hookForm
                    grouped
                    errors={errors?.modifierType?.message}
                    options={PRICE_MODIFIERS}
                    {...field}
                  />
                )}
              />

              <Controller
                name={`${name}.amount`}
                render={({ field }) => (
                  <FormInputNumber
                    hookForm
                    grouped
                    errors={errors?.amount?.message}
                    min={1}
                    {...field}
                  />
                )}
              />
            </InputGroup>
          </>
        )}
      </Col>
    </Row>
  );
}
