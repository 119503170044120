const parseBoolean = (value, defaultValue) => {
  if (value === undefined) {
    return defaultValue;
  }

  if (value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  return !!value;
};

const config = {
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
  MIXPANEL_ENABLED: import.meta.env.VITE_MIXPANEL_ENABLED === "true" || import.meta.env.VITE_MIXPANEL_ENABLED === true,
  GOOGLE_MAP_API_KEY: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
  APP_ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT || "local",
  APP_VERSION: import.meta.env.VITE_APP_VERSION || "0.0.0",
  API_SECURE: parseBoolean(import.meta.env.VITE_API_SECURE, true),
  API_HOST: import.meta.env.VITE_API_HOST || window.location.host,
  IS_TEST: import.meta.env.NODE_ENV === "test" || import.meta.env.VITE_CI || import.meta.env.STORYBOOK === "true",
};

export default config;
