// import mixpanel from "mixpanel-browser";
// import { convertToSnakeCase } from "store/utils/case_converter";
import getSessionParamsFromUrl from "store/utils/get_session_params_from_url";

import config from "config";

import AppStorageManager from "utils/app_storage_manager";
import { consoleLogInDev } from "utils/console_log_in_dev";
import { iframeInfo, isIframe } from "utils/is_iframe";

import { JsonValue } from "@/types";

type Payload = Record<string, JsonValue>;

const isEnabled = config.MIXPANEL_ENABLED && !!config.MIXPANEL_TOKEN;

const { appMode } = getSessionParamsFromUrl();

let email: string | undefined;

try {
  const user = AppStorageManager.getItem("CHANNEX_USER");
  email = user?.email;
} catch {
  // Ignore
}

const trackEvent = (eventName: string, payload: Payload = {}) => {
  if (!isEnabled) {
    consoleLogInDev("track event", eventName, payload);
    return;
  }

  // mixpanel.track(eventName, convertToSnakeCase(payload));
}

const trackPageView = (route: string) => {
  if (!isEnabled) {
    consoleLogInDev("track page", route);
    return;
  }

  // mixpanel.track("page_view", { route });
}

const identify = (email: string) => {
  if (!isEnabled) {
    return;
  }

  // mixpanel.identify(email);
  // mixpanel.people.set({ "$email": email })
  register({ email });
}

const register = (_params: Payload) => {
  if (!isEnabled) {
    return;
  }

  // mixpanel.register(convertToSnakeCase(params), { persistent: false });
}

const reset = () => {
  if (!isEnabled) {
    return;
  }

  // mixpanel.reset();
}

if (isEnabled) {
  // mixpanel.init(config.MIXPANEL_TOKEN, {
  //   debug: config.APP_ENVIRONMENT === "local",
  //   ignore_dnt: config.APP_ENVIRONMENT === "local",
  //
  //   persistence: "cookie",
  // });

  register({
    iframe: isIframe(),
    appMode,
    environment: config.APP_ENVIRONMENT,
    release: config.APP_VERSION,
    domain: window.location.origin,
  });

  if (isIframe()) {
    register(iframeInfo());
  }

  if (email) {
    identify(email);
  }
}

export const analytics = {
  identify,
  register,
  trackPageView,
  trackEvent,
  reset
};

export const FEATURE_ACTIONS = {
  INIT: "init",
  ACT: "act",
  COMPLETE: "complete",
  FAIL: "fail",
  CANCEL: "cancel",
}
