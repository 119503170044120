import React, { useMemo } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

import { ItemSelector } from "./item_selector";

const ROW_SELECTOR_HEIGHT = 32;

export const NestedSelectableList = (props) => {
  const {
    disabled,
    lookupTable,
    onItemToggle,
  } = props;

  const items = useMemo(() => Array.from(lookupTable.values()), [lookupTable]);
  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: ROW_SELECTOR_HEIGHT,
    fixedWidth: true,
    minHeight: ROW_SELECTOR_HEIGHT,
  }), [items]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          rowCount={items.length}
          height={height - 20}
          width={width}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={({ index, columnIndex, key, parent, style }) => {
            const item = items[index];

            return (
              <CellMeasurer
                key={key}
                cache={cache}
                columnIndex={columnIndex}
                parent={parent}
                rowIndex={index}
              >
                <div style={style}>
                  <ItemSelector
                    id={item.id}
                    level={item.level}
                    title={item.title}
                    selected={lookupTable.get(item.id)?.selected}
                    disabled={lookupTable.get(item.id)?.disabled || disabled}
                    indeterminate={lookupTable.get(item.id)?.indeterminate}
                    onItemToggle={onItemToggle}
                  />
                </div>
              </CellMeasurer>
            );
          }}
        />
      )}
    </AutoSizer>
  );
};
