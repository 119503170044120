import React, { forwardRef } from "react";
import { DatePicker } from "antd";

import { ensureDayjsDate } from "utils/dates";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

import styles from "styles/form_in_drawer.module.css";

const { RangePicker: AntRangePicker } = DatePicker;

const RIGHT_ARROW = (
  <span
    style={{
      display: "inline-block",
      paddingTop: "2px",
      width: "14px",
      fill: "rgba(0, 0, 0, 0.25)",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 476.213 476.213" xmlSpace="preserve">
      <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 " />
    </svg>
  </span>
);

const DEFAULTS = {};

export const RangePicker = forwardRef((props, ref) => {
  const propsWithDefaults = { ...DEFAULTS, ...props };

  return (
    <AntRangePicker
      className={styles.buttonGroup}
      separator={RIGHT_ARROW}
      {...propsWithDefaults}
      ref={ref}
    />
  );
});

export const FormRangePicker = forwardRef((props, ref) => {
  const { sharedProps, hookFormInputProps, antdFormFieldProps, inputProps } = parseProps(props);

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
        >
          <RangePicker
            {...inputProps}
            {...input}
            value={
              input.value
                ? input.value.map((value) => (value ? ensureDayjsDate(value) : null))
                : null
            }
            ref={ref}
          />
        </AntdFormField>
      )}
    />
  );
});
