import React from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

import styles from "./applications_view_item_cover.module.css";

const ICON_SIZE = 64;

export default function ApplicationsViewItemCover({ item }) {
  const { logoUrl, title } = item;

  return (
    <div className={styles.cover}>
      <Avatar
        data-testid="app_icon"
        className={styles.icon}
        size={ICON_SIZE}
        src={logoUrl}
        icon={<AppstoreOutlined />}
      />
      <div data-testid="app_title" className={styles.title}>
        {title}
      </div>
    </div>
  );
}
