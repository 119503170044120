import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import { Loading } from "components/_v2/states/loading";
import SubmitButton from "components/forms/buttons/submit_button";
import CleaningPolicyForm from "components/hotel_policy/forms/cleaning_policy_form";
import GeneralPolicyForm from "components/hotel_policy/forms/general_policy_form";
import InternetAccessForm from "components/hotel_policy/forms/internet_access_form";
import ParkingPolicyForm from "components/hotel_policy/forms/parking_policy_form";
import PetsPolicyForm from "components/hotel_policy/forms/pets_policy_form";
import SmokingPolicyForm from "components/hotel_policy/forms/smoking_policy_form";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import styles from "styles/form_in_drawer.module.css";

const { HotelPolicies } = store;

class HotelPolicyEditForm extends Component {
  state = {
    value: {},
    errors: {},
    loading: true,
    submitting: false,
  };

  formRefs = {
    generalFormRef: React.createRef(),
    internetFormRef: React.createRef(),
    parkingPolicyRef: React.createRef(),
    petsPolicyRef: React.createRef(),
    smokingPolicyRef: React.createRef(),
    cleaningPolicyRef: React.createRef(),
  };

  componentDidMount() {
    const { policyId } = this.props;

    this.setState({ loading: true });

    HotelPolicies.find(policyId).then(({ data = {} }) => {
      const { attributes } = data;
      this.setState({ value: attributes, loading: false });
    });
  }

  onChange = (subFormValue) => {
    this.setState(({ value }) => ({
      value: { ...value, ...subFormValue },
    }));
  };

  validate = () => {
    const validationHandlers = Object.values(this.formRefs).map((formComponent) => {
      return formComponent.current.validate();
    });

    return Promise.all(validationHandlers);
  };

  onSubmit = () => {
    const { onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    this.setState({ submitting: true });

    this.validate()
      .then(() => {
        HotelPolicies.update(value)
          .then((response) => {
            onClose();
            onCreate(response);
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            this.setState({ errors: parseApiErrors(error) });
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  beforeClose = () => {
    const isChanged = Object.values(this.formRefs)
      .map(({ current }) => current.formik.current)
      .some(({ dirty }) => dirty);

    return confirmDirtyStateSave(isChanged);
  };

  render() {
    const { t, focusField } = this.props;
    const { value, loading, submitting, errors } = this.state;

    const defaultFormProps = {
      onChange: this.onChange,
      value,
      errors,
    };

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <GeneralPolicyForm componentRef={this.formRefs.generalFormRef} focusField={focusField} {...defaultFormProps} />
        <legend>{t("properties_page:policies:legends:internet_access")}</legend>
        <InternetAccessForm componentRef={this.formRefs.internetFormRef} focusField={focusField} {...defaultFormProps} />
        <legend>{t("properties_page:policies:legends:parking")}</legend>
        <ParkingPolicyForm componentRef={this.formRefs.parkingPolicyRef} focusField={focusField} {...defaultFormProps} />
        <legend>{t("properties_page:policies:legends:other")}</legend>
        <PetsPolicyForm componentRef={this.formRefs.petsPolicyRef} focusField={focusField} {...defaultFormProps} />
        <SmokingPolicyForm componentRef={this.formRefs.smokingPolicyRef} focusField={focusField} {...defaultFormProps} />
        <CleaningPolicyForm componentRef={this.formRefs.cleaningPolicyRef} focusField={focusField} {...defaultFormProps} />

        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={this.onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withComponentRef(HotelPolicyEditForm));
