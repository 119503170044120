import { useQuery } from "@tanstack/react-query";
import store from "store";

const { App } = store;

export default function useLatestAgreementContent(options = {}) {
  return useQuery({
    queryKey: ["latest_agreement_content"],
    queryFn: () => App.loadLatestAgreementContent(),
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });
}
