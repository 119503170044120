import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import { Loading } from "components/_v2/states/loading";

import styles from "styles/view_list.module.css";

const { RoomTypes } = store;

class RoomView extends Component {
  state = {
    model: null,
    loading: true,
  };

  componentDidMount() {
    const { roomId } = this.props;

    RoomTypes.find(roomId).then((model) => this.setState({ model, loading: false }));
  }

  render() {
    const { t } = this.props;
    const { loading, model } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <dl className={styles.list}>
        <dt className={styles.list__term}>{t("rooms_page:form:title_label")}:</dt>
        <dd className={styles.list__description}>{model.title}</dd>
        <dt className={styles.list__term}>{t("rooms_page:form:count_of_rooms_label")}:</dt>
        <dd className={styles.list__description}>{model.count_of_rooms}</dd>
        <dt className={styles.list__term}>{t("rooms_page:form:occupancy_label")}:</dt>
        <dd className={styles.list__description}>{model.default_occupancy}</dd>
        <dt className={styles.list__term}>{t("rooms_page:form:occ_adults_label")}:</dt>
        <dd className={styles.list__description}>{model.occ_adults}</dd>
        <dt className={styles.list__term}>{t("rooms_page:form:occ_children_label")}:</dt>
        <dd className={styles.list__description}>{model.occ_children}</dd>
        <dt className={styles.list__term}>{t("rooms_page:form:occ_infants_label")}:</dt>
        <dd className={styles.list__description}>{model.occ_infants}</dd>
      </dl>
    );
  }
}

export default withTranslation()(RoomView);
