import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AdminWlPartners } = store;

export function useDeleteAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => AdminWlPartners.delete(id),
    onSuccess: () => {
      void queryClient.invalidateQueries("admin_wl_partners");
    },
  });
}
