import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(validationMessages.required),
  priceMode: yup.string().trim().required(validationMessages.required),
  pricePerUnit: yup.number()
    .moreThan(-1, validationMessages.positive)
    .required(validationMessages.required)
    .typeError(validationMessages.number),
});
