import React, { Component } from "react";
import { Form, Rate } from "antd";
import snakeCase from "lodash/snakeCase";

import getFieldLayout from "./utils/get_field_layout";
import getFieldStyle from "./utils/get_field_style";

const FormItem = Form.Item;

class FormRate extends Component {
  state = {
    stateValue: null,
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({
      stateValue: defaultValue,
    });
  }

  onChange = (value) => {
    const { onChange } = this.props;

    this.setState({
      stateValue: value,
    });

    if (onChange && typeof onChange === "function") {
      onChange(this.props.name, value);
    }
  };

  getFieldValue = () => {
    const { value, onChange } = this.props;
    const { stateValue } = this.state;

    const isValueProvided = "value" in this.props;

    return onChange && isValueProvided ? value : value || stateValue;
  };

  render() {
    const { extra, label, view, name } = this.props;
    const formItemLayout = getFieldLayout(view);
    const style = getFieldStyle(this.props);
    const dataTestid = name ? snakeCase(`rate_${name.replace(".", "_")}`) : null;

    return (
      <FormItem {...formItemLayout} style={style} extra={extra} label={label} data-testid={dataTestid}>
        <Rate value={this.getFieldValue()} onChange={this.onChange} />
      </FormItem>
    );
  }
}

export default FormRate;
