import { useMutation } from "@tanstack/react-query";
import store from "store";

const { AdminApplications } = store;

export default function useGrantAccessAction() {
  return useMutation({
    mutationFn: ({ applicationId, userId }) => AdminApplications.grantUserAccess({ applicationId, userId }),
  });
}
