import React from "react";

import { AppForm } from "components/_v2/forms/hook_form";

import { FormBody } from "./form_body";
import useCreateAction from "./use_create_action";
import useUpdateAction from "./use_update_action";
import { validationSchema } from "./validation_schema";
import { fromApi, toApi } from "./value_transformations";

export function AirBnbRatePlanForm({ channelId, airBnbRatePlans, value, onFinish }) {
  const { mutateAsync: create, isLoading: isCreateLoading } = useCreateAction();
  const { mutateAsync: update, isLoading: isUpdateLoading } = useUpdateAction();

  return (
    <AppForm
      validationSchema={validationSchema}
      initialValue={value}
      toForm={fromApi}
      fromForm={toApi}
      submitHandler={async (values) => {
        const submit = values.id ? update : create;

        const result = await submit({
          channelId,
          attrs: values,
        });

        onFinish(result.data.attributes);
      }}
    >
      <FormBody
        airBnbRatePlans={airBnbRatePlans}
        isSubmitting={isCreateLoading || isUpdateLoading}
      />
    </AppForm>
  );
}
