import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import classifyApiErrors from "utils/classify_api_errors";
import { flattenObject } from "utils/flatten_object";
import { nullifyEmptyValues } from "utils/nullify_empty_values";
import channexApiErrorParser from "utils/parse_api_errors";

export type UseAppFormParams<TFieldValues extends FieldValues = FieldValues> = {
  validationSchema?: any;
  defaultValue?: TFieldValues; // @deprecated — please use initialValue instead
  initialValue?: TFieldValues;
  fieldNames?: string[];
  apiErrorParser?: any;
  submitHandler?: (values: TFieldValues) => Promise<void> | void; // @deprecated
  onSubmit?: (values: TFieldValues) => Promise<void> | void;
  resetAfterSubmit?: boolean;
  nullifyEmptyFields?: boolean;
  ensureFieldsPresent?: boolean;
  errors?: any;
  errorRoot?: string;
  shouldUnregister?: boolean;
  mode?: any;
  reValidateMode?: any;
  toForm?: (v: any) => TFieldValues;
  fromForm?: (v: TFieldValues) => any;
};

export type UseAppFormReturn<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseFormReturn<TFieldValues>,
  "handleSubmit"
> & {
  handleSubmit: () => Promise<void>;
  errors: any;
  dirtyFields: any;
  isValid: boolean;
};

export const useAppForm = <TFieldValues extends FieldValues = FieldValues>(
  options: UseAppFormParams<TFieldValues>,
): UseAppFormReturn<TFieldValues> => {
  const {
    validationSchema,
    defaultValue, // @deprecated
    initialValue,
    fieldNames = [],
    apiErrorParser,
    submitHandler, // @deprecated
    onSubmit = async () => {},
    resetAfterSubmit = false,
    nullifyEmptyFields = false,
    ensureFieldsPresent = false,
    errors,
    errorRoot = null,
    shouldUnregister = false,
    mode,
    reValidateMode,
    toForm = (v) => v,
    fromForm = (v) => v,
  } = options;

  const {
    handleSubmit: originalHandleSubmit,
    formState,
    clearErrors,
    setError,
    control,
    resetField,
    setValue,
    watch,
    reset,
    trigger,
    ...rest
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: toForm(defaultValue || initialValue),
    errors,
    shouldUnregister,
    mode,
    reValidateMode,
  });

  const customSubmit = async (values: TFieldValues) => {
    try {
      let normalizedValues: any = values;

      if (nullifyEmptyFields) {
        normalizedValues = nullifyEmptyValues(values, nullifyEmptyFields);
      }

      if (ensureFieldsPresent) {
        if (fieldNames.length === 0) {
          throw new Error("fieldNames must be specified when ensureFieldsPresent is true");
        }
        fieldNames.forEach((fieldName) => {
          if (!_.get(normalizedValues, fieldName)) {
            _.set(normalizedValues, fieldName, null);
          }
        });
      }

      normalizedValues = fromForm(normalizedValues);

      await (submitHandler || onSubmit)(normalizedValues);

      if (resetAfterSubmit) {
        reset();
      }
    } catch (error: any) {
      // If the error is not a validation error, throw it.
      if (!error.isValidationError) {
        throw error;
      }

      const parsedErrors = apiErrorParser
        ? apiErrorParser(error)
        : channexApiErrorParser(error, { camelCaseFields: true, root: errorRoot });

      const fieldNamesFromValue = Object.keys(flattenObject({ obj: rest.getValues() }));

      const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, [
        ...fieldNamesFromValue,
        ...fieldNames,
      ]);

      if (Object.keys(globalErrors).length !== 0) {
        setError("root.global", {
          // @ts-ignore
          errors: globalErrors,
        });
      }

      Object.entries(formErrors).forEach(([fieldName, errorMessage]) => {
        setError(fieldName as any, { type: "api", message: errorMessage });
      });
    }
  };

  // Bind our custom submission to react-hook-form's handler.
  const boundSubmit = originalHandleSubmit(customSubmit);

  // Our handleSubmit takes no arguments.
  const handleSubmit = async () => {
    clearErrors();
    await boundSubmit();
  };

  return {
    handleSubmit,
    setValue,
    errors: formState.errors,
    dirtyFields: formState.dirtyFields,
    isValid: formState.isValid,
    clearErrors,
    setError,
    control,
    resetField,
    watch,
    reset,
    trigger,
    formState,
    ...rest,
  };
};
