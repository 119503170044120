import React from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "antd";
import { ButtonProps } from "antd/es/button/button";

export type SubmitButtonProps = {
  children: React.ReactNode;
  icon?: ButtonProps["icon"];
  loading?: ButtonProps["loading"];
  disabled?: ButtonProps["disabled"];
  onClick?: () => void | Promise<void>;
  dataTestid?: string;
}

export function SubmitButton({ icon, children, loading, disabled, onClick, dataTestid }: SubmitButtonProps) {
  const formContext = useFormContext();

  return (
    <Button
      block
      icon={icon}
      type="primary"
      loading={loading}
      size="large"
      htmlType="submit"
      disabled={disabled}
      onClick={() => {
        if (formContext) {
          // @ts-ignore
          formContext.handleSubmit();
        }

        if (onClick) {
          void onClick();
        }
      }}
      data-testid={dataTestid || "submit"}
    >
      {children}
    </Button>
  );
}
