import React, { useState } from "react";

import { InitialDataForm } from "./initial_data_form";
import { ListingForm } from "./listing_form";

const STEPS = {
  initial: "initial",
  form: "form",
};

export const MultiStepListingForm = ({ channelId, listing, onFinish }) => {
  const [step, setStep] = useState(listing ? STEPS.form : STEPS.initial);
  const [propertyAndRoomType, setPropertyAndRoomType] = useState(null);

  if (step === STEPS.initial) {
    return (
      <InitialDataForm
        channelId={channelId}
        onFinish={({ property, roomType }) => {
          setPropertyAndRoomType({ property, roomType });
          setStep(STEPS.form);
        }}
      />
    );
  }

  return (
    <ListingForm
      channelId={channelId}
      listing={listing}
      property={propertyAndRoomType?.property}
      roomType={propertyAndRoomType?.roomType}
      onFinish={onFinish}
      onBack={() => {
        setStep(STEPS.initial);
      }}
    />
  );
};
