import { useMutation, useQuery } from "@tanstack/react-query";
import store from "store";

import { Loading } from "components/_v2/states/loading";
import { showFailureMessage } from "components/toasts/messages";

import { ListingCheckoutTasksForm } from "./listing_checkout_tasks_form";

const { Channels } = store;

const useListingCheckoutTasks = (listing, channelId) => {
  return useQuery({
    queryKey: ["listing_checkout_tasks", { listingId: listing.listing_id, channelId }],
    queryFn: () => Channels.airbnbGetListingCheckoutTasks(channelId, listing.listing_id),
  });
};

const useListingCheckoutTasksMutation = (channelId, listingId) => {
  return useMutation({
    mutationFn: (values) => Channels.airbnbUpdateListingCheckoutTasks(channelId, listingId, values),
  });
};

export const ListingCheckoutTasks = ({ listing, channelId, onFinish }) => {
  const { data, isLoading } = useListingCheckoutTasks(listing, channelId);
  const mutation = useListingCheckoutTasksMutation(channelId, listing.listing_id);

  const handleSubmit = (values) => {
    mutation.mutate(values, {
      onSuccess: () => onFinish(),
      onError: showFailureMessage,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ListingCheckoutTasksForm
      initialValue={data}
      errors={mutation.error}
      isSubmitting={mutation.isLoading}
      onSubmit={handleSubmit}
    />
  );
};
