import React from "react";
import { useLocation, useMatches, useNavigate, useOutletContext, useParams } from "react-router";

import useRouting from "./use_routing";

export default function withRouter(Component) {
  function WithRouter(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const outletContext = useOutletContext();
    const routes = useRouting();
    const matches = useMatches();

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
        outletContext={outletContext}
        routes={routes}
        matches={matches}
      />
    );
  }

  return WithRouter;
}
