import React, { useEffect, useState } from "react";
import store from "store";

import { Loading } from "components/_v2/states/loading";
import DrawerFormWrapper from "components/drawer_form_wrapper";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import AlterationRequest from "./events/alteration_request";
import ReservationRequest from "./events/reservation_request";

const { LiveFeedEvents } = store;

const EVENT_VIEW_BY_TYPE = {
  reservation_request: ReservationRequest,
  alteration_request: AlterationRequest,
};

export default function LiveFeedEventView({ id, onClose }) {
  const [loading, setLoading, setLoaded] = useBoolState(true);
  const [eventData, setEventData] = useState({});

  useEffect(
    function loadEventData() {
      setLoading();

      LiveFeedEvents.find(id)
        .then(setEventData)
        .catch(showErrorMessageFromResponse)
        .finally(setLoaded);
    },
    [id, setLoading, setLoaded],
  );

  if (loading) {
    return <Loading />;
  }

  const EventView = EVENT_VIEW_BY_TYPE[eventData.event];

  if (!EventView) {
    return null;
  }

  return (
    <div>
      <EventView.Actions eventData={eventData} onClose={onClose} />
      <DrawerFormWrapper>
        <EventView.Content eventData={eventData} />
      </DrawerFormWrapper>
    </div>
  );
}
