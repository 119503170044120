import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Spin } from "antd";
import store from "store";

import ChannexModal from "components/channex_modal";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormSelect from "components/forms/inputs/form_select";
import InventoryRatePlan from "components/inventory/inventory_rate_plan";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import styles from "./mapping_modal.module.css";

const { Channels, Properties, RatePlans, RoomTypes } = store;

class MappingModal extends Component {
  state = {
    loading: true,
    properties: [],
    ratePlans: [],
    roomTypes: [],
    value: [],
    errors: [],
  };

  componentDidMount() {
    const { details, issue } = this.props;
    const { rooms } = details;
    const { channel_id: channelId } = issue;
    const { property_id: propertyId } = details;
    let propertiesArray = [];

    const value = rooms.map(({ booking_room_id, rate_plan_id, room_type_id }) => ({
      booking_room_id,
      rate_plan_id,
      room_type_id,
    }));

    if (propertyId) {
      const roomRequest = RoomTypes.options({ property_id: propertyId });
      const rateRequest = RatePlans.options({ property_id: propertyId, channel_id: "null" });

      Promise.all([roomRequest, rateRequest])
        .then((results) => {
          const [roomTypes, ratePlans] = results;

          this.setState({
            loading: false,
            properties: propertiesArray,
            roomTypes,
            ratePlans,
            value,
          });
        });
    } else {
      Channels.find(channelId)
        .then(({ data }) => {
          const {
            attributes: { properties },
          } = data;

          const propertyRequests = properties.map(Properties.find.bind(Properties));

          return Promise.all(propertyRequests);
        })
        .then((properties) => {
          propertiesArray = properties;
          const propertyIds = properties.map(({ id }) => id);

          const roomRequest = RoomTypes.options({ property_id: propertyIds });
          const rateRequest = RatePlans.options({ property_id: propertyIds, channel_id: "null" });

          return Promise.all([roomRequest, rateRequest]);
        })
        .then((results) => {
          const [roomTypes, ratePlans] = results;

          this.setState({
            loading: false,
            properties: propertiesArray,
            roomTypes,
            ratePlans,
            value,
          });
        })
        .catch(showErrorMessageFromResponse);
    }
  }

  getPropertyOptions = () => {
    const { properties } = this.state;

    if (!Array.isArray(properties)) {
      return [];
    }

    return properties.map(({ data }) => {
      const {
        attributes: { id, title },
      } = data;

      return {
        value: id,
        representation: title,
      };
    });
  };

  getRoomTypeOptions = (propertyValue) => {
    const { properties } = this.state;
    let { roomTypes } = this.state;

    if (!Array.isArray(roomTypes)) {
      return [];
    }

    const hasMultipleProperties = properties.length > 1;

    if (hasMultipleProperties) {
      roomTypes = roomTypes.filter(({ property_id }) => property_id === propertyValue);
    }

    const roomTypeOptions = roomTypes.map(({ id, title }) => ({
      value: id,
      representation: title,
    }));

    return roomTypeOptions;
  };

  getRatePlanOptions = (roomTypeId) => {
    const { ratePlans } = this.state;

    const ratePlanOptions = ratePlans
      .filter(({ room_type_id }) => room_type_id === roomTypeId)
      .map(({ id, title, occupancy }) => {
        const representation = (
          <InventoryRatePlan
            className={styles.ratePlanOption}
            title={title}
            occupancy={occupancy}
          />
        );

        return {
          value: id,
          representation,
        };
      });

    return ratePlanOptions;
  };

  renderRoomInputs = (index) => {
    const { t } = this.props;
    const { errors, value, properties } = this.state;

    const roomErrors = errors[index];
    const { roomType, ratePlan, property, saveSolutions } = roomErrors || {};
    const roomValues = value[index];
    const { room_type_id, rate_plan_id, property_id, save_solutions } = roomValues || {};
    const roomTypeValue = room_type_id || undefined;
    const ratePlanValue = rate_plan_id || undefined;
    const propertyValue = property_id || undefined;

    const hasMultipleProperties = properties.length > 1;
    const isRoomTypeDisabled = hasMultipleProperties && !propertyValue;
    const isRatePlanDisabled = !roomTypeValue;
    const ratePlanPlaceholder = t(
      `issues_drawer:unmapped_room:rate_plan${isRatePlanDisabled ? "_disabled" : ""}`,
    );

    return (
      <div key={index}>
        <legend className={styles.legend}>
          {`${t("issues_drawer:unmapped_room:room")}${index + 1}`}
        </legend>

        {hasMultipleProperties && (
          <FormSelect
            view="horizontal"
            value={propertyValue}
            placeholder={t("general:property")}
            label={t("general:property")}
            options={this.getPropertyOptions()}
            errors={property}
            onChange={this.onChange(`${index}.property_id`)}
          />
        )}
        <FormSelect
          view="horizontal"
          value={roomTypeValue}
          disabled={isRoomTypeDisabled}
          placeholder={t("issues_drawer:unmapped_room:room_type")}
          label={t("issues_drawer:unmapped_room:room_type")}
          options={this.getRoomTypeOptions(propertyValue)}
          errors={roomType}
          onChange={this.onChange(`${index}.room_type_id`)}
        />
        <FormSelect
          view="horizontal"
          value={ratePlanValue}
          disabled={isRatePlanDisabled}
          placeholder={ratePlanPlaceholder}
          label={t("issues_drawer:unmapped_room:rate_plan")}
          options={this.getRatePlanOptions(roomTypeValue)}
          errors={ratePlan}
          onChange={this.onChange(`${index}.rate_plan_id`)}
        />
        <FormCheckbox
          view="horizontal"
          name="save_solutions"
          label={t("issues_drawer:unmapped_room:save_solutions")}
          defaultValue={save_solutions}
          onChange={this.onChange(`${index}.save_solutions`)}
          errors={saveSolutions}
        />
      </div>
    );
  };

  validate = () => {
    const { t } = this.props;
    const { value, properties } = this.state;

    const errorText = t("validation_messages:required");

    let formIsValid = true;

    const errors = value.map(({ rate_plan_id, room_type_id, property_id }) => {
      const isPropertyValid = properties.length > 1 ? property_id : true;

      if (rate_plan_id && room_type_id && isPropertyValid) {
        return {};
      }

      formIsValid = false;

      return {
        property: isPropertyValid ? null : errorText,
        ratePlan: rate_plan_id ? null : errorText,
        roomType: room_type_id ? null : errorText,
      };
    });

    return formIsValid ? Promise.resolve() : Promise.reject(errors);
  };

  onChange = (name) => (fieldValue) => {
    const { value } = this.state;

    const [index, fieldName] = name.split(".");

    const roomMapping = value[index];
    const updatedMapping = { ...roomMapping, [fieldName]: fieldValue };

    value[index] = updatedMapping;

    this.setState({ value: [...value] });
  };

  handleOk = () => {
    const { onResolve, onClose } = this.props;
    const { value } = this.state;

    const filteredValue = value.map(({ _property_id, ...rest }) => ({ ...rest }));

    this.validate()
      .then(() => onResolve(filteredValue))
      .then(onClose)
      .catch((errors) => this.setState({ errors }));
  };

  render() {
    const { t, visible, details, onClose } = this.props;
    const { loading } = this.state;
    const { rooms } = details;
    const roomsList = rooms.map((value, index) => this.renderRoomInputs(index));

    return (
      <ChannexModal
        width={540}
        title={t("issues_drawer:unmapped_room:modal_title")}
        onCancel={onClose}
        visible={visible}
        onOk={this.handleOk}
      >
        <Spin spinning={loading}>{roomsList}</Spin>
      </ChannexModal>
    );
  }
}

export default withTranslation()(MappingModal);
