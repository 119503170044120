import { object, string } from "yup";

import { validationMessages } from "config/constants/errors";

const testRequiredWeekDayIfNightlyPriceIsNotSet = (value, ctx) => {
  const parent = ctx.from[1].value;

  if (parent.nightlyPrice !== null && parent.nightlyPrice !== "") {
    return true;
  }

  if (Object.values(parent.dayOfWeekPrices).every((price) => price === null || price === "")) {
    return true;
  }

  return value !== null && value !== "";
};

const weekDayPriceSchema = string().nullable().notRequired().test(
  "optional-required",
  validationMessages.required,
  testRequiredWeekDayIfNightlyPriceIsNotSet,
);

export const validationSchema = object().shape({
  nightlyPrice: string().nullable(),
  guestsIncludedRent: string().nullable(),
  additionalGuestFee: string().nullable(),
  dayOfWeekPrices: object().shape({
    mon: weekDayPriceSchema,
    tue: weekDayPriceSchema,
    wed: weekDayPriceSchema,
    thu: weekDayPriceSchema,
    fri: weekDayPriceSchema,
    sat: weekDayPriceSchema,
    sun: weekDayPriceSchema,
  }),
});
