import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Form, Row } from "antd";
import { Field } from "formik";
import _ from "lodash";

import TaxEditDrawer from "drawers/tax_edit_drawer";

import NoDataPlaceholder from "components/no_data_placeholder";

import TaxAddButton from "./tax_add_button";
import TaxTreeNode from "./tax_tree_node";

import treeStyles from "./tax_tree.module.css";

class TaxTreeInput extends Component {
  state = {
    selectedTaxId: null,
    isTaxDrawerVisible: false,
  };

  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors,
      };
    }
    return {};
  }

  handleAddToRoot = (fieldValue, onChange) => (newTax) => {
    newTax.level = 0;
    onChange({ taxes: [...fieldValue, newTax] });
  };

  toggleTaxDrawer = (selectedTaxId = null) => {
    this.setState(({ isTaxDrawerVisible }) => ({
      isTaxDrawerVisible: !isTaxDrawerVisible,
      selectedTaxId,
    }));
  };

  updateTaxesById = (nodes, tax) => {
    if (!nodes) {
      return [];
    }

    const taxToUpdatePosition = nodes.findIndex((node) => node.id === tax.id);

    if (taxToUpdatePosition !== -1) {
      const taxToUpdate = nodes[taxToUpdatePosition];

      nodes[taxToUpdatePosition] = { ...taxToUpdate, ...tax };
    }

    return nodes.map((node) => {
      const updatedTaxes = this.updateTaxesById(node.taxes, tax);

      return { ...node, taxes: updatedTaxes };
    });
  };

  handleTaxUpdate = (value, onChange) => (updatedTax) => {
    const updatedTaxes = this.updateTaxesById(value, updatedTax);

    onChange({ taxes: updatedTaxes });
  };

  normalize_taxes = (taxes) => {
    return _.flatten(_.uniq(_.map(taxes, (tax) => tax.level))
      .sort()
      .map((level, index) => {
        return taxes
          .filter((tax) => tax.level === level)
          .map((tax) => {
            return { ...tax, level: index };
          });
      }));
  };

  render() {
    const { t, name, taxes, propertyId } = this.props;

    const { selectedTaxId, isTaxDrawerVisible } = this.state;

    return (
      <Field name={name}>
        {({ field, form }) => {
          const { value: fieldValue = [] } = field;
          const onChange = (newValue) => {
            return form.setFieldValue(name, this.normalize_taxes(newValue.taxes));
          };
          const errors = form.touched[name] && form.errors[name];
          const hasNodes = Boolean(fieldValue.length);
          const rootNodeValue = { taxes: fieldValue };

          const levels = _.uniq(fieldValue.map((el) => el.level))
            .sort()
            .reverse();

          const actionParams = {
            createMessageText: t("taxes:tax_set:form:create_message"),
            createComponent: (
              <TaxAddButton
                dataTestid="add_tax"
                text={t("taxes:tax_set:form:create_link")}
                showIcon={false}
                parentTax={rootNodeValue}
                propertyId={propertyId}
                onSelect={this.handleAddToRoot(fieldValue, onChange)}
              />
            ),
          };

          return (
            <>
              <legend>
                {t("taxes:tax_set:form:table_legend")}
                {hasNodes && (
                  <TaxAddButton
                    dataTestid="add_tax"
                    parentTax={rootNodeValue}
                    propertyId={propertyId}
                    onSelect={this.handleAddToRoot(fieldValue, onChange)}
                  />
                )}
              </legend>

              {hasNodes && (
                <Row className={treeStyles.columnHeaders}>
                  <Col xs={10}>
                    {t("taxes:tax_set:form:table_columns:title")}
                  </Col>
                  <Col xs={4} className={treeStyles.inclusive}>
                    {t("taxes:tax_set:form:table_columns:inclusive")}
                  </Col>
                  <Col xs={4}>{t("taxes:tax_set:form:table_columns:rate")}</Col>
                </Row>
              )}

              {!hasNodes && (
                <NoDataPlaceholder
                  emptyMessage={t("taxes:tax_set:form:empty_message")}
                  {...actionParams}
                />
              )}

              <Form.Item {...TaxTreeInput.validation(errors)}>
                {levels.map((level, index) => (
                  <div key={index}>
                    <Row>
                      <Col xs={24}>
                        <strong>Group #{index + 1}</strong>
                      </Col>
                    </Row>
                    <TaxTreeNode
                      taxesList={taxes}
                      propertyId={propertyId}
                      value={rootNodeValue}
                      level={level}
                      onEdit={this.toggleTaxDrawer}
                      onChange={onChange}
                    />
                  </div>
                ))}
              </Form.Item>

              {hasNodes && (
                <Row className={treeStyles.helper}>
                  <Col xs={24}>
                    Tax Group 1 is calculated first and the sum is used for next
                    group
                  </Col>
                </Row>
              )}

              <TaxEditDrawer
                visible={isTaxDrawerVisible}
                onClose={this.toggleTaxDrawer}
                onCreate={this.handleTaxUpdate(fieldValue, onChange)}
                id={selectedTaxId}
              />
            </>
          );
        }}
      </Field>
    );
  }
}

const mapStateToProps = ({ taxes }) => {
  const { entities = [], meta } = taxes || {};
  const data = Object.values(entities);

  return {
    taxes: data,
    meta,
  };
};

export default withTranslation()(connect(mapStateToProps)(TaxTreeInput));
