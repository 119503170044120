import { Checkbox } from "components/_v2/forms/fields/checkbox";
import { MappingRow } from "components/channel_management/components/mapping/common/mapping_row";
import RatePlanTitle from "components/rates/title";

import { useMapping } from "../use_mapping";

export const RateMappingRow = ({ mappingItem }) => {
  const { handleRateMappingToggle } = useMapping();

  return (
    <MappingRow
      showMapping
      showStatus={false}
      target={(
        <Checkbox
          style={{ paddingLeft: 24 }}
          checked={mappingItem.isSelected}
          disabled={mappingItem.isRemoved}
          onChange={() => handleRateMappingToggle({
            ratePlanId: mappingItem.ratePlan.id,
            roomTypeCode: mappingItem.roomTypeCode,
          })}
        >
          <RatePlanTitle rate={mappingItem.ratePlan} />
        </Checkbox>
       )}
    />
  );
};
