import { withTranslation } from "react-i18next";
import { withFormik } from "formik";
import { compose } from "recompose";
import store from "store";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import FormComponent from "./password_recovery_form_component";

const { Auth } = store;

const getSchema = () => yup.object({
  email: yup.string().email(validationMessages.email).required(validationMessages.required),
});

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: getSchema,
  mapPropsToValues: () => ({
    email: "",
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    Auth.requestRestorePassword(values.email)
      .then(() => {
        setSubmitting(false);
        setStatus({
          sent: true,
        });
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
});

const enhance = compose(withTranslation(), withFormContainer);

export default enhance(FormComponent);
