import React, { Component } from "react";
import { Navigate } from "react-router";

import withRouter from "routing/with_router";

class UnauthorizedRouteRedirect extends Component {
  render() {
    const { location, routes } = this.props;
    const { pathname } = location;

    // Redirects are managed by route_manager component in user_app, after user signs in
    const redirectRoute = `${routes.publicAppRoutes.signIn._path}?redirect_to=${pathname}`;

    return <Navigate to={redirectRoute} />;
  }
}

export default withRouter(UnauthorizedRouteRedirect);
