import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { ExtraCategories } = store;

export function useCreateAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attrs }) => ExtraCategories.create(attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["extras", propertyId],
      });
    },
  });
}
