import React from "react";

import styles from "./page_header.module.css";

type Props = {
  title: string;
  subTitle?: string;
}

export const PageHeader = ({ title, subTitle }: Props) => (
  <div className={styles.pageHeader}>
    <div className={styles.pageHeader__title}>{title}</div>
    {subTitle && (<div className={styles.pageHeader__subTitle}>{subTitle}</div>)}
  </div>
);
