import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

type Params = {
  message?: string
}

export const SectionEmpty = ({ message }: Params) => {
  const { t } = useTranslation();

  const resultMessage = message || t("common:messages:no_items_added");

  return (
    <div data-testid="section-empty">
      <Typography.Text type="secondary">{resultMessage}</Typography.Text>
    </div>
  );
};
