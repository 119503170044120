import { withFormik } from "formik";
import { compose } from "recompose";
import store from "store";

import FormComponent from "./group_form_component";

const { Groups } = store;

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ model = {} }) => ({
    id: model.id || null,
    title: model.title || "",
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    const { group, onClose } = props;

    const attrs = { ...group, ...values };

    const groupRequest = values.id
      ? (data) => Groups.update(data)
      : (data) => Groups.create(data);

    groupRequest(attrs)
      .then(() => onClose())
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        setSubmitting(false);
        setErrors(error.body.errors.details);
      });
  },
});

const enhance = compose(withFormContainer);

export default enhance(FormComponent);
