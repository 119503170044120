import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Button, Skeleton } from "antd";
import store from "store";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import styles from "./applications_view_modal_footer.module.css";

const { Properties } = store;

function ApplicationsViewModalFooter({
  item,
  loading,
  onInstall,
  onUninstall,
  onClose,
  canManageApplications,
}) {
  const { t } = useTranslation();
  const [propertyCategory, setPropertyCategory] = useState(null);
  const activeProperty = useSelector(getActivePropertyId);
  const { price, vrPrice, propertyId } = item;
  const visiblePrice = propertyCategory === "vacation_rental" ? vrPrice : price;

  useEffect(
    function loadProperty() {
      Properties.find(activeProperty).then(
        (property) => {
          setPropertyCategory(property.property_category || "hotel");
        },
        (_error) => {},
      );
    },
    [setPropertyCategory, activeProperty],
  );

  const uninstallButton = (
    <Button
      data-testid="app_uninstall_button"
      danger
      className={styles.actionButton}
      loading={loading}
      disabled={loading}
      onClick={onUninstall}
    >
      {t("applications_page:uninstall")}
    </Button>
  );

  const installButton = (
    <Button
      data-testid="app_install_button"
      type="primary"
      className={styles.actionButton}
      loading={loading}
      disabled={loading}
      onClick={onInstall}
    >
      {t("applications_page:install")}
    </Button>
  );

  const actionButton = propertyId ? uninstallButton : installButton;

  return (
    <div>
      <Skeleton
        className={styles.skeleton}
        loading={!propertyCategory}
        title={{ width: 120 }}
        active="true"
        paragraph={false}
      >
        {visiblePrice && (
          <span className={styles.price}>
            {t("applications_page:modal:monthly_fee", { price: visiblePrice })}
          </span>
        )}
      </Skeleton>
      {!canManageApplications && t("applications_page:modal:billing_note")}
      <div>
        {canManageApplications && actionButton}
        <Button onClick={onClose} disabled={loading}>
          {t("general:action:cancel")}
        </Button>
      </div>
    </div>
  );
}

export default connect()(ApplicationsViewModalFooter);
