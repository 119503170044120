import React from "react";
import { useTranslation } from "react-i18next";

import EmptyIcon from "static/empty_search_icon.svg";

const ErrorMessage = ({ dataTestid = "error", text, icon }) => {
  return (
    <div data-testid={dataTestid} style={{ padding: 24 }}>
      <div style={{ textAlign: "center" }}>
        <img src={icon} alt="Error" />
      </div>
      <div style={{ textAlign: "center" }}>
        {text}
      </div>
    </div>
  );
};

export const QueryError = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessage dataTestid="query-error" text={t("query_error_page:message")} icon={EmptyIcon} />
  );
};
