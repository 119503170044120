import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Select } from "antd";
import classNames from "classnames";
import store from "store";
import { getGroupsOptions } from "store/storage/selectors/groups_selector";
import { getSession } from "store/storage/selectors/session_selector";

import styles from "./group_filter.module.css";

const { Auth } = store;

const { Option } = Select;

const ANIMATION_DELAY = 300;

export default function GroupFilter({ isMobile }) {
  const { t } = useTranslation();

  const initialClassName = classNames(styles.groupFilterContainer, {
    [styles.groupFilterContainerMobile]: isMobile,
  });

  const [activeClass, setActiveClass] = useState(initialClassName);
  const groupsOptions = useSelector(getGroupsOptions);
  const session = useSelector(getSession);

  const { activeGroup } = session;

  const selectedValue = activeGroup || "";

  const handleVisibilityChange = (newVisibility) => {
    const newActiveClass = classNames(styles.groupFilterContainer, {
      [styles.groupFilterContainerActive]: newVisibility,
      [styles.groupFilterContainerMobile]: isMobile,
    });

    if (newVisibility) {
      setActiveClass(newActiveClass);
      return;
    }

    setTimeout(() => setActiveClass(newActiveClass), ANIMATION_DELAY);
  };

  const handleChangeGroup = useCallback(
    (selectedId) => {
      const selectedGroup = groupsOptions?.find((group) => group.id === selectedId);

      if (!selectedId) {
        Auth.chooseGroup(selectedId);

        return;
      }

      if (selectedGroup) {
        Auth.chooseGroup(selectedId);
      }
    },
    [groupsOptions],
  );

  useEffect(
    function resetIfSelectedEntityMissing() {
      const isOptionsPresent = groupsOptions;
      const isValueSelected = activeGroup;

      if (!isOptionsPresent || !isValueSelected) {
        return;
      }

      const isSelectedGroupExist = activeGroup && groupsOptions.find((group) => group.id === activeGroup);

      if (!isSelectedGroupExist) {
        handleChangeGroup(null);
      }
    },
    [groupsOptions, activeGroup, handleChangeGroup],
  );

  const isLoading = groupsOptions?.length === 0;
  const isJustSignedUp = groupsOptions?.length === 1;

  if (isLoading || isJustSignedUp) {
    return null;
  }

  return (
    <div data-testid="top_group_filter" className={activeClass}>
      <Select
        className={styles.groupFilter}
        value={selectedValue}
        onChange={handleChangeGroup}
        showSearch
        filterOption
        onDropdownVisibleChange={handleVisibilityChange}
        optionFilterProp="children"
        allowClear="true"
      >
        <Option value="">{t("general:all_groups")}</Option>
        {groupsOptions
          && groupsOptions.map((group) => (
            <Option value={group.id} key={group.id}>
              {group.title}
            </Option>
          ))}
      </Select>
    </div>
  );
}
