import React, { createContext, useContext, useMemo } from "react";

import { useMappingLogic } from "./use_mapping_logic";

const MappingContext = createContext(null);

export const MappingProvider = ({ children, mappings, mappingOptions, mappingSettings, ratePlans, roomTypes, onChangeSettings, onChangeMapping }) => {
  const {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handleRateMappingToggle,
  } = useMappingLogic({
    mappings,
    mappingOptions,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  });

  const value = useMemo(() => ({
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handleRateMappingToggle,
  }), [mappingItems, handleRoomMappingChange, handleRoomMappingDelete, handleRateMappingToggle]);

  return (
    <MappingContext.Provider value={value}>
      {children}
    </MappingContext.Provider>
  );
};

export const useMapping = () => {
  const context = useContext(MappingContext);
  if (!context) {
    throw new Error("useMapping must be used within MappingProvider");
  }

  return context;
};
