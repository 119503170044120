import React, { useMemo } from "react";
import { Radio } from "antd";
import classNames from "classnames";

import styles from "./group_radio_button.module.css";

export default function GroupRadioButton({
  value: groupValue,
  onChange,
  options,
  disabled,
  className,
}) {
  const handleChange = (e) => {
    return onChange(e.target.value);
  };

  const classNameGroup = classNames(styles.buttonGroup, className);

  return (
    <Radio.Group
      value={groupValue}
      onChange={handleChange}
      className={classNameGroup}
      disabled={disabled}
    >
      {useMemo(
        () => options.map((item, index) => {
          const { value, label } = item;
          return (
            <Radio.Button value={value} key={index}>
              <span data-testid={`${value}_button`}>{label}</span>
            </Radio.Button>
          );
        }),
        [options],
      )}
    </Radio.Group>
  );
}
