import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Collapse, Flex } from "antd";
import store from "store";
import { channelsEventsApiClient } from "store/api_clients/channel_events";
 import { useUserChannel } from "store/channels_provider";

import defaultPaginationVars from "config/constants/default_pagination_variables";

import { useTokenBasedChannelSubscription } from "components/_v2/ws/use_token_based_channel_subscription";
import ErrorBoundary from "components/error_boundary";
import NoDataPlaceholder from "components/no_data_placeholder/no_data_placeholder";

import { download } from "utils/download";
import showErrorMessage from "utils/show_error_message";
import useBoolState from "utils/use_bool_state";
import usePagination from "utils/use_pagination";

import ChannelEventsLog from "./channel_events_log";

import styles from "./channel_events.module.css";

const { ChannelEvents } = store;

const PAGE_LIMIT = 13;
const ITEM_HEIGHT = 48;
const DISPLAYED_ITEMS = 10;
const LIST_HEIGHT = ITEM_HEIGHT * DISPLAYED_ITEMS;

const useDownloadLogsAction = () => {
  return useMutation({
    mutationFn: (eventId) => {
      return channelsEventsApiClient.initDownload({ eventId });
    }
  })
}

export default function ChannelEventsLogsList({ actionId }) {
  const { t } = useTranslation();
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const [isDownloading, setIsDownloading, setDownloadingComplete] = useBoolState(false);
  const { handleError } = useContext(ErrorBoundary.Context);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(defaultPaginationVars);
  const { nextPage, isLastPage } = usePagination(meta);

  const downloadLogsAction = useDownloadLogsAction();

  const userChannel = useUserChannel();
  const userChannelSubscription = useTokenBasedChannelSubscription(userChannel, {
    events: ["channel_event_logs_export_started", "channel_event_logs_export_completed", "channel_event_logs_export_failed"],
    handler: async ({ eventName, ctx }) => {
      if (eventName === "channel_event_logs_export_completed") {
        const content = await channelsEventsApiClient.downloadLogs({ token: ctx.token });
        download(`channel_event_logs_${actionId}.zip`, content);

        setDownloadingComplete();
        userChannelSubscription.stop();
      }

      if (eventName === "channel_event_logs_export_failed") {
        setDownloadingComplete();
        userChannelSubscription.stop();

        showErrorMessage(t("general:errors:request_failed"));
      }
    },
    eventTimeout: 60_000,
    onTimeout: () => {
      showErrorMessage(t("general:errors:request_failed"));
      setDownloadingComplete();
      userChannelSubscription.stop();
    },
  });

  const loadLogs = useCallback(
    async (page) => {
      setLoading();

      const pagination = { ...meta, page, limit: PAGE_LIMIT };

      try {
        const response = await ChannelEvents.loadLogs(actionId, pagination);

        const newData = [...data, ...response.data.logs];
        setData(newData);

        setMeta(response.meta);
      } catch (error) {
        handleError(error);
      }

      setLoadingComplete();
    },
    [data, meta, actionId, handleError, setLoading, setLoadingComplete],
  );

  const loadNext = useCallback(() => {
    loadLogs(nextPage);
  }, [loadLogs, nextPage]);

  const handlePanelToggle = useCallback(
    (activePanels) => {
      const isOpened = activePanels.length;
      const shouldLoadLogs = isOpened && !data.length && !loading;

      if (!shouldLoadLogs) {
        return;
      }

      loadLogs(1);
    },
    [data, loading, loadLogs],
  );

  const handleDownloadClick = async (e) => {
    e.stopPropagation();
    setIsDownloading();

    try {
      userChannelSubscription.startEventsCapture();

      const { status, token } = await downloadLogsAction.mutateAsync(actionId);

      if (status === "completed") {
        const content = await channelsEventsApiClient.downloadLogs({ token });
        download(`channel_event_logs_${actionId}.zip`, content);
        setDownloadingComplete();
        userChannelSubscription.stop();
        return;
      }

      if (status !== "started") {
        throw new Error("Unexpected status");
      }

      userChannelSubscription.processEventsForToken(token);
    } catch (error) {
      userChannelSubscription.stop();
      setDownloadingComplete();

      if (!error.isHttp) {
        throw error;
      }
    }
  }

  const listHeight = data.length > DISPLAYED_ITEMS ? LIST_HEIGHT : "auto";

  const listItems = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((log) => (
      <ChannelEventsLog log={log} key={`${log.data.query_id}_${log.data.finished_at}`} />
    ));
  }, [data]);

  const renderIcon = useCallback(
    () => (
      <span>
        <LoadingOutlined />
      </span>
    ),
    [],
  );

  const expandIcon = loading ? renderIcon : null;
  const isLogsMissing = !loading && !data.length;
  const content = isLogsMissing ? (
    <NoDataPlaceholder emptyMessage={t("general:no_data_placeholder")} />
  ) : (
    <InfiniteScroll
      dataLength={data.length}
      next={loadNext}
      hasMore={!isLastPage}
      height={listHeight}
    >
      {listItems}
    </InfiniteScroll>
  );

  const items = [{
    key: "logs",
    label: (
      <Flex justify="space-between">
        {t("channel_events_page:channel_events_view_dialog:logs")}
        <Button
          style={{ height: 22 }}
          data-testid="dowload-logs-btn"
          type="link"
          loading={isDownloading}
          icon={<DownloadOutlined />}
          onClick={(e) => void handleDownloadClick(e)}
        >
          {t("common:actions:download")}
        </Button>
      </Flex>
    ),
    className: styles.logsContainer,
    children: content,
  }];

  return (
    <Collapse items={items} bordered={false} expandIcon={expandIcon} onChange={handlePanelToggle} />
  );
}
