export const ScrollableContainer = ({ padding, leftBorder, children }) => {
  const style = {
    flex: 1,
    overflowY: "auto",
    padding,
    height: "100%",
  };

  if (leftBorder) {
    style.borderLeft = "1px solid #f0f0f0";
  }

  return (
    <div style={style}>
      {children}
    </div>
  );
};
