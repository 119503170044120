import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

type Props = {
  onClick: () => void;
}

export const IconEditButton = ({ onClick }: Props) => {
  return (
    <Button data-testid="edit-btn" type="link" onClick={onClick} icon={<EditOutlined />} />
  );
};
