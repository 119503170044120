import { useEffectOnce } from "hooks/use_effect_once";

import { EXPORT_STATES } from "./use_export";

import { analytics, FEATURE_ACTIONS } from "@/analytics";

const featureName = "bookings-export_pdf";

export const useFeatureTracking = (isOpen: boolean, exportState: string) => {
  useEffectOnce(() => {
    if (!isOpen) {
      return;
    }

    if (exportState === EXPORT_STATES.INITIAL) {
      analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.INIT}`, {
        feature: featureName,
      });
    }

    if (exportState === EXPORT_STATES.FINISHED) {
      analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.ACT}`, {
        feature: featureName,
        action: "ready",
      });
    }

    if (exportState === EXPORT_STATES.FAILED) {
      analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.FAIL}`, {
        feature: featureName,
      });
    }
  }, [exportState, isOpen]);
}

export const trackFeatureCancel = () => {
  analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.CANCEL}`, {
    feature: featureName,
  });
}

export const trackFeatureComplete = () => {
  analytics.trackEvent(`${featureName} - ${FEATURE_ACTIONS.COMPLETE}`, {
    feature: featureName,
  });
}
