import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export function useEffectOnce(effect: EffectCallback, deps: DependencyList = []) {
  const prevDeps = useRef<DependencyList>(undefined);
  const cleanup = useRef<ReturnType<EffectCallback>>(undefined);
  const alreadyRun = useRef(false);

  useEffect(() => {
    const prevDepsPresent = !!prevDeps.current;
    const depsChanged = prevDepsPresent && (deps.length !== prevDeps.current?.length || deps.some((dep, i) => !Object.is(dep, prevDeps.current?.[i])));

    if (depsChanged) {
      alreadyRun.current = false;
    }

    if (alreadyRun.current) {
      return;
    }

    alreadyRun.current = true;

    if (prevDepsPresent && typeof cleanup.current === "function") {
      cleanup.current();
    }

    cleanup.current = effect();
    prevDeps.current = deps;

    return () => {
      if (typeof cleanup.current === "function") {
        cleanup.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
