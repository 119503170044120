import React from "react";
import { DatePicker } from "antd";

import styles from "./range_picker.module.css";

export default function RangePicker({
  presets,
  isMobile,
  value,
  defaultValue,
  disabledDate,
  disabled,
  onChange,
  dataTestid,
  ...rest
}) {
  const datePickerMobileProps = isMobile
    ? {
      showTime: true,
      format: "YYYY/MM/DD",
      popupClassName: styles.datePickerMobileDropdown,
    }
    : {};

  return (
    <DatePicker.RangePicker
      data-testid={dataTestid}
      value={value}
      defaultValue={defaultValue}
      className={styles.datePicker}
      disabledDate={disabledDate}
      presets={presets}
      disabled={disabled}
      onChange={onChange}
      {...datePickerMobileProps}
      {...rest}
    />
  );
}
