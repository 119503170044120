import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Button, Empty, Timeline } from "antd";
import store from "store";

import ChannexDrawer from "drawers/channex_drawer";
import TaskDrawer from "drawers/task_drawer";

import { Loading } from "components/_v2/states/loading";
import DrawerFormWrapper from "components/drawer_form_wrapper";

import { parseQueryParams, stringifyQueryParams } from "routing/utils";

import { dayjs, formatDate, formatToLocal } from "utils/dates";

import { FiltersForm } from "./filters_form/filters_form";

import styles from "./state_changes_drawer.module.css";

const { StateChanges } = store;

const calcIsFiltersComplete = ({ propertyId, date, restriction, roomTypeId, ratePlanId }) => !!(propertyId && date && restriction && (roomTypeId || ratePlanId));

const useStateChanges = ({ propertyId, date, restriction, ratePlanId, roomTypeId }) => {
  return useQuery({
    queryKey: ["stateChanges", propertyId, ratePlanId, roomTypeId, date, restriction],
    queryFn: () => {
      if (roomTypeId) {
        return StateChanges.forRoom(
          propertyId,
          roomTypeId,
          date,
          restriction,
        );
      }

      if (ratePlanId) {
        return StateChanges.forRate(
          propertyId,
          ratePlanId,
          date,
          restriction,
        );
      }
    },
    enabled: calcIsFiltersComplete({ propertyId, date, restriction, ratePlanId, roomTypeId }),
  });
};

const Results = ({ dataQuery, restriction, isFiltersComplete, onTaskClick }) => {
  const { t } = useTranslation();
  const { data, isFetching } = dataQuery;

  if (!isFiltersComplete || (!data && !isFetching)) {
    return null;
  }

  if (isFetching) {
    return <Loading />;
  }

  if (data.length === 0) {
    return (
      <DrawerFormWrapper>
        <Empty description={t("state_changes:empty_message")} />
      </DrawerFormWrapper>
    );
  }

  return (
    <DrawerFormWrapper>
      <Timeline>
        {
          data.map(({ attributes }) => {
            const {
              taskId,
              task,
              insertedAt,
              value,
            } = attributes;

            const valueRepresentation = (typeof value === "boolean") ? value.toString() : value;

            return (
              <Timeline.Item key={insertedAt}>
                <small>{formatToLocal(insertedAt, t("formats:iso_date_with_time"))}</small><br />
                {t(`general:restrictions:${restriction}`)}: <strong>{valueRepresentation}</strong><br />
                {t("state_changes:task")}: <Button className={styles.flatButton} type="link" onClick={() => onTaskClick(taskId)}>{task}</Button>
              </Timeline.Item>
            );
          })
        }
      </Timeline>
    </DrawerFormWrapper>
  );
};

export default function StateChangesDrawer(props) {
  const {
    visible,
    ratePlanId,
    roomTypeId,
    date,
    restriction,
    propertyId,
    ratePlans,
    isLoadingRatePlans,
    roomTypes,
    isLoadingRoomTypes,
    onClose,
  } = props;

  const { t } = useTranslation();
  const [taskDrawerIsVisible, setTaskDrawerIsVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [filters, setFilters] = useState({ ratePlanId, roomTypeId, date, restriction });
  const dataQuery = useStateChanges({ propertyId, ...filters });
  const navigate = useNavigate();

  const handleTaskDrawerClose = useCallback(() => {
    setSelectedTaskId(null);
    setTaskDrawerIsVisible(false);
  }, [setTaskDrawerIsVisible, setSelectedTaskId]);

  const handleTaskDrawerOpen = useCallback((taskId) => {
    setSelectedTaskId(taskId);
    setTaskDrawerIsVisible(true);
  }, [setTaskDrawerIsVisible, setSelectedTaskId]);

  const handleFiltersChange = useCallback((values) => {
    const params = {
      ...values,
      date: formatDate(values.date),
    };

    setFilters(params);
    const currentParams = parseQueryParams();
    currentParams.log = params;
    const newParamString = stringifyQueryParams(currentParams);

    navigate(`/inventory/state-changes${newParamString}`, { replace: true });
  }, [setFilters, navigate]);

  return (
    <ChannexDrawer
      title={t("state_changes:header")}
      onClose={onClose}
      visible={visible}
    >
      <FiltersForm
        defaultValue={{
          date: dayjs(date),
          restriction,
          ratePlanId,
          roomTypeId,
        }}
        isLoadingData={dataQuery.isFetching}
        ratePlans={ratePlans}
        isLoadingRatePlans={isLoadingRatePlans}
        roomTypes={roomTypes}
        isLoadingRoomTypes={isLoadingRoomTypes}
        onChange={handleFiltersChange}
      />

      <Results
        dataQuery={dataQuery}
        restriction={filters.restriction}
        isFiltersComplete={calcIsFiltersComplete({ propertyId, ...filters })}
        onTaskClick={handleTaskDrawerOpen}
      />

      <TaskDrawer
        visible={taskDrawerIsVisible}
        taskId={selectedTaskId}
        onClose={handleTaskDrawerClose}
      />
    </ChannexDrawer>
  );
}
