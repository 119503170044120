import { Id } from "@/types/index";

export const SELL_MODE_PER_ROOM = "per_room";
export const SELL_MODE_PER_PERSON = "per_person";

export type RatePlanSellMode = typeof SELL_MODE_PER_ROOM | typeof SELL_MODE_PER_PERSON;

export type RatePlan = {
  id: Id;
  title: string;
  sell_mode: RatePlanSellMode;
  occupancy: number;
  property_id: string;
  room_type_id: string;
  parent_rate_plan_id: Id | null;
};
