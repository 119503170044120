import { createWizard } from "components/_v2/wizard";

export type NewBookingWizardData = never;

const { Wizard, WizardSidebar, WizardStep, useWizard } = createWizard<NewBookingWizardData>();

export {
  Wizard,
  WizardSidebar,
  WizardStep,
  useWizard,
}
