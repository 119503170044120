import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router";
import { Row } from "antd";
import { getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import AuthFormSubTitle from "components/auth_form_sub_title";
import AuthFormTitle from "components/auth_form_title";
import SocialAuth from "components/social_auth";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";
import withRouter from "routing/with_router";

import SignUpForm from "./components/sign_up_form";

function SignUpPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { publicAppRoutes } = useRouting();

  const isDisableSignUp = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_SIGN_UP));

  useEffect(() => {
    if (isDisableSignUp) {
      navigate(publicAppRoutes.signIn._path);
    }
  }, [isDisableSignUp, publicAppRoutes, navigate]);

  return (
    <>
      <AuthFormTitle>{t("sign_up_page:header")}</AuthFormTitle>
      <AuthFormSubTitle>{t("sign_up_page:sub_title")}</AuthFormSubTitle>
      <SocialAuth />
      <SignUpForm />

      <Row justify="space-between">
        <Link to={pathBuilder(publicAppRoutes.signIn)}>
          <span data-testid="sign_in_link">{t("public_pages:sign_in_link")}</span>
        </Link>

        <Link to={pathBuilder(publicAppRoutes.passwordRecovery)}>
          <span data-testid="password_recovery_link">{t("public_pages:password_recovery_link")}</span>
        </Link>
      </Row>
    </>
  );
}

export default withRouter(SignUpPage);
