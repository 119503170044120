import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import UserInviteForm from "./form/form";

const { AdminUsers } = store;

class UserInviteFormWrapper extends Component {
  state = {
    submitting: false,
    value: {},
    errors: {},
  };

  formRef = React.createRef();

  handleSubmit = () => {
    const { onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        AdminUsers.invite({ ...value })
          .then((result) => {
            onCreate(result);
            onClose();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            this.setState({ errors: parseApiErrors(error) });
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (!current) {
      return Promise.resolve();
    }

    const { dirty } = current.formik.current;

    return confirmDirtyStateSave(dirty);
  };

  render() {
    const { value, submitting, errors } = this.state;

    return (
      <UserInviteForm
        value={value}
        errors={errors}
        submitting={submitting}
        componentRef={this.formRef}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withComponentRef(withTranslation()(UserInviteFormWrapper));
