import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { MewsSettings } from "pages/applications_page/application_settings/mews";
import { useInstallation } from "pages/applications_page/use_installation";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { NextButton } from "components/_v2/forms/buttons/next";
import { Loading } from "components/_v2/states/loading";

import { useWizard,WizardStep } from "../wizard";

type MewsSettingsRef = {
  submit: () => Promise<void>;
}

export const StepSettings = () => {
  const { t } = useTranslation();
  const { data, goNext } = useWizard();
  const settingsRef = useRef<MewsSettingsRef>(null);

  if (!data?.applicationInstallationId) {
    throw new Error("Missing required data for step");
  }

  const { applicationInstallationId } = data;

  const installationQuery = useInstallation(applicationInstallationId);

  const handleNext = async () => {
    await settingsRef.current?.submit();
  };

  return (
    <WizardStep dataTestid="settings-step">
      <BodyContainer overflow dataTestid="settings-form">
        <ScrollableContainer leftBorder={false} padding="16px 16px">
          {installationQuery.isLoading && <Loading />}
          {installationQuery.isError && <div>{t("mews:messages:loading_error")}</div>}
          {installationQuery.isSuccess && (
            <MewsSettings
              ref={settingsRef}
              // @ts-ignore
              installation={installationQuery.data}
              embedded
              onSuccess={goNext}
            />
          )}
        </ScrollableContainer>
        <FooterContainer>
          <NextButton disabled={installationQuery.isLoading} onClick={handleNext} />
        </FooterContainer>
      </BodyContainer>
    </WizardStep>
  );
};
