import React from "react";
import { useTranslation } from "react-i18next";

import { SubmitButton, SubmitButtonProps } from "components/_v2/forms/buttons/submit";

export const NextButton = (props: Omit<SubmitButtonProps, "children">) => {
  const { t } = useTranslation();

  return (
    <SubmitButton dataTestid="next-btn" {...props}>
      {t("common:actions:next")}
    </SubmitButton>
  );
};
