import React from "react";
import { StepProps, Steps } from "antd";

import { ScrollBox } from "components/_v2/layout/scroll_box";

import { createWizardProvider } from "./create_wizard_provider";
import { BaseWizardData, StepIndex, WizardInnerProps, WizardParams, WizardStep } from "./types";

type WizardProps<TData extends BaseWizardData = BaseWizardData> = WizardParams<TData> & WizardInnerProps;

const createWizardInner = (useWizard: () => { steps: WizardStep[]; currentStepIndex: StepIndex }) => {
  return function WizardInner({ controller }: WizardInnerProps) {
    const { steps, currentStepIndex } = useWizard();

    return (
      <>
        {steps[currentStepIndex].component}
        {controller && controller}
      </>
    );
  };
};

const createWizardSidebar = (useWizard: () => { steps: WizardStep[]; stepsErrors: number[]; seenSteps: number[]; currentStepIndex: StepIndex }) => {
  return function WizardSidebar() {
    const { steps, stepsErrors, seenSteps, currentStepIndex } = useWizard();

    const items: StepProps[] = steps.map(({ title }, i) => {
      const step: StepProps = {
        title,
        disabled: !seenSteps.includes(i),
      };

      if (seenSteps.includes(i)) {
        step.status = "finish";
      }

      if (stepsErrors.includes(i)) {
        step.status = "error";
      }

      if (i === currentStepIndex) {
        step.status = "process";
      }

      return step;
    });

    return (
      <Steps
        size="small"
        direction="vertical"
        current={currentStepIndex}
        items={items}
      />
    );
  };
}

type WizardStepProps = {
  children: React.ReactNode;
  dataTestid?: string;
  footer?: React.ReactNode;
}

export function createWizard<TData extends BaseWizardData = BaseWizardData>() {
  const { WizardProvider, useWizard } = createWizardProvider<TData>();
  const WizardInner = createWizardInner(useWizard);
  const WizardSidebar = createWizardSidebar(useWizard);

  const WizardStep = ({ children, dataTestid, footer }: WizardStepProps) => {
    return (
      <ScrollBox
        dataTestid={dataTestid}
        overflow
        sidebarWidth={300}
        containerPadding="0"
        sidebar={
          <WizardSidebar />
        }
        footer={footer}
        content={children}
      />
    );
  };

  const Wizard = ({
    initialStep,
    initialData,
    steps,
    controller,
    onComplete,
  }: WizardProps<TData>) => {
    return (
      <WizardProvider
        steps={steps}
        initialData={initialData}
        initialStep={initialStep}
        onComplete={onComplete}
      >
        <WizardInner controller={controller} />
      </WizardProvider>
    );
  };

  return {
    Wizard,
    WizardSidebar,
    WizardStep,
    useWizard
  };
}
