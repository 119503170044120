import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex } from "antd";

import currencyOptions from "config/constants/currency_options";

import { NextButton } from "components/_v2/forms/buttons/next";
import { PrevButton } from "components/_v2/forms/buttons/prev";
import { convertValueToCurrencyFormat } from "components/_v2/forms/fields/currency";
import { FormSelect } from "components/_v2/forms/fields/select";
import { AppForm, FormSchemaRenderer } from "components/_v2/forms/hook_form";
import { Legend } from "components/_v2/forms/legend";

import { Deposits } from "../../common/deposits";
import { useWizard, WizardSidebar } from "../wizard";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ currency }) => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();
  const { getValues, setValue } = useFormContext();

  const paymentCollectOptions = [{
    label: t("common:terms:ota"),
    value: "ota",
  }, {
    label: t("common:terms:property"),
    value: "property",
  }];

  const paymentTypeOptions = [{
    label: t("common:terms:credit_card"),
    value: "credit_card",
  }, {
    label: t("common:terms:bank_transfer"),
    value: "bank_transfer",
  }];

  const fields = [
    {
      render: () => <Legend marginTop={false} label={t("common:headings:payment")} />,
    }, {
      name: "paymentCollect",
      component: FormSelect,
      label: t("common:terms:payment_collect"),
      options: paymentCollectOptions,
    }, {
      name: "paymentType",
      component: FormSelect,
      label: t("common:terms:payment_type"),
      options: paymentTypeOptions,
    }, {
      name: "currency",
      component: FormSelect,
      label: t("common:terms:currency"),
      options: currencyOptions({ label: "label" }),
      onChange: (newCurrency) => {
        const value = getValues("otaCommission");
        const compatibleValue = convertValueToCurrencyFormat(value, newCurrency);

        setValue("otaCommission", compatibleValue);
      },
    }, {
      name: "otaCommission",
      label: t("common:terms:ota_commission"),
      componentProps: {
        currency,
      },
    }, {
      render: () => <Deposits name="deposits" currency={currency} />,
    },
  ];

  return (
    <FormSchemaRenderer
      dataTestid="payments-form"
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <PrevButton onClick={goPrev} />
              <NextButton />
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step5 = () => {
  const { goNext } = useWizard();
  const { setValue, getValues, formState } = useFormContext();
  const { currency } = getValues();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner currency={currency} />
    </AppForm>
  );
};
