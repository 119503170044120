import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import { WEEKDAY_VALUES } from "components/forms/inputs/form_weekdays_v2";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(validationMessages.required),
  type: yup.string().trim().required(validationMessages.required),
  value: yup.string()
    .nullable()
    .trim()
    .when("type", {
      is: (typeValue) => {
        return ["availability_offset", "max_availability"].includes(typeValue);
      },
      then: (schema) => schema.required(validationMessages.required),
    }),
  affectedDates: yup.object().shape({
    range: yup.array()
      .test("ensure start date", validationMessages.required, (value) => {
        const [startDate, _endDate] = value || [];

        return !!startDate;
      })
      .required(validationMessages.required),
    weekdays: yup.array().of(
      yup.string().oneOf(WEEKDAY_VALUES),
    )
      .min(1, validationMessages.required)
      .required(validationMessages.required),
  }),
  affectedChannels: yup
    .array().of(
      yup.string().trim().required(validationMessages.required),
    )
    .min(1, validationMessages.required)
    .required(validationMessages.required),
  affectedRoomTypes: yup
    .array().of(
      yup.string().trim().required(validationMessages.required),
    )
    .min(1, validationMessages.required)
    .required(validationMessages.required),
});
