import alphabetSort from "utils/alphabet_sort";

import { buildRatePlansByRoomTypeId } from "../../utils/build_rate_plans_by_room_type_id";
import { buildRoomTypeOptionsByExternalRoomId } from "../../utils/build_room_type_options_by_external_room_id";

const buildMappingItemsPerRoomAndRate = ({
  rooms,
  roomTypeOptionsByExternalRoomId,
  externalRoomsMapping,
  mappingsPerExternalRoom,
  ratePlanByRoomTypeId,
}) => {
  return [...(rooms || [])]
    .sort(alphabetSort("title"))
    .reduce((acc, room) => {
      const roomMappingItem = {
        type: "room",
        isInvalid: room.isInvalid,
        isRemoved: room.isRemoved,
        externalRoom: {
          id: room.id,
          title: room.title,
        },
        roomTypes: roomTypeOptionsByExternalRoomId[room.id],
      };

      const roomTypeId = externalRoomsMapping[room.id];
      if (roomTypeId) {
        roomMappingItem.mapping = {
          roomTypeId,
        };
      }

      acc.push(roomMappingItem);

      const mappedRoomRatePlans = ratePlanByRoomTypeId[roomTypeId];

      mappedRoomRatePlans?.forEach((ratePlan) => {
        const parentRoomId = room.id;

        const rateMappingItem = {
          type: "rate",
          isInvalid: room.isInvalid,
          isRemoved: room.isRemoved,
          roomTypeCode: parentRoomId,
          ratePlan,
          isSelected: !!mappingsPerExternalRoom[room.id]?.[ratePlan.id],
        };

        acc.push(rateMappingItem);
      });

      return acc;
    }, []);
};

export const buildMappingItems = ({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }) => {
  const externalRoomsMapping = mappingSettings.rooms;
  const roomTypeOptionsByExternalRoomId = buildRoomTypeOptionsByExternalRoomId({ mappingOptions, unknownMappingOptions, roomTypes, externalRoomsMapping });
  const ratePlanByRoomTypeId = buildRatePlansByRoomTypeId({ ratePlans });

  const invalidItems = buildMappingItemsPerRoomAndRate({ rooms: unknownMappingOptions.rooms, roomTypeOptionsByExternalRoomId, externalRoomsMapping, mappingsPerExternalRoom, ratePlanByRoomTypeId, t });
  const items = buildMappingItemsPerRoomAndRate({ rooms: mappingOptions.rooms, roomTypeOptionsByExternalRoomId, externalRoomsMapping, mappingsPerExternalRoom, ratePlanByRoomTypeId, t });

  return [
    ...invalidItems,
    ...items,
  ];
};
