import { convertToCamelCase } from "../../utils/case_converter";
import extractRelationships from "../../utils/relationships_extractor";

export const parseResponse = (response) => {
  const data = convertToCamelCase(
    extractRelationships(response.data, { group: false }),
  );

  if (response.meta) {
    return {
      data,
      meta: response.meta,
    };
  }

  return data;
};
