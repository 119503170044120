import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import dayjs from "utils/dayjs";
import useBoolState from "utils/use_bool_state";

import BookingSummary from "./booking_summary";
import HeaderActions from "./header_actions";

import styles from "../styles.module.css";

const DATE_FORMAT = "Do MMM YYYY";

const buildBookingInfo = (t, booking) => {
  if (!booking) {
    return null;
  }

  const { arrival_date, departure_date, rooms, properties } = booking;
  const arrivalDate = dayjs(arrival_date);
  const departureDate = dayjs(departure_date);

  const formattedArrival = arrivalDate.format(DATE_FORMAT);
  const lengthOfStay = departureDate.diff(arrivalDate, "day");
  const propertyTitles = properties
    ? Object.values(properties)
      .map((property) => property.title)
      .join(", ")
    : "";

  const lengthOfStayMessage = t("reviews_page:dialog:length_of_stay", { amount: lengthOfStay });
  const roomNumberMessage = t("reviews_page:dialog:room_amount", { amount: rooms?.length });

  return `${t(
    "reviews_page:dialog:arriving",
  )}: ${formattedArrival}, ${roomNumberMessage}, ${lengthOfStayMessage} (${propertyTitles})`;
};

function Header({ bookingLoading, bookingParams, review, archive }) {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();

  const [isOpenBooking, setOpenBooking, setCloseBooking] = useBoolState(false);
  const { relationships, attributes } = review;
  const { isReplied } = attributes;
  const { booking } = relationships;

  const bookingInfo = buildBookingInfo(t, bookingParams);
  const backRoute = `${pathBuilder(userAppRoutes.reviews)}?is_active=${!isReplied}`;

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(backRoute);
  };

  return (
    <>
      <div className={styles.header}>
        <div style={{ display: "flex", flex: 1 }}>
          <svg onClick={navigateBack} style={{ marginTop: 3, marginRight: 24, cursor: "pointer" }} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd" opacity=".65">
              <path d="M0 0h18v18H0z" />
              <path d="m15.75 8.223-11.6.04 7.035-6.107a.16.16 0 0 0-.104-.281H9.303a.322.322 0 0 0-.211.078L.75 9l8.386 7.138c.03.027.066.04.104.04h1.839a.16.16 0 0 0 .104-.28L4.15 9.79l11.6-.04c.088 0 .16-.072.16-.16V8.383a.161.161 0 0 0-.16-.16z" fill="#000" fillRule="nonzero" />
            </g>
          </svg>
          <div style={{ flex: 1 }}>
            <BookingSummary review={review} />
            {bookingLoading === true && <p className={styles.bookingInfo}>{t("general:loading")}</p>}
            {!bookingInfo && bookingLoading === false && <p className={styles.bookingInfo}>N/A</p>}
            {bookingInfo && bookingLoading === false && (
              <p className={styles.bookingInfo}>{bookingInfo || t("general:loading")}</p>
            )}
          </div>
        </div>
        {bookingLoading === false && (
          <HeaderActions handleOpenBooking={booking && setOpenBooking} archive={archive} />
        )}
      </div>
      {booking && (
        <BookingViewControlledDrawer
          bookingId={booking.data.id}
          visible={isOpenBooking}
          onClose={setCloseBooking}
        />
      )}
    </>
  );
}

export default Header;
