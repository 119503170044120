import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminUserRequestLimitsClient } from "store/api_clients/admin/user_request_limits";

import { showSuccessMessage } from "components/toasts/messages";

export function useDisableMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => adminUserRequestLimitsClient.disable(id),
    onSuccess: () => {
      void queryClient.invalidateQueries("user_request_limits");
      showSuccessMessage();
    },
  });
}
