import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import store from "store";
import { getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import { Loading } from "components/_v2/states/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import showErrorMessage from "utils/show_error_message";
import useBoolState from "utils/use_bool_state";

import styles from "./token_exchange_page.module.css";

const { Auth } = store;

export default function TokenExchangePage() {
  const [isLoading, , setNotLoading] = useBoolState(true);
  const navigate = useNavigate();
  const appMode = useSelector(getAppMode);
  const { search } = useLocation();
  const { t } = useTranslation();
  const { publicAppRoutes } = useRouting();

  const exchangeAuthToken = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(search);
      const sessionKey = searchParams.get("oauth_session_key");

      await Auth.exchangeSocialAuthKey(sessionKey);
    } catch {
      setNotLoading();

      if (appMode !== APP_MODES.HEADLESS) {
        showErrorMessage(t("general:authentication_error"));
        navigate(pathBuilder(publicAppRoutes.signIn));
      }
    }
  }, [search, t, publicAppRoutes, setNotLoading, appMode, navigate]);

  useEffect(() => {
    exchangeAuthToken();
  }, [exchangeAuthToken]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <NoDataPlaceholder emptyMessage={t("general:authentication_error")} />
      )}
    </div>
  );
}
