import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  name: yup.string()
    .required(validationMessages.required),
  type: yup.string()
    .required(validationMessages.required),
  amount: yup.string()
    .matches(/^\d*\.?\d+$/, validationMessages.number)
    .when("type", {
      is: "flat_fee",
      then: (schema) => schema.required(validationMessages.required),
      otherwise: (schema) => schema.nullable(),
    }),
  percent: yup.string()
    .matches(/^\d*\.?\d+$/, validationMessages.number)
    .when("type", {
      is: "percentage_fee",
      then: (schema) => schema.required(validationMessages.required),
      otherwise: (schema) => schema.nullable(),
    }),
  includeInRent: yup.boolean(),
  kind: yup.string()
    .required(validationMessages.required),
  chargeMode: yup.string()
    .required(validationMessages.required),
  applicabilityRulePresent: yup.boolean(),

  applicabilityRule: yup.object().when("applicabilityRulePresent", {
    is: true,
    then: (schema) => schema.shape({
      type: yup.string()
        .required(validationMessages.required),
      minLos: yup.number()
        .typeError(validationMessages.number)
        .when("type", {
          is: "length_of_stay_between",
          then: (schema2) => schema2
            .nullable()
            .integer(validationMessages.number)
            .typeError(validationMessages.number)
            .min(0, validationMessages.greaterOrEqual(0)),
          otherwise: (schema2) => schema2.nullable(),
        }),
      maxLos: yup.number()
        .typeError(validationMessages.number)
        .when("type", {
          is: "length_of_stay_between",
          then: (schema2) => schema2
            .nullable()
            .integer(validationMessages.number)
            .typeError(validationMessages.number)
            .min(0, validationMessages.greaterOrEqual(0)),
          otherwise: (schema2) => schema2.nullable(),
        }),
      dateRange: yup.array()
        .when("type", {
          is: "stay_dates_overlap",
          then: (schema2) => schema2
            .of(yup.date().required(validationMessages.required))
            .required(validationMessages.required),
          otherwise: (schema2) => schema2.nullable(),
        }),
    }),
    otherwise: (schema2) => schema2.nullable(),
  }),
});
