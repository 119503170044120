import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";

import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";

import styles from "styles/form_in_drawer.module.css";

function options(t) {
  return [
    { value: "increase_by_amount", representation: t("rates_page:form:increase_by_amount") },
    { value: "decrease_by_amount", representation: t("rates_page:form:decrease_by_amount") },
    { value: "increase_by_percent", representation: t("rates_page:form:increase_by_percent") },
    { value: "decrease_by_percent", representation: t("rates_page:form:decrease_by_percent") },
  ];
}

const getErrorProps = (errors = [], index) => {
  const errorMessage = errors[index];

  if (errorMessage) {
    return {
      validateStatus: "error",
      help: errorMessage,
    };
  }

  return {};
};

function modificationOptionTemplate(t, args) {
  return function (el, index) {
    if (!el) {
      return null;
    }

    const { disabled, errors } = args;

    return (
      <Form.Item key={index} {...getErrorProps(errors, index)}>
        <Row className={styles.rateModificator__row}>
          <Col span={11}>
            <FormSelect
              name="rate_modificator"
              view="vertical"
              placeholder={t("rates_page:form:rate_modificator_type_placeholder")}
              onChange={args.onChangeModificator(index, 0)}
              defaultValue={el[0]}
              value={el[0]}
              options={options(t)}
              disabled={disabled}
            />
          </Col>
          <Col span={11}>
            <FormInput
              name="rate_modificator"
              view="vertical"
              type="text"
              onChange={args.onChangeModificator(index, 1)}
              defaultValue={el[1]}
              placeholder={t("rates_page:form:rate_modificator_value_placeholder")}
              disabled={disabled}
            />
          </Col>
          <Col span={2}>
            <a
              data-testid="remove_modificator"
              href="#remove"
              onClick={args.onDropModificator(index)}
              disabled={disabled}
            >
              <DeleteOutlined />
            </a>
          </Col>
        </Row>
      </Form.Item>
    );
  };
}

function RateModificator(props) {
  const { t } = useTranslation();
  const { disabled, modificators, onAddModificator } = props;
  const renderModificationOption = modificationOptionTemplate(t, props);

  return (
    <>
      {modificators.map(renderModificationOption)}
      <a
        data-testid="add_modificator"
        href="_"
        className={styles.addRateModificator}
        onClick={onAddModificator}
        disabled={disabled}
      >
        <PlusCircleOutlined />
        {t("rates_page:form:add_modificator")}
      </a>
    </>
  );
}

RateModificator.propTypes = {
  modificators: PropTypes.array,
  onAddModificator: PropTypes.func,
};

export default RateModificator;
