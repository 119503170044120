import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classnames from "classnames";

import styles from "./mapping_row.module.css";

export const MappingDeleteButton = ({ disabled, visible, onClick }) => {
  if (!visible) {
    return null;
  }

  const className = classnames(styles.deleteButton, {
    [styles.deleteButtonDisabled]: disabled,
  });

  return (
    <Button data-testid="mapping_delete_button" type="link" disabled={disabled} className={className} onClick={onClick}><DeleteOutlined /></Button>
  );
};
