import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import styles from "styles/form_in_drawer.module.css";

const columnLayout = { xs: { span: 24 }, sm: { span: 16, offset: 8 } };

export default function AdvancedSettingsButton({ model, onChange }) {
  const { t } = useTranslation();

  const onHandleChange = useCallback(
    (event) => {
      event.preventDefault();
      onChange("is_advanced_rate", !model.is_advanced_rate);
    },
    [model.is_advanced_rate, onChange],
  );

  return (
    <Row className={styles.advancedSettingsLink}>
      <Col {...columnLayout}>
        <a data-testid="advanced_settings_button" href="_" onClick={onHandleChange}>
          {model.is_advanced_rate
            ? t("rates_page:form:hide_advanced_settings_button")
            : t("rates_page:form:show_advanced_settings_button")}
        </a>
      </Col>
    </Row>
  );
}
