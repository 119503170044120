import React, { ElementType } from "react";
import classnames from "classnames";

import styles from "./icon_placeholder.module.css";

type Props = {
  Icon: ElementType;
  text: string;
  className?: string;
  dataTestid?: string;
  centerPlacement?: boolean;
}

export function IconPlaceholder({ Icon, text, className, dataTestid, centerPlacement = true }: Props) {
  const contentClassName = classnames(className, {
    [styles.content]: centerPlacement,
  });

  return (
    <div className={contentClassName} data-testid={dataTestid}>
      <Icon className={styles.icon} />
      <br />
      {text}
    </div>
  );
}
