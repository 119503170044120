import React from "react";

import countries from "config/constants/countries";

import alphabetSort from "utils/alphabet_sort";

import { FormSelect } from "../select";

const options = countries
  .map((el) => ({
    value: el[2],
    label: `${el[0]} (${el[2]})`,
  }))
  .sort(alphabetSort("label"));

export const CountrySelector = (props) => {
  return (
    <FormSelect
      options={options}
      {...props}
    />
  );
};
