import Flexbox from "components/flexbox";

import { MappingDirection } from "../common/mapping_direction";
import { VirtualList } from "../common/virtual_list";

import { RateMappingRow } from "./rate_mapping_row";
import { RoomMappingRow } from "./room_mapping_row";
import { MappingProvider, useMapping } from "./use_mapping";

const MAPPING_ITEM_COMPONENTS = {
  room: RoomMappingRow,
  rate: RateMappingRow,
};

const MappingListItem = ({ mappingItem }) => {
  const MappingItemComponent = MAPPING_ITEM_COMPONENTS[mappingItem.type];

  if (!MappingItemComponent) {
    throw new Error(`unsupported mapping item: ${mappingItem}`);
  }

  return (
    <MappingItemComponent
      mappingItem={mappingItem}
    />
  );
};

const MappingList = () => {
  const { mappingItems } = useMapping();

  return (
    <VirtualList
      itemDataTestid="mapping_row"
      items={mappingItems}
      renderItem={(mappingItem) => (<MappingListItem mappingItem={mappingItem} />)}
    />
  );
};

export const Ctoutvert = ({
  channelTitle,
  roomTypes,
  ratePlans,
  mappings,
  mappingOptions,
  mappingSettings,
  onChangeSettings,
  onChangeMapping,
}) => {
  return (
    <MappingProvider
      mappings={mappings}
      mappingOptions={mappingOptions}
      mappingSettings={mappingSettings}
      ratePlans={ratePlans}
      roomTypes={roomTypes}
      onChangeSettings={onChangeSettings}
      onChangeMapping={onChangeMapping}
    >
      <Flexbox flexDirection="column" fullHeight>
        <MappingDirection channelTitle={channelTitle} />
        <Flexbox flexDirection="column">
          <MappingList />
        </Flexbox>
      </Flexbox>
    </MappingProvider>
  );
};

Ctoutvert.MAPPING_TYPE = "Ctoutvert";
