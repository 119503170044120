import { useTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import { FormSelect } from "components/_v2/forms/fields/select";
import { useAppForm } from "components/_v2/forms/hook_form";
import CancelButton from "components/forms/buttons/cancel";
import RemoveButton from "components/forms/buttons/remove";
import SaveButton from "components/forms/buttons/save";
import RatePlanTitle from "components/rates/title/title";

import { Field } from "./field";

import styles from "./styles.module.css";

const MAPPING_FIELDS = ["rate_plan_code", "room_type_code", "occupancy", "primary_occ", "inv_type_code"];
MAPPING_FIELDS.push("pricing_type"); // TMP: backend should remove it for reconline mapping schema

export const MappingForm = ({ mapping, externalRate, ratePlans, mappingSchema, onCancel, onSubmit, onDelete }) => {
  const { t } = useTranslation();

  // mapping settings contains main fields, which are defines mapping itself - room_type_code, rate_plan_code, occupancy and primary_occ
  // all other fields in mapping schema are used as additional configuration for that mapping, for example "extra_child_price"
  const fields = Object.entries(mappingSchema)
    .filter(([name]) => !MAPPING_FIELDS.includes(name))
    .sort(([_name, schema]) => schema.position);

  const fieldNames = fields.map(([name]) => name);

  let defaultValue = {};
  if (mapping) {
    const ratePlanId = mapping.ratePlanId;
    const ratePlanMapping = mapping.settings;

    const mappingConfigValue = Object.entries(ratePlanMapping).reduce((acc, [name, value]) => {
      if (!fieldNames.includes(name)) {
        return acc;
      }

      acc[name] = value;

      return acc;
    }, {});

    defaultValue = {
      ratePlanId,
      ...mappingConfigValue,
    };
  }

  const {
    control,
    handleSubmit,
    isValid,
  } = useAppForm({
    validationSchema: yup.object().shape({
      ratePlanId: yup.string().trim().required(validationMessages.required),
    }),
    fieldNames: [...fieldNames, "ratePlanId"],
    defaultValue,
    submitHandler: (values) => {
      const { ratePlanId, ...mappingConfig } = values;

      const settings = {
        room_type_code: externalRate.roomTypeCode,
        rate_plan_code: externalRate.id,
        ...mappingConfig,
      };

      if (typeof externalRate.occupancy !== "undefined") {
        settings.occupancy = externalRate.occupancy;
        settings.primary_occ = false;

        // if we are changing existing mapping we should keep primary occ value,
        // because it is set externally
        if (mapping) {
          settings.primary_occ = mapping.settings.primary_occ;
        }
      }

      if (typeof externalRate.readonly !== "undefined") {
        settings.readonly = externalRate.readonly;
      }

      onSubmit({
        ratePlanId,
        settings,
      });
    },
  });

  const isMappingSet = !!mapping;

  const options = ratePlans.map((ratePlan) => ({
    value: ratePlan.id,
    label: <RatePlanTitle rate={ratePlan} />,
    searchValue: ratePlan.title,
  }));

  return (
    <div className={styles.container}>
      <Form>
        <FormSelect
          className={styles.select}
          name="ratePlanId"
          label={t("mapping:field:label:rate_plan_id")}
          placeholder={t("mapping:field:placeholder:rate_plan_id")}
          autoFocus
          showSearch
          options={options}
          filterOption={(input, option) => (option?.searchValue || "").toLowerCase().includes(input.toLowerCase())}
          control={control}
        />
        {fields.map(([name, schema]) => (
          <Field
            key={name}
            control={control}
            name={name}
            type={schema.type}
          />
        ))}
      </Form>
      <div className={styles.actions}>
        <div className={styles.actionsSection}>
          {isMappingSet && <RemoveButton onClick={onDelete} />}
        </div>
        <div className={styles.actionsSection}>
          <SaveButton disabled={!isValid} onClick={handleSubmit} />
          <CancelButton onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};
