import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { AvailabilityRules } = store;

export function useUpdateAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, attrs }) => AvailabilityRules.update(id, attrs),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["availability_rules", propertyId],
      });
    },
  });
}
