import React, { ComponentType } from "react";

import { useProperty } from "./use_property";

import { Property } from "@/types/property";

type Props = Record<string, any> & {
  propertyId: string;
}

type WithPropertyProps = Props & {
  property: Property | undefined;
  propertyError: any;
  isPropertyLoading: boolean;
}
export default function withProperty(Component: ComponentType<WithPropertyProps>) {
  function WithProperty(props: Props) {
    const { data: property, error, isLoading } = useProperty(props.propertyId);

    return <Component {...props} property={property} propertyError={error} isPropertyLoading={isLoading} />;
  }

  return WithProperty;
}
