import { useQuery } from "@tanstack/react-query";
import store from "store";

const { AdminWlEmailSettings } = store;
export function useAdminWlEmailSettings(whiteLabelPartnerId, tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  const filter = {
    wlPartnerId: whiteLabelPartnerId,
  };

  if (searchQuery) {
    filter.fromName = { has: searchQuery };
  }

  return useQuery({
    queryKey: ["admin_wl_email_settings", whiteLabelPartnerId, tableParams],
    queryFn: () => AdminWlEmailSettings.list(filter, pagination, order),
    enabled: !!whiteLabelPartnerId && !!tableParams,
    retry: false,
  });
}
