import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import FULL_RESTRICTIONS from "config/constants/inventory/full_restrictions";

import sortRestrictionsByRepresentation from "utils/translated_sort";

import RestrictionFilterDropdown from "./restriction_filter_dropdown";

import styles from "../inventory_filters.module.css";
import resctictionSelectStyles from "./restriction_filter.module.css";

const generateRestrictionsLabel = (t, selectedRestrictions) => {
  let code = selectedRestrictions.sort().join("__");
  let representation = t(`general:restrictions:${code}`);
  let output = representation;

  if (representation === `general:restrictions:${code}`) {
    code = code.replace("availability__", "");
    representation = t(`general:restrictions:${code}`);
    output = representation;
  }

  if (representation === `general:restrictions:${code}`) {
    output = `${selectedRestrictions.length - 1} ${t("general:restrictions:default")}`;
  }

  if (selectedRestrictions.length === FULL_RESTRICTIONS.length) {
    output = t("general:restrictions:all");
  }

  return output;
};

export default function RestrictionFilters({ filters, className, onChange, minStayType }) {
  const { t } = useTranslation();
  const { selectedRestrictions } = filters;

  const restrictions = FULL_RESTRICTIONS.filter((el) => el !== "availability")
    .filter((el) => {
      switch (minStayType) {
        case "arrival":
          return el !== "min_stay_through" && el !== "min_stay_arrival";

        case "through":
          return el !== "min_stay_arrival" && el !== "min_stay_through";

        default:
          return el !== "min_stay";
      }
    })
    .sort(sortRestrictionsByRepresentation(t));

  const maxTagPlaceholder = generateRestrictionsLabel(t, selectedRestrictions);

  const compiledClassName = [
    styles.filter__selectContainer,
    resctictionSelectStyles.restrictionFilterContainer,
    className,
  ].join(" ");

  return (
    <div className={compiledClassName}>
      <Select
        data-testid="restrictions_filter"
        mode="multiple"
        showSearch={false}
        defaultValue={selectedRestrictions}
        value={selectedRestrictions}
        placeholder={t("inventory_page:filters:restrictions")}
        onChange={onChange("selectedRestrictions")}
        maxTagCount={0}
        maxTagPlaceholder={maxTagPlaceholder}
        dropdownRender={(menu) => (
          <RestrictionFilterDropdown menu={menu} onChange={onChange} minStayType={minStayType} />
        )}
      >
        {restrictions.map((restriction) => (
          <Select.Option
            data-testid={`restriction_${restriction}`}
            value={restriction}
            key={restriction}
          >
            {t(`general:restrictions:${restriction}`)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
