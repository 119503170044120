import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch, useParams } from "react-router";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import GroupManagement from "components/group_management";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

function GroupManagementDrawer() {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { userAppRoutes } = useRouting();
  const isGroupsPage = useMatch({
    path: userAppRoutes.groups._path,
    end: false,
  });

  const title = t(groupId ? "groups_page:edit_dialog_header" : "groups_page:create_dialog_header");
  let path = isGroupsPage ? userAppRoutes.groups.edit : userAppRoutes.properties.editGroup;

  if (!groupId) {
    path = userAppRoutes.groups.create;
  }

  const closePath = pathBuilder(path, { groupId });

  return (
    <ChannexDrawerRoutable title={title} dataTestid="group_drawer">
      {({ componentRef, handleCloseFromContent }) => (
        <>
          <GroupManagement
            id={groupId}
            componentRef={componentRef}
            onClose={handleCloseFromContent}
          />

          {groupId && <Outlet context={{ closePath }} />}
        </>
      )}
    </ChannexDrawerRoutable>
  );
}

export default GroupManagementDrawer;
