import { analytics, FEATURE_ACTIONS } from "@/analytics";

const scope = "inventory";

const overrideFeatureName = `${scope}-override`;
type InitParams = {
  featureRestriction: string;
  featureDays: number;
}
export const trackValueOverrideInit = (params: InitParams) => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.INIT}`, {
    feature: overrideFeatureName,
    ...params
  });
}

type SaveParams = {
  featureRestriction: string;
  featureDays: number;
}
export const trackValueOverrideSave = (params: SaveParams) => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.ACT}`, {
    feature: overrideFeatureName,
    featureAction: "save",
    ...params,
  });
}
type ChangeDateParams = {
  featureDays: number;
}
export const trackValueOverrideChangeDate = (params: ChangeDateParams) => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.ACT}`, {
    feature: overrideFeatureName,
    featureAction: "change_date",
    ...params,
  });
}
export const trackValueOverrideError = () => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.ACT}`, {
    feature: overrideFeatureName,
    featureAction: "error",
  });
}
export const trackValueOverrideReset = () => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.ACT}`, {
    feature: overrideFeatureName,
    featureAction: "reset",
  });
}
export const trackValueOverrideComplete = () => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.COMPLETE}`, {
    feature: overrideFeatureName
  });
}
export const trackValueOverrideCancel = () => {
  analytics.trackEvent(`${overrideFeatureName} - ${FEATURE_ACTIONS.CANCEL}`, {
    feature: overrideFeatureName
  });
}
