import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";

import { channels } from "config/constants/channels/channel_codes";
import { sources } from "config/constants/channels/offline";

import { NextButton } from "components/_v2/forms/buttons/next";
import { HourSelector } from "components/_v2/forms/fields/hour_selector";
import { FormPropertySelector } from "components/_v2/forms/fields/property_selector";
import { FormRangePicker } from "components/_v2/forms/fields/range_picker";
import { FormSelect } from "components/_v2/forms/fields/select";
import { AppForm, FormSchemaRenderer } from "components/_v2/forms/hook_form";
import { Legend } from "components/_v2/forms/legend";

import dayjs from "utils/dayjs";

import { useWizard, WizardSidebar } from "../wizard";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ otaNames }) => {
  const { t } = useTranslation();
  const today = dayjs().startOf("day");

  const otaNameOptions = uniqBy(otaNames, (i) => i.code.toLowerCase())
    .map((otaName) => ({
      value: otaName.code,
      label: otaName.title,
    }));

  const [otaName] = useWatch({ name: ["otaName"]})
  const offlineSources = Object.entries(sources()).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const fields = [{
    render: () => <Legend marginTop={false} label={t("common:headings:basic_info")} />,
  }, {
    name: "propertyId",
    component: FormPropertySelector,
    disabled: true,
    label: t("common:terms:property"),
  }, {
    name: "otaName",
    component: FormSelect,
    label: t("common:terms:ota"),
    options: otaNameOptions,
  }, {
    name: "otaReservationCode",
    conditional: otaName !== channels.Offline.code,
    label: t("common:terms:ota_reservation_code"),
  }, {
    name: "meta.source",
    conditional: otaName === channels.Offline.code,
    label: t("common:terms:source"),
    component: FormSelect,
    options: offlineSources,
  }, {
    name: "bookingDateRange",
    component: FormRangePicker,
    label: t("general:dates"),
    componentProps: {
      disabledDate: (date, { from }) => {
        if (date.isBefore(today)) {
          return true;
        }

        if (from && date.isSame(from, "day")) {
          return true;
        }

        if (from && date.isAfter(from.add(6, "months").startOf("day"))) {
          return true;
        }

        return false;
      },
    },
  }, {
    name: "arrivalHour",
    component: HourSelector,
    label: t("common:terms:arrival_hour"),
  }];

  return (
    <FormSchemaRenderer
      dataTestid="basic-info-form"
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <NextButton />
          ),
        },
        fields,
      }}
    />
  );
};

export const Step1 = ({ otaNames }) => {
  const { goNext } = useWizard();
  const { setValue, getValues, formState } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      validationSchema={validationSchema}
      errors={formState.errors}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner otaNames={otaNames} />
    </AppForm>
  );
};
