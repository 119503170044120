import React from "react";
import { Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import { SelectableList } from "./selectable_list";

export const FormListSelector = (props) => {
  const {
    label,
    errors,
    ...inputProps
  } = props;

  const validation = () => {
    if (errors) {
      const message = Array.isArray(errors) ? errors.join(" ") : errors;

      return {
        validateStatus: "error",
        help: <span data-testid="input_error_message">{message}</span>,
      };
    }

    return {};
  };

  return (
    <Form.Item
      {...horizontalFormItemLayout}
      label={label}
      {...validation()}
    >
      <SelectableList
        {...inputProps}
      />
    </Form.Item>
  );
};
