 
export const consoleLogInDev = (...data: any[]) => {
  if (!import.meta.env.DEV) {
    return;
  }

  if (import.meta.env.NODE_ENV === "test") {
    return;
  }

  if (import.meta.env.VITE_CI) {
    return;
  }

  console.log(data);
}
