import React, { Component } from "react";
import { Navigate } from "react-router";
import queryString from "query-string";

export default class SignedUserConfirmationRedirect extends Component {
  render() {
    const query = queryString.parse(window.location.search);
    const token = query.token;

    return (
      <Navigate
        to="/"
        state={{
          confirmationToken: token,
        }}
      />
    );
  }
}
