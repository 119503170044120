import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";

import { features } from "config/feature_flags";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import useFeatures from "hooks/use_features";

import { EditExtraForm } from "./edit_form/edit_form";

export function EditExtraDrawer({ visible, propertyId, extraCategoryId, extra, onClose }) {
  const { t } = useTranslation();
  const title = t("property_extras:titles:edit_extra");

  const { isFeatureEnabled } = useFeatures();
  if (!isFeatureEnabled(features.EXTRAS_MANAGEMENT)) {
    return <Navigate to="/" />;
  }

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <EditExtraForm propertyId={propertyId} extraCategoryId={extraCategoryId} value={extra} closeDrawer={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
