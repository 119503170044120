const DEFAULT_PREVIEW_URL_PARAMS = "-/scale_crop/450x250/center/-/progressive/yes/-/format/jpeg/";
const ROOM_PHOTO_PREVIEW_URL_PARAMS = "-/scale_crop/320x120/center/-/progressive/yes/-/format/jpeg/";

export const getPreviewUrl = (url) => {
  if (url.startsWith("https://images.channex.io")) {
    return `${url}${DEFAULT_PREVIEW_URL_PARAMS}`;
  }

  return url;
};

export const getRoomPhotoPreviewUrl = (url) => {
  if (url.startsWith("https://images.channex.io")) {
    return `${url}${ROOM_PHOTO_PREVIEW_URL_PARAMS}`;
  }

  return url;
};
