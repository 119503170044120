import { useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import store from "store";

const { ws } = store;

const debug = false;
const noop = () => {};

export default function useWebSocket(options = {}) {
  const nextEventTimeoutRef = useRef(null);
  const channelRef = useRef(null);
  const isTimedOutSubscribedTokenEventStream = useRef(false);
  const subscribedToken = useRef(null);
  const buffer = useRef({});
  const handlers = useRef([]);

  const debugMessage = (...args) => {
    if (!debug) {
      return;
    }

    console.log(...args);
  };

  const eventTimeout = options.eventTimeout;
  const onTimeout = options.onTimeout || noop;

  const nextEventTimeout = useCallback(() => {
    debugMessage("nextEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);

    if (eventTimeout) {
      nextEventTimeoutRef.current = setTimeout(() => {
        isTimedOutSubscribedTokenEventStream.current = true;

        debugMessage("timeout");
        onTimeout();
      }, eventTimeout);
    }
  }, [onTimeout, eventTimeout]);

  const cleanEventTimeout = useCallback(() => {
    debugMessage("cleanEventTimeout");
    clearTimeout(nextEventTimeoutRef.current);
  }, []);

  const subscribe = useCallback((token, handler) => {
    debugMessage("subscribe", { token, handler });
    subscribedToken.current = token;
    const existingEvents = buffer.current[token] || [];

    debugMessage("subscribe", { existingEvents });
    existingEvents.forEach((event) => {
      debugMessage("handle buffered message", { name: event.name, payload: event.payload });
      handler(event.name, event.payload);
    });

    buffer.current[token] = [];

    handlers.current.push(handler);
  }, []);

  const processEventWithoutSubscription = useCallback(({ name, eventToken, payload }) => {
    debugMessage("processEventWithoutSubscription", { name, eventToken, payload });
    buffer.current[eventToken] = buffer.current[eventToken] || [];
    buffer.current[eventToken].push({ name, payload });
  }, []);

  const processEventWithSubscription = useCallback(({ name, payload }) => {
    debugMessage("processEventWithSubscription", { name, payload });
    handlers.current.forEach((handler) => {
      debugMessage("invoke subscribed handler", { name, payload });
      handler(name, payload);
    });
  }, []);

  const join = useCallback(({ channel: channelName, events }) => {
    debugMessage("join", { channel: channelName, events });

    return new Promise((resolve, reject) => {
      const channel = ws.socket.channel(channelName);

      channel.join()
        .receive("ok", () => {
          debugMessage("successfully joined");
          resolve();
        })
        .receive("error", (error) => {
          console.error("websocket channel error", error);
          Sentry.captureMessage("useWebSocket: join error", error);
          reject(error);
        })
        .receive("timeout", () => {
          console.error("websocket channel timeout");
          Sentry.captureMessage("useWebSocket: join timeout");
        });

      channel.onError((e) => {
        console.error("websocket channel error", e);
        Sentry.captureMessage("useWebSocket: channel error", e);
      });

      nextEventTimeout();

      const bufferedEventRunner = (name) => {
        return (payload) => {
          debugMessage("bufferedEventRunner", { name, payload });
          nextEventTimeout();

          const eventToken = payload.token;

          if (!eventToken) {
            debugMessage("bufferedEventRunner: no token in event payload");
            Sentry.captureMessage("useWebSocket: no token in event payload");
            return;
          }

          const isSubscribed = !!subscribedToken.current;
          if (!isSubscribed) {
            processEventWithoutSubscription({ name, eventToken, payload });
            return;
          }

          const isEventFromSubscription = subscribedToken.current === eventToken;
          if (!isEventFromSubscription) {
            debugMessage("bufferedEventRunner: event from another subscription, skip", { name, payload });
            return;
          }

          if (isTimedOutSubscribedTokenEventStream.current) {
            return;
          }

          processEventWithSubscription({ name, payload });
        };
      };

      events.forEach((eventName) => {
        debugMessage("listening event", { event: eventName });
        channel.on(eventName, (event) => {
          return bufferedEventRunner(eventName)(event);
        });
      });

      channelRef.current = channel;
    });
  }, [nextEventTimeout, processEventWithSubscription, processEventWithoutSubscription]);

  const leave = useCallback(() => {
    debugMessage("leave");
    buffer.current = {};
    subscribedToken.current = null;
    handlers.current = [];
    channelRef.current?.leave();
    channelRef.current = null;
    isTimedOutSubscribedTokenEventStream.current = false;
    cleanEventTimeout();
  }, [cleanEventTimeout]);

  return {
    join,
    subscribe,
    leave,
  };
}
