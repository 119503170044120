import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import store from "store";

import { Loading } from "components/_v2/states/loading";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import List from "components/list";
import NoDataPlaceholder from "components/no_data_placeholder";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import { formatToLocal } from "utils/dates";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import styles from "./styles.module.css";
import dashboard_styles from "styles/dashboard.module.css";

const { Tasks } = store;

export default function TaskViewDetails({ taskId }) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const [task, setTask] = useState(null);
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();

  useEffect(
    function loadTask() {
      setLoading();

      Tasks.find(taskId)
        .then(setTask)
        .catch(showErrorMessageFromResponse)
        .finally(setLoadingComplete);
    },
    [taskId, setLoading, setLoadingComplete],
  );

  if (loading) {
    return <Loading />;
  }

  if (!task) {
    return <NoDataPlaceholder />;
  }

  const hasErrors = Boolean(task.errors?.length);
  const successStatus = task.success ? "TRUE" : "FALSE";
  const received = formatToLocal(task.received_at, t("formats:iso_date_with_miliseconds"));
  const executed = formatToLocal(task.executed_at, t("formats:iso_date_with_miliseconds"));
  const finished = formatToLocal(task.finished_at, t("formats:iso_date_with_miliseconds"));
  const channelEvents = (task.channel_events && task.channel_events.length > 0)
    ? (
      <>
        {task.channel_events.map((event) => (
          <div key={event.id}>
            <Link
              to={pathBuilder(userAppRoutes.channelEvents.view, {
                channelId: event.channel_id,
                actionId: event.id,
              })}
            >
              <div
                className={[
                  dashboard_styles.dashboard__icon,
                  event.success ? dashboard_styles.dashboard__icons__success : dashboard_styles.dashboard__icons__fail,
                ].join(" ")}
              />
              &nbsp;
              {event.channel_title}
            </Link>
            <br />
          </div>
        ))}
      </>
    )
    : "This task not have changes to sync with OTA's";

  const description = (
    <>
      {task.id}
      {task.id && <CopyToClipboard className={styles.copyIcon} text={task.id} />}
    </>
  );

  return (
    <>
      <List>
        <List.Item term={t("tasks_page:form:task_id")} description={description} />
        <List.Item term={t("tasks_page:form:task")} description={task.task} />
        <List.Item term={t("tasks_page:form:user")} description={task.user_name} />
        <List.Item term={t("tasks_page:form:success")} description={successStatus} />
        <List.Item term={t("tasks_page:form:received_at")} description={received} />
        <List.Item term={t("tasks_page:form:executed_at")} description={executed} />
        <List.Item term={t("tasks_page:form:finished_at")} description={finished} />
        <List.Item term={t("tasks_page:form:channel_events")} description={channelEvents} />
        {hasErrors && <List.Item term={t("tasks_page:form:errors")} description={task.errors} />}
      </List>

      <pre className={styles.payload}>
        <CopyToClipboard className={styles.payloadCopyIcon} text={JSON.stringify(task.payload, null, 2)} />
        {JSON.stringify(task.payload, null, 2)}
      </pre>
    </>
  );
}
