import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import _ from "lodash";

import withComponentRef from "containers/with_component_ref";
import withSizes from "containers/with_sizes";

import withRouter from "routing/with_router";

import { getParamsFromUrl, setParamsToUrl } from "utils/crud_table_url_pagination";

import CRUDTopBar from "../crud_top_bar";
import itemsPerPage from "../items_per_page";

import InnerTable from "./inner_table";

const DEFAULT_PAGE = 1;
const DEFAULT_ORDER = { inserted_at: "desc" };
const DEBOUNCE_TIME = 300;

class CRUDTable extends Component {
  state = {
    isPreventUpdateStateFromUrl: false,
    page: DEFAULT_PAGE,
    limit: null,
    order: this.props.defaultOrder || DEFAULT_ORDER,
    searchQuery: "",
    isPaginated: false,
    total: null,
  };

  componentDidMount() {
    this.updateStateFromUrl();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search, pathname },
      basePath,
      advancedSearchFilter,
      query,
    } = this.props;

    const { isPreventUpdateStateFromUrl, limit, isPaginated } = this.state;

    if (prevProps.query !== query) {
      if (query?.data?.meta) {
        this.setState({
          total: query.data.meta.total,
        });
      }
    }

    if (isPreventUpdateStateFromUrl) {
      return;
    }

    const isEntriesOverflowLimit = query?.data?.data?.length > limit;

    if (isPaginated && isEntriesOverflowLimit) {
      this.applyChanges();
      return;
    }

    if (prevProps.advancedSearchFilter !== advancedSearchFilter) {
      this.applyChanges();
      return;
    }

    const paramsFromState = this.getTableParamsFromState();
    const defaultParams = this.getDefaultTableParams();

    const urlParams = {};
    new URLSearchParams(search).forEach((value, key) => {
      urlParams[key] = value;
    });

    const stateDiff = this.getChangedTableParams(paramsFromState, urlParams);
    const defaultDiff = this.getChangedTableParams(stateDiff, defaultParams);

    const ifStateDiffersFromUrl = Object.keys(defaultDiff).length;

    if (pathname === basePath && ifStateDiffersFromUrl) {
      this.updateStateFromUrl();
    }
  }

  updateStateFromUrl = () => {
    const { height, rowHeight, topBar } = this.props;

    const tableParamsFromUrl = getParamsFromUrl();
    const defaultOrder = this.getDefaultOrder();

    const fullTableState = {
      page: DEFAULT_PAGE,
      limit: itemsPerPage(height, rowHeight, { topBar }),
      order: defaultOrder,
      searchQuery: "",
      ...tableParamsFromUrl,
    };

    const { page, limit, searchQuery, order } = fullTableState;

    this.setState({
      searchQuery,
      page,
      limit,
      order,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  resetTable = () => {
    const { height, rowHeight, topBar } = this.props;

    this.setState({
      searchQuery: "",
      page: DEFAULT_PAGE,
      limit: itemsPerPage(height, rowHeight, { topBar }),
      order: this.getDefaultOrder(),
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  applyChanges = () => {
    this.updateUrl();
    this.notifyTableParamsChange();
  };

  applyChangesDebounced = _.debounce(() => {
    this.applyChanges();
  }, DEBOUNCE_TIME);

  notifyTableParamsChange = () => {
    const { searchQuery, page, limit } = this.state;
    const { onTableParamsChange } = this.props;

    if (!onTableParamsChange) {
      return;
    }

    const pagination = { page, limit };
    const { orderBy, orderDir } = this.getTableParamsFromState();
    const order = { [orderBy]: orderDir };

    onTableParamsChange({ searchQuery, pagination, order });
  };

  getTableParamsFromState = () => {
    const { searchQuery, order, page, limit } = this.state;
    const [orderBy, orderDir] = Object.entries(order)[0];

    return {
      page,
      limit,
      searchQuery,
      orderBy,
      orderDir,
    };
  };

  getDefaultTableParams = () => {
    const { height, rowHeight, topBar } = this.props;
    const { page } = this.state;

    const defaultOrder = this.getDefaultOrder();

    const [orderBy, orderDir] = Object.entries(defaultOrder)[0];
    const limit = page > 1 ? null : itemsPerPage(height, rowHeight, { topBar });

    return {
      page: DEFAULT_PAGE,
      searchQuery: "",
      limit,
      orderBy,
      orderDir,
    };
  };

  getChangedTableParams = (a, b) => {
    const delta = {};

    const { orderBy, orderDir, ...rest } = a;
    const hasOrder = orderBy && orderDir;
    const isOrderChanged = orderBy !== b.orderBy || orderDir !== b.orderDir;

    if (hasOrder && isOrderChanged) {
      delta.orderBy = orderBy;
      delta.orderDir = orderDir;
    }

    Object.keys(rest).forEach((key) => {
      if (`${a[key]}` !== `${b[key]}`) {
        delta[key] = a[key];
      }
    });

    return delta;
  };

  getUrlParams = () => {
    const tableParams = this.getTableParamsFromState();
    const defaultTableParams = this.getDefaultTableParams();

    return this.getChangedTableParams(tableParams, defaultTableParams);
  };

  updateUrl = () => {
    const { navigate, updateUrl = true, onTableQueryChange = () => {} } = this.props;

    if (!updateUrl) {
      return;
    }

    const urlParams = this.getUrlParams();
    const queryString = this.getQueryString();

    onTableQueryChange(queryString);
    setParamsToUrl(urlParams, navigate);

    this.setState({ isPreventUpdateStateFromUrl: false });
  };

  getQueryString = () => {
    const urlParams = this.getUrlParams();

    const queryParams = new URLSearchParams();
    Object.entries(urlParams).forEach(([key, value]) => queryParams.set(key, value));

    return queryParams.toString();
  };

  onSearchChange = (searchQuery) => {
    this.setState({
      page: DEFAULT_PAGE,
      searchQuery,
      isPreventUpdateStateFromUrl: true,
    }, _.isEmpty(searchQuery) ? this.applyChanges : this.applyChangesDebounced);
  };

  getDefaultOrder = () => {
    const { defaultOrder = DEFAULT_ORDER } = this.props;
    return defaultOrder;
  };

  generateOrder = (sorter) => {
    if (sorter && sorter.field && sorter.order) {
      return { [sorter.field]: sorter.order === "ascend" ? "asc" : "desc" };
    }

    return this.getDefaultOrder();
  };

  onTableChange = ({ current, pageSize }, _filters, sorter) => {
    const order = this.generateOrder(sorter);

    this.setState({
      page: current,
      limit: pageSize,
      order,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  handleAdvancedSearchApply = (advancedSearch) => {
    const { advancedSearchApply } = this.props;

    this.setState({
      page: DEFAULT_PAGE,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);

    advancedSearchApply(advancedSearch);
  };

  render() {
    const {
      t,
      createLink,
      onCreateClick,
      actions,
      columns,
      emptyMessage,
      topBar = true,
      showHeader = true,
      msgCreateLink,
      showCreateMessage,
      hideCreateAction,
      showTableWithoutResults,
      expandedMethod,
      onExpand,
      advancedSearch,
      advancedSearchFilter,
      query,
      search,
      searchAddon,
      searchPlaceholder,
      searchInput,
    } = this.props;

    const {
      searchQuery,
      limit,
      total,
      page,
      order,
    } = this.state;

    if (createLink && onCreateClick) {
      throw new Error("createLink and onCreateClick cannot be specified at the same time");
    }

    let createAction = (
      <Button
        data-testid="crud_table_create_button"
        icon={<PlusCircleOutlined />}
        type="primary"
        onClick={onCreateClick}
      >
        {t("general:action:create")}
      </Button>
    );

    if (createLink) {
      createAction = (
        <Link to={createLink}>
          {createAction}
        </Link>
      );
    }

    if (hideCreateAction) {
      createAction = null;
    }

    const bar = topBar
      ? (
        <CRUDTopBar
          refreshAction={false}
          search={search}
          searchQuery={searchQuery}
          actions={
            <>
              {actions && actions}
              {createAction}
            </>
          }
          searchAddon={searchAddon}
          searchInput={searchInput}
          searchPlaceholder={searchPlaceholder}
          onSearchChange={this.onSearchChange}
          advancedSearch={advancedSearch}
          advancedSearchApply={this.handleAdvancedSearchApply}
          advancedSearchFilter={advancedSearchFilter}
        />
      )
      : null;

    const isAdvancedSearchApplied = advancedSearch && (
      Object.keys(advancedSearchFilter).some((key) => advancedSearch.filters.includes(key))
    );

    const isDataFilteredBySearch = searchQuery || isAdvancedSearchApplied;

    return (
      <InnerTable
        style={{ height: this.props.height, width: this.props.width }}
        bar={bar}
        showHeader={showHeader}

        isDataFilteredBySearch={isDataFilteredBySearch}

        limit={limit}
        total={total}
        page={page}
        order={order}

        query={query}
        columns={columns}

        emptyMessage={emptyMessage}
        msgCreateLink={msgCreateLink}
        showCreateMessage={showCreateMessage}
        showTableWithoutResults={showTableWithoutResults}

        expandedMethod={expandedMethod}
        onExpand={onExpand}

        onTableChange={this.onTableChange}
      />
    );
  }
}

export default withSizes(withRouter(withTranslation()(withComponentRef(CRUDTable))));
