import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { SubmitButton } from "components/_v2/forms/buttons/submit";
import { FormCheckbox } from "components/_v2/forms/fields/checkbox";
import { FormCurrency } from "components/_v2/forms/fields/currency";
import { FormInput } from "components/_v2/forms/fields/input";
import { FormSelect } from "components/_v2/forms/fields/select";
import { StyledForm } from "components/forms/styled_form";

export const ServiceFormInner = ({ currency }) => {
  const { t } = useTranslation();

  const priceMode = useWatch({ name: "priceMode" });

  return (
    <BodyContainer dataTestid="service-form">
      <ScrollableContainer padding="16px 16px">
        <StyledForm>
          <FormInput
            name="name"
            label={t("common:terms:service")}
          />
          <FormCheckbox
            name="isInclusive"
            label={t("common:terms:is_inclusive")}
          />
          <FormSelect
            name="priceMode"
            label={t("common:terms:price_mode")}
            options={[
              { label: t("common:dicts:price_mode:per_person_per_night"), value: "Per person per night" },
              { label: t("common:dicts:price_mode:per_person"), value: "Per person" },
              { label: t("common:dicts:price_mode:per_night"), value: "Per night" },
              { label: t("common:dicts:price_mode:per_booking"), value: "Per booking" },
            ]}
          />
          {["Per night", "Per person per night"].includes(priceMode) && (
            <FormInput
              name="nights"
              label={t("common:terms:nights")}
            />
          )}
          {["Per person", "Per person per night"].includes(priceMode) && (
            <FormInput
              name="persons"
              label={t("common:terms:persons")}
            />
          )}
          <FormCurrency
            name="pricePerUnit"
            label={t("common:terms:price_per_unit")}
            currency={currency}
          />
          <FormInput
            name="type"
            label={t("common:terms:category")}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
