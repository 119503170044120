import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import { AirBnbRatePlanForm } from "./form";

export default function AirBnbManageRatePlanDrawer({ channelId, value, airBnbRatePlans, visible, onClose }) {
  const { t } = useTranslation();

  const title = value ? t("airbnb_rate_plans:edit:header") : t("airbnb_rate_plans:create:header");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {() => (
        <DrawerFormWrapper>
          <AirBnbRatePlanForm channelId={channelId} value={value} airBnbRatePlans={airBnbRatePlans} onFinish={onClose} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
