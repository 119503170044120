import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Cascader from "components/forms/inputs/form_cascader";
import FormItem from "components/forms/items/form_item";

import convertToHashmap from "utils/convert_to_hashmap";
import showErrorMessage from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import styles from "./pitchup.module.css";

const convertHotelsToOptions = (hotels) => {
  return hotels.map(({ id, title }) => {
    return {
      value: id,
      label: `${title} (${id})`
    };
  });
};

const convertHotelsToObject = (hotels) => {
  return convertToHashmap(hotels);
};

const updateSettings = (selectedPath, settings, hotelsById) => {
  const selectedHotel = hotelsById[selectedPath];
  const { id, title, slug } = selectedHotel;

  return {
    ...settings,
    campsite_id: Number(id),
    campsite_title: title,
    campsite_slug: slug
  };
};

const getDefaultValue = (settings) => {
  const { campsite_id } = settings || {};

  return campsite_id ? { campsiteId: campsite_id } : null;
};

export default function Pitchup({ form, connectionSettings, onMappingDetailsLoad }) {
  const { t } = useTranslation();
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { channel, settings } = form.values;
  const hasOptions = connectionSettings.hotels?.length;
  const isInputDisabled = !hasOptions || loading;
  const defaultValue = getDefaultValue(settings);
  const preselectedValue = defaultValue ? `${defaultValue.campsiteId}` : null;

  const options = useMemo(() => {
    const { hotels = [] } = connectionSettings;

    return convertHotelsToOptions(hotels);
  }, [connectionSettings]);

  const hotelsById = useMemo(() => {
    const { hotels = [] } = connectionSettings;

    return convertHotelsToObject(hotels);
  }, [connectionSettings]);

  const handleChange = (selectedPath) => {
    const updatedSettings = updateSettings(selectedPath, settings, hotelsById);

    form.setFieldValue("settings", updatedSettings);

    setLoading();

    onMappingDetailsLoad(updatedSettings, channel)
      .catch(showErrorMessage)
      .finally(setLoadingComplete);
  };

  let campsiteInfo = null;
  if (preselectedValue) {
    campsiteInfo = (
      <FormItem label={t("channels_page:form:campsite_info")}>
        <div className={styles.placeholderContainer}>
          {defaultValue && (
            <>
              <div>{t("channels_page:form:campsite_id", defaultValue)}</div>
            </>
          )}
        </div>
      </FormItem>
    );
  }

  return (
    <>
      <Cascader
        label={t("channels_page:form:select_campsite")}
        options={options}
        allowClear={false}
        changeOnSelect
        defaultValue={preselectedValue}
        disabled={isInputDisabled}
        onChange={handleChange}
      />
      {campsiteInfo}
    </>
  );
}
