import React from "react";
import { createRoutesFromChildren, matchRoutes , useLocation, useNavigationType } from "react-router";
import * as Sentry from "@sentry/react";

import config from "config";

import AppStorageManager from "utils/app_storage_manager";

let email;

try {
  const user = AppStorageManager.getItem("CHANNEX_USER");
  email = user?.email;
} catch {
  // Ignore
}

if (config.SENTRY_DNS) {
  const sentryOptions = {
    dsn: config.SENTRY_DNS,
    environment: config.APP_ENVIRONMENT,
    release: config.APP_VERSION,

    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.globalHandlersIntegration({
        onunhandledrejection: false, // We handle unhandled rejections ourselves in use_unhandled_rejection_handler.jsx
        onerror: true,
      }),
    ],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      // ResizeObserver
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",

      // Network errors
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "TypeError: Cancelled",
      "TypeError: NetworkError when attempting to fetch resource.",

      // Community suggestions
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
    ],
    ignoreUrls: [
      // Community suggestions
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  };

  if (email) {
    sentryOptions.initialScope = {
      user: {
        email,
      },
    };
  }

  Sentry.init(sentryOptions);
}
