import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

export const validationSchema = yup.object().shape({
  checkInTimeStart: yup.string().required(validationMessages.required),
  checkInTimeEnd: yup.string().required(validationMessages.required),
  instantBookingAllowedCategory: yup.string().required(validationMessages.required),
  instantBookWelcomeMessage: yup.string().nullable()
    .when("instantBookingAllowedCategory", {
      is: (value) => value !== "off",
      then: (schema) => schema.max(200, validationMessages.lessOrEqual(200)),
      otherwise: (schema) => schema.nullable(),
    }),
  cancellationPolicySettings: yup.object().shape({
    nonRefundablePriceFactor: yup.number().typeError(validationMessages.number).nullable().moreThan(0, validationMessages.greater(0))
      .lessThan(1, validationMessages.less(1)),
  }),
  listingExpectationsForGuests: yup.array().of(
    yup.object().shape({
      type: yup.string().required(validationMessages.required),
      addedDetails: yup.string().nullable().max(300, validationMessages.lessOrEqual(300)),
    }),
  ),
  guestControls: yup.object().shape({
    allowsChildrenAsHost: yup.boolean().nullable(),
    allowsInfantsAsHost: yup.boolean().nullable(),
    childrenNotAllowedDetails: yup.string()
      .when(["allowsChildrenAsHost", "allowsInfantsAsHost"], {
        is: (allowsChildrenAsHost, allowsInfantsAsHost) => {
          return !allowsChildrenAsHost || !allowsInfantsAsHost;
        },
        then: (schema) => schema.required(validationMessages.required),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
});
