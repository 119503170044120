import { isDayjs } from "./dates";

export const flattenObject = ({ obj, prefix = "", delimiter = ".", result = {} }) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}${delimiter}${key}` : key;

      if (Array.isArray(obj[key])) {
        // Handle array elements individually with index
        obj[key].forEach((item, index) => {
          flattenObject({ obj: item, prefix: `${newKey}.${index}`, delimiter, result });
        });
      } else if (isDayjs(obj[key])) {
        // return dayjs objects as is
        result[newKey] = obj[key];
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively handle nested objects
        flattenObject({ obj: obj[key], prefix: newKey, delimiter, result });
      } else {
        // Assign value to result for non-object, non-array values
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};
