import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CalendarOutlined, ExclamationCircleOutlined, TagOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row, Tag } from "antd";

import channelNames from "config/constants/channels/channel_codes";

import ChannelDrawerControlled from "drawers/channel_management_drawer_controlled";
import PropertyFormDrawerControlled from "drawers/property_form_drawer_controlled";

import { Legend } from "components/_v2/forms/legend";
import { InvalidCardReport } from "components/bookings/booking_view/invalid_card_report";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";

import { bookingDate, formatToLocal, sortStringDates } from "utils/dates";
import dayjs from "utils/dayjs";
import getCountryName from "utils/get_country_name";

import BookingRevisionCard from "./booking_revision_card";
import BookingRevisionTags from "./booking_revision_tags";
import CancelButton from "./cancel_button";
import ShowCardButton from "./show_card_button";

import styles from "./booking_revision.module.css";
import utilStyles from "styles/utils.module.css";
import viewStyles from "styles/view_list.module.css";

class BookingRevision extends Component {
  state = {
    channelDrawerVisible: false,
    propertyDrawerVisible: false,
  };

  t = (word) => {
    const { t } = this.props;
    return t(`bookings_page:booking_view_dialog:${word}`);
  };

  handleChanelDrawerClose = () => this.setState({ channelDrawerVisible: false });

  handleChanelDrawerOpen = () => this.setState({ channelDrawerVisible: true });

  handlePropertyDrawerClose = () => this.setState({ propertyDrawerVisible: false });

  handlePropertyDrawerOpen = () => this.setState({ propertyDrawerVisible: true });

  renderRooms = (revision) => {
    const { t, ratePlans = [], roomTypes = [] } = this.props;

    if (!revision || !revision.rooms) {
      return null;
    }

    const items = revision.rooms
      .sort((a, b) => a.is_cancelled - b.is_cancelled)
      .map((room) => {
        const { currency } = revision;

        const ratePlan = ratePlans[room.rate_plan_id];
        const roomType = roomTypes[room.room_type_id];
        const isUnallocated = !roomType;
        const hasUnmappedRate = !ratePlan;

        const label = (
          <Row>
            <Col span={12}>
              <strong>
                {roomType && ratePlan && roomType.title}
                {roomType && hasUnmappedRate && (
                  <>
                    <ExclamationCircleOutlined />
                    &nbsp;
                    {roomType.title}
                  </>
                )}
                {isUnallocated && (
                  <>
                    <ExclamationCircleOutlined />
                    &nbsp;
                    {this.t("info:unallocated_room")}
                  </>
                )}
              </strong>
              {room.is_cancelled && (
                <>
                  &nbsp;<Tag color="red">{this.t("info:cancelled_room")}</Tag>
                </>
              )}
              <br />
              <small>
                <CalendarOutlined />
                &nbsp;
                {bookingDate(room.checkin_date, t("formats:date_time_with_weekday"))} -{" "}
                {bookingDate(room.checkout_date, t("formats:date_time_with_weekday"))}
              </small>
            </Col>
            <Col span={12} className={utilStyles.textRight}>
              <strong>
                {room.amount} {currency}
              </strong>
            </Col>
          </Row>
        );

        return {
          key: room.id,
          label,
          children: this.renderRoom(room),
        };
      });

    return (
      <>
        <Legend label={this.t("info:rooms")} />
        <Collapse items={items} className={styles.rooms} bordered={false} />
      </>
    );
  };

  renderAges = (occupancy) => {
    const { t } = this.props;
    let output = null;

    if (occupancy.ages && occupancy.ages.length > 0) {
      output = ` (${t("bookings_page:booking_view_dialog:info:ages")}: ${occupancy.ages.join(", ")})`;
    }

    return output;
  };

  renderBreakdown = (room, roomType, ratePlan, currency) => {
    const { t, ratePlans = [] } = this.props;

    const daysBreakdown = (
      room.meta
        && room.meta.days_breakdown
        && room.meta.days_breakdown.reduce((acc, el) => {
          acc[el.date] = el;
          return acc;
        }, {})
    ) || {};

    const excludedTaxes = (
      room.taxes && room.taxes.filter((tax) => {
        return !tax.is_inclusive;
      })
    ) || null;

    const includedTaxes = (
      room.taxes && room.taxes.filter((tax) => {
        return tax.is_inclusive;
      })
    ) || null;

    const subtotal = Object.values(room.days)
      .reduce((acc, el) => acc + parseFloat(el), 0)
      .toFixed(2);

    const sortedRoomDays = sortStringDates(Object.keys(room.days));

    return (
      <>
        <dt className={[viewStyles.list__term, viewStyles.list__term_marged].join(" ")}>
          Price Breakdown:
        </dt>
        <dd className={[viewStyles.list__description, viewStyles.list__term_marged].join(" ")}>
          {sortedRoomDays.map((date) => {
            let rateTitle = roomType && ratePlan && ratePlan.title;

            if (daysBreakdown[date]) {
              rateTitle = ratePlans[daysBreakdown[date].rate_plan]
                  && ratePlans[daysBreakdown[date].rate_plan].title;
            }

            rateTitle = rateTitle || (<i>{t("bookings_page:booking_view_dialog:error:unmapped_rate")}</i>);

            if (daysBreakdown[date] && daysBreakdown[date].promotion) {
              rateTitle = (
                <>
                  {rateTitle}
                  <br />
                  <small>{daysBreakdown[date].promotion.title}</small>
                </>
              );
            }

            return (
              <Row key={date}>
                <Col span={8}>{bookingDate(date, t("formats:date_time_with_weekday"))}</Col>
                <Col span={8}>{rateTitle}</Col>
                <Col span={8} className={utilStyles.textRight}>
                  {room.days[date]} {currency}
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col span={16}>
              <strong>{this.t("info:subtotal")}</strong>
            </Col>
            <Col span={8} className={utilStyles.textRight}>
              <strong>
                {subtotal} {currency}
              </strong>
            </Col>
          </Row>
          {room.services
              && room.services.map((service, index) => {
                return (
                  <Row key={`service-${index}`}>
                    <Col span={16}>
                      {service.name}
                      {service.count > 1 ? ` x ${service.count}` : ""}
                    </Col>
                    <Col span={8} className={utilStyles.textRight}>
                      {service.total_price} {currency}
                    </Col>
                  </Row>
                );
              })}
          {excludedTaxes
              && excludedTaxes.map((tax, index) => {
                return (
                  <Row key={`excluded-tax-${index}`}>
                    <Col span={16}>{tax.name}</Col>
                    <Col span={8} className={utilStyles.textRight}>
                      {tax.total_price} {currency}
                    </Col>
                  </Row>
                );
              })}
          {includedTaxes && includedTaxes.length > 0 && (
            <Row className={viewStyles.inactive}>
              <Col span={8}>{this.t("info:rate_included")}:</Col>
              <Col span={16}>
                {includedTaxes.map((tax, index) => {
                  return (
                    <React.Fragment key={`included_tax__${index}`}>
                      {tax.name}
                      <br />
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
          )}
          {room.collected_taxes && room.collected_taxes.length > 0 && (
            <Row className={viewStyles.inactive}>
              <Col span={8}>{this.t("info:rate_collected")}:</Col>
              <Col span={16}>
                {room.collected_taxes.map((tax, index) => {
                  return (
                    <Row key={`collected-tax-${index}`}>
                      <Col span={16}>{tax.name}</Col>
                      <Col span={8} className={utilStyles.textRight}>
                        {tax.total_price} {currency}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          )}
        </dd>
      </>
    );
  };

  renderRoom = (room) => {
    const { revision, ratePlans = [], roomTypes = [] } = this.props;
    const { currency } = revision;

    const ratePlan = ratePlans[room.rate_plan_id];
    const roomType = roomTypes[room.room_type_id];

    return (
      <dl className={viewStyles.list}>
        {this.renderBreakdown(room, roomType, ratePlan, currency)}
        {room.guests && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:guests")}:</dt>
            <dd className={viewStyles.list__description}>
              {room.guests.map((guest) => {
                return (
                  <div key={guest.name}>
                    {guest.name} {guest.surname}
                  </div>
                );
              })}
            </dd>
          </>
        )}
        <dt className={viewStyles.list__term}>{this.t("info:occupancy")}:</dt>
        <dd className={viewStyles.list__description}>
          {this.t("info:adults")}: {room.occupancy.adults}, &nbsp;
          {this.t("info:children")}: {room.occupancy.children}
          {this.renderAges(room.occupancy)}, &nbsp;
          {this.t("info:infants")}: {room.occupancy.infants}
        </dd>
        {this.renderMetaFields(room.meta)}
      </dl>
    );
  };

  renderMetaFields = (meta) => {
    if (!meta) {
      return null;
    }

    return (
      <>
        {meta.meal_plan && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:meal_plan")}:</dt>
            <dd className={viewStyles.list__description}>{meta.meal_plan}</dd>
          </>
        )}
        {meta.bed_preferences && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:bed_preferences")}:</dt>
            <dd className={viewStyles.list__description}>{meta.bed_preferences}</dd>
          </>
        )}
        {meta.smoking_preferences && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:smoking_preferences")}:</dt>
            <dd className={viewStyles.list__description}>{meta.smoking_preferences}</dd>
          </>
        )}
        {meta.policies && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:policies")}:</dt>
            <dd className={viewStyles.list__description}>{meta.policies}</dd>
          </>
        )}
        {meta.payment_instruction && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:payment_instruction")}:</dt>
            <dd className={viewStyles.list__description}>{meta.payment_instruction}</dd>
          </>
        )}
        {meta.promotion_code && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:promotion_code")}:</dt>
            <dd className={viewStyles.list__description}>{meta.promotion_code}</dd>
          </>
        )}
        {meta.value_add_promotion && (
          <>
            <dt className={viewStyles.list__term}>{this.t("info:value_add_promotion")}:</dt>
            <dd className={viewStyles.list__description}>{meta.value_add_promotion}</dd>
          </>
        )}
        {this.renderCancelPenalties(meta.cancel_penalties)}
      </>
    );
  };

  renderCancelPenalties = (cancelPenalties) => {
    const { t } = this.props;

    if (!cancelPenalties || !cancelPenalties.length) {
      return null;
    }

    return (
      <>
        <dt className={viewStyles.list__term}>{this.t("info:cancellation_policy")}:</dt>
        <dd className={viewStyles.list__description}>
          {cancelPenalties.map((penalty, penaltyIndex) => {
            return (
              <Row key={`penalty-${penaltyIndex}`}>
                <Col span={16}>
                  {`${this.t("info:from")} ${bookingDate(
                    penalty.from,
                    t("formats:date_time_with_seconds"),
                  )}`}
                </Col>
                <Col span={8} className={utilStyles.textRight}>
                  {penalty.amount} {penalty.currency}
                </Col>
              </Row>
            );
          })}
        </dd>
      </>
    );
  };

  renderServices = (revision) => {
    if (!revision || !revision.services || revision.services.length === 0) {
      return null;
    }

    const services = revision.services.filter((el) => el.service_rph === null);

    if (services.length === 0) {
      return null;
    }

    return (
      <>
        <Legend label={this.t("info:services")} />
        {services.map((service, index) => (
          <Row key={`service__${index}`}>
            <Col span={16}>
              <strong>{service.name}</strong>
            </Col>
            <Col span={8} className={utilStyles.textRight}>
              <strong>
                {service.total_price} {revision.currency}
              </strong>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  formatExpriationDate = (expirationDate) => {
    const { t } = this.props;

    if (!expirationDate) {
      return t("general:not_applicable");
    }

    if (expirationDate.includes("/")) {
      return expirationDate;
    }

    const expMonth = expirationDate.substring(0, 2);
    const expYear = expirationDate.substring(2);

    return `${expMonth}/${expYear}`;
  };

  renderGuaranteeDetails = (revision) => {
    const { t, isBookingView } = this.props;

    if (!revision) {
      return null;
    }

    const { guarantee } = revision;

    if (!guarantee) {
      return (
        <>
          <Legend label={this.t("info:guarantee")} />
          <i>{this.t("info:no_guarantee_provided")}</i>
          <br />
          <br />
        </>
      );
    }

    return (
      <>
        <Legend
          label={this.t("info:guarantee")}
          actionsSize="middle"
          actions={isBookingView ? <InvalidCardReport booking={revision} /> : null}
        />
        <dl className={viewStyles.list}>
          <dt className={viewStyles.list__term}>{this.t("info:cc_type")}:</dt>
          <dd className={viewStyles.list__description}>
            <BookingRevisionCard type={guarantee.card_type} />
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:cc_number")}:</dt>
          <dd className={viewStyles.list__description}>
            {guarantee.card_number || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:cc_expiration_date")}:</dt>
          <dd className={viewStyles.list__description}>
            {this.formatExpriationDate(guarantee.expiration_date)}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:cc_cardholder_name")}:</dt>
          <dd className={viewStyles.list__description}>
            {guarantee.cardholder_name || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:cc_cvv")}:</dt>
          <dd className={viewStyles.list__description}>
            {guarantee.cvv ? "Present" : "Not Present"}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:is_virtual")}:</dt>
          <dd className={viewStyles.list__description}>{guarantee.is_virtual ? "Yes" : "No"}</dd>
          {this.renderVirtualCardInfo(guarantee)}
          <dd className={viewStyles.list__description}>
            <ShowCardButton revision={revision} />
          </dd>
        </dl>
      </>
    );
  };

  renderVirtualCardInfo = ({ is_virtual, meta }) => {
    if (!is_virtual) {
      return null;
    }

    const { t } = this.props;
    const {
      virtual_card_effective_date,
      virtual_card_current_balance = 0,
      virtual_card_currency_code,
      virtual_card_decimal_places = 0,
    } = meta || {};

    const balanceValue = virtual_card_current_balance / 10 ** virtual_card_decimal_places;
    const formattedBalance = balanceValue.toFixed(virtual_card_decimal_places);

    return (
      <>
        <dt className={viewStyles.list__term}>{this.t("info:virtual_card_effective_date")}:</dt>
        <dd className={viewStyles.list__description}>
          {bookingDate(virtual_card_effective_date, t("formats:date_time_with_weekday"))}
        </dd>
        <dt className={viewStyles.list__term}>{this.t("info:virtual_card_balance")}:</dt>
        <dd className={viewStyles.list__description}>
          {balanceValue ? formattedBalance : t("general:not_applicable")}
        </dd>
        <dt className={viewStyles.list__term}>{this.t("info:virtual_card_currency")}:</dt>
        <dd className={viewStyles.list__description}>
          {virtual_card_currency_code || t("general:not_applicable")}
        </dd>
      </>
    );
  };

  renderNotes(model) {
    return (
      <>
        <Legend label={this.t("info:notes")} />
        <div>
          {model.notes ? (
            <span className={styles.notes}>{model.notes}</span>
          ) : (
            <i>{this.t("not_provided")}</i>
          )}
          <br />
          <br />
        </div>
      </>
    );
  }

  getPaymentMethod = (revision) => {
    const { payment_collect, payment_type } = revision;
    const { t } = this.props;

    const paymentMethods = {
      property: {
        credit_card: this.t("info:credit_card"),
        null: this.t("info:cash"),
      },
      ota: {
        credit_card: this.t("info:virtual_card"),
        bank_transfer: this.t("info:bank_transfer"),
      },
      default: t("general:not_applicable"),
    };

    const { [payment_collect]: availableTypes = {} } = paymentMethods;
    const { [payment_type]: paymentType = paymentMethods.default } = availableTypes;

    return (
      <>
        <dt className={viewStyles.list__term}>{this.t("info:payment_method")}:</dt>
        <dd className={viewStyles.list__description}>{paymentType}</dd>
      </>
    );
  };

  render() {
    const { channelDrawerVisible, propertyDrawerVisible } = this.state;
    const { t, revision, hiddenFields = [] } = this.props;

    const {
      customer = {},
      ota_name,
      channel,
      unique_id,
      ota_reservation_code,
      inserted_at,
      arrival_date,
      arrival_hour,
      departure_date,
      rooms,
      occupancy,
      guests,
      amount,
      currency,
      meta,
      properties,
      channel_id,
      property_id,
      booking_id,
      revision_id,
      id,
    } = revision;

    const otaName = channelNames[ota_name]?.title || ota_name;
    const propertiesList = properties && Object.values(properties);
    const channelId = channel_id || channel?.id;

    return (
      <>
        <CancelButton revision={revision} />
        <dl className={viewStyles.list}>
          <dt className={viewStyles.list__term}>{this.t("info:status")}:</dt>
          <dd className={viewStyles.list__description_small}>
            <BookingRevisionTags revision={revision} />
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:ota_name")}:</dt>
          <dd className={viewStyles.list__description_small}>{otaName}</dd>
          {!hiddenFields.includes("channel") && (
            <>
              <dt className={viewStyles.list__term}>{this.t("info:channel_name")}:</dt>
              <dd className={viewStyles.list__description_small}>
                {channel?.attributes?.title ? (
                  <Button
                    type="link"
                    className={styles.linkButton}
                    onClick={this.handleChanelDrawerOpen}
                  >
                    {channel?.attributes?.title}
                  </Button>
                ) : (
                  t("general:not_applicable")
                )}
              </dd>
              <dt className={viewStyles.list__term}>{this.t("info:reservation_id")}:</dt>
              <dd className={viewStyles.list__description_small}>
                {unique_id}
                <CopyToClipboard text={unique_id} />
              </dd>
            </>
          )}
          {(booking_id && !hiddenFields.includes("reservation_id")) ? (
            <>
              <dt className={viewStyles.list__term}>{this.t("info:booking_id")}:</dt>
              <dd className={viewStyles.list__description_small}>
                {booking_id}
                <CopyToClipboard text={booking_id} />
              </dd>
            </>
          ) : null}
          {((revision_id || id) && !hiddenFields.includes("revision_id")) ? (
            <>
              <dt className={viewStyles.list__term}>{this.t("info:revision_id")}:</dt>
              <dd className={viewStyles.list__description_small}>
                {revision_id || id}
                <CopyToClipboard text={revision_id || id} />
              </dd>
            </>
          ) : null}
          <dt className={viewStyles.list__term}>{this.t("info:ota_reservation_id")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {ota_reservation_code || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:booked_at")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {formatToLocal(inserted_at, t("formats:date_time_with_weekday"))}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:property")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {property_id && propertiesList?.length > 0 && hiddenFields.includes("property") && propertiesList[0]?.title}
            {property_id && propertiesList?.length > 0 && !hiddenFields.includes("property") && (
              <Button
                type="link"
                className={styles.linkButton}
                onClick={this.handlePropertyDrawerOpen}
              >
                {propertiesList[0]?.title}
              </Button>
            )}
            {(!property_id || propertiesList?.length === 0) ? (
              t("general:not_applicable")
            ) : null}
          </dd>
          {(meta?.ruid && !hiddenFields.includes("ruid")) && (
            <>
              <dt className={viewStyles.list__term}>{this.t("info:ruid")}:</dt>
              <dd
                className={[viewStyles.list__description_small, styles.listDescriptionInline].join(
                  " ",
                )}
              >
                <span className={viewStyles.list__description_trimmed}>{meta.ruid}</span>
                <CopyToClipboard text={meta.ruid} />
              </dd>
            </>
          )}
        </dl>
        <Legend label={this.t("info:checkin_details")} />
        <dl className={viewStyles.list}>
          <dt className={viewStyles.list__term}>{this.t("info:check_in")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {bookingDate(arrival_date, t("formats:date_time_with_weekday"))}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:check_out")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {bookingDate(departure_date, t("formats:date_time_with_weekday"))}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:arrival_time")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {bookingDate(arrival_hour && `2019-01-01T${arrival_hour}`, t("formats:time"))}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:nights")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {dayjs(departure_date).diff(arrival_date, "days")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:rooms")}:</dt>
          <dd className={viewStyles.list__description_small}>{(rooms || []).length}</dd>
          <dt className={viewStyles.list__term}>{this.t("info:occupancy")}:</dt>
          <dd className={viewStyles.list__description_small}>
            A: {occupancy.adults ?? t("general:not_applicable")}
            &nbsp; C: {occupancy.children ?? t("general:not_applicable")}
            {this.renderAges(occupancy)}
            &nbsp; I: {occupancy.infants ?? t("general:not_applicable")}
          </dd>
        </dl>
        <Legend label={this.t("info:customer")} />
        <dl className={viewStyles.list}>
          <dt className={viewStyles.list__term}>{this.t("info:name")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.name} {customer.surname}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:email")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.mail ? (
              <a href={`mailto:${customer.mail}`}>{customer.mail || t("general:not_applicable")}</a>
            ) : (
              t("general:not_applicable")
            )}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:phone")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.phone || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:language")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.language || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:country")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {getCountryName(customer.country) || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:city")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.city || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:address")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.address || t("general:not_applicable")}
          </dd>
          <dt className={viewStyles.list__term}>{this.t("info:zip")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {customer.zip || t("general:not_applicable")}
          </dd>
          {customer.meta && customer.meta.is_genius && (
            <>
              <dt className={viewStyles.list__term} />
              <dd className={viewStyles.list__description_small}>
                <Tag color="volcano">
                  <TagOutlined />
                  &nbsp; Genius Booker
                </Tag>
              </dd>
            </>
          )}
        </dl>
        {this.renderRooms(revision)}
        {guests && guests.length > 0 && <Legend label={this.t("info:guests")} />}
        {this.renderServices(revision)}
        {this.renderGuaranteeDetails(revision)}
        {this.renderNotes(revision)}
        <Legend label={this.t("info:booking_expenses")} />
        <dl className={viewStyles.list}>
          <dt className={viewStyles.list__term}>{this.t("info:total_amount")}:</dt>
          <dd className={viewStyles.list__description_small}>
            {amount} {currency}
          </dd>
          {meta?.payment_model && (
            <>
              <dt className={viewStyles.list__term}>{this.t("info:payment_model")}:</dt>
              <dd className={viewStyles.list__description_small}>{meta.payment_model}</dd>
            </>
          )}
          {this.getPaymentMethod(revision)}
          {revision.deposits && (
            <>
              <dt className={viewStyles.list__term}>Deposits:</dt>
              <dd className={viewStyles.list__description_small}>
                {revision.deposits.map((deposit) => (
                  <>
                    {deposit.amount} {deposit.currency}<br />
                  </>
                ))}
              </dd>
            </>
          )}
        </dl>

        {channelId && (
          <ChannelDrawerControlled
            channelId={channelId}
            visible={channelDrawerVisible}
            onClose={this.handleChanelDrawerClose}
          />
        )}
        {property_id && (
          <PropertyFormDrawerControlled
            propertyId={property_id}
            visible={propertyDrawerVisible}
            onClose={this.handlePropertyDrawerClose}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(BookingRevision);
