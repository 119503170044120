import { useCallback, useState } from "react";

import { BaseWizardData, StepIndex, WizardParams } from "./types";

export const useWizardLogic = <TData extends BaseWizardData = BaseWizardData>({
  initialStep = 0,
  initialData,
  onComplete,
  steps,
}: WizardParams<TData>) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<StepIndex>(initialStep);
  const [data, setData] = useState<TData | undefined>(initialData);
  const [stepsErrors, setStepsErrors] = useState<StepIndex[]>([]);
  const [completedSteps, setCompletedSteps] = useState<StepIndex[]>([]);
  const [seenSteps, setSeenSteps] = useState<StepIndex[]>([initialStep]);

  // Add steps to completed steps without duplicates
  const completeStep = useCallback((index: StepIndex) => {
    setCompletedSteps((prevSteps) => (prevSteps.includes(index) ? prevSteps : [...prevSteps, index]));
  }, []);

  // Navigate to next step
  const goNext = useCallback(() => {
    if (currentStepIndex >= steps.length - 1) {
      return false;
    }

    // Update current step
    setCurrentStepIndex((i) => i + 1);

    // Mark current step as completed
    completeStep(currentStepIndex);

    // Add next step to seen steps
    setSeenSteps((prevSteps) => {
      const nextStep = currentStepIndex + 1;
      return prevSteps.includes(nextStep) ? prevSteps : [...prevSteps, nextStep];
    });

    // Remove any errors from current step
    setStepsErrors((errors) => errors.filter((stepIndex) => stepIndex !== currentStepIndex));

    return true;
  }, [currentStepIndex, steps.length, completeStep]);

  // Navigate to previous step
  const goPrev = useCallback(() => {
    if (currentStepIndex <= 0) {
      return false;
    }

    setCurrentStepIndex((i) => i - 1);
    return true;
  }, [currentStepIndex]);

  // Go to specific step
  const goTo = useCallback((index: StepIndex) => {
    if (index < 0 || index >= steps.length) {
      return false;
    }

    setCurrentStepIndex(index);

    // Add step to seen steps
    setSeenSteps((prevSteps) => (prevSteps.includes(index) ? prevSteps : [...prevSteps, index]));

    return true;
  }, [steps.length]);

  // Update data with partial updates
  const updateData = useCallback((newData: TData) => {
    setData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          ...newData,
        };
      }

      return newData;
    });
  }, []);

  // Complete the wizard
  const finish = useCallback(() => {
    // Mark the final step as completed
    completeStep(currentStepIndex);

    // Remove any errors from the final step
    setStepsErrors((errors) => errors.filter((stepIndex) => stepIndex !== currentStepIndex));

    if (onComplete) {
      onComplete(data);
    }

    return true;
  }, [data, onComplete, currentStepIndex, completeStep]);

  // Reset the wizard
  const startAgain = useCallback(() => {
    setCurrentStepIndex(0);
    setData(initialData);
    setCompletedSteps([]);
    setSeenSteps([0]);
    setStepsErrors([]);
  }, [initialData]);

  // Compute derived state
  const haveNextStep = currentStepIndex < steps.length - 1;
  const havePrevStep = currentStepIndex > 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  // Return hook API
  return {
    // Steps data
    steps,
    currentStepIndex,
    currentStep: steps[currentStepIndex],

    // State management
    data,
    updateData,
    setData, // Keep original setState for full replacement if needed

    // Navigation
    goNext,
    goPrev,
    goTo,
    finish,
    startAgain,

    // Status tracking
    stepsErrors,
    setStepsErrors,
    completedSteps,
    completeStep,
    seenSteps,

    // Derived state
    haveNextStep,
    havePrevStep,
    isLastStep,
  };
};
