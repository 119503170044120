import React from "react";

import Flexbox from "components/flexbox";

import { CascaderEditor } from "./mapping_editors/cascader_editor";
import { ListingMappingRow } from "./listing_mapping_row";
import { MappingDirection } from "./mapping_direction";
import { useListingMapping } from "./use_listing_mapping";
import { VirtualList } from "./virtual_list";

export const HopperHomesMapping = ({
  channelTitle,
  properties,
  channelProperties: channelPropertiesProp,
  roomTypes,
  ratePlans,
  mappings,
  mappingOptions,
  mappingSchema,
  RateMappingEditor = CascaderEditor,
  onChangeSettings,
  onChangeMapping,
}) => {
  const channelProperties = channelPropertiesProp.map((propertyId) => {
    const property = properties[propertyId];

    if (!property) {
      throw new Error(`Property with id ${propertyId} not found in channel`);
    }

    return property;
  });

  const {
    mappingItems,
    handleRateMappingChange,
    handleRateMappingDelete,
  } = useListingMapping({
    mappings,
    mappingOptions,
    properties: channelProperties,
    roomTypes,
    ratePlans,
    onChangeSettings,
    onChangeMapping,
  });

  return (
    <Flexbox flexDirection="column" fullHeight>
      <MappingDirection channelTitle={channelTitle} />
      <Flexbox flexDirection="column">
        <VirtualList
          itemDataTestid="mapping_row"
          items={mappingItems}
          renderItem={(mappingItem) => {
            return (
              <ListingMappingRow
                mappingSchema={mappingSchema}
                mappingItem={mappingItem}
                MappingEditor={RateMappingEditor}
                onChange={handleRateMappingChange}
                onDelete={handleRateMappingDelete}
              />
            );
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

HopperHomesMapping.MAPPING_TYPE = "Listing";
