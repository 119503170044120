export const extractRelationshipIds = (entity) => {
  const parsedEntity = structuredClone(entity);

  Object.keys(parsedEntity.relationships || {}).forEach((relation) => {
    const { data } = parsedEntity.relationships[relation];

    if (Array.isArray(data)) {
      parsedEntity.attributes[relation] = data
        .map((el) => el.attributes)
        .reduce((acc, el) => {
          acc[el.id] = el;
          return acc;
        }, {});
    } else {
      parsedEntity.attributes[`${relation}_id`] = data && data.id;
      parsedEntity.attributes[relation] = data;
    }
  });

  return parsedEntity;
};

function extractRelationshipsFromEntity(model) {
  Object.keys(model.relationships || {}).forEach((key) => {
    const { data } = model.relationships[key];

    if (Array.isArray(data)) {
      model.attributes[key] = data
        .map((el) => el.attributes)
        .reduce((acc, el) => {
          acc[el.id] = el;
          return acc;
        }, {});
    } else {
      model.attributes[`${key}_id`] = data && data.id;
      model.attributes[key] = data;
    }
  });

  return model.attributes;
}

function extractRelationships(models, options = {}) {
  const {
    group = true,
  } = options;

  let result;

  if (Array.isArray(models)) {
    if (group) {
      result = models.reduce((acc, el) => {
        acc[el.id] = extractRelationshipsFromEntity(el);
        return acc;
      }, {});
    } else {
      result = models.map(extractRelationshipsFromEntity);
    }
  } else {
    result = extractRelationshipsFromEntity(models);
  }

  return result;
}

export default extractRelationships;
