import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import store from "store";

import { ListingUnpublishWorkflowInner } from "./listing_unpublish_workflow_inner";

const { Channels } = store;

const useUnpublishReasons = (channelId) => useQuery({
  queryKey: ["unpublish_reasons", channelId],
  queryFn: () => Channels.airbnbGetUnpublishReasons(channelId),
  retry: false,
});

const useUnpublishListingMutation = () => useMutation({
  mutationFn: (params) => Channels.airbnbUnpublishListing(params),
});

export function ListingUnpublishWorkflow({ channelId, listingId, onSuccess, onCancel }) {
  const [answers, setAnswers] = useState([]);
  const unpublishListingMutation = useUnpublishListingMutation();
  const { data: flow, isFetching: flowIsFetching, error: flowLoadError } = useUnpublishReasons(channelId);

  const handleFinish = (payload) => {
    unpublishListingMutation.mutate({ channelId, listingId, payload }, {
      onSuccess,
    });
  };

  const handleNext = (answer) => {
    setAnswers((prev) => [...prev, answer]);
  };

  const handleBack = () => {
    setAnswers((prev) => prev.slice(0, -1));
  };

  return (
    <ListingUnpublishWorkflowInner
      flow={flow}
      answers={answers}
      isFlowLoading={flowIsFetching}
      flowLoadError={flowLoadError?.message}
      isSubmitting={unpublishListingMutation.isLoading}
      submitError={unpublishListingMutation.error?.message}
      onNext={handleNext}
      onBack={handleBack}
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
}
