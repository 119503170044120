import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Space } from "antd";
import classNames from "classnames";

import styles from "./crud_top_bar.module.css";

export default function CRUDTopBar({
  borderless,
  actions,
  searchQuery,
  onSearchChange,
  onRefreshClick,
  advancedSearch,
  advancedSearchApply,
  advancedSearchFilter,
  searchAddon,
  searchPlaceholder,
  search = true,
  searchInput = true,
  refreshAction = true,
}) {
  const [advancedSearchIsVisible, setAdvancedSearchIsVisible] = useState(false);
  const { t } = useTranslation();

  const containerClassName = classNames(styles.contentSearch, {
    [styles.contentSearchBorderless]: borderless,
  });

  const handleSearchChange = useCallback(
    (event) => {
      const formattedQuery = event.target.value || "";

      onSearchChange(formattedQuery);
    },
    [onSearchChange],
  );

  const toggleAdvancedSearch = (_event) => {
    setAdvancedSearchIsVisible(!advancedSearchIsVisible);
  };

  const apply = (filter) => {
    toggleAdvancedSearch();
    advancedSearchApply(filter);
  };

  const filterIsNotEmpty = (filter) => {
    const globalApplicableFilters = ["property_id", "group_id"];

    return (
      Object.keys(filter).filter((key) => globalApplicableFilters.indexOf(key) === -1).length > 0
    );
  };

  const AdvancedSearch = advancedSearch;

  return (
    <div className={containerClassName}>
      <div data-testid="crud_table_search_field" className={styles.contentSearchField}>
        {search && (
          <Space.Compact style={{ width: "100%" }}>
            {searchInput && (
              <Input
                type="text"
                prefix={<SearchOutlined className={styles.contentSearchIcon} />}
                placeholder={searchPlaceholder || t("general:search_field_placeholder")}
                className={styles.contentSearchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            )}
            {advancedSearch && (
              <Button onClick={toggleAdvancedSearch}>
                <Badge dot={filterIsNotEmpty(advancedSearchFilter)}>
                  <FilterOutlined />
                  &nbsp;{t("general:crud_table:advanced_search:button")}
                </Badge>
              </Button>
            )}
            {searchAddon && searchAddon}
            {refreshAction && (
              <Button icon={<ReloadOutlined />} onClick={onRefreshClick}>{t("general:action:refresh")}</Button>
            )}
          </Space.Compact>
        )}
        {AdvancedSearch && advancedSearchIsVisible && (
          <AdvancedSearch
            advancedSearchApply={apply}
            advancedSearchFilter={advancedSearchFilter}
            toggleVisibility={toggleAdvancedSearch}
          />
        )}
      </div>
      {actions && <div className={styles.contentSearchActions}>{actions}</div>}
    </div>
  );
}
