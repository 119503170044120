import { useMutation, useQueryClient } from "@tanstack/react-query";
import store from "store";

const { ExtraCategories } = store;

export function useDeleteCategoryAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => ExtraCategories.delete(id),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["extras", propertyId],
      });
    },
  });
}
