import { Outlet } from "react-router";
import { UserChannelProvider } from "store/use_user_channel";

import { usePageTracking } from "hooks/use_page_tracking";

import AppLayout from "./app_layout";
import DataLoader from "./data_loader";
import GlobalComponents from "./global_components";
import Header from "./header";
import { InitWsConnection } from "./init_ws_connection";
import Redirects from "./redirects";

export default function EntryPoint() {
  usePageTracking();

  return (
    <InitWsConnection>
      <UserChannelProvider>
        <DataLoader>
          <Redirects>
            <AppLayout>
              <GlobalComponents />
              <Header />
              <Outlet />
            </AppLayout>
          </Redirects>
        </DataLoader>
      </UserChannelProvider>
    </InitWsConnection>
  );
}
