import { useMutation } from "@tanstack/react-query";
import store from "store";

const { Profiles } = store;

export default function useSignAgreementAction() {
  return useMutation({
    mutationFn: ({ version }) => Profiles.signAgreement(version),
  });
}
