import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import ChannexDrawerRoutable from "../channex_drawer_routable";

import { ExtrasList } from "./extras_list";

export function PropertyManageExtrasDrawer() {
  const { t } = useTranslation();
  const title = t("property_extras:titles:edit_extras");

  const { propertyId } = useParams();

  return (
    <ChannexDrawerRoutable title={title}>
      <ExtrasList propertyId={propertyId} />
    </ChannexDrawerRoutable>
  );
}
