import { AppForm } from "components/_v2/forms/hook_form";

import { DepositFormInner } from "./deposit_form_inner";
import { validationSchema } from "./validation_schema";

export const DepositForm = ({ initialValue, errors, onSubmit }) => {
  return (
    <AppForm
      initialValue={initialValue}
      errors={errors}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <DepositFormInner />
    </AppForm>
  );
};
