import React from "react";
import { Typography } from "antd";

type Params = {
  message: string
}

export const SectionMessage = ({ message }: Params) => {
  return (
    <div data-testid="section-message">
      <Typography.Text type="secondary">{message}</Typography.Text>
    </div>
  );
};
