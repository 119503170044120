import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { mewsApiClient } from "store/api_clients/applications/mews";

import { useWizard,WizardStep } from "../wizard";

import { FormValues, TokenForm } from "./token_form";

const useRoomAndRatesMQuery = () => {
  return useMutation({
    mutationFn: async ({ token }: { token: string }) => mewsApiClient.getMappingDetails({ token }),
  });
};

export const StepMappingDetails = () => {
  const { t } = useTranslation();
  const { goNext, updateData } = useWizard();
  const [formErrors, setFormErrors] = React.useState({});

  const roomAndRatesMQuery = useRoomAndRatesMQuery();

  const handleFormFinish = async ({ token }: { token: string }) => {
    try {
      const { connected, roomTypes } = await roomAndRatesMQuery.mutateAsync({ token });

      if (connected) {
        setFormErrors({ token: { message: t("mews:messages:token_already_used") } });
        return;
      }

      updateData({
        token,
        mewsRooms: roomTypes,
      });
      goNext();
    } catch (error: any) {
      if (error.isServiceUnavailable) {
        setFormErrors({ token: { message: t("mews:messages:invalid_token") } });
        return;
      }

      throw error;
    }
  };

  return (
    <WizardStep dataTestid="mapping-details-step">
      <TokenForm
        errors={formErrors}
        isSubmitting={roomAndRatesMQuery.isPending}
        onFinish={async (values) => {
          // TODO[TS]: Need correct types for forms
          await handleFormFinish(values as FormValues);
        }}
      />
    </WizardStep>
  );
};
