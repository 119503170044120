import { ChannelsProvider } from "store/channels_provider";
import { useUserChannel } from "store/use_user_channel";

import ChannelEventsLogsList from "./channel_events_logs_list";

export function ChannelEventsLogsListContainer({ actionId }) {
  const userChannel = useUserChannel();

  return (
    <ChannelsProvider channels={{ userChannel }}>
      <ChannelEventsLogsList actionId={actionId} />
    </ChannelsProvider>
  );
}
