import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router";
import PropTypes from "prop-types";
import store from "store";

import CrudTableWithFilters from "components/crud_table/crud_table_with_filters";
import * as Page from "components/page";
import { PageHeader } from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

import styles from "styles/dashboard.module.css";

const { Properties, Tasks } = store;

const taskOptions = [{
  value: "Property.ActivateProperty",
  label: "Activate Property",
}, {
  value: "Property.AddRatePlan",
  label: "Add Rate Plan",
}, {
  value: "Property.AddRoomType",
  label: "Add Room Type",
}, {
  value: "Property.ApplyAvailabilityChanges",
  label: "Apply Availability Changes",
}, {
  value: "Property.BackstageSync",
  label: "Backstage Sync",
}, {
  value: "Property.DeactivateProperty",
  label: "Deactivate Property",
}, {
  value: "Property.ReceiveBooking",
  label: "Receive Booking",
}, {
  value: "Property.RemoveRatePlan",
  label: "Remove Rate Plan",
}, {
  value: "Property.RemoveRoomType",
  label: "Remove Room Type",
}, {
  value: "Property.UpdateAvailability",
  label: "Update Availability",
}, {
  value: "Property.UpdateChannels",
  label: "Update Channels",
}, {
  value: "Property.UpdateDate",
  label: "Update Date",
}, {
  value: "Property.UpdateProperty",
  label: "Update Property",
}, {
  value: "Property.UpdateRatePlan",
  label: "Update Rate Plan",
}, {
  value: "Property.UpdateRestrictions",
  label: "Update Restrictions",
}, {
  value: "Property.UpdateRoomType",
  label: "Update Room Type",
}, {
  value: "Property.SetCutOffTime",
  label: "Set Cut Off Time",
}, {
  value: "Property.AddChannel",
  label: "Add Channel",
}, {
  value: "Property.RemoveChannel",
  label: "Remove Channel",
}, {
  value: "Property.AddWebhook",
  label: "Add Webhook",
}, {
  value: "Property.UpdateWebhook",
  label: "Update Webhook",
}, {
  value: "Property.RemoveWebhook",
  label: "Remove Webhook",
}, {
  value: "Property.DeactivateWebhook",
  label: "Deactivate Webhook",
}];

const resultOptions = [{
  value: "true",
  label: "Success",
}, {
  value: "false",
  label: "Failure",
}];

class TasksPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    property: null,
    columnsToHide: 0,
    tableQuery: "",
  };

  componentDidMount() {
    const { propertyId } = this.props;

    Properties.find(propertyId).then((property) => this.setState({ property }));
  }

  actions = (t) => {
    const { routes } = this.props;

    return (text, record) => (
      <span>
        <Link
          to={pathBuilder(routes.userAppRoutes.tasks.view, {
            propertyId: record.property_id,
            taskId: record.id,
          })}
        >
          {t("general:action:view")}
        </Link>
      </span>
    );
  };

  loadData = (filters, pagination, order) => {
    const { propertyId } = this.props;

    const loadFilters = {
      property_id: propertyId,
    };

    if (filters.task) {
      loadFilters.task = filters.task.map((name) => name.replaceAll(" ", "")).join(",");
    }

    if (filters.received_at) {
      loadFilters.received_at = {};

      if (filters.received_at[0]) {
        loadFilters.received_at.gte = filters.received_at[0].startOf("day").utc().format();
      }

      if (filters.received_at[1]) {
        loadFilters.received_at.lte = filters.received_at[1].endOf("day").utc().format();
      }
    }

    if (filters.success) {
      loadFilters.success = filters.success.join(",");
    }

    return Tasks.list(loadFilters, pagination, order);
  };

  columns = () => {
    const { t } = this.props;

    let dataColumns = [
      {
        title: t("tasks_page:columns:task"),
        dataIndex: "task",
        key: "task",
        sorter: true,
      },
      {
        title: t("tasks_page:columns:received_at"),
        dataIndex: "received_at",
        key: "received_at",
        sorter: true,
        render: (timestamp) => formatToLocal(timestamp, t("formats:iso_date_with_time")),
      },
      {
        title: t("tasks_page:columns:waiting_time"),
        dataIndex: "waiting_time",
        key: "waiting_time",
      },
      {
        title: t("tasks_page:columns:execution_time"),
        dataIndex: "execution_time",
        key: "execution_time",
      },
      {
        title: t("tasks_page:columns:is_success"),
        dataIndex: "success",
        key: "success",
        render: (text, record) => {
          return (
            <div
              className={[
                styles.dashboard__icon,
                record.success ? styles.dashboard__icons__success : styles.dashboard__icons__fail,
              ].join(" ")}
            />
          );
        },
      },
    ];

    const actionColumns = [
      {
        title: t("rooms_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions(t),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("tasks_page:empty_message");
  }

  pageTitle() {
    const { property } = this.state;
    const { routes } = this.props;

    const propertyTitle = property ? (
      <Link to={pathBuilder(routes.userAppRoutes.properties.edit, { propertyId: property.id })}>
        {property.title}
      </Link>
    ) : null;

    return propertyTitle;
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { tableQuery } = this.state;
    const { propertyId, routes } = this.props;

    const basePath = pathBuilder(routes.userAppRoutes.tasks, { propertyId });
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  render() {
    const { data, t } = this.props;
    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <Media query="(max-width: 619px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 620px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 719px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 720px) and (max-width: 799px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 800px)" onChange={this.handleMediaChange(0)} />

        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <PageHeader
            title={t("tasks_page:header")}
            subTitle={this.pageTitle()}
          />
          <div style={{ height: "100%" }}>
            <CrudTableWithFilters
              data={data}
              columns={this.columns}
              emptyMessage={this.emptyMessage()}
              showCreateMessage={false}
              defaultOrder={{ received_at: "desc" }}
              onTableQueryChange={this.handleTableQueryChange}
              loadData={this.loadData}
              filters={{
                task: {
                  type: "select",
                  options: taskOptions,
                  placeholder: "Task",
                  maxWidth: 320,
                },
                success: {
                  type: "select",
                  options: resultOptions,
                  placeholder: "Result",
                  maxWidth: 220,
                },
                received_at: {
                  type: "dateRange",
                  maxWidth: 380,
                },
              }}
            />
          </div>
        </div>

        <Outlet context={{ closePath }} />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ tasks }, { params }) => {
  const { propertyId } = params;
  const { entities } = tasks;

  return {
    data: entities ? Object.values(entities) : null,
    propertyId,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(TasksPage)));
