import merge from "lodash/merge";

const deepmerge = (...args) => merge({}, ...args);

class LokexBackend {
  init(_services, options, _allOptions) {
    this.options = options;
  }

  read(language, _namespace) {
    return this.loadTranslations({ language });
  }

  async loadTranslations({ language }) {
    const {
      urls,
      defaultTranslation,
      onLoadFailure = () => {},
    } = this.options;

    const translationPromises = urls.map((url) => this.fetchTranslation(url, language));
    const results = await Promise.allSettled(translationPromises);

    let resultTranslation = { ...defaultTranslation };

    results.forEach((result) => {
      if (result.status === "fulfilled") {
        resultTranslation = deepmerge(resultTranslation, result.value);
      }

      if (result.status === "rejected") {
        onLoadFailure(result.reason);
      }
    });

    return resultTranslation;
  }

  async fetchTranslation(urlTemplate, lng) {
    const response = await fetch(urlTemplate.replace("{{lng}}", lng));

    if (!response.ok) {
      throw new Error(`Failed to load translations for ${lng} from ${urlTemplate}`);
    }

    return response.json();
  }
}

LokexBackend.type = "backend";

export { LokexBackend };
