//eslint-disable-next-line
import "./sentry";
import "./analytics";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import { App as AntApp, ConfigProvider } from "antd";
import { initI18n } from "i18n/i18n";
import store from "store";

import { theme } from "config/theme";
import App from "./app";
import { queryClient } from "./query_client";

const { storage } = store;

const container = document.getElementById("root");
const root = createRoot(container);

function EnvSpecificReactMode({ children }) {
  // in local development need to omit react strict mode because of
  // https://github.com/google-map-react/google-map-react/issues/1116
  if (import.meta.env.DEV) {
    return children;
  }

  return (
    <React.StrictMode>
      {children}
    </React.StrictMode>
  );
}

initI18n.then(() => {
  root.render(
    <React.Suspense fallback="Loading...">
      <EnvSpecificReactMode>
        <QueryClientProvider client={queryClient}>
          <Provider store={storage}>
            <ConfigProvider theme={theme}>
              <AntApp>
                <App />
              </AntApp>
            </ConfigProvider>
          </Provider>
        </QueryClientProvider>
      </EnvSpecificReactMode>
    </React.Suspense>,
  );
});
