import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import LiveFeedEventView from "components/live_feed_event_view";

export default function LiveFeedEventDrawer() {
  const { t } = useTranslation();
  const { eventId } = useParams();

  return (
    <ChannexDrawerRoutable title={t("live_feed_event_drawer:header", { id: eventId })}>
      {({ handleCloseFromContent }) => (
        <LiveFeedEventView id={eventId} onClose={handleCloseFromContent} />
      )}
    </ChannexDrawerRoutable>
  );
}
