import React from "react";
import { useTranslation } from "react-i18next";
import { ChannelsProvider } from "store/channels_provider";
import { useUserChannel } from "store/use_user_channel";

import { MewsImportWizard } from "pages/applications_page/mews/import/mews_import_wizard";

import * as Page from "components/page";
import { PageHeader } from "components/page";

export function MewsImportPage() {
  const { t } = useTranslation();
  const userChannel = useUserChannel();

  return (
    <Page.Main>
      <Page.Content>
        <PageHeader title={t("mews:headings:import")} />
        <ChannelsProvider channels={{ userChannel }}>
          <MewsImportWizard />
        </ChannelsProvider>
      </Page.Content>
    </Page.Main>
  );
}
