export const STORAGE_CACHE_KEY = "CHANNEX_BL_CACHE";
export const SESSION_ADD = "SESSION_ADD";
export const CHOOSE_PROPERTY = "CHOOSE_PROPERTY";
export const CHOOSE_GROUP = "CHOOSE_GROUP";
export const GROUPS_LOAD_OPTIONS = "GROUPS_LOAD_OPTIONS";
export const SET_APP_MODE = "SET_APP_MODE";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_UI_SETTINGS = "SET_UI_SETTINGS";
export const SET_ACTIVE_FEATURE_FLAGS = "SET_ACTIVE_FEATURE_FLAGS";
export const SET_ALLOWED_CHANNELS = "SET_ACTIVE_FEATURE_FLAGS";

export const PROPERTIES_LOAD = "PROPERTIES_LOAD";
export const PROPERTIES_ADD = "PROPERTIES_ADD";
export const PROPERTIES_DROP = "PROPERTIES_DROP";
export const PROPERTIES_OPTIONS_LOAD = "PROPERTIES_OPTIONS_LOAD";
export const PROPERTIES_OPTIONS_ADD = "PROPERTIES_OPTIONS_ADD";

export const PROPERTIES_HEALTH_LOAD = "PROPERTIES_HEALTH_LOAD";

export const ROOM_TYPES_LOAD = "ROOM_TYPES_LOAD";
export const ROOM_TYPES_ADD = "ROOM_TYPES_ADD";
export const ROOM_TYPES_DROP = "ROOM_TYPES_DROP";

export const RATE_CATEGORIES_LOAD = "RATE_CATEGORIES_LOAD";
export const RATE_CATEGORIES_ADD = "RATE_CATEGORIES_ADD";
export const RATE_CATEGORIES_DROP = "RATE_CATEGORIES_DROP";

export const RATE_PLANS_LOAD = "RATE_PLANS_LOAD";
export const RATE_PLANS_ADD = "RATE_PLANS_ADD";
export const RATE_PLANS_DROP = "RATE_PLANS_DROP";

export const CHANNELS_LOAD = "CHANNELS_LOAD";
export const CHANNELS_ADD = "CHANNELS_ADD";
export const CHANNELS_DROP = "CHANNELS_DROP";
export const CHANNELS_HEALTH_LOAD = "CHANNELS_HEALTH_LOAD";
export const CHANNELS_ACTIVATE = "CHANNELS_ACTIVATE";
export const CHANNELS_DEACTIVATE = "CHANNELS_DEACTIVATE";

export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";

export const ADMIN_USER_ADD = "ADMIN_USER_ADD";
export const ADMIN_USER_UPDATE = "ADMIN_USER_UPDATE";
export const ADMIN_USERS_LOAD = "ADMIN_USERS_LOAD";
export const ADMIN_USERS_ADD = "ADMIN_USERS_ADD";
export const ADMIN_USERS_DROP = "ADMIN_USERS_DROP";

export const ADMIN_PROPERTIES_LOAD = "ADMIN_PROPERTIES_LOAD";
export const ADMIN_PROPERTIES_ADD = "ADMIN_PROPERTIES_ADD";
export const ADMIN_PROPERTIES_DROP = "ADMIN_PROPERTIES_DROP";

export const ADMIN_BILLING_ACCOUNTS_LOAD = "ADMIN_BILLING_ACCOUNTS_LOAD";
export const ADMIN_BILLING_ACCOUNTS_ADD = "ADMIN_BILLING_ACCOUNTS_ADD";
export const ADMIN_BILLING_ACCOUNTS_DROP = "ADMIN_BILLING_ACCOUNTS_DROP";

export const WHITE_LABEL_PARTNERS_LOAD = "WHITE_LABEL_PARTNERS_LOAD";
export const WHITE_LABEL_PARTNERS_ADD = "WHITE_LABEL_PARTNERS_ADD";
export const WHITE_LABEL_PARTNERS_DROP = "WHITE_LABEL_PARTNERS_DROP";

export const WHITE_LABEL_DOMAINS_LOAD = "WHITE_LABEL_DOMAINS_LOAD";
export const WHITE_LABEL_DOMAINS_ADD = "WHITE_LABEL_DOMAINS_ADD";
export const WHITE_LABEL_DOMAINS_DROP = "WHITE_LABEL_DOMAINS_DROP";

export const WHITE_LABEL_EMAIL_SETTINGS_LOAD = "WHITE_LABEL_EMAIL_SETTINGS_LOAD";
export const WHITE_LABEL_EMAIL_SETTINGS_ADD = "WHITE_LABEL_EMAIL_SETTINGS_ADD";
export const WHITE_LABEL_EMAIL_SETTINGS_DROP = "WHITE_LABEL_EMAIL_SETTINGS_DROP";

export const EMAIL_TEMPLATES_LOAD = "EMAIL_TEMPLATES_LOAD";
export const EMAIL_TEMPLATES_ADD = "EMAIL_TEMPLATES_ADD";
export const EMAIL_TEMPLATES_DROP = "EMAIL_TEMPLATES_DROP";

export const TASKS_LOAD = "TASKS_LOAD";

export const TAXES_LOAD = "TAXES_LOAD";
export const TAXES_ADD = "TAXES_ADD";
export const TAXES_DROP = "TAXES_DROP";

export const TAX_SETS_LOAD = "TAX_SETS_LOAD";
export const TAX_SETS_ADD = "TAX_SETS_ADD";
export const TAX_SETS_DROP = "TAX_SETS_DROP";

export const GROUPS_LOAD = "GROUPS_LOAD";
export const GROUPS_ADD = "GROUPS_ADD";
export const GROUPS_DROP = "GROUPS_DROP";

export const CHANNEL_EVENTS_LOAD = "CHANNEL_EVENTS_LOAD";
export const BOOKINGS_LOAD = "BOOKINGS_LOAD";
export const BOOKING_STATS_LOAD = "BOOKING_STATS_LOAD";
export const BOOKINGS_ADD = "BOOKINGS_ADD";

export const HOTEL_POLICIES_LOAD = "HOTEL_POLICIES_LOAD";
export const HOTEL_POLICIES_ADD = "HOTEL_POLICIES_ADD";
export const HOTEL_POLICIES_DROP = "HOTEL_POLICIES_DROP";

export const CANCELLATION_POLICIES_LOAD = "CANCELLATION_POLICIES_LOAD";
export const CANCELLATION_POLICIES_ADD = "CANCELLATION_POLICIES_ADD";
export const CANCELLATION_POLICIES_DROP = "CANCELLATION_POLICIES_DROP";

export const LIVE_FEED_EVENTS_LOAD = "LIVE_FEED_EVENTS_LOAD";
export const LIVE_FEED_EVENT_ADD = "LIVE_FEED_EVENT_ADD";
export const LIVE_FEED_EVENTS_RESET = "LIVE_FEED_EVENTS_RESET";
export const LIVE_FEED_SET_REQUEST_ID = "LIVE_FEED_SET_REQUEST_ID";

export const CHANNEL_MESSAGES_THREADS_LOAD = "CHANNEL_MESSAGES_THREADS_LOAD";
export const CHANNEL_MESSAGES_THREAD_CLOSE = "CHANNEL_MESSAGES_THREAD_CLOSE";
export const CHANNEL_MESSAGES_THREAD_OPEN = "CHANNEL_MESSAGES_THREAD_OPEN";
export const CHANNEL_MESSAGES_RESET_THREADS = "CHANNEL_MESSAGES_RESET_THREADS";
export const CHANNEL_MESSAGES_ADD_THREAD = "CHANNEL_MESSAGES_ADD_THREAD";

export const CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE = "CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE";
export const CHANNEL_MESSAGES_LOAD = "CHANNEL_MESSAGES_LOAD";
export const CHANNEL_MESSAGES_ADD = "CHANNEL_MESSAGES_ADD";
export const CHANNEL_MESSAGES_RESET = "CHANNEL_MESSAGES_RESET";
export const CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID = "CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID";

export const APPLICATIONS_LOAD_INSTALLED = "APPLICATIONS_LOAD_INSTALLED";
export const APPLICATIONS_ADD_INSTALLED = "APPLICATIONS_ADD_INSTALLED";
export const APPLICATIONS_REMOVE_INSTALLED = "APPLICATIONS_REMOVE_INSTALLED";

export const WEBHOOKS_ADD = "WEBHOOKS_ADD";
export const WEBHOOKS_DROP = "WEBHOOKS_DROP";
export const WEBHOOKS_LOAD = "WEBHOOKS_LOAD";

export const WEBHOOK_LOGS_LOAD = "WEBHOOK_LOGS_LOAD";

export const REVIEWS_ADD = "REVIEWS_ADD";
export const REVIEWS_LOAD = "REVIEWS_LOAD";
export const REVIEWS_SET_REQUEST_ID = "REVIEWS_SET_REQUEST_ID";
export const REVIEWS_RESET = "REVIEWS_RESET";

export const SCORES_ADD = "SCORES_ADD";

export const PCI_LOGS_LOAD = "PCI_LOGS_LOAD";
