import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router";
import PropTypes from "prop-types";
import { DownOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Modal, notification, Tag } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import AssignBillingAccountModal from "./components/assign_billing_account_modal";
import EditDrawer from "./edit_drawer";

import ErrorImage from "static/too-many-properties.svg";
import usersSubPageStyles from "./users_sub_page.module.css";
import generalStyles from "styles/general.module.css";

const { AdminUsers } = store;
const DROPDOWN_TRIGGER = ["click"];
const confirm = Modal.confirm;

class UsersSubPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
    tableQuery: "",
    error: null,
    assignBillingAccountModal: null,
    editDrawer: {
      visible: false,
      userId: null,
    },
  };

  tableRef = React.createRef();

  actions = (_text, record) => {
    const { t } = this.props;

    const items = [{
      key: "users_sub_page_actions_edit",
      onClick: this.onEdit(record.id),
      label: (
        <div>
          {t("general:action:edit")}
        </div>
      ),
    }];

    if (record.is_api_key_allowed === true) {
      items.push({
        key: "users_sub_page_actions_disallow_api_key",
        onClick: this.onDisallowAPIKey(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:disallow_api_key")}
          </div>
        ),
      });
    } else {
      items.push({
        key: "users_sub_page_actions_allow_api_key",
        onClick: this.onAllowAPIKey(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:allow_api_key")}
          </div>
        ),
      });
    }

    if ((!record.settings || !!record.settings?.allow_edit_admin_fields === false)) {
      items.push({
        key: "users_sub_page_actions_allow_google_fields",
        onClick: this.onAllowAdminFieldsAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:allow_google_fields")}
          </div>
        ),
      });
    }

    if (record.settings?.allow_edit_admin_fields === true) {
      items.push({
        key: "users_sub_page_actions_disallow_google_fields",
        onClick: this.onDisallowAdminFieldsAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:disallow_google_fields")}
          </div>
        ),
      });
    }

    if ((!record.settings || !!record.settings?.allow_airbnb_rate_plans === false)) {
      items.push({
        key: "users_sub_page_actions_allow_airbnb_rate_plans",
        onClick: this.onAllowAirbnbRatePlansAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:allow_airbnb_rate_plans")}
          </div>
        ),
      });
    }

    if (record.settings?.allow_airbnb_rate_plans === true) {
      items.push({
        key: "users_sub_page_actions_disallow_airbnb_rate_plans",
        onClick: this.onDisallowAirbnbRatePlansAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:disallow_airbnb_rate_plans")}
          </div>
        ),
      });
    }

    if ((!record.settings || !!record.settings?.allow_apaleo_reserva_import === false)) {
      items.push({
        key: "users_sub_page_actions_allow_apaleo_reserva_import",
        onClick: this.onAllowApaleoReservaImportAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:allow_apaleo_reserva_import")}
          </div>
        ),
      });
    }

    if (record.settings?.allow_apaleo_reserva_import === true) {
      items.push({
        key: "users_sub_page_actions_disallow_apaleo_reserva_import",
        onClick: this.onDisallowApaleoReservaImportAccess(record.id),
        label: (
          <div>
            {t("users_sub_page:actions:disallow_apaleo_reserva_import")}
          </div>
        ),
      });
    }

    items.push({
      key: "users_sub_page_actions_mark_as_removed",
      onClick: this.onMarkAsRemoved(record.id),
      label: (
        <div>
          {t("users_sub_page:actions:mark_as_removed")}
        </div>
      ),
    });

    items.push({
      key: "users_sub_page_actions_assign_billing_account",
      onClick: this.onAssignBillingAccount(record),
      label: (
        <div>
          {t("users_sub_page:actions:assign_billing_account")}
        </div>
      ),
    });

    items.push({
      key: "users_sub_page_actions_send_reset_password",
      onClick: this.onSendResetPassword(record),
      label: (
        <div>
          {t("users_sub_page:actions:send_reset_password")}
        </div>
      ),
    });

    if (record.status !== "blocked") {
      items.push({
        key: "users_sub_page_actions_block_user",
        onClick: this.onBlockUser(record),
        label: (
          <div>
            {t("users_sub_page:actions:block_user")}
          </div>
        ),
      });
    }

    if (record.status === "blocked") {
      items.push({
        key: "users_sub_page_actions_unblock_user",
        onClick: this.onUnblockUser(record),
        label: (
          <div>
            {t("users_sub_page:actions:unblock_user")}
          </div>
        ),
      });
    }

    return (
      <Dropdown
        menu={{ items }}
        trigger={DROPDOWN_TRIGGER}
      >
        <a
          data-testid="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(event) => event.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  loadData = (query, pagination, order) => {
    const filter = { email: { has: query } };

    return AdminUsers.list(filter, pagination, order).then(
      (response) => response,
      (error) => {
        this.setState({ error: error.errors.code });
        return error;
      },
    );
  };

  onEdit = (id) => {
    return () => {
      this.setState({
        editDrawer: {
          visible: true,
          userId: id,
        },
      });
    };
  };

  onAllowAPIKey = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:allow_api_key_dialog:title"),
        content: t("general:allow_api_key_dialog:description"),
        onOk: () => {
          AdminUsers.allowAPIKey(id).catch(this.onError);
        },
      });
    };
  };

  onDisallowAPIKey = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:disallow_api_key_dialog:title"),
        content: t("general:disallow_api_key_dialog:description"),
        onOk: () => {
          AdminUsers.disallowAPIKey(id).catch(this.onError);
        },
      });
    };
  };

  onAllowAdminFieldsAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:allow_admin_field_access_dialog:title"),
        content: t("general:allow_admin_field_access_dialog:description"),
        onOk: () => {
          AdminUsers.allowAdminFieldsAccess(id).catch(this.onError);
        },
      });
    };
  };

  onDisallowAdminFieldsAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:disallow_admin_field_access_dialog:title"),
        content: t("general:disallow_admin_field_access_dialog:description"),
        onOk: () => {
          AdminUsers.disallowAdminFieldsAccess(id).catch(this.onError);
        },
      });
    };
  };

  onAllowAirbnbRatePlansAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:allow_airbnb_rate_plans_access_dialog:title"),
        content: t("general:allow_airbnb_rate_plans_access_dialog:description"),
        onOk: () => {
          AdminUsers.allowAirbnbRatePlansAccess(id).catch(this.onError);
        },
      });
    };
  };

  onDisallowAirbnbRatePlansAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:disallow_airbnb_rate_plans_access_dialog:title"),
        content: t("general:disallow_airbnb_rate_plans_access_dialog:description"),
        onOk: () => {
          AdminUsers.disallowAirbnbRatePlansAccess(id).catch(this.onError);
        },
      });
    };
  };

  onAllowApaleoReservaImportAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:allow_apaleo_reserva_import_access_dialog:title"),
        content: t("general:allow_apaleo_reserva_import_access_dialog:description"),
        onOk: () => {
          AdminUsers.allowApaleoReservaImportAccess(id).catch(this.onError);
        },
      });
    };
  };

  onDisallowApaleoReservaImportAccess = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:disallow_apaleo_reserva_import_access_dialog:title"),
        content: t("general:disallow_apaleo_reserva_import_access_dialog:description"),
        onOk: () => {
          AdminUsers.disallowApaleoReservaImportAccess(id).catch(this.onError);
        },
      });
    };
  };

  onMarkAsRemoved = (id) => {
    return () => {
      const { t } = this.props;

      confirm({
        title: t("general:mark_as_removed_dialog:title"),
        content: t("general:mark_as_removed_dialog:description"),
        onOk: () => {
          AdminUsers.markAsRemoved(id).catch(this.onError);
        },
      });
    };
  };

  onAssignBillingAccount = (record) => {
    return () => {
      this.setState({
        assignBillingAccountModal: {
          userId: record.id,
          billingAccountId: record.billing_account_id,
          role: record.relationships?.billing_account?.data?.attributes?.role,
        },
      });
    };
  };

  onCloseAssignBillingAccount = () => {
    return () => {
      this.setState({
        assignBillingAccountModal: null,
      });
    };
  };

  onSaveAssignBillingAccount = () => {
    return (data) => {
      AdminUsers.assignBillingAccount(data.userId, data.billingAccountId, data.role).then(() => {
        this.setState({
          assignBillingAccountModal: null,
        });
      });
    };
  };

  onSendResetPassword = (record) => {
    return () => {
      const { t } = this.props;

      AdminUsers.sendResetPassword(record.id).then(
        (_response) => notification.success({
          message: t("users_sub_page:notifications:send_reset_password:header"),
          description: t("users_sub_page:notifications:send_reset_password:success"),
        }),
        (_error) => {
          notification.error({
            message: t("users_sub_page:notifications:send_reset_password:header"),
            description: t("common:errors:request_failed"),
          });
        },
      );
    };
  };

  onBlockUser = (record) => {
    return () => {
      const { t } = this.props;

      AdminUsers.block(record.id).then(
        (_response) => notification.success({
          message: t("users_sub_page:notifications:block_user:header"),
          description: t("users_sub_page:notifications:block_user:success"),
        }),
        (_error) => {
          notification.error({
            message: t("users_sub_page:notifications:block_user:header"),
            description: t("common:errors:request_failed"),
          });
        },
      );
    };
  };

  onUnblockUser = (record) => {
    return () => {
      const { t } = this.props;

      AdminUsers.unblock(record.id).then(
        (_response) => notification.success({
          message: t("users_sub_page:notifications:unblock_user:header"),
          description: t("users_sub_page:notifications:unblock_user:success"),
        }),
        (_error) => {
          notification.error({
            message: t("users_sub_page:notifications:unblock_user:header"),
            description: t("common:errors:request_failed"),
          });
        },
      );
    };
  };

  columns = () => {
    const { t, routes } = this.props;

    let dataColumns = [
      {
        title: t("users_sub_page:columns:email"),
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (value, entity) => {
          return (
            <>
              {value}
              {entity.system_role === "admin" ? (
                <>
                  &nbsp;<Tag>Admin</Tag>
                </>
              ) : null}
            </>
          );
        },
      },
      {
        title: t("users_sub_page:columns:name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: t("users_sub_page:columns:status"),
        dataIndex: "status",
        key: "status",
        sorter: true,
      },
      {
        title: t("users_sub_page:columns:owned_properties"),
        dataIndex: "owned_properties_count",
        key: "owned_properties_count",
        sorter: true,
      },
      {
        title: t("users_sub_page:columns:accessible_properties"),
        dataIndex: "accessible_properties_count",
        key: "accessible_properties_count",
        sorter: false,
      },
      {
        title: t("users_sub_page:columns:billing_account"),
        dataIndex: "billing_account.company_name",
        key: "billing_account_company_name",
        sorter: true,
        render: (_value, entity) => {
          let value = null;
          const billing_account = entity.relationships?.billing_account?.data?.attributes;
          if (billing_account) {
            const role = billing_account.role;
            const roleRepresentation = t(
              `users_sub_page:assign_billing_account_form:role:options:${role}`,
            );
            value = (
              <Link
                to={pathBuilder(routes.userAppRoutes.admin.users.billing_account_edit, {
                  billingAccountId: billing_account.id,
                })}
                data-testid="crud_admin_billing_account_edit_action"
              >
                {billing_account.company_name} ({roleRepresentation})
              </Link>
            );
          }

          return value;
        },
      },
      {
        title: t("users_sub_page:columns:api_access"),
        dataIndex: "is_api_key_allowed",
        key: "is_api_key_allowed",
        sorter: true,
        render: (value) => {
          return <Badge status={value === true ? "success" : "error"} />;
        },
      },
    ];

    const actionColumns = [
      {
        className: usersSubPageStyles.columnActions,
        title: t("users_sub_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("bookings_page:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { routes } = this.props;
    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.admin.users);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  handleCloseEditDrawer = () => {
    this.setState({
      editDrawer: {
        visible: false,
        userId: null,
      },
    });
  };

  render() {
    const { t, routes, data } = this.props;
    const closePath = this.getClosePath();
    const { error, assignBillingAccountModal, editDrawer } = this.state;

    if (error) {
      return <Page.ErrorMessage text={t(`users_sub_page:errors:${error}`)} icon={ErrorImage} />;
    }

    return (
      <>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable.Container>
          <CRUDTable
            data={data}
            componentRef={this.tableRef}
            emptyMessage={this.emptyMessage()}
            showCreateMessage={false}
            onTablePramsChange={this.handleTableQueryChange}
            columns={this.columns}
            loadData={this.loadData}
            createLink={pathBuilder(routes.userAppRoutes.admin.users.invite)}
          />
        </CRUDTable.Container>

        <Outlet context={{ closePath }} />

        <EditDrawer visible={editDrawer.visible} userId={editDrawer.userId} onClose={this.handleCloseEditDrawer} />

        {assignBillingAccountModal && (
          <AssignBillingAccountModal
            userId={assignBillingAccountModal.userId}
            billingAccountId={assignBillingAccountModal.billingAccountId}
            role={assignBillingAccountModal.role}
            onSave={this.onSaveAssignBillingAccount()}
            onClose={this.onCloseAssignBillingAccount()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ adminUsers }) => {
  const { entities } = adminUsers || { entities: null };

  return {
    data: entities ? Object.values(entities) : null,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(UsersSubPage)));
