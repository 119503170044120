import { convertToSnakeCase } from "../../utils/case_converter";
import { parseResponse } from "../_utils/parse_response";
import { httpClient } from "../http_client";

const ENDPOINT = "admin/user_request_limits";

export const adminUserRequestLimitsClient = {
  async list(filter = {}, pagination = { page: 1, limit: 10 }, order) {
    const response = await httpClient.send("GET", ENDPOINT, { filter, pagination, order });

    return parseResponse(response);
  },

  async create(params) {
    const body = convertToSnakeCase({
      userRequestLimit: params,
    });

    const response = await httpClient.send("POST", ENDPOINT, body);

    return parseResponse(response);
  },

  async update({ id, params }) {
    const body = convertToSnakeCase({
      userRequestLimit: params,
    });

    const path = `${ENDPOINT}/${id}`;

    const response = await httpClient.send("PUT", path, body);

    return parseResponse(response);
  },

  async delete(id) {
    const response = await httpClient.send("DELETE", `${ENDPOINT}/${id}`);

    return parseResponse(response);
  },

  async enable(id) {
    const response = await httpClient.send("POST", `${ENDPOINT}/${id}/enable`);

    return parseResponse(response);
  },

  async disable(id) {
    const response = await httpClient.send("POST", `${ENDPOINT}/${id}/disable`);

    return parseResponse(response);
  },
};
