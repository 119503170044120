import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ChannexDrawer } from "components/_v2/channex_drawer";

import { MultiStepListingForm } from "./multi_step_listing_form";

export const ManageListingDrawer = ({ listing, visible, onClose }) => {
  const { t } = useTranslation();

  const title = listing ? t("listings:headings:update_listing") : t("listings:headings:create_listing");

  const { channelId } = useParams();

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <MultiStepListingForm
        channelId={channelId}
        listing={listing}
        onFinish={onClose}
      />
    </ChannexDrawer>
  );
};
