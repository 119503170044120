import React from "react";
import { useFormContext } from "react-hook-form";
import { Flex } from "antd";

import { NextButton } from "components/_v2/forms/buttons/next";
import { PrevButton } from "components/_v2/forms/buttons/prev";
import { AppForm } from "components/_v2/forms/hook_form";
import { ScrollBox } from "components/_v2/layout/scroll_box";

import { Rooms } from "../../common/rooms";
import { useWizard, WizardSidebar } from "../wizard";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ roomTypes, ratePlans, multiOccRatePlans }) => {
  const { goPrev } = useWizard();

  return (
    <ScrollBox
      dataTestid="rooms-form"
      content={
        <Rooms layout="embedded" name="rooms" roomTypes={roomTypes} ratePlans={ratePlans} multiOccRatePlans={multiOccRatePlans} />
      }
      sidebar={
        <WizardSidebar />
      }
      footer={
        <Flex style={{ width: "100%" }} gap={8}>
          <PrevButton onClick={goPrev} />
          <NextButton />
        </Flex>
      }
    />
  );
};

export const Step3 = ({ roomTypes, ratePlans, multiOccRatePlans }) => {
  const { goNext } = useWizard();
  const { setValue, getValues, formState } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner roomTypes={roomTypes} ratePlans={ratePlans} multiOccRatePlans={multiOccRatePlans} />
    </AppForm>
  );
};
