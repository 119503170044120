import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import currencyOptions from "config/constants/currency_options";
import { validationMessages } from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

class GeneralForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().trim().required(validationMessages.required),
    currency: yup.string().required(validationMessages.required),
  });

  DEFAULT_VALUE = {
    title: "",
  };

  static FIELDS = ["title", "currency"];

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={GeneralForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormInput
              name="title"
              view="horizontal"
              placeholder={t("policies:guarantee_payment:cancellation_policy_title")}
              label={t("policies:guarantee_payment:cancellation_policy_title")}
            />
            <FormikFormSelect
              name="currency"
              view="horizontal"
              placeholder={t("policies:general_policy:currency")}
              label={t("policies:general_policy:currency")}
              options={currencyOptions()}
            />
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(GeneralForm);
