import { createContext, ReactNode, useContext } from "react";

export type EventHandler = (payload: any) => void;
export type ErrorHandler = (error: any) => void;

export type Channel = {
  join: () => Channel;
  leave: () => Channel;
  on: (eventName: string, handler: EventHandler) => Channel;
  off: (eventName: string, handler: EventHandler) => Channel;
  onError: (handler: ErrorHandler) => Channel;
}

type Channels = {
  userChannel?: Channel
}

const ChannelContext = createContext<Channels>({});

type Props = {
  children: ReactNode;
  channels: Channels
}

export const ChannelsProvider = ({ children, channels }: Props) => {
  return (
    <ChannelContext.Provider value={channels}>
      {children}
    </ChannelContext.Provider>
  );
};

export const useUserChannel = (): Channel => {
  const channels = useContext(ChannelContext);
  if (!channels.userChannel) {
    throw new Error("useUserChannel must be used within a ChannelProvider with userChannel");
  }

  return channels.userChannel;
};
