import { useEffect } from "react";
import { useUserChannel } from "store/use_user_channel";
import relationshipsExtractor from "store/utils/relationships_extractor";

import EventEmitter from "utils/event_emitter";

// TODO: this hook should be separated to own components which uses needed events with own subscription to channel events
export default function useUserChannelSubscription() {
  const userChannel = useUserChannel();

  useEffect(() => {
    userChannel.on("property_created", (payload) => {
      const newProperty = relationshipsExtractor(payload.data);

      EventEmitter.trigger("property:created", newProperty);
    });

    userChannel.on("property_updated", (payload) => {
      const updatedProperty = relationshipsExtractor(payload.data);

      EventEmitter.trigger("property:updated", updatedProperty);
    });

    userChannel.on("property_deleted", (payload) => {
      EventEmitter.trigger("property:deleted", payload.id);
    });

    userChannel.on("live_feed_event_created", (payload) => EventEmitter.trigger("live_feed_event:created", payload.data));

    userChannel.on("bookings_export_started", (payload) => {
      EventEmitter.trigger("bookings_export_started", payload);
    });

    userChannel.on("bookings_export_progress", (payload) => {
      EventEmitter.trigger("bookings_export_progress", payload);
    });

    userChannel.on("bookings_export_complete", (payload) => {
      EventEmitter.trigger("bookings_export_complete", payload);
    });

    userChannel.on("bookings_export_failed", (payload) => {
      EventEmitter.trigger("bookings_export_failed", payload);
    });

    userChannel.on("pdf_completed", (payload) => {
      EventEmitter.trigger("pdf_completed", payload);
    });

    userChannel.on("pdf_failed", (payload) => {
      EventEmitter.trigger("pdf_failed", payload);
    });

    return () => {
      userChannel.off("property_created");
      userChannel.off("property_updated");
      userChannel.off("property_deleted");
      userChannel.off("live_feed_event_created");
      userChannel.off("bookings_export_started");
      userChannel.off("bookings_export_progress");
      userChannel.off("bookings_export_complete");
      userChannel.off("bookings_export_failed");
      userChannel.off("pdf_completed");
      userChannel.off("pdf_failed");
    };
  }, [userChannel]);
}
