import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import compose from "recompose/compose";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

class SmokingPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    smoking_policy: yup.string().required(validationMessages.required),
  });

  DEFAULT_VALUE = {
    smoking_policy: "no_smoking",
  };

  static FIELDS = ["smoking_policy"];

  inputRefs = {
    smoking_policy: React.createRef(),
  };

  componentDidMount() {
    const { focusField } = this.props;

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  getSmokingPolicyOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "no_smoking",
        representation: t("policies:smoking_policy:options:no_smoking"),
      },
      {
        value: "permitted_areas_only",
        representation: t("policies:smoking_policy:options:permitted_areas_only"),
      },
      {
        value: "allowed",
        representation: t("policies:smoking_policy:options:allowed"),
      },
    ];
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={SmokingPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormSelect
              name="smoking_policy"
              inputRef={this.inputRefs.smoking_policy}
              view="horizontal"
              placeholder={t("policies:smoking_policy:title")}
              label={t("policies:smoking_policy:title")}
              options={this.getSmokingPolicyOptions()}
            />
          </Form>
        )}
      </ChannexForm>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(SmokingPolicyForm);
