import React from "react";
import PropTypes from "prop-types";
import { Col, Radio, Row } from "antd";

import styles from "styles/form_in_drawer.module.css";

const layout = { xs: { span: 24 }, sm: { span: 16, offset: 8 } };

function handleChangeCallback(callback, field) {
  return (event) => {
    callback(field, event.target.value);
  };
}

function InputRateMode(props) {
  const { t, model, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "is_derived");
  const isDisabled = Boolean(model.rate_category_id) || Boolean(model.id) || disabled;

  return (
    <Row>
      <Col {...layout}>
        <Radio.Group
          value={model.is_derived}
          onChange={onHandleChange}
          className={styles.buttonGroup}
          disabled={isDisabled}
        >
          <Radio.Button className={styles.buttonGroup__button__1_2} value={false}>
            <span data-testid="radio_group_rate_type_manual">
              {t("rates_page:form:rate_type_manual")}
            </span>
          </Radio.Button>
          <Radio.Button className={styles.buttonGroup__button__1_2} value>
            <span data-testid="radio_group_rate_type_derived">
              {t("rates_page:form:rate_type_derived")}
            </span>
          </Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  );
}

InputRateMode.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputRateMode;
