import React, { useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "antd";
import store from "store";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/form_input";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

const { Integrations: { Tripla } } = store;

const FIELD_LAYOUT = {
  span: 24,
};

function PropertySearchForm(props, ref) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    username: yup.string().trim().required(validationMessages.required),
    password: yup.string().trim().required(validationMessages.required),
    hotelCode: yup.string().trim().required(validationMessages.required),
    mappingId: yup.string().trim().required(validationMessages.required),
    publicKey: yup.string().trim().required(validationMessages.required),
    secretKey: yup.string().trim().required(validationMessages.required),
  });

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submit = (values) => {
    return Tripla.findProperty({
      hotelCode: values.hotelCode,
      mappingId: values.mappingId,
      username: values.username,
      password: values.password,
      publicKey: values.publicKey,
      secretKey: values.secretKey,
    })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, ["username", "password", "hotelCode", "mappingId", "publicKey", "secretKey"]);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, { message: value });
        });

        throw error;
      });
  };

  useImperativeHandle(ref, () => ({
    proceed: async () => {
      clearErrors();

      const result = await new Promise((resolve, reject) => {
        handleSubmit(async (data) => {
          try {
            const submitResult = await submit(data);
            resolve(submitResult);
          } catch (e) {
            reject(e);
          }
        }, reject)();
      });

      return { triplaProperty: result };
    },
  }));

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors.global} />

        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  label={t("applications_page:tripla_import:username")}
                  errors={errors?.username?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  type="password"
                  label={t("applications_page:tripla_import:password")}
                  errors={errors?.password?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="publicKey"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  label={t("applications_page:tripla_import:public_key")}
                  errors={errors?.publicKey?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="secretKey"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  label={t("applications_page:tripla_import:secret_key")}
                  errors={errors?.secretKey?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="hotelCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  label={t("applications_page:tripla_import:hotel_code")}
                  errors={errors?.hotelCode?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col {...FIELD_LAYOUT}>
            <Controller
              name="mappingId"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  label={t("applications_page:tripla_import:mapping_id")}
                  errors={errors?.mappingId?.message}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default React.forwardRef(PropertySearchForm);
