import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import FULL_RESTRICTIONS from "config/constants/inventory/full_restrictions";

import { useAppForm } from "components/_v2/forms/hook_form";
import { FormDatePicker } from "components/forms/inputs/hook_form/form_datepicker";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { RatePlanSelect } from "components/forms/inputs/hook_form/rate_plan_select";
import { RoomTypeSelect } from "components/forms/inputs/hook_form/room_type_select";

const toFormValue = (value, ratePlans) => {
  const formValue = { ...value };

  if (value.ratePlanId) {
    const ratePlan = ratePlans.find((r) => r.id === value.ratePlanId);
    formValue.roomTypeId = ratePlan?.room_type_id;
  }

  return formValue;
};

export const FiltersForm = ({ defaultValue, ratePlans, isLoadingRatePlans, roomTypes, isLoadingRoomTypes, isLoadingData, onChange }) => {
  const { t } = useTranslation();

  const { control, setValue, watch } = useAppForm({
    defaultValue: toFormValue(defaultValue, ratePlans),
    fieldNames: ["date", "restriction", "roomTypeId", "ratePlanId"],
    submitHandler: onChange,
  });

  const date = watch("date");
  const restriction = watch("restriction");
  const roomTypeId = watch("roomTypeId");
  const ratePlanId = watch("ratePlanId");

  const isRateRestriction = restriction !== "availability";

  useEffect(() => {
    if (isRateRestriction) {
      onChange({ date, restriction, ratePlanId });
    } else {
      onChange({ date, restriction, roomTypeId });
    }
  }, [date, restriction, roomTypeId, ratePlanId, isRateRestriction, onChange]);

  const roomRatePlans = ratePlans.filter((r) => r.room_type_id === roomTypeId);

  return (
    <div style={{ padding: "0 24px" }}>
      <FormDatePicker
        name="date"
        control={control}
        style={{ width: "100%" }}
        label={t("general:date")}
        disabled={isLoadingData}
      />
      <FormSelect
        control={control}
        name="restriction"
        label={t("inventory_page:restriction")}
        disabled={isLoadingData}
        options={
          FULL_RESTRICTIONS.map((r) => ({
            label: t(`general:restrictions:${r}`),
            value: r,
          }))
            .sort((a, b) => a.label.localeCompare(b.label))
        }
      />
      <RoomTypeSelect
        control={control}
        name="roomTypeId"
        roomTypes={roomTypes}
        isLoading={isLoadingRoomTypes}
        isDisabled={isLoadingData}
        onChange={(val) => {
          setValue("roomTypeId", val);
          setValue("ratePlanId", null);
        }}
      />
      {isRateRestriction && (
        <RatePlanSelect
          control={control}
          name="ratePlanId"
          ratePlans={roomRatePlans}
          isLoading={isLoadingRatePlans}
          isDisabled={isLoadingData}
        />
      )}
    </div>
  );
};
