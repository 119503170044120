import { useQuery } from "@tanstack/react-query";
import store from "store";

const { AdminWlDomains } = store;
export function useAdminWlDomains(whiteLabelPartnerId, tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  const filter = {
    wlPartnerId: whiteLabelPartnerId,
  };

  if (searchQuery) {
    filter.domain = { has: searchQuery };
  }

  return useQuery({
    queryKey: ["admin_wl_domains", whiteLabelPartnerId, tableParams],
    queryFn: () => AdminWlDomains.list(filter, pagination, order),
    enabled: !!whiteLabelPartnerId && !!tableParams,
    retry: false,
  });
}
