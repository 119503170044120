import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import { validationMessages } from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormCheckbox from "components/forms/inputs/formik/form_checkbox";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import RadioInput from "components/forms/inputs/formik/radio";
import InputGroup from "components/forms/inputs/input_group/input_group";

const DECIMAL_PART_LENGTH = 2;
const MAX_PERCENT_VALUE = 100;

class CancellationPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    after_reservation_cancellation_logic: yup.string().required(validationMessages.required),
    cancellation_policy_logic: yup.string().required(validationMessages.required),
    cancellation_policy_deadline: yup
      .number(validationMessages.number)
      .nullable()
      .when("cancellation_policy_logic", {
        is: "deadline",
        then: () => yup
          .number()
          .positive(validationMessages.positive)
          .integer(validationMessages.integer)
          .required(validationMessages.required)
          .typeError(validationMessages.number),
      }),
    cancellation_policy_deadline_type: yup.string().required(validationMessages.required),
    cancellation_policy_mode: yup.string().required(validationMessages.required),
    cancellation_policy_penalty: yup
      .number()
      .nullable()
      .when("cancellation_policy_logic", {
        is: "deadline",
        then: () => yup
          .number()
          .required(validationMessages.required)
          .typeError(validationMessages.number)
          .when("cancellation_policy_mode", {
            is: "percent",
            then: () => yup
              .number()
              .positive(validationMessages.positive)
              .max(MAX_PERCENT_VALUE, validationMessages.lessOrEqual(MAX_PERCENT_VALUE))
              .test("isFloat", validationMessages.maxDecimalPlaces(DECIMAL_PART_LENGTH), (value = 0) => {
                const [, decimalPart = ""] = value.toString().split(".");
                return decimalPart.length <= DECIMAL_PART_LENGTH;
              })
              .typeError(validationMessages.number),
            otherwise: () => yup
              .number()
              .positive(validationMessages.positive)
              .integer(validationMessages.integer)
              .typeError(validationMessages.number),
          }),
      }),
  });

  DEFAULT_VALUE = {
    cancellation_policy_logic: "free",
    cancellation_policy_deadline_type: "days",
    cancellation_policy_mode: "percent",
  };

  static FIELDS = [
    "after_reservation_cancellation_logic",
    "after_reservation_cancellation_amount",
    "allow_deadline_based_logic",
    "cancellation_policy_logic",
    "cancellation_policy_deadline",
    "cancellation_policy_deadline_type",
    "cancellation_policy_mode",
    "cancellation_policy_penalty",
  ];

  getCancellationTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "non_refundable",
        representation: t("policies:cancellation_policy:type_options:non_refundable"),
      },
      {
        value: "deadline",
        representation: t("policies:cancellation_policy:type_options:deadline_based"),
      },
    ];
  };

  getAfterReservationCancellationTypeOptions = (_value) => {
    const { t } = this.props;

    return [
      {
        value: "free",
        representation: t("policies:after_reservation_cancellation_policy:type_options:free"),
      },
      {
        value: "non_refundable",
        representation: t("policies:after_reservation_cancellation_policy:type_options:non_refundable"),
      },
      {
        value: "guarantee_amount",
        representation: t("policies:after_reservation_cancellation_policy:type_options:guarantee_amount"),
      },
      {
        value: "nights_based",
        representation: t("policies:after_reservation_cancellation_policy:type_options:nights_based"),
      },
      {
        value: "percent_based",
        representation: t("policies:after_reservation_cancellation_policy:type_options:percent_based"),
      },
    ];
  };

  getDeadlineOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "days",
        representation: t("policies:cancellation_policy:deadline_options:days"),
      },
      {
        value: "hours",
        representation: t("policies:cancellation_policy:deadline_options:hours"),
      },
    ];
  };

  getModeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "percent",
        representation: t("policies:cancellation_policy:mode_options:percent"),
      },
      {
        value: "nights",
        representation: t("policies:cancellation_policy:mode_options:nights"),
      },
    ];
  };

  isCancellationDeadlineBased = ({ cancellation_policy_logic }) => {
    return cancellation_policy_logic && cancellation_policy_logic === "deadline";
  };

  shouldShowAfterReservationCancellationAmount = ({ after_reservation_cancellation_logic }) => {
    return after_reservation_cancellation_logic && (
      after_reservation_cancellation_logic === "nights_based" || after_reservation_cancellation_logic === "percent_based"
    );
  };

  isDeadlinePolicyAllowed = (value) => {
    return value.allow_deadline_based_logic && value.after_reservation_cancellation_logic !== "non_refundable";
  };

  isDeadlinePolicyDisabled = (value) => {
    return value.after_reservation_cancellation_logic === "non_refundable";
  };

  onAllowDeadlineBasedLogicChange = (value) => {
    if (value) {
      this.props.onChange({
        allow_deadline_based_logic: true,
        cancellation_policy_logic: "non_refundable",
        cancellation_policy_deadline: null,
        cancellation_policy_deadline_type: "days",
      });
    } else {
      this.props.onChange({
        allow_deadline_based_logic: false,
        cancellation_policy_logic: "free",
        cancellation_policy_deadline: null,
        cancellation_policy_deadline_type: "days",
      });
    }
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={CancellationPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <legend>{t("policies:after_reservation_cancellation_logic:legend")}</legend>
            <FormikFormSelect
              name="after_reservation_cancellation_logic"
              view="horizontal"
              placeholder={t("policies:after_reservation_cancellation_logic:type_label")}
              label={t("policies:after_reservation_cancellation_logic:type_label")}
              options={this.getAfterReservationCancellationTypeOptions(value)}
            />
            {this.shouldShowAfterReservationCancellationAmount(value) && (
              <FormikFormInput
                name="after_reservation_cancellation_amount"
                view="horizontal"
                placeholder={t("policies:after_reservation_cancellation_amount:label")}
                label={t("policies:after_reservation_cancellation_amount:label")}
              />
            )}

            <legend>{t("policies:cancellation_policy:legend")}</legend>
            <FormikFormCheckbox
              name="allow_deadline_based_logic"
              label={t("policies:cancellation_policy:allowed_label")}
              onChange={this.onAllowDeadlineBasedLogicChange}
              disabled={this.isDeadlinePolicyDisabled(value)}
            />

            {this.isDeadlinePolicyAllowed(value) && (
              <>
                <InputGroup
                  label={t("policies:cancellation_policy:deadline_label")}
                >
                  <FormikFormInput
                    grouped
                    groupMain
                    name="cancellation_policy_deadline"
                    view="horizontal"
                    placeholder={t("policies:cancellation_policy:deadline_label")}
                  />
                  <FormikFormSelect
                    grouped
                    showSearch={false}
                    name="cancellation_policy_deadline_type"
                    view="horizontal"
                    placeholder={t("policies:cancellation_policy:deadline_options_label")}
                    options={this.getDeadlineOptions()}
                  />
                </InputGroup>
                <FormikFormSelect
                  name="cancellation_policy_logic"
                  view="horizontal"
                  placeholder={t("policies:cancellation_policy:type_label")}
                  label={t("policies:cancellation_policy:type_label")}
                  options={this.getCancellationTypeOptions()}
                />

                {this.isCancellationDeadlineBased(value) && (
                  <>
                    <RadioInput
                      name="cancellation_policy_mode"
                      label={t("policies:cancellation_policy:mode_label")}
                      options={this.getModeOptions()}
                    />
                    <FormikFormInput
                      name="cancellation_policy_penalty"
                      view="horizontal"
                      placeholder={t("policies:cancellation_policy:penalty_label")}
                      label={t("policies:cancellation_policy:penalty_label")}
                    />
                  </>
                )}
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(CancellationPolicyForm);
